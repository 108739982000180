import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { informasiList } from "./repository";
import pagination from "./pagination";
import SearchBox from "./SearchBox";
import Button from "react-bootstrap/Button";
import "./table.css";
import Delete from "../Delete";
import { FaRegEdit } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsStopwatch } from "react-icons/bs";
import { getaddon } from "../../Api/addonsApi";
import { getaddonbyid } from "../../Api/addonsApi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SITE_URL } from "../../Api/config";
import { updateaddon } from "../../Api/addonsApi";
import { deleteaddonbyid } from "../../Api/addonsApi";
import { activestatusaddon } from "../../Api/addonsApi";
import { deactivetestatusaddon } from "../../Api/addonsApi";
import { getServices } from "../../Api/addonsApi";
import { Roller } from "react-awesome-spinners";

const Informasi = () => {

  const [showError, setShowError] = useState(false)
  const [show003, setShow003] = useState(false);
  const handleClose003 = () => setShow003(false);
  const handleShow003 = () => setShow003(true);
  const [selected, setSelected] = useState([]);
  const [addons, setAddons] = useState([]);
  const [searchtext, setSearchText] = useState("");
  const [addonImagePreview, setAddonImagePreview] = useState("");
  const [addonimage, setAddonimage] = useState(null);
  const [Hours, setHours] = useState(0);
  const [Minutes, setMinutes] = useState(0);
  const [services, setServices] = useState([]);

  const [loading, setLoading] = useState(false);

  const [editaddon, setEditAddon] = useState({
    id: "",
    addon_service_name: "",
    base_price: "",
    extra_minute_per_unit: "",
    extra_minute_price: "",
    sercives_of_this_addon: "",
    price_for_per_extra_unit: "",
    image: null,
    base_duration: "",
  });

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditAddon((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    })); 
  };

  const handleEditServiceImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEditAddon((prevUserData) => ({
        ...prevUserData,
        image: file,
      }));
      setAddonImagePreview(reader.result);
    };
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (searchtext === "") {
      searchtext = " ";
    } else {
      searchtext = e.target.value;
    }
    setLoading(true)
    getaddon(searchtext)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false)
          const apiResponse = res.data;
          setAddons(apiResponse.data.addonsList); 
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const AddonsList = () => {
    setLoading(true)
    getaddon(searchtext)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false) 
          const apiResponse = res.data;
          setAddons(apiResponse.data.addonsList); 
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    setLoading(true);
    setLoading(true);
    AddonsList();
    getservices();

    setTimeout(() => {
      setLoading(false);
    }
      , 3000)
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    AddonsList();
  }, [searchtext]);

  const getdataaddon = (id) => {
    handleShow003();

    const formData = new FormData();
    formData.append("addon_id", id);
    getaddonbyid(formData)
      .then((res) => {
        if (res.code === 200) { 
          const apiResponse = res.data; 
          setAddonImagePreview(apiResponse.data.image);
          setEditAddon(apiResponse.data);
          setHours(getHours(apiResponse.data.base_duration));
          setMinutes(getMinutes(apiResponse.data.base_duration));
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const UpdateAddondata = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("addon_id", editaddon.id);
    formData.append("addon_title", editaddon.addon_service_name);
    formData.append("base_price_for_first_unit", editaddon.base_price);
    formData.append("price_for_per_extra_unit", editaddon.extra_minute_price);
    formData.append("extra_minute_per_unit", editaddon.extra_minute_per_unit);
    formData.append("sercives_of_this_addon", editaddon.sercives_of_this_addon);
    formData.append(
      "price_for_per_extra_unit",
      editaddon.price_for_per_extra_unit
    );
    formData.append("image", addonimage);
    formData.append("base_hours", Hours);
    formData.append("base_minutes", Minutes);

    if (editaddon.addon_service_name == '' ||
      editaddon.extra_minute_per_unit == "" ||
      editaddon.extra_minute_price == "" ||
      editaddon.sercives_of_this_addon == "" ||
      editaddon.price_for_per_extra_unit == ""
    ) {
      setShowError(true)

    } else {
      updateaddon(formData)
        .then((res) => {
          if (res.code === 200) {
            if (res.data.status === "success") { 
              AddonsList();
              handleClose003();
              setLoading(false);
            } else {
              setLoading(false);
              alert("data not updated");
            }
          } else {
            setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }


    // updateaddon(formData)
    //   .then((res) => {
    //     if (res.code === 200) {
    //       if (res.data.status === "success") {
    //         console.log("res", res);
    //         AddonsList();
    //         handleClose003();
    //         setLoading(false);
    //       } else {
    //         setLoading(false);
    //         alert("data not updated");
    //       }
    //     } else {
    //       setLoading(false);
    //       alert("Something went wrong");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };
  const getHours = (base_duration) => {
    return Math.floor(base_duration / 60);
  };

  const getMinutes = (base_duration) => {
    return base_duration % 60;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAddonimage(file); 
  };

  const handleActive = (id) => {
    if (window.confirm("Are you sure you wish to enable this item?")) {
      const formData = new FormData();
      formData.append("addon_id", id);
      activestatusaddon(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "success") {
            alert("Addon is activated");
          } else {
            alert("Addon is not activated");
          }
          AddonsList();
        } else {
          alert("Something went wrong");
        }
      });
    }
  };

  const handleDeactive = (id) => {
    if (window.confirm("Are you sure you wish to disable this item?")) {
      const formData = new FormData();
      formData.append("addon_id", id);
      deactivetestatusaddon(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "success") {
            alert("Addon is deactive");
          } else {
            alert("Addon is not deactive");
          }
          AddonsList();
        } else {
          alert("Something went wrong");
        }
      });
    }
  };


  const optionFormatter2 = (cell, row) => {
    return (
      <div className="button-group">
        {row.status === "D" ? (
          <Button
            className="Enable-Active-bttn"
            onClick={(id) => handleActive(row.id, window.event)}
          >
            Enable
          </Button>
        ) : (
          <Button
            className="Enable-Active-bttn"
            onClick={(id) => handleDeactive(row.id, window.event)}
          >
            Disable
          </Button>
        )}
      </div>
    );
  };

  const optionFormatter3 = (cell, row) => {
    return (
      <>
        <div className="button-group">
          <Button
            className="Delete-Active-bttn me-2"
            onClick={() => {
              deleteaddon(row.id);
            }}
          >
            <RiDeleteBin6Line />
          </Button>
          <Button
            className="Edit-Active-bttn"
            onClick={(e) => {
              getdataaddon(row.id);
            }}
          >
            <FaRegEdit />
          </Button>
        </div>
      </>
    );
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.id]);
    } else {
      setSelected(selected.filter((x) => x !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      // formatter: priceFormatter,
      sort: true,
    },
    {
      dataField: "addonservicename",
      text: "ADDON NAME",
      // formatter: priceFormatter,
      sort: true,
    },
    {
      dataField: "servicename",
      text: "SERVICES",
      sort: true,
    },
    {
      dataField: "baseprice",
      text: "PRICING",
      sort: true,
    },
    {
      text: "STATUS",
      isDummy: true,
      formatter: optionFormatter2,
    },
    {
      text: "ACTION",
      isDummy: true,
      formatter: optionFormatter3,
    },
  ];

  
  const deleteaddon = (id) => {
    if (window.confirm("Are you sure you want to delete this addon?")) {
      setLoading(true);
      const formData = new FormData();
      formData.append("addon_id", id);
      deleteaddonbyid(formData)
        .then((res) => {
          if (res.code === 200) {
            if (res.data.status === "success") { 
              setLoading(false);
              AddonsList();
            } else {
              setLoading(false);
              alert("data not deleted");
            }
          } else {
            setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((err) => {
          setLoading(false); 
        });
    }
  };

  function priceFormatter(cell, row) {
    return (
      <div className="d-flex align-items-center priceFormatterp">
        <div className="priceFormatterp-img me-4">
          <img src={cell.src} className="img-fluid" />
        </div>
        <p className="mb-0">{cell.name}</p>
      </div>
    );
  }

  const getservices = () => {
    // setLoading(true);
    getServices().then((res) => {
      if (res.code === 200) {
        setServices(res.data.data);
        // setLoading(false);
      }
      else {
        // setLoading(false);
        alert("Something went wrong");
      }
    }).catch((err) => {
      console.log("err", err);
      // setLoading(false);
    });
  };





  return (
    <div>
      {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (<>
          {/* Create Addon modal start here */}
          <Modal
            show={show003}
            onHide={handleClose003}
            className="Sidebar-Modal-end"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Addon</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custum-modal-body">
              <form id="Edit Addon">
                <div className="row">
                  <div className="col-md-12">
                    <div className="logo-content">
                      <div className="add-logo">
                        <div className="image-preview">
                          {addonImagePreview && (
                            <img
                              src={SITE_URL + "uploads/photo/" + addonImagePreview}
                              alt="Profile Preview"
                              className="profile-img"
                            />
                          )}
                        </div>
                        <div className="mt-2">
                          <div className="form-group block-upload">
                            <div className="upload-logo">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="customFile"
                                  alt="Choose"
                                  onChange={handleFileChange}
                                  required
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Choose
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                      <Form.Label>Addon Title</Form.Label>
                      <Form.Control
                        type="text"
                        value={editaddon.addon_service_name || ""}
                        name="addon_service_name"
                        onChange={handleEditInputChange}
                        placeholder="" 
                      />
                      {showError && editaddon.addon_service_name == '' ? <p style={{ color: 'red' }}>field is are required</p> : ''}
                      <Form.Control
                        type="hidden"
                        value={editaddon.id || ""}
                        name="id"
                        onChange={handleEditInputChange}
                        placeholder=""
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Current Base Duration</Form.Label>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-group">
                            <span className="input-group-text">
                              <BsStopwatch />
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              value={getHours(editaddon.base_duration) || ""}
                              placeholder="0"
                            />
                            <span className="input-group-text">Hours</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              value={getMinutes(editaddon.base_duration) || ""}
                              placeholder="0"
                            />
                            <span className="input-group-text">Minutes</span>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>New Base Duration</Form.Label>
                      <div className="row">
                        <div className="col-md-6"> 
                          <div className="input-group">
                            <span className="input-group-text">
                              <BsStopwatch />
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              placeholder=""
                              value={Hours}
                              onChange={(e) => {
                                setHours(e.target.value);
                              }}
                            />
                            <span className="input-group-text">Hours</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder=""
                              value={Minutes}
                              onChange={(e) => {
                                setMinutes(e.target.value);
                              }}
                            />
                            <span className="input-group-text">Minutes</span>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Extra Minute Per Unit</Form.Label>
                      <Form.Control
                        type="text"
                        value={editaddon.extra_minute_per_unit || ""}
                        name="extra_minute_per_unit"
                        onChange={handleEditInputChange}
                        placeholder="0"
                      />
                      {showError && editaddon.extra_minute_per_unit == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Sercives Of This Addon</Form.Label>
                      {/* <Form.Control
                      //   type="text"
                      //   value={editaddon.sercives_of_this_addon || ""}
                      //   name="sercives_of_this_addon"
                      //   onChange={handleEditInputChange}
                      //   placeholder="Cleaning"
                      // /> */}

                      <select className="form-control" name="sercives_of_this_addon" onChange={handleEditInputChange} value={editaddon.sercives_of_this_addon || ""}>
                        <option value="">Select Service</option>
                        {services.map((item, index) => (
                          <option key={index} value={item.id}>{item.title}</option>
                        ))}
                      </select>

                      {showError && editaddon.sercives_of_this_addon == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Base Price For First Unit</Form.Label>
                      <Form.Control
                        type="text"
                        value={editaddon.base_price || ""}
                        name="base_price"
                        onChange={handleEditInputChange}
                        placeholder="0"
                      />
                      {showError && editaddon.base_price == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Price For Per Extra Unit</Form.Label>
                      <Form.Control
                        type="text"
                        value={editaddon.price_for_per_extra_unit || ""}
                        name="price_for_per_extra_unit"
                        onChange={handleEditInputChange}
                        placeholder="0"
                      />
                      {showError && editaddon.price_for_per_extra_unit == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                    </Form.Group>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button className="Active-bttn btn" onClick={UpdateAddondata}>
                {" "}
                Update{" "}
              </button>
              <button className="Cancel-bttn btn" onClick={handleClose003}>
                {" "}
                Cancel{" "}
              </button>
            </Modal.Footer>
          </Modal>
          {/* modal End here */}

          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-flex table-card-header">
                    <Form.Group>
                      <div className="d-flex">
                        <div addonType="prepend"></div>
                        <Form.Control
                          type="text"
                          placeholder="Keywords..."
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="card-body">
                    <BootstrapTable
                      bootstrap4
                      keyField="id"
                      data={addons}
                      columns={columns}
                      // selectRow={selectRow}
                      pagination={pagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>)
      }
    </div>
  );
};

export default Informasi;
