import React from "react";
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import Usercomment from "./camponent/Testimonials/Usercomment";
import Testimonial from "./camponent/Testimonials/Testimonials";
import Profile from './assets/Images/profile.jpg';
import './assets/css/Service.css'
import Header2 from "./camponent/header/Header2";
import { Helmet } from 'react-helmet';

function CleaningTip() {


    const generateMetaTags = () => {
        const metaTags = [
            { name: "title", content: `Greenfrog Cleaning Tip` },
            { name: "description", content: `Greenfrog Cleaning Tip` },
            { property: "og:title", content: `Greenfrog Cleaning Tip` },
            { property: "og:description", content: `Greenfrog Cleaning Tip` },
            { property: "og:url", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@greenfrog" },
            { name: "twitter:title", content: `Greenfrog Cleaning Tip` },
            { name: "twitter:description", content: `Greenfrog Cleaning Tip` },
        ];

        metaTags.push({ property: "og:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        metaTags.push({ name: "twitter:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        // if (og_image) {
        // }

        return metaTags;
    };

    return (
        <>
            <div>
                <Helmet>
                    {generateMetaTags().map((tag, index) => (
                        <meta key={index} {...tag} />
                    ))}
                </Helmet>
            </div>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="cleaningtip-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>House Cleaning <span className='bold-title'><span className='bold-title bg-color'>Tips</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">How to Clean and Disinfect Your Home’s Most Touched Areas</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Germs can be found in almost every area of our homes. While this might be normal, there are high-touch areas in our homes that are covered in thousands of bacteria that can easily spread diseases or cause infections to us and our families. That is why it’s very important to disinfect our homes regularly, especially the areas we touch the most every day.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Working Table</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Your working table is where you usually place your phone, keys, and wallets. According to research, the average working table has 100x more bacteria than a kitchen table, and 400x more than the average toilet seat.</p>
                                        <p>Spray a disinfectant on the desk or tabletop. Next, use wipes or a damp fabric to clean the whole surface. You may clean with any DIY alcohol solution or distilled bleach solution as it eliminates germs and bacteria.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Switchboards</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Switchboards are another often touched area in your house. They are frequently touched as people enter or leave a room, therefore by the end of the day, you can guarantee that the surfaces will be covered in bacteria.</p>
                                        <p>Get a soft clean cloth and spray some disinfectant solution on it. Then, wipe the switches one by one, covering all the sides. REMINDER: Avoid spraying extra solution on the switchboard and make sure no liquid gets into the sockets.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Kitchen Counters</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>As you prepare and cook food on your countertop everyday, it’s easy to transfer Salmonella and E.coli from raw food to its surfaces and your other kitchen equipment.</p>
                                        <p>Wipe off the countertops with a disinfectant-soaked cloth or wipes. Remember to wipe the whole surface, including the cabinet handles, which you may come into contact with while preparing raw food.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Phones</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>According to Seattle Times journalist Bobby Caina Calvan, your phone is covered in germs, with 25,127 bacteria per square inch. This easily makes it one of the dirtiest things in your home.</p>
                                        <p>To keep it as clean as possible, use a wipeable or washable cover on the phone and wipe it frequently. Make sure to use an alcohol-based spray solution or wipes with at least 70% alcohol, and allow the surface to dry properly before touching it.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Remote Controls</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>If you’re like the majority of people, you probably don’t keep your hands off the air conditioner or the TV remote. Other people at your house or business are likely to do the same.</p>
                                        <p>There is a significant risk of bacteria spreading from one area to another, so to reduce infection risks, you need to disinfect all remote controls on a daily basis. Use disinfectant wipes or homemade disinfectant solution with alcohol to clean the remotes effectively.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">CONCLUSION</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Germs and bacteria are present almost everywhere, especially in areas and the things we touch most frequently every day. By following the cleaning tips we shared in this blog post, you can reduce the risks of spreading germs and diseases and make sure that you and your family stay safe and healthy at home.</p>
                                    </div>
                                </div>

                                {/* testimonial section start here  */}
                                <section className="custom-section">
                                    <div className="container">
                                        <Testimonial sectiontitle={'Testimonials'} sectiondescription={'Find out why Green Frog Cleaning is San Diego’s favorite cleaning company...'} />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Usercomment imagesrc={Profile} username={'Andrea L.'} userdiscription1={'We’ve been with Green Frog for a while and have been happy with our housekeeping services. I found them on Yelp and they responded through the app to my message. They always respond to my emails and answer the phone. '} heighlight={'The staff here is awesome!'} />
                                            </div>
                                            <div className="col-md-6">
                                                <Usercomment imagesrc={Profile} username={'Sarah B.'} heighlight={'I love Green Frog! '} userdiscription2={' I can not believe how thorough the initial cleaning was. It was fantastic. I have had my house cleaned by a few different services - no one has cleaned as well as Green Frog. I love that they use natural products, too.'} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* testimonial section end here  */}
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default CleaningTip;