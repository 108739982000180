import React, { useState, useEffect } from "react";
import { Roller } from "react-awesome-spinners";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Form, Button, Modal } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { RiAddFill } from "react-icons/ri";
import { addblogcategory } from "../Api/blogsapi";
import { getblogcategory } from "../Api/blogsapi";
import { editblogcategory } from "../Api/blogsapi";

// Custom Components
import Sidebar from "../Components/Sidebar/Sidebar";

// Your informasiList data
// import { informasiList } from "./yourInformasiListFile";

// Styles or other utility imports go here

const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true
};

const pagination = paginationFactory(options);

function BlogCategory() {
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);



    
    const handleSearch = (e) => {
        console.log(e.target);
        // Add your search logic here
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [categoryname, setcategoryname] = useState("");
    const [categorydescription, setcategorydescription] = useState("");

    const [blogcategorydata, setblogcategorydata] = useState([]);

    const [editcategoryname, seteditcategoryname] = useState("");
    const [editcategorydescription, seteditcategorydescription] = useState("");
    const [editcategoryid, seteditcategoryid] = useState("");


    
    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Edit-Active-bttn" onClick={() => {
                    handleShow1();
                    seteditcategoryname(row.name);
                    seteditcategorydescription(row.description);
                    seteditcategoryid(row.id);
                }
                }>
                    <FaRegEdit />
                </Button>
            </div>
        );
    }

    
    const columns = [
        { dataField: 'name', text: 'NAME', sort: true },
        { dataField: 'description', text: 'DESCRIPTION', sort: true },
        { text: 'ACTION', isDummy: true, formatter: optionFormatter }
    ];
    
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", categoryname);
        formData.append("description", categorydescription);


        addblogcategory(formData).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if(apiResponse.status === 1){
                    alert(apiResponse.message);
                    handleClose();
                    getblogcategorydata();
                }else{
                    alert(apiResponse.message);
                }
            } else {
                alert("Something went wrong");
            }
        });


    }

    const getblogcategorydata = () => {
        getblogcategory().then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if (apiResponse.status === 1) {
                    setblogcategorydata(apiResponse.data);
                } else {
                    alert(apiResponse.message);
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            alert(err.message);
        });
    }

    useEffect(() => {
        getblogcategorydata();
    },[]);

    const handleEditdata = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", editcategoryname);
        formData.append("description", editcategorydescription);
        formData.append("blog_category_id", editcategoryid);

        editblogcategory(formData).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if(apiResponse.status === 1){
                    alert("Category updated successfully");
                    handleClose1();
                    getblogcategorydata();
                }else{
                    alert(apiResponse.message);
                }
            } else {
                alert("Something went wrong");
            }
        })


        
    }



    return (
        <>
            <Modal show={show} onHide={handleClose} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setcategoryname(e.target.value)}
                                        className='custom-input' />
                                </Form.Group>
                            </div>
                            <div className="col-md-12">
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Description</Form.Label>
                                    <textarea className="form-control" onChange={(e) => setcategorydescription(e.target.value)} id="exampleFormControlTextarea1" rows="3"></textarea>
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="Active-bttn" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editcategoryname}
                                        onChange={(e) => seteditcategoryname(e.target.value)}
                                        className='custom-input' />
                                </Form.Group>
                            </div>
                            <div className="col-md-12">
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Description</Form.Label>
                                    <textarea className="form-control" 
                                     value={editcategorydescription}
                                     onChange={(e) => seteditcategorydescription(e.target.value)}
                                     id="exampleFormControlTextarea1" rows="3"></textarea>
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="Active-bttn" onClick={handleEditdata}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <div>
                {loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* ... Your other components */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fluid">
                                            <div className="">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header">
                                                            <h5 className="card-title">Blogs Category</h5>
                                                        </div>
                                                        <div className="table-card-body-inner">
                                                            <div className="animated fadeIn" >
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="card">
                                                                            <div className="card-header d-flex table-card-header">
                                                                                <Form.Group>
                                                                                    <div className='d-flex'>
                                                                                        <div addonType="prepend">
                                                                                        </div>
                                                                                        <Form.Control type="text" placeholder="Keywords..." onSearch={handleSearch} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                                <Button
                                                                                    onClick={handleShow}
                                                                                    className="btn btn-sm float-right add-user-btn"
                                                                                >
                                                                                    {" "}
                                                                                    <RiAddFill /> Add New Category
                                                                                </Button>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <BootstrapTable bootstrap4 keyField='id' data={blogcategorydata} noDataIndication="No data available" columns={columns} pagination={pagination} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}

export default BlogCategory;
