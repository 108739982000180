import React , { useContext } from "react"; 
import MasterForm from "./camponent/BookingForm/MasterForm";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from './camponent/header/Header';
import './assets/css/Booking.css'
import Totalpayment from "./camponent/Totalpayment/Payment";
import Header2 from "./camponent/header/Header2";
import { BookingProvider } from "./camponent/BookingForm/BookingContext";



function Booking() {
    
    return (
        <BookingProvider> 
        <div className="Booking">
            <div className="Header">
                <Header2 />
            </div>
            <section className="BookingForm booking-form-section">
                <div className="BookingForm">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-12">
                                <div className="booking-form">
                                    <MasterForm />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12">
                                <div className="booking-sidebar">
                                    <Totalpayment />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </BookingProvider>
    );
}

export default Booking;