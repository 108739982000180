import { useState, useEffect, createRef, useRef } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { PHASES_COLORS } from "./utils";
import 'react-notifications/lib/notifications.css';
import { ModalPopContext } from "./ModalPopContext";
import { useContext } from "react";
import { getbookings } from "../Api/bookingApi";
import { getStaff } from "../Api/bookingApi";
import { getBookingById } from "../Api/bookingApi";
import { updateBookingtime } from "../Api/bookingApi";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Roller } from "react-awesome-spinners";
import { Link } from "react-router-dom";
//const calendarComponentRef = useRef(null);


const ModeSelector = ({ current, onChange }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "30px",
      }}
    >
      <div className="fc fc-direction-ltr">
        <div className="fc-button-group">
          <button
            className={`fc-button fc-button-primary ${current === "day" ? "fc-button-active" : ""
              }`}
            type="button"
            onClick={() => onChange("day")}
          >
            Day
          </button>
          <button
            className={`fc-button fc-button-primary ${current === "week" ? "fc-button-active" : ""
              }`}
            type="button"
            onClick={() => onChange("week")}
          >
            week
          </button>
          <button
            className={`fc-button fc-button-primary ${current === "month" ? "fc-button-active" : ""
              }`}
            type="button"
            onClick={() => onChange("month")}
          >
            month
          </button>
          <Link to="/mapview" >
            <button
              className={`fc-button fc-button-primary ${current === "year" ? "fc-button-active" : ""
                }`}
              type="button"
            // onClick={() => onChange("year")}
            >
              Map View
            </button>
          </Link>
          <Link to="/technicianavailability" style={{ textDecoration: "none" }}>
            <button
              className={`fc-button fc-button-primary technicianavailability-btn ${current === "all" ? "fc-button-active" : ""
                }`}
              type="button"
            // onClick={() => onChange("all")}
            >
              Technician Availiblity
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Avatar = ({ image, label }) => {
  return (
    <div
      title={label}
      style={{
        backgroundSize: "cover",
        width: "20px",
        height: "20px",
        backgroundPosition: "top center",
        borderRadius: "50%",
        marginRight: "5px",
        backgroundImage: `url(${image})`,
      }}
    />
  );
};

const computeDurations = (mode) => {
  switch (mode) {
    case "week":
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ month: "long" }, { day: "2-digit" }],
        duration: { weeks: 1 },
      };
    case "2weeks":
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ month: "long" }, { day: "2-digit" }, { weekday: "long" }],
        duration: { weeks: 2 },
      };
    case "month":
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ day: "2-digit" }],
        duration: { months: 1 },
      };
    case "year":
      return {
        slotDuration: { months: 1 },
        slotLabelFormat: [{ month: "short" }, { day: "2-digit" }, { weekday: "long" }],
        duration: { years: 1 },
      };
    case "day":
      return {
        slotDuration: { minutes: 30 },
        slotLabelFormat: [{ hour: "2-digit" }],
        duration: { days: 1 },
      };
    default:
      return {};
  }
};

const Calendar = ({ campaigns, gotodate, zonesdata, eventcount, filterbooking }) => {

  
  const [mode, setMode] = useState("day");
  const [resources, setResources] = useState([]);
  const [staffList, setStaffList] = useState(null);
  const [stafflistoriginal, setSatffOriginale] = useState(null);
  const [newdate, setNewDate] = useState('');
  const [myBookings, setMyBookings] = useState([]);
  const [calType, setCalType] = useState("day");
  const [refreshKey, setRefreshKey] = useState(0); // State variable for refreshing
  const [loading, setLoading] = useState(false);

  const [drapdrop, setDrapdrop] = useState();


  const getStaffList = () => {
    setLoading(true);
    getStaff("0").then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setStaffList(apiResponse.data);
        setSatffOriginale(apiResponse.data);
      }
    })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      })
  };


  const bookingsList = async (date, type) => {
    // setLoading(true);
    getbookings(date, type).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setBooking(apiResponse.data);
      }
    })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    // bookingsList();
    getStaffList();
  }, []);






  // useEffect(() => {
  //   bookingsList(newdate, calType);
  // }, [dateData]);



  const handleEventAdd = (booking) => {
    setBooking([...booking, booking]);
  };

  // const handleEventResize = (booking) => {
  //   const index = booking.findIndex((e) => e.id === booking.event.id);
  //   const newbooking = [...booking];
  //   newbooking[index] = booking.event;
  //   setBooking(newbooking);
  // };



  const getBookingDetails = (id) => {
    // setLoading(true);
    const order_id = id;
    const formData = new FormData();
    formData.append("order_id", order_id);
    getBookingById(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        console.log("getBookingDetails", apiResponse.data);
        setBookingDetails(apiResponse.data);
      }
    }).catch((err) => {
      console.log(err);
      // setLoading(false);
    }).finally(() => {
      // setLoading(false);
      console.log("getBookingDetails", id);
      setShowBookingDeatils(true);
    })

  }


  const handleDatesSet = async (info) => {

    const startDate = moment(info.start).format("YYYY-MM-DD HH:mm:ss");
    const endDate = moment(info.end).format("YYYY-MM-DD HH:mm:ss");


    //formate date YYYY-MM-DD 
    const checkstartdate = moment(info.start).format("YYYY-MM-DD");
    const checkendenddate = moment(info.end).format("YYYY-MM-DD");

    //get month from date
    // console.log("checkstartdate", moment(checkstartdate).format("MM"));
    // console.log("checkendenddate", moment(checkendenddate).format("MM"));

    var type = "day";

    const startDate2 = moment(checkstartdate);
    const endDate2 = moment(checkendenddate);

    const daysDifference = endDate2.diff(startDate2, 'days');

    if (daysDifference === 1) {
      type = "day";
    }
    else if (daysDifference === 7) {
      type = "week";
    }
    else if (daysDifference > 7) {
      type = "month";
    }
    else {
      type = "day";
    }
    setCalType(type);
    setNewDate(checkstartdate.toString());

    bookingsList(startDate, type);
    let dateString = new Date(info.start);
    let timeString = moment(dateData).format("HH:mm:ss");

    // Parse the date string into a Date object
    let date = new Date(dateString);

    // Split the time string into components
    let timeParts = timeString.split(":");
    let hours = parseInt(timeParts[0], 10);
    let minutes = parseInt(timeParts[1], 10);
    let seconds = parseInt(timeParts[2], 10);

    // Set the hours, minutes, and seconds to the date object
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    // setDateData(new Date(info.start));
    setDateData(date);
  };


  const calendarRef = useRef();


  useEffect(() => {
    if (gotodate && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(gotodate);
    }
  }, [gotodate, calendarRef.current]);

  useEffect(() => {
    if (zonesdata && zonesdata !== null) {
      setStaffList(zonesdata);
    }
  }, [zonesdata, calendarRef.current]);


  useEffect(() => {
    // console.log("filterbooking", filterbooking.length)
    // console.log("staffList", stafflistoriginal)
    if (filterbooking && filterbooking !== null && filterbooking.length > 0) {
      const filteredStaffList = stafflistoriginal.filter(staff => {
        return filterbooking.some(filterItem => filterItem.value === staff.id);
      });

      setStaffList(filteredStaffList);
    }
    if (filterbooking.length == 0) {
      setStaffList(stafflistoriginal);
    }
  }, [filterbooking, calendarRef.current]);


  const [showNewBooking, setShowNewBooking, showBookingDetails, setShowBookingDeatils, bookingDetails, setBookingDetails, dateData, setDateData, booking, setBooking, staffId, setStaffId] = useContext(ModalPopContext);

  const handleEventDrop = (booking) => {

    setLoading(true);
    console.log("handleEventDrop", booking);
    var newResourceStaff = null;
    var oldResourceStaff = null;
    if (booking.newResource !== null && booking.oldResource !== null) {
      newResourceStaff = booking.newResource._resource.id;
      oldResourceStaff = booking.oldResource._resource.id;
    }
    const startdate = moment(booking.event.start).format("YYYY-MM-DD HH:mm:ss");
    const enddate = moment(booking.event.end).format("YYYY-MM-DD HH:mm:ss");
    const order_id = booking.event._def.extendedProps.order_id;


    const formData = new FormData();
    formData.append("order_id", order_id);
    formData.append("start_date", startdate);
    formData.append("end_date", enddate);
    formData.append("new_staffid", newResourceStaff);
    formData.append("old_staffid", oldResourceStaff);
    formData.append("id", booking.event._def.publicId);
    formData.append("title", booking.event._def.title);


    updateBookingtime(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        if (apiResponse.status === '1') {
          const newstartdate = moment(startdate).format("YYYY-MM-DD 00:00:00");
          bookingsList((newstartdate).toString(), "day");
        }
        else {
          NotificationManager.error(apiResponse.message, "Error");
        }
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      const newstartdate = moment(startdate).format("YYYY-MM-DD");
      setDrapdrop(newstartdate);
      setLoading(false);
      // setTimeout(() => {

      // }, 3000);

    });

  };


  useEffect(() => {
    if (drapdrop && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(drapdrop);
    }
  }, [drapdrop, calendarRef.current]);



  useEffect(() => {
    if (calendarRef.current) {

      calendarRef.current.getApi().refetchEvents();
    }
  }, [booking]);

  const { duration, slotDuration, slotLabelFormat } = computeDurations(mode);

  const eventBorderColor = 'black';

  const customDateFormatter = (dateInfo) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(dateInfo.date);
  };

  useEffect(() => {
    if (eventcount > 0) {
      bookingsList(newdate, calType);
      // if (calendarRef.current) {
      //   calendarRef.current.getApi().refetchEvents();
      // }
    }
  }, [eventcount]);


  const eventDidMount = ({ event, el }) => {
    const titleElement = el.querySelector('.fc-title');

    // Check if titleElement is not null before accessing its properties
    if (titleElement) {
      const timeDiff = event.end - event.start;
      const titleWidth = titleElement.offsetWidth;

      if (titleWidth > timeDiff) {
        el.style.width = timeDiff + 'px';
      }
    }
  };




  return (
    <>

      {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (
          <>
            <ModeSelector current={mode} onChange={setMode} />
            {staffList && staffList.length > 0 ? (
              <FullCalendar
                //key={refreshKey}
                height={"auto"}
                ref={calendarRef}
                plugins={[resourceTimelinePlugin, interactionPlugin, dayGridPlugin, timeGridPlugin]}
                editable={true}
                eventDidMount={eventDidMount}
                resourceAreaWidth="15%"
                contentHeight="150px"
                eventResourceEditable={true}
                initialView="resourceTimelineDay"
                datesSet={handleDatesSet}
                headerToolbar={{
                  left: "prev,next today",
                  // left: "today",
                  center: "title",
                  right: "",
                }}
                views={{
                  resourceTimelineDay: {
                    buttonText: 'timeline',
                  },
                  resourceTimeGridDay: {
                    buttonText: 'timeGrid',
                  }
                }}
                titleFormat={{
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  weekday: 'long',
                }}
                titleRangeSeparator={' - '}

                resourceAreaHeaderContent="Technicians"
                slotMinTime='06:00:00'
                slotMaxTime='20:00:00'
                resourcesInitiallyExpanded={true}
                // resourceOrder="from"
                resourceOrder={['2', '1']}
                slotDuration={slotDuration}
                slotLabelFormat={slotLabelFormat}
                duration={duration}
                resources={staffList}
                resourceLabelClassNames={(info) => {
                  return info.resource._resource.extendedProps.zone;
                }}
                resourceGroupField="zone_name"
                aspectRatio={1.6}
                eventContent={renderEventContent}
                events={booking}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  meridiem: false,
                }}
                eventBorderColor={eventBorderColor}
                dayHeaderContent={customDateFormatter}
                eventClick={
                  function (arg) {
                    if (arg.event.extendedProps.open_popup == false) {
                    }
                    else {
                      getBookingDetails(arg.event._def.extendedProps.order_id);
                    }
                    // getBookingDetails(arg.event.id);
                  }
                }
                // eventResourceEditable={false}
                eventAdd={handleEventAdd}
                // eventResize={handleEventResize}
                eventDrop={handleEventDrop}
                dateClick={function (arg) {
                  //check date is not past date 
                  console.log("arg.date=======>", arg.resource);
                  const today = moment().format("YYYY-MM-DD");
                  const selectedDate = moment(arg.date).format("YYYY-MM-DD");
                  if (moment(selectedDate).isBefore(today)) {
                    NotificationManager.error("You can not booking for past date", "Error");
                  }
                  else {
                    setShowNewBooking(true);
                    setDateData(new Date(arg.date));
                    setStaffId({
                      value: arg.resource.id,
                      label: arg.resource.title,
                      role: arg.resource.extendedProps.role,
                      service_ids: arg.resource.extendedProps.service_ids,
                    });
                  }
                }}
              />
            ) : (
              <div className="no-staff">No Staff Available</div>
            )}
            <NotificationContainer />
          </>
        )
      }
    </>
  );
};


export default Calendar;


function renderEventContent(eventInfo) {

  let eventContent = null;

  if (eventInfo.event._def.extendedProps.bookingstatus !== undefined) {
    if (eventInfo.event._def.extendedProps.bookingstatus === '0') {
      eventContent = (
        <>
          <b><i class="fas fa-shield-alt"></i>{eventInfo.event._def.title}</b>
        </>
      );
    } else if (eventInfo.event._def.extendedProps.bookingstatus === '1') {
      eventContent = (
        <>
          <b><i class="fas fa-desktop"></i>{eventInfo.event._def.title}</b>
        </>
      );
    } else if (eventInfo.event._def.extendedProps.bookingstatus === '2') {
      eventContent = (
        <>
          <b><i class="fas fa-mobile-alt"></i>{eventInfo.event._def.title}</b>
        </>
      );
    } else {
      // Default case if 'bookingstatus' has some other value
      eventContent = (
        <>
          <b>{eventInfo.event._def.title}</b>
          {/* Add content for other bookingstatus values */}
        </>
      );
    }
  } else {
    eventContent = <b>{eventInfo.event._def.title}</b>;
  }


  return eventContent;
}
