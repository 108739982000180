import React, { createContext, useState } from "react";

export const ModalPopContext = createContext();

export const ModalPopProvider = (props) => {
    const[technicianDetails , setTechnicianDetails] = useState([]);
    const[serviceList ,setServiceList] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [technicianAvailabilty,setTechnicianAvailibility] = useState([]);
    const [technicianOfftime, setTechnicianOfftime] = useState([]);
    const [technicianOffday, setTechnicianOffday] = useState([]);
    const currentYear = new Date().getFullYear();
    const [selectYear , setSelectYear] = useState(currentYear);
    const [technicianofftimerequest, setTechnicianofftimerequest] = useState([]);
    


  return (
    <ModalPopContext.Provider value={[technicianDetails, setTechnicianDetails,serviceList ,setServiceList,services, setServices,selectedOptions, setSelectedOptions,technicianAvailabilty,setTechnicianAvailibility,technicianOfftime, setTechnicianOfftime,technicianOffday, setTechnicianOffday,selectYear , setSelectYear,technicianofftimerequest, setTechnicianofftimerequest]}>
      {props.children}
    </ModalPopContext.Provider>
  );
};



