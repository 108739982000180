import React, { useState,useEffect } from "react";
import Informasi from "../Components/ServicepriceTable/Informasi";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Services.css";
import { RiAddFill } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsStopwatch } from "react-icons/bs";
import { StoreSubService } from "../Api/servicesApi";
import { getservicesdatabyid } from "../Api/servicesApi";

function Pricing() {

  const [show09, setShow09] = useState(false);
  const [showError, setShowError] = useState(false)

  

  const [serviceData, setServiceData] = useState([]);
  const [addSubService, setAddSubService] = useState([]);
  const [addHours, setAddHours] = useState([]);
  const [addMinutes, setAddMinutes] = useState([]);
  const [
    extra_duration_per_unit_in_minute,
    setExtra_duration_per_unit_in_minute,
  ] = useState([]);
  const [base_price_for_first_unit, setBase_price_for_first_unit] = useState(
    []
  );
  const [base_price_for_extra_unit, setExtra_price_extra_unit] = useState([]);


  const handleClose09 = () =>{

    setShow09(false);
    setShowError(false)

  } 
  const handleShow09 = () => setShow09(true);


  //get service data by id
  const getServiceData = () => {
    const id = window.location.pathname.split("/")[2];
    const formData = new FormData();
    formData.append("service_id", id);
    getservicesdatabyid(formData)
      .then((res) => { 
        if (res.code === 200) {
          if (res.data.status === "success") {
            setServiceData(res.data.data);
          } else {
            alert("Service not found");
          }
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getServiceData();
  }, []);

  const SaveSubService = () => {
    //get id from url
    const id = window.location.pathname.split("/")[2];

    const formData = new FormData();
    formData.append("service_id", id);
    formData.append("sub_service_name", addSubService);
    formData.append("base_hours", addHours);
    formData.append("base_minutes", addMinutes);
    formData.append(
      "extra_duration_per_unit_in_minute",
      extra_duration_per_unit_in_minute
    );
    formData.append("base_price_for_first_unit", base_price_for_first_unit);
    formData.append("price_for_per_extra_unit", base_price_for_extra_unit);
    formData.append("service_id", id);

    if (addSubService == '' ||
    addHours == '' ||
    addMinutes == '' ||
    extra_duration_per_unit_in_minute == '' ||
    base_price_for_first_unit == '' ||
    base_price_for_extra_unit == '' ) {
            setShowError(true)
        }

    StoreSubService(formData)
      .then((res) => { 
        if (res.code === 200) {
          if (res.data.status === "success") {
            alert("Sub Service added successfully");
            window.location.reload();
          } else {
            alert("Sub Service not added");
          }
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      {/* Add Unit modal start here */}
      <Modal show={show09} onHide={handleClose09} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Create Sub-Service</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custum-modal-body">
          <form id="Create Sub-Service">
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Sub Service Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sub Service Name"
                    onChange={(e) => setAddSubService(e.target.value)}
                  />
                  {showError && addSubService == '' ? <p style={{ color: 'red' }}>filed is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Base Duration</Form.Label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsStopwatch />
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="10"
                          onChange={(e) => setAddHours(e.target.value)}
                        />
                        <span className="input-group-text">Hours</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="30"
                          onChange={(e) => setAddMinutes(e.target.value)}
                        />
                        <span className="input-group-text">Minutes</span>
                      </div>
                    </div>
                  </div>
                  {showError && addHours == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                  {showError && addMinutes == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Extra Duration Per Unit In Minute</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="10"
                    onChange={(e) =>
                      setExtra_duration_per_unit_in_minute(e.target.value)
                    }
                  />
                  {showError && extra_duration_per_unit_in_minute == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Base Price For First Unit</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="293"
                    onChange={(e) =>
                      setBase_price_for_first_unit(e.target.value)
                    }
                  />
                  {showError && base_price_for_first_unit == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Price For Per Extra Unit</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="325"
                    onChange={(e) => setExtra_price_extra_unit(e.target.value)}
                  />
                  {showError && base_price_for_extra_unit == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="Active-bttn btn" onClick={SaveSubService}>
            {" "}
            Save{" "}
          </button>
          <button className="Cancel-bttn btn" onClick={handleClose09}>
            {" "}
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
      {/* modal End here */}

      <div className="dashboard-container d-flex">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="main-container">
          <div className="main-page-body-content">
            <div className="main-page-body-content-header">
              {/* <Header /> */}
            </div>
            <div className="main-page-body-content-body">
              <div className="main-page-body-content-body-container">
                <div className="container-fulid">
                  <div className="row pd-26">
                    <div className="table-card ">
                      <div className="card-body table-card-body">
                        <div className="table-card-header mb-4 d-flex justify-content-between">
                          <h5 className="card-title Clr-Green">
                            <span> {serviceData.title} / </span> Price
                            Management
                          </h5>
                          <button
                            className="btn btn-primary d-flex align-items-center btn-sm add-user-btn"
                            onClick={handleShow09}
                          >
                            <RiAddFill />
                            Add Unit
                          </button>
                        </div>
                        <div className="table-card-body-inner">
                          <Informasi />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
