const informasiList = [{
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 1
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 2
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 3
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 4
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 5
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 6
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 7
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 8
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 9
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 10
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 11
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 12
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 13
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 14
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 15
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 16
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 17
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 18
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 19
  },
  {
    name: 'Surya',
    email: 'denial@gmail.com',
    phone: '08123456789',
    zipCode: '12345',
    address: 'Jl. Jalan',
    joinon: 'Jan-10-2023 08:01 AM',
    id: 20
  },

];

  export {informasiList}