// MyEditor.js
import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { API_URL } from '../Api/config';

const MyEditor = ({ onImageUpload , initialContent ,onContentChange }) => {

  const url = API_URL + 'uploadImage';
  const [editor, setEditor] = useState('');

  useEffect(() => {
    // CKEditor initialization logic
    ClassicEditor
      .create(document.querySelector('#blog_description'), {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo'
          ]
        },
        language: 'en',
        image: {
          toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
          styles: ['full', 'alignLeft', 'alignRight']
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
          ]
        },
        licenseKey: '',
        ckfinder: {
          uploadUrl: url
        }
      })
      .then(editor => {
        window.editor = editor;
        // Additional logic after editor initialization
        editor.model.document.on('change', () => {
          const data = editor.getData();
          setEditor(data);

          // Notify parent component about the content change
          if (onContentChange) {
            onContentChange(data);
          }
        });
        if (initialContent) {
          editor.setData(initialContent);
        }
      })
      .catch(error => {
        console.error('Oops, something went wrong!');
        console.error('Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:');
        console.warn('Build id: 9jk0hs3q1ekc-8o65j7c6blw0');
        console.error(error);
      });
  }, [onImageUpload]); // Add any dependencies if needed

  return (
    <div id="blog_description">
      {/* CKEditor instance will be initialized here */}
    </div>
  );
};

export default MyEditor;
