import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';

function Usercomment({ imagesrc, username, userdiscription1, heighlight, userdiscription2, }) {
    return (

        <div className="user-comment">
            <div className="d-flex">
                <div className="user-img">
                    <img src={imagesrc} alt="user" />
                </div>
                <div className="comment-icon">
                    <FontAwesomeIcon icon={faQuoteRight} />
                </div>
            </div>
            <div className="user-name">
                <h3>{username}</h3>
            </div>
            <div className="user-comment-description">
                <p>{userdiscription1} <span>{heighlight}</span> {userdiscription2}</p>
            </div>
            <div className="view-all-reviews-btn">
                <a href="/Reviews" className="All-Reviews-btn" >View All Reviews</a>
            </div>
        </div >
    );
}

export default Usercomment;