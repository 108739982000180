import React from "react";
import { useContext, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Form from 'react-bootstrap/Form';
import pagination from '../../Components/TimesheetDataTable/pagination';
import '../../Components/TimesheetDataTable/table.css';
import Modal from 'react-bootstrap/Modal';
import { SettingContext } from '../../Setting/SettingContext';
import { companyservicearea, deletezoneDataApi ,deletezipcode } from "../../Api/settingApi";
import { getzones } from "../../Api/settingApi";
import { RiDeleteBin6Line } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';

function TabData2() {

    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings, paymenetmethod, setPaymenetmethod, emailData, setEmailData, emailTemplate, setEmailTemplate, promocodeData, setPromocodeData, zonesdata, setZonesData] = useContext(SettingContext);
    const [SearchText, setSearchText] = useState('');


    useEffect(() => {
        companyservicearea(SearchText).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                setCompanyServiceArea(apiResponse.data);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, [SearchText]);

    // useEffect(() => {
    //     getzones().then((res) => {
    //         if (res.code === 200) {
    //             const apiResponse = res.data;
    //             setZonesData(apiResponse.data);
    //         }
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, [zonesdata]);


    const [show11, setShow11] = useState(false);
    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);


    const [keywords, setKeywords] = useState('');

    const handleChange = (e) => {
        setKeywords(e.target.value);
    }

    const columns = [{
        dataField: 'id',
        text: '#',
        sort: true
    },
    {
        dataField: 'zonename',
        text: 'ZONE NAME',
        sort: true
    },
    {
        dataField: 'zipcode',
        text: 'ZIP CODE',
        sort: true
    },
    {
        dataField: 'action',
        text: 'ACTION',
        formatter: (cell, row) => {
            return (
                <div className="button-group">
                    <Button className="Delete-Active-bttn me-2" onClick={
                        () => {
                            const deleteId = row.id;
                            handleDelete(deleteId)
                        }
                    }><RiDeleteBin6Line /></Button>
                </div>
            );

        }
    }
    ];


    const handleDelete = (deleteId) => {
        alert("Are you sure you want to delete this record?");
        const formData = new FormData();
        formData.append("id", deleteId);
        deletezipcode(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == "1") {
                    const companyServiceAreaaa = companyServiceArea.filter((item) => item.id !== deleteId);
                    setCompanyServiceArea(companyServiceAreaaa);
                }
            }
            else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    const handleSearch = (e) => {
        console.log(e.target);
    }

    return (
        <div className="col-md-12">

            {/* Timesheet modal start here */}
            {/* <Modal show={show11} onHide={handleClose11} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Time Sheet</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Stop Time</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Add a Note</Form.Label>
                                <Form.Control type="text" placeholder=" " as="textarea" rows={3} />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose11}> Update </button>
                    <button className="Cancel-bttn btn" onClick={handleClose11}> Cancel </button>
                </Modal.Footer>
            </Modal> */}
            {/* modal End here */}

            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex table-card-header">
                                <Form.Group>
                                    <div className='d-flex'>
                                        <div addonType="prepend">
                                        </div>
                                        <Form.Control type="text" placeholder="Keywords..." onChange={
                                            (e) => {
                                                setSearchText(e.target.value);
                                            }
                                        } />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="card-body">
                                <BootstrapTable bootstrap4 keyField='id' data={companyServiceArea} columns={columns} pagination={pagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TabData2;