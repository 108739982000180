import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import './Accordion.css';
import Service1 from '../../assets/Images/service-image/initial.webp';
import Service2 from '../../assets/Images/bg-image/housecleaning.webp';
import Service3 from '../../assets/Images/bg-image/lamesa.webp';
import Service4 from '../../assets/Images/bg-image/sandiego.webp';
import Service5 from '../../assets/Images/bg-image/checklist.webp';
import Service6 from '../../assets/Images/bg-image/scheduled.webp';

function Accordion1() {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className='service-accordion-item mb-3'>
                <Accordion.Header className='service-accordion-header'><FontAwesomeIcon icon={faCircle} />Initial House Cleaning</Accordion.Header>
                <Accordion.Body className='service-accordion-body'>
                    <hr className='accordion-hr' />
                    <div className='accrordion-content-img m-auto'>
                        <img src={Service1} alt="Initial House Cleaning" />
                        <div className='accrordion-content-img-text'>
                            <h3>Get ready for clean!</h3>
                            <p>Thanks for considering Green Frog. Here’s a few things to keep in mind about your first time house cleaning with us.</p>
                            <p>Your initial house cleaning differs from regularly scheduled maid service in several important ways.</p>
                            <p>It would be virtually impossible for your team to “skip” our standard first-time cleaning routine and try to carry on maintaining your home in a satisfactorily-clean fashion. There’s a big difference between “old dirt” and “new dirt”. If we don’t get rid of the old dirt, no matter how hard we try, simply removing the new dirt isn’t going to make things look sparkling fresh and clean.</p>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className='service-accordion-item mb-3'>
                <Accordion.Header className='service-accordion-header'><FontAwesomeIcon icon={faCircle} />Scheduled Maid Service</Accordion.Header>
                <Accordion.Body className='service-accordion-body'>
                    <hr className='accordion-hr' />
                    <div className='accrordion-content-img m-auto'>
                        <img src={Service6} alt="Initial House Cleaning" />
                        <div className='accrordion-content-img-text'>
                            <h3>Why schedule regular maid service?</h3>
                            <p>Once you’ve had our team out for your initial house cleaning and things are looking clean and tidy you’ll want to keep it that way.</p>
                            <p>We’ll work hard to keep your home in tip-top shape. We progressively work trough rotational tasks at your request to keep your home clean.</p>
                            <p>With scheduled maid service we can we match you with the same cleaner every visit so you’ll get to know each other over time. Of course things happen and if for some reason we can’t send the same technician on a specific visit we will contact you to see if you prefer to reschedule or continue with a different technician.</p>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className='service-accordion-item mb-3'>
                <Accordion.Header className='service-accordion-header'><FontAwesomeIcon icon={faCircle} />Move In/Out Cleaning Service</Accordion.Header>
                <Accordion.Body className='service-accordion-body'>
                    <hr className='accordion-hr' />
                    <div className='accrordion-content-img m-auto'>
                        <img src={Service2} alt="Initial House Cleaning" />
                        <div className='accrordion-content-img-text'>
                            <h3>Benefits of Move In Cleaning Services</h3>
                            <p>Now that you’re out of your old place and partially into your new place, there are a few things you need to do before you unpack. It’s important to clean and disinfect surfaces in your new home before settling in. You want to make sure the kitchen is cleaned as well as the bathroom. A professional cleaning service can have this taken care of before you get the last box in the door making sure that the only thing you have left to do is unpack.</p>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className='service-accordion-item mb-3'>
                <Accordion.Header className='service-accordion-header'><FontAwesomeIcon icon={faCircle} />La Mesa House Cleaning</Accordion.Header>
                <Accordion.Body className='service-accordion-body'>
                    <hr className='accordion-hr' />
                    <div className='accrordion-content-img m-auto'>
                        <img src={Service3} alt="Initial House Cleaning" />
                        <div className='accrordion-content-img-text'>
                            <h3>Who We Are</h3>
                            <p>Welcome to Green Frog La Mesa where we serve La Mesa and the surrounding cities. We’ve built a legacy in premier house cleaning industry. Here at Green Frog Cleaning, we give the gift of time so that you are able to focus on the things that truly matter – Instead of cleaning the house all day.</p>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className='service-accordion-item mb-3'>
                <Accordion.Header className='service-accordion-header'><FontAwesomeIcon icon={faCircle} />San Diego House Cleaning</Accordion.Header>
                <Accordion.Body className='service-accordion-body'>
                    <hr className='accordion-hr' />
                    <div className='accrordion-content-img m-auto'>
                        <img src={Service4} alt="Initial House Cleaning" />
                        <div className='accrordion-content-img-text'>
                            <h3>Who We Are</h3>
                            <p>Welcome to Green Frog La Mesa where we serve La Mesa and the surrounding cities. We’ve built a legacy in premier house cleaning industry. Here at Green Frog Cleaning, we give the gift of time so that you are able to focus on the things that truly matter – Instead of cleaning the house all day.</p>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className='service-accordion-item mb-3'>
                <Accordion.Header className='service-accordion-header'><FontAwesomeIcon icon={faCircle} />Cleaning Services Checklist</Accordion.Header>
                <Accordion.Body className='service-accordion-body'>
                    <hr className='accordion-hr' />
                    <div className='accrordion-content-img m-auto'>
                        <img src={Service5} alt="Initial House Cleaning" />
                        <div className='accrordion-content-img-text'>
                            <h3>Who We Are</h3>
                            <p>Welcome to Green Frog La Mesa where we serve La Mesa and the surrounding cities. We’ve built a legacy in premier house cleaning industry. Here at Green Frog Cleaning, we give the gift of time so that you are able to focus on the things that truly matter – Instead of cleaning the house all day.</p>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default Accordion1;