import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";


//addAddon
export const addAddon = (formData) => apiCall(`${API_URL}addaddon`, "POST", formData); 

//getaddon
export const getaddon = (searchtext) => apiCall(`${API_URL}getaddon?searchtext=${searchtext}`, "GET", []);

//getServices
export const getServices = () => apiCall(`${API_URL}getbookingservicesApi`, "GET", []);

//getaddonbyid 
export const getaddonbyid = (formData) => apiCall(`${API_URL}getaddonData`, "POST", formData);

//updateaddon
export const updateaddon = (formData) => apiCall(`${API_URL}updateaddon`, "POST", formData);

//deleteaddonbyid 
export const deleteaddonbyid = (formData) => apiCall(`${API_URL}deleteaddon`, "POST", formData);

//activestatusaddon 
export const activestatusaddon = (formData) => apiCall(`${API_URL}activestatusaddon`, "POST", formData);

//deactivetestatusaddon 
export const deactivetestatusaddon = (formData) => apiCall(`${API_URL}deactivetestatusaddon`, "POST", formData);