import React from "react";
import { useState,useEffect } from "react";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import TabData3 from "../Tab/ScheduleTab";
import { Roller } from "react-awesome-spinners";


function Schedule() {
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
            <div className="dashboard-container d-flex">
                <div className="sidebar-container">
                    <Sidebar />
                </div>
                <div className="main-container">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-header">
                            <Header />
                        </div>
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="">
                                    <div className='tab-content-body'>
                                        <div className='card-header-custom'>
                                            <h5 className="tab-content-title">Schedule</h5>
                                        </div>
                                        <div className='card-body'>
                                            <TabData3 />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
                )}
        </div>
    );
}

export default Schedule;