import React, { useState, useContext, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
// import moment from 'moment';
import moment from "moment-timezone";
import { informasiList } from './repository';
import pagination from './pagination';
import SearchBox from './SearchBox';
import Button from 'react-bootstrap/Button';
import { RiAddFill } from 'react-icons/ri';
import './table.css';
import Icon from '../../assets/images/traffic-lights.png';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Profile from '../../assets/images/Avatar2.png';
import { FiCopy } from "react-icons/fi";
import Accordion from "../Accordion/Accordion";
import { FormControl } from "react-bootstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { getstaffreportlist } from "../../Api/technician";
import Sidebar from "../Sidebar/Sidebar";
import { Roller } from "react-awesome-spinners";
import { CSVLink } from 'react-csv';
import { DateRange } from 'react-date-range';
import Spinner from 'react-bootstrap/Spinner';
import { DateRangePicker } from 'react-date-range';


const Informasi = () => {

    const [loadingsppiner, setLoadingSpinner] = useState(false);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show02, setShow02] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);

    const [reportlist, setReportlist] = useState([]);

    const [show9, setShow9] = useState(false);

    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);


    const [sdate, setSdate] = useState(new Date());
    const sevenDaysLater = new Date();
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
    const [edate, setEdate] = useState(sevenDaysLater);

    const [showDateDisplay, setShowDateDisplay] = useState(false);

    const [dates, setDates] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    const handleButtonClick = () => {
        getreportdata()
    };


    const columns = [
        {
            dataField: 'fullname',
            text: 'TECHNICIAN NAME',
            sort: true,
            footer: 'Total'
        }, {
            dataField: 'date',
            text: 'DATE',
            sort: true,
            footer: '-'
        }, {
            dataField: 'total_avalibility',
            text: 'AVAILABLE TIME(HOURS)',
            sort: true,
            footer: () => {
                if (reportlist.length > 0) {
                    const total_avalibility = reportlist.reduce((acc, item) => acc + parseFloat(item.total_avalibility), 0);
                    return Math.round(total_avalibility).toFixed(2);
                }
            }
        },
        {
            dataField: 'bookinghourremaning',
            text: 'REMAINING TIME(HOURS)',
            sort: true,
            footer: () => {
                if (reportlist.length > 0) {
                    const bookinghourremaning = reportlist.reduce((acc, item) => acc + parseFloat(item.bookinghourremaning), 0);
                    return Math.round(bookinghourremaning).toFixed(2);
                }
            },
        },
        {
            dataField: 'bookingEventDuration',
            text: 'BOOKED TIME(HOURS)',
            sort: true,
            footer: () => {
                if (reportlist.length > 0) {
                    const bookingEventDuration = reportlist.reduce((acc, item) => acc + parseFloat(item.bookingEventDuration), 0);
                    return Math.round(bookingEventDuration).toFixed(2);
                }
            },
        },

    ];


    const [changesearch, setchangesearch] = useState("");

    const [start, setStart] = useState(moment());
    const [end, setEnd] = useState(moment(start).add(5, "days").subtract(1, "second"));

    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment(start).add(5, "days").subtract(1, "second"));

    const applyCallback = (startDate, endDate) => {

        console.log("Apply Callback");
        console.log(startDate.format("DD-MM-YYYY HH:mm:ss"));
        console.log(endDate.format("DD-MM-YYYY HH:mm:ss"));


        setStart(startDate);
        setEnd(endDate);

        const start_date = startDate.format("YYYY-MM-DD");
        const end_date = endDate.format("YYYY-MM-DD");

        console.log("start_date", start_date);
        console.log("end_date", end_date);

        setStartTime(start_date);
        setEndTime(end_date);

    };


    const rangeCallback = (index, value) => {
        console.log(index, value);
    };

    const onClick = () => {
        const newStart = moment(start).subtract(3, "days");
        setStart(newStart);
    };

    const renderVanillaPicker = (ranges, local, maxDate) => {
        const value = `${start.format("DD-MM-YYYY HH:mm")} - ${end.format("DD-MM-YYYY HH:mm")}`;
        const disabled = false;



        return (
            <div>
                {/* <br /> */}
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    // maxDate={maxDate}
                    applyCallback={applyCallback}
                    rangeCallback={rangeCallback}
                    smartMode
                >
                    <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                        style={{ cursor: "pointer" }}
                        disabled={disabled}
                        value={value}
                    />
                </DateTimeRangeContainer>
                {/* <br /> */}
            </div>
        );
    };

    let now = new Date();

    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)],
        "5 Days": [moment(start).subtract(5, "days"), moment(end)],
        "1 Week": [moment(start).subtract(7, "days"), moment(end)],
        "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
        "1 Month": [moment(start).subtract(1, "months"), moment(end)],
        "1st August 18": [
            moment("2018-08-01 00:00:00"),
            moment("2018-08-02 23:59:59")
        ],
        "1 Year": [moment(start).subtract(1, "years"), moment(end)]
    };

    let local = {
        format: "DD-MM-YYYY HH:mm",
        sundayFirst: false
    };

    let maxDate = moment(end).add(24, "hour");

    let pickersRender = (
        <div className="col-md-12">
            {/* <br /> */}
            {renderVanillaPicker(ranges, local, maxDate)}
        </div>
    );

    const getreportdata = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("start_date", moment(sdate).format("YYYY-MM-DD"));
        formData.append("end_date", moment(edate).format("YYYY-MM-DD"));
        getstaffreportlist(formData).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data; 
                setReportlist(apiResponse.data)
            }
            else {
                alert("No data found");
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            handleClose9();
        }).finally(() => {
            setLoading(false);
            handleClose9();
        });
    }

    useEffect(() => {
        getreportdata();
    }, []);


    const filteritem = reportlist.filter((item) => {
        return Object.values(item).join(" ").toLowerCase().includes(changesearch.toLowerCase());
    });

    const totalAvailabilityFooter = () => {
        if (reportlist.length > 0) {
            const total_avalibility = reportlist.reduce((acc, item) => acc + parseFloat(item.total_avalibility), 0);
            return Math.round(total_avalibility).toFixed(2);
        }
        return "-";
    };

    const remainingTimeFooter = () => {
        if (reportlist.length > 0) {
            const bookinghourremaning = reportlist.reduce((acc, item) => acc + parseFloat(item.bookinghourremaning), 0);
            return Math.round(bookinghourremaning).toFixed(2);
        }
        return "-";
    };

    const bookedTimeFooter = () => {
        if (reportlist.length > 0) {
            const bookingEventDuration = reportlist.reduce((acc, item) => acc + parseFloat(item.bookingEventDuration), 0);
            return Math.round(bookingEventDuration).toFixed(2);
        }
        return "-";
    };

    const csvReport = {
        data: reportlist.map((item) => {
            return {
                TECHNICIAN_NAME: item.fullname,
                DATE: item.date,
                AVAILABLE_TIME_HOURS: item.total_avalibility,
                REMAINING_TIME_HOURS: item.bookinghourremaning,
                BOOKED_TIME_HOURS: item.bookingEventDuration,
            };
        }),
        footer: [
            { label: "TOTAL", key: "TECHNICIAN_NAME" },
            { label: "-", key: "DATE" },
            { label: totalAvailabilityFooter(), key: "AVAILABLE_TIME_HOURS" },
            { label: remainingTimeFooter(), key: "REMAINING_TIME_HOURS" },
            { label: bookedTimeFooter(), key: "BOOKED_TIME_HOURS" },
        ],
        headers: [
            { label: "TECHNICIAN NAME", key: "TECHNICIAN_NAME" },
            { label: "DATE", key: "DATE" },
            { label: "AVAILABLE TIME(HOURS)", key: "AVAILABLE_TIME_HOURS" },
            { label: "REMAINING TIME(HOURS)", key: "REMAINING_TIME_HOURS" },
            { label: "BOOKED TIME(HOURS)", key: "BOOKED_TIME_HOURS" },
        ],
        filename: "Billed hours report"
    };



    return (
        <>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (
                    <>

                        <Modal show={show9} onHide={handleClose9} centered className="modal-center">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Filter data
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <label className="form-label">Search Date</label>

                                    <div className="col-md-8">
                                        <div className="date-picker">

                                            <Form.Group
                                                className="booking-custom-lable"
                                                controlId="formBasicEmail"
                                            >
                                                <div className="row">
                                                    {/* <div className="col-md-12">

                                                        <input
                                                            type="text"
                                                            className="form-control mb-3"
                                                            onClick={() => setShowDateDisplay(true)}
                                                            placeholder="Click to select dates"
                                                            value={
                                                                `${moment(sdate).format('DD-MM-YYYY')} - ${moment(edate).format('DD-MM-YYYY')}`
                                                            }
                                                        />
                                                        <DateRange
                                                            editableDateInputs={true}
                                                            onChange={(item) => {
                                                                setSdate(item.selection.startDate);
                                                                setEdate(item.selection.endDate);
                                                                setDates([item.selection]);
                                                            }}
                                                            shownDate={dates[0]?.startDate}
                                                            moveRangeOnFirstSelection={false}
                                                            ranges={dates}
                                                        />
                                                    </div> */}
                                                    <div className="col-md-12">
                                                        <DateRangePicker
                                                            onChange={(item) => {
                                                                setSdate(item.selection.startDate);
                                                                setEdate(item.selection.endDate);
                                                                setDates([item.selection]);
                                                            }}
                                                            showSelectionPreview={true}
                                                            moveRangeOnFirstSelection={false}
                                                            // months={2}
                                                            ranges={dates}
                                                            direction="horizontal"
                                                        />
                                                    </div>
                                                </div>


                                            </Form.Group>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4"> */}
                                        <div className="date-picker">
                                            <Form.Group
                                                className="booking-custom-lable"
                                                controlId="formBasicEmail"
                                            >


                                                {
                                                    loadingsppiner ? (
                                                        <Button className="resize_btn btn" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </Button>
                                                    ) : (
                                                        <div className="toggle_btn">
                                                            <button className="resize_btn btn" onClick={
                                                                () => {
                                                                    handleButtonClick();
                                                                }
                                                            }>
                                                                <div className="btn-text">
                                                                    Submit
                                                                </div>
                                                            </button>
                                                        </div>
                                                    )
                                                }




                                            </Form.Group>
                                        </div>
                                    {/* </div> */}

                                </div>
                            </Modal.Body>
                        </Modal>


                        <div className="dashboard-container d-flex">
                            <div className="sidebar-container">
                                <Sidebar />
                            </div>
                            <div className="main-container">
                                <div className="main-page-body-content">
                                    <div className="main-page-body-content-body">
                                        <div className="main-page-body-content-body-container">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="search-box">
                                                        <Form.Control type="text" placeholder="Keywords..." onChange={(e) => {
                                                            setchangesearch(e.target.value);
                                                        }
                                                        } />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div class="toggle_btn"><button class="resize_btn filter_btn btn" onClick={handleShow9}><div class="btn-text">Filter</div></button></div>
                                                </div>
                                            </div>

                                            <div className="pd-26">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header mb-4 d-flex justify-content-between">
                                                            <h5 className="card-title">Billed Hours Report Sheet</h5>
                                                            <div className="d-flex justify-content-end">
                                                                <div className="export-btn">
                                                                    <CSVLink {...csvReport}>
                                                                        <button className="btn btn-primary downloads-btn-preview me-1">Export CSV</button>
                                                                    </CSVLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-card-body-inner">

                                                            {/* modal End here */}
                                                            <div className="animated fadeIn">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="card">
                                                                            <div className="card-header d-flex table-card-header">
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <BootstrapTable bootstrap4 keyField='id' data={filteritem}
                                                                                    columns={columns} pagination={pagination}
                                                                                    noDataIndication="No data available" />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default Informasi;