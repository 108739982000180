import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import './keapsyncform.css'
import moment from "moment-timezone";
import BootstrapTable from 'react-bootstrap-table-next';
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import pagination from '../Components/TimesheetDataTable/pagination';
import Form from 'react-bootstrap/Form';
import '../Components/TimesheetDataTable/table.css';
import { createkeapsysnsid } from "../Api/crm";
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import { Roller } from "react-awesome-spinners";

function Keapsyncform() {


    const [technicianofftimerequest, setTechnicianofftimerequest] = useState([]);


    const [loading, setLoading] = useState(false)

    const [keapid, setKeapId] = useState('');
    const [validInput, setValidInput] = useState(false);





    const getkeapId = () => {
        if (keapid == undefined && keapid == null || keapid == "") {
            setValidInput(true);
        }
        else{
            setValidInput(false);
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('keap_id', keapid);
        createkeapsysnsid(formData).then((res) => {
            if (res.code === 200) {
                alert(res.data.message);
            }
            else {
                console.log("getTechnicianOffTime");
                alert("No data found");    
            }
        })
            .catch(function (err) {
                alert(err.message);
            }).finally(function () {
                setLoading(false);
            });
    };





    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="pd-26">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-header mb-4 d-flex justify-content-between">
                                                        <h5 className="card-title">Keap Sync</h5>
                                                        <div className="d-flex justify-content-end">
                                                            <div className="export-btn">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-card-body-inner">

                                                        {/* modal End here */}
                                                        <div className="animated fadeIn">
                                                            <div className="row">
                                                                <Form.Label>Keap Id</Form.Label>
                                                                <div className="col-md-6 mb-3">
                                                                    <Form.Group controlId="formBasicEmail">
                                                                        <Form.Control type="text" value={keapid} onChange={(e) => { setKeapId(e.target.value) }} placeholder="" />
                                                                    </Form.Group>
                                                                    {
                                                                        validInput && validInput ?
                                                                            <div class="form-error">Please provide a valid keap_id.</div>

                                                                            : null
                                                                    }

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Form.Group controlId="formBasicEmail">
                                                                        <Form.Label></Form.Label>
                                                                        <button type="button" className="btn btn-primary" onClick={getkeapId}>Submit</button>
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Keapsyncform;