import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import Informasi from '../Components/DataTable OffTime/Informasi';
import Calendar from '../Components/Calendar/Calendar';

function TabData3() {
    return (
        <Tabs
            defaultActiveKey="profile"
            id="justify-tab-example"
            className="mb-3"
            justify
        >
            <Tab eventKey="profile" title="Off Time">
                <div className="off-time-detail">
                    <div className="card-custom">
                        <div className="card-custom-header">
                            <h5 className="tab-content-title">Add Your Off Times</h5>
                        </div>
                        <div className="row add-offtime-row">
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Add New Off Time</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select</option>
                                        <option value="1">11/01/2023 10:14AM - 12/01/2023 10:30AM</option>
                                        <option value="2">11/01/2023 10:14AM - 12/01/2023 10:30AM</option>
                                        <option value="3">11/01/2023 10:14AM - 12/01/2023 10:30AM</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <div className="off-time-detail-btn">
                                    <button type="button" className="btn bg-green-dark action-btn btn-green">
                                        Add Brack
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row pd-26'>
                            <div className="table-card ">
                                <div className="card-body table-card-body">
                                    <div className="table-card-header"><h5 className="card-title">Your Added Off Times</h5></div>
                                    <div className="table-card-body-inner">
                                        <Informasi />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab>
            <Tab eventKey="longer-tab" title="Off Day's">
                <div className="off-time-detail">
                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <Calendar />
                        </div>
                    </div>
                </div>
            </Tab>

        </Tabs>
    );
}

export default TabData3;