import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RiDeleteBin6Line } from 'react-icons/ri';

function Delete() {
  const [show04, setShow04] = useState(false);

  const handleClose04 = () => setShow04(false);
  const handleShow04 = () => setShow04(true);

  return (
    <>
    <Button className="Delete-Active-bttn me-2" onClick={handleShow04}><RiDeleteBin6Line /></Button>

      <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user.</Modal.Body>
        <Modal.Footer>
          <Button className='delete-Cancel-bttn' onClick={handleClose04}>
            Cancel
          </Button>
          <Button className='confirm-Delete-bttn bg-red-dark' onClick={handleClose04}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Delete;