import React from "react";
import { useContext, useEffect, useState, useRef } from 'react';
import { SettingContext } from '../../Setting/SettingContext';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { updateemailtemplatestatus } from '../../Api/settingApi';
import { updatesmstemplate } from '../../Api/settingApi';
import { saveappointment_request, updatesmstemplatestatus } from '../../Api/settingApi';



function TabData7() {

    const textareaRef = useRef(null);
    const textareaRef2 = useRef(null);
    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings, paymenetmethod, setPaymenetmethod, emailData, setEmailData, emailTemplate, setEmailTemplate, promocodeData, setPromocodeData, zonesdata, setZonesData, smsstatus, setSmsstatus, masterpassword, setMasterpassword] = useContext(SettingContext);

    const [appointmentCancelled, setAppointmentCancelled] = useState(false);
    const [clientAppointmentReminder, setClientAppointmentReminder] = useState(false);

    const [appointementsmsbody, setAppointementsmsbody] = useState("");
    const [appoimentremainderbody, setAppoimentremainderbody] = useState("");
    const [cursorPosition, setCursorPosition] = useState(0);

    const [tags, setTags] = useState('');

    const extraTags = (e) => {
        const extraText = e;
        const newTextareaValue = appointementsmsbody.substring(0, cursorPosition)
            .concat(extraText)
            .concat(appointementsmsbody.substring(cursorPosition));
        setAppointementsmsbody(newTextareaValue);

        setCursorPosition(cursorPosition + extraText.length);
    }

    const extraTags2 = (e) => {
        const extraText = e;
        const newTextareaValue = appoimentremainderbody.substring(0, cursorPosition)
            .concat(extraText)
            .concat(appoimentremainderbody.substring(cursorPosition));
        setAppoimentremainderbody(newTextareaValue);

        setCursorPosition(cursorPosition + extraText.length);
    }

    useEffect(() => {
        textareaRef.current.selectionStart = textareaRef.current.selectionEnd = cursorPosition;
        textareaRef.current.focus();
    }, [appointementsmsbody, cursorPosition])

    useEffect(() => {
        textareaRef2.current.selectionStart = textareaRef2.current.selectionEnd = cursorPosition;
        textareaRef2.current.focus();
    }, [appoimentremainderbody, cursorPosition])

    const handleEditInputChange = (event) => {
        const { name, value } = event.target;
        setEmailTemplate((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }));
    };

    const CheckSMSTemplate = (e) => {
        smsstatus.map((item) => {
            if (item.id === "1") {
                if (item.sms_template_status === "E") {
                    setAppointmentCancelled(true);
                    setAppointementsmsbody(item.sms_message);
                }
                else {
                    setAppointmentCancelled(false);
                    setAppointementsmsbody(item.sms_message);
                }
            }

            if (item.id === "11") {
                if (item.sms_template_status === "E") {
                    setClientAppointmentReminder(true);
                    setAppoimentremainderbody(item.sms_message);
                }
                else {
                    setClientAppointmentReminder(false);
                    setAppoimentremainderbody(item.sms_message);
                }
            }

        })

    }

    useEffect(() => {
        CheckSMSTemplate(); 
    }, [smsstatus]);

    const adminAppointment = (e) => {
        setAppointmentCancelled(e.target.checked);
        if (e.target.checked === true) {
            smsstatus.sms_template_status = "E";
        }
        else {
            smsstatus.sms_template_status = "D";
        }

        const formData = new FormData();
        formData.append("sms_template_status", smsstatus.sms_template_status);
        formData.append("id", "1");
        updatesmstemplatestatus(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    alert("Status change successfully!");
                }
                else {
                    alert("change not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const saveAppointmentEmailTemplate = (e) => {
        const formData = new FormData();
        formData.append("id", "1");
        formData.append("sms_messages", appointementsmsbody);

        updatesmstemplate(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert(res.data.message);
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const saveClientEmailTemplate = (e) => {
        const formData = new FormData();
        formData.append("id", "1");
        formData.append("sms_messages", appointementsmsbody);

        updatesmstemplate(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const remainderAppointmentEmailTemplate = (e) => {
        const formData = new FormData();
        formData.append("id", "11");
        formData.append("sms_messages", appoimentremainderbody);

        updatesmstemplate(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const onTextAreaChange = (e) => {
        setAppointementsmsbody(e.target.value); 
        setCursorPosition(e.target.selectionStart);
    }

    const onTextAreaChangeremainder = (e) => {
        setAppoimentremainderbody(e.target.value); 
        setCursorPosition(e.target.selectionStart);
    }

    const appointmentrequest = (e) => {
        setClientAppointmentReminder(e.target.checked);
        if (e.target.checked === true) {
            smsstatus.sms_template_status = "E";
        }
        else {
            smsstatus.sms_template_status = "D";
        }

        const formData = new FormData();
        formData.append("sms_template_status", smsstatus.sms_template_status);
        formData.append("id", "11");
        updatesmstemplatestatus(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    alert("Status change successfully!");
                }
                else {
                    alert("change not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const tagsname = ["{{booking_id}}", "{{booking_date}}", "{{booking_time}}", "{{service_name}}", "{{client_name}}", "{{methodname}}", "{{units}}", "{{booking_notes}}", "{{addons}}", "{{firstname}}", "{{lastname}}", "{{client_email}}", "{{client_phone}}", "{{payment_method}}", "{{vaccum_cleaner_status}}", "{{parking_status}}", "{{notes}}", "{{preferences}}", "{{family_info}}", "{{parking_access}}", "{{preferrend_technician}}", "{{special_instructions}}", "{{contact_status}}", "{{price}}", "{{client_address}}", "{{client_city}}", "{{client_state}}", "{{client_zip}}", "{{client_promocode}}", "{{app_remain_time}}", "{{reject_status}}", "{{business_logo}}", "{{admin_name}}", "{{company_name}}", "{{company_address}}", "{{company_city}}", "{{company_state}}", "{{company_zip}}", "{{company_country}}", "{{company_phone}}", "{{company_email}}"];


    return (
        <div>
            <div className="row">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-3 custom-accordion-item">
                        <Accordion.Header>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={appointmentCancelled}
                                onChange={adminAppointment}

                            />
                            Client Appointment Reminder
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-9">
                                    <FloatingLabel controlId="floatingTextarea2" >
                                        <Form.Control
                                            ref={textareaRef}
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '400px' }}
                                            value={appointementsmsbody || ""}
                                            onChange={
                                                (e) => {
                                                    onTextAreaChange(e);
                                                }
                                            }
                                            onFocus={
                                                (e) => { 
                                                    setCursorPosition(e.target.selectionStart);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                    <div className="mt-3">
                                        <div className="btn-list">
                                            <button type="button" className="btn setting-btn bg-green-dark" onClick={saveAppointmentEmailTemplate}>Save Template</button>
                                            {/* <button type="button" className="btn setting-btn bg-purple-dark ">Preview Template</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="accordian-header-title-small">
                                        <h4>Tags</h4>
                                    </div>

                                    <div className="tag-list">
                                        {
                                            tagsname.map((tag, index) => {
                                                return (
                                                    <div key={index} className="tag">
                                                        <button className="tag-btn-data" onClick={
                                                            () => {
                                                                extraTags(tag);
                                                            }
                                                        }>{tag}</button>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className="custom-accordion-item">
                        <Accordion.Header>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={clientAppointmentReminder}
                                onChange={appointmentrequest}
                            />
                            Appointment Request
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-9">
                                    <FloatingLabel controlId="floatingTextarea2" >
                                        <Form.Control
                                            ref={textareaRef2}
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '400px' }}
                                            value={appoimentremainderbody || ""}
                                            onChange={
                                                (e) => {
                                                    onTextAreaChangeremainder(e);
                                                }
                                            }
                                            onFocus={
                                                (e) => { 
                                                    setCursorPosition(e.target.selectionStart);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                    <div className="mt-3">
                                        <div className="btn-list">
                                            <button type="button" className="btn setting-btn bg-green-dark" onClick={remainderAppointmentEmailTemplate}>Save Template</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="accordian-header-title-small">
                                        <h4>Tags</h4>
                                    </div>

                                    <div className="tag-list">
                                        {
                                            tagsname.map((tag, index) => {
                                                return (
                                                    <div key={index} className="tag">
                                                        <button className="tag-btn-data" onClick={
                                                            () => {
                                                                extraTags2(tag);
                                                            }
                                                        }>{tag}</button>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>

    )
}

export default TabData7;