import React, { useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { informasiList } from './repository';
import pagination from './pagination';
import SearchBox from './SearchBox';
import Button from 'react-bootstrap/Button';
import './table.css';

const Informasi = () => {

    const [selected, setSelected] = useState([]);

    const datetimeFormatter = (cell, row) => {
        return moment(cell).format('DD MMM YYYY hh:mm');
    }

    const handleDelete = (id) => {
        alert('delete :' + id);
    }

    const handleEdit = (id) => {
        alert('edit :' + id);
    }

    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Yes-Active-bttn" onClick={(id) => handleEdit(row.id, window.event)}>Yes</Button>
            </div>
        );
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id]);
        } else {
            setSelected(selected.filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids);
        } else {
            setSelected([]);
        }
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    const columns = [{
        dataField: 'name',
        text: 'NAME',
        sort: true
    }, {
        dataField: 'email',
        text: 'EMAIL',
        sort: true
    }, {
        dataField: 'phone',
        text: 'PHONE',
        sort: true
    },
    {
        dataField: 'zipCode',
        text: 'ZIP CODE',
        sort: true
    },
    {
        dataField: 'address',
        text: 'ADDRESS',
        sort: true
    },
    {
        dataField: 'joinon',
        text: 'JOIN ON',
        sort: true,
    },
    {
        text: 'STAX ID',
        isDummy: true,
        formatter: optionFormatter
    }];

    const data = informasiList;



    const handleSearch = (e) => {
        console.log(e.target);
    }

    return (
        <>

            <div className="animated fadeIn" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex table-card-header">
                                <SearchBox onSearch={handleSearch} />
                            </div>
                            <div className="card-body">
                                <BootstrapTable bootstrap4 keyField='id' data={data} columns={columns} selectRow={selectRow} pagination={pagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Informasi;