import React from "react";
import Informasi from "../Components/UserProfile/Informasi";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import Accordion from "../Components/Accordion/Accordion";
import { FiCopy } from "react-icons/fi";
import Profile from "../assets/images/Avatar2.png";

function UserProfile() {
    return (
        <div>
            <div className="dashboard-container d-flex">
                <div className="sidebar-container">
                    <Sidebar />
                </div>
                <div className="main-container">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-header">
                            <Header />
                        </div>
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="container-fulid">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-body-inner">
                                                        <Informasi />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-header">
                                                        <div className="col-md-12">
                                                            <div className="user-profile-details mb-3">
                                                                <div className="user-profile-details-inner">
                                                                    <div className="user-profile-img">
                                                                        <img src={Profile} alt="Profile" />
                                                                    </div>
                                                                    <div className="user-profile-name">
                                                                        <h4>Denial Dev</h4>
                                                                        <p>denialdev@gmail.com <button className="copy-btn" ><FiCopy /></button></p>
                                                                        <p>+1312654654 <button className="copy-btn" ><FiCopy /></button></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-card-body-inner">
                                                        <Accordion />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserProfile;