import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import { FaStar } from 'react-icons/fa';
import './assets/css/Service.css'
import Header2 from "./camponent/header/Header2";

function WindowCleaningPrice() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="windowcleaning-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Window Cleaning <span className='bold-title'><span className='bold-title bg-color'>Price</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">San Diego Window Cleaning Prices</h2>
                                    </div>
                                    <div className="title_bold">
                                        <p>San Diego Window Cleaning Prices</p>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>These are  approximate prices for window washing inside and out. You can read more about what affects prices here: <a href="#" className="link">How much does window washing cost?</a></p>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Window Cleaning for 1,000 Sq. Ft. Home <br />$ 125</p>
                                        <p>Window Cleaning for 1,500 Sq. Ft. Home <br />$ 175</p>
                                        <p>Window Cleaning for 2,000 Sq. Ft. Home <br />$ 220</p>
                                        <p>Window Cleaning for 2,500 Sq. Ft. Home <br />$ 275</p>
                                        <p>Window Cleaning for 3,000 Sq. Ft. Home <br />$ 330</p>
                                        <p>Window Cleaning for 3,500 Sq. Ft. Home <br />$ 385</p>
                                        <p>Window Cleaning for 4,000 Sq. Ft. Home <br />$ 440</p>
                                        <p>Window Cleaning for 4,500 Sq. Ft. Home <br />$ 495</p>
                                        <p>Window Cleaning for 5,000 Sq. Ft. Home <br />$ 550</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default WindowCleaningPrice;