import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Crm.css";
import { Roller } from "react-awesome-spinners";
import Card from 'react-bootstrap/Card';
import { getduplicateemail, margecontectApi, updatedata } from "../Api/crm";
import Spinner from 'react-bootstrap/Spinner';


function Getmarge() {
    const [loading, setLoading] = useState(false);
    const [apiloader, setApiloader] = useState(false);
    const [apiloader2, setApiloader2] = useState(false);
    const [margedata, setMargedata] = useState([]);
    const [fitercontectids, setFiterContectids] = useState([]);
    const [contectids, setContectids] = useState([]);
    const [masterid, setMasterid] = useState(null);
    const [error, setError] = useState(null);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email');

    const getmargedata = (id) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("email", email);
        getduplicateemail(formData).then((response) => {
            if (response.code === 200) {
                const apiResponse = response.data;
                if (apiResponse.status === 1) {
                    const fetchedMargedata = apiResponse.data;
                    setMargedata(apiResponse.data);
                    const ids = fetchedMargedata.map((item) => item.id); // Adjust 'id' to match your data structure
                    setFiterContectids(ids);
                    setContectids(ids);
                } else {
                    console.log(apiResponse);
                }
            }
        }).catch(function (err) {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleRadioChange = (e) => {
        // const selectedId = parseInt(e.target.value); // Parse the selected ID as an integer
        setMasterid(e.target.value); // Set the selected master ID

        const updatedFilteredContectIds = fitercontectids.filter((id) => id !== e.target.value);
        setContectids(updatedFilteredContectIds);
    };

    useEffect(() => {
        getmargedata();
    }, []);


    const margecontect = () => {
        setApiloader(true);
        if (!masterid) {
            setError(true);
            setApiloader(false);
            return;
        }
        const formData = new FormData();
        formData.append("masterid", masterid);
        formData.append("users_id", contectids);
        margecontectApi(formData).then((response) => {
            if (response.code === 200) {
                const apiResponse = response.data;
                if (apiResponse.status === 1) {
                    window.location.reload();
                    alert('Updated');
                } else {
                    console.log(apiResponse);
                }
            }
        }).catch(function (err) {
            console.log(err);
        }).finally(() => {
            setApiloader(false);
            setError(false);
        });
    }
    const handleInputChange = (e, field, index) => {
        const updatedMargeData = [...margedata]; // Create a copy of margedata
        updatedMargeData[index][field] = e.target.value; // Update the specific field in the selected card
        setMargedata(updatedMargeData); // Update the state with the modified data
    };

    const updateCard = (index) => {
        const updatedCard = margedata[index];

        setApiloader2(true);
        const formData = new FormData();
        formData.append("userid", updatedCard.id);
        formData.append("first_name", updatedCard.first_name);
        formData.append("last_name", updatedCard.last_name);
        formData.append("phone", updatedCard.phone);
        formData.append("customer_stage", updatedCard.customer_stage);
        formData.append("address", updatedCard.address);
        formData.append("state", updatedCard.state);
        formData.append("city", updatedCard.city);
        formData.append("zip", updatedCard.zip);
        formData.append("special_instructions_admin", updatedCard.special_instructions_admin);
        updatedata(formData).then((response) => {
            if (response.code === 200) {
                const apiResponse = response.data;
                setMasterid(0);
            }
        }).catch(function (err) {
            console.log(err);
        }).finally(() => {
            setApiloader2(false);
        });
    };


    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fulid">
                                            <div className="">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header">
                                                            <h5 className="card-title">Merge Contact</h5>
                                                        </div>
                                                        <div className="table-card-body-inner">
                                                            <div className="animated fadeIn">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="card">
                                                                            <div className="card-header d-flex justify-content-between table-card-header" >
                                                                                <div className="card-header d-flex table-card-header justify-content-between align-items-center">
                                                                                    <div className="ml-auto d-flex">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                {
                                                                                    error && <div className="invalid-error" style={{ color: "red" }}>Select main account</div>
                                                                                }
                                                                                {/* <div className="booking-list" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "15px" }}>
                                                                                    {
                                                                                        margedata.length > 0 ? (
                                                                                            margedata.map((data, index) => {
                                                                                                return (
                                                                                                    <Card style={{ width: '18rem' }}>
                                                                                                        <Card.Body>
                                                                                                            <Card.Title>{data.user_email}</Card.Title>
                                                                                                            <Card.Subtitle className="mb-2 text-muted">{data.first_name + " " + data.last_name}</Card.Subtitle>
                                                                                                            <Card.Text>Id: {data.id}</Card.Text>
                                                                                                            <Card.Text>Phone: {data.phone}</Card.Text>
                                                                                                            <Card.Text>Customer Stage: {data.customer_stage}</Card.Text>
                                                                                                            <Card.Text>Bedrooms: {data.bedrooms}</Card.Text>
                                                                                                            <Card.Text>Bathrooms: {data.bathrooms}</Card.Text>
                                                                                                            <Card.Text>Address: {data.address}</Card.Text>
                                                                                                            <Card.Text>State: {data.state}</Card.Text>
                                                                                                            <Card.Text>City: {data.city}</Card.Text>
                                                                                                            <Card.Text>Zip: {data.customer_stage}</Card.Text>
                                                                                                            <Card.Text>Total bookings: {data.total_bookings}</Card.Text>
                                                                                                            <Card.Text>Special instructions from the admin: {data.special_instructions_admin}</Card.Text>
                                                                                                             
                                                                                                            <div className="form-check">
                                                                                                                <input
                                                                                                                    className="form-check-input"
                                                                                                                    type="radio"
                                                                                                                    name="selectedCard"
                                                                                                                    value={data.id}  
                                                                                                                    id={`radio-${index}`}
                                                                                                                    onChange={(e) => {
                                                                                                                        handleRadioChange(e);
                                                                                                                        setError(false);
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor={`radio-${index}`}>
                                                                                                                    Select
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                )
                                                                                            })
                                                                                        ) : (
                                                                                            <div className="col-12">
                                                                                                <p>No Data Found</p>
                                                                                            </div>
                                                                                        )}
                                                                                </div> */}

                                                                                <div className="booking-list" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "15px" }}>
                                                                                    {margedata.length > 0 ? (
                                                                                        margedata.map((data, index) => {
                                                                                            const isSelected = masterid === data.id; // Check if the current card is selected
                                                                                            return (
                                                                                                <Card style={{ width: '18rem' }} key={index}>
                                                                                                    <Card.Body>
                                                                                                        <Card.Title>{data.user_email}</Card.Title>
                                                                                                        <Card.Subtitle className="mb-2 text-muted">
                                                                                                            {isSelected ? (
                                                                                                                <>
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        value={data.first_name}
                                                                                                                        onChange={(e) => handleInputChange(e, 'first_name', index)}
                                                                                                                    />
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        value={data.last_name}
                                                                                                                        onChange={(e) => handleInputChange(e, 'last_name', index)}
                                                                                                                    />
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>{data.first_name + ' ' + data.last_name}</>
                                                                                                            )}
                                                                                                        </Card.Subtitle>

                                                                                                        {/* Editable input fields for all other data when card is selected */}
                                                                                                        {isSelected ? (
                                                                                                            <>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.id}
                                                                                                                    readOnly
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.phone}
                                                                                                                    onChange={(e) => handleInputChange(e, 'phone', index)}
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.customer_stage}
                                                                                                                    onChange={(e) => handleInputChange(e, 'customer_stage', index)}
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.bedrooms}
                                                                                                                    onChange={(e) => handleInputChange(e, 'bedrooms', index)}
                                                                                                                    readOnly
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.bathrooms}
                                                                                                                    onChange={(e) => handleInputChange(e, 'bathrooms', index)}
                                                                                                                    readOnly
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.address}
                                                                                                                    onChange={(e) => handleInputChange(e, 'address', index)}
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.state}
                                                                                                                    onChange={(e) => handleInputChange(e, 'state', index)}
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.city}
                                                                                                                    onChange={(e) => handleInputChange(e, 'city', index)}
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.zip}
                                                                                                                    onChange={(e) => handleInputChange(e, 'zip', index)}
                                                                                                                />
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.total_bookings}
                                                                                                                    onChange={(e) => handleInputChange(e, 'total_bookings', index)}
                                                                                                                    readOnly
                                                                                                                />
                                                                                                                <textarea
                                                                                                                    className="form-control mb-2"
                                                                                                                    value={data.special_instructions_admin}
                                                                                                                    onChange={(e) => handleInputChange(e, 'special_instructions_admin', index)}
                                                                                                                />

                                                                                                                {/* Update Button */}
                                                                                                                <button
                                                                                                                    className="btn btn-primary"
                                                                                                                    onClick={() => updateCard(index)}
                                                                                                                >
                                                                                                                    {
                                                                                                                        apiloader2 ? (<Spinner animation="grow" variant="success" />) : (<span >Update</span>)
                                                                                                                    }

                                                                                                                </button>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <Card.Text>Id: {data.id}</Card.Text>
                                                                                                                <Card.Text>Phone: {data.phone}</Card.Text>
                                                                                                                <Card.Text>Customer Stage: {data.customer_stage}</Card.Text>
                                                                                                                <Card.Text>Bedrooms: {data.bedrooms}</Card.Text>
                                                                                                                <Card.Text>Bathrooms: {data.bathrooms}</Card.Text>
                                                                                                                <Card.Text>Address: {data.address}</Card.Text>
                                                                                                                <Card.Text>State: {data.state}</Card.Text>
                                                                                                                <Card.Text>City: {data.city}</Card.Text>
                                                                                                                <Card.Text>Zip: {data.zip}</Card.Text>
                                                                                                                <Card.Text>Total bookings: {data.total_bookings}</Card.Text>
                                                                                                                <Card.Text>Special instructions from the admin: {data.special_instructions_admin}</Card.Text>
                                                                                                            </>
                                                                                                        )}

                                                                                                        {/* Radio Button */}
                                                                                                        <div className="form-check">
                                                                                                            <input
                                                                                                                className="form-check-input"
                                                                                                                type="radio"
                                                                                                                name="selectedCard"
                                                                                                                value={data.id}
                                                                                                                id={`radio-${index}`}
                                                                                                                onChange={(e) => handleRadioChange(e, data.id)}
                                                                                                            />
                                                                                                            <label className="form-check-label" htmlFor={`radio-${index}`}>
                                                                                                                Select
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            );
                                                                                        })
                                                                                    ) : (
                                                                                        <div className="col-12">
                                                                                            <p>No Data Found</p>
                                                                                        </div>
                                                                                    )}
                                                                                </div>


                                                                                {
                                                                                    margedata.length > 1 ? (
                                                                                        <button href="#" class="btn btn-sm add-user-btn btn btn-primary" onClick={margecontect} >
                                                                                            {
                                                                                                apiloader === true ? (<Spinner animation="border" />) : (<span> Merge</span>)
                                                                                            }

                                                                                        </button>) :
                                                                                        (
                                                                                            <button href="#" class="btn btn-sm add-user-btn btn btn-primary" disabled >Merge</button>
                                                                                        )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Getmarge;