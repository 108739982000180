import React from "react";
import Form from 'react-bootstrap/Form';
import { useContext, useEffect, useState } from 'react';
import { SettingContext } from '../../Setting/SettingContext';
import { paymentsmethodchange } from '../../Api/settingApi';

function TabData4() {

    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings, paymenetmethod, setPaymenetmethod, emailData, setEmailData, emailTemplate, setEmailTemplate, promocodeData, setPromocodeData] = useContext(SettingContext);

    const [paylocally, setPaylocally] = useState(false);
    const [fattmerchant, setFattmerchant] = useState(false);

    useEffect(() => {
        PaymentMethodcheck();
    }, [paymenetmethod]);


    const PaymentMethodcheck = () => {
        if (paymenetmethod.pay_locally === "1") {
            setPaylocally(true);
        }
        else {
            setPaylocally(false);
        }

        if (paymenetmethod.fattmerchant_payment_form === "1") {
            setFattmerchant(true);
        }
        else {
            setFattmerchant(false);
        }
    }



    const UpdatePaymentMethod = (e) => {
        const formData = new FormData();
        if (paylocally === true) {
            formData.append('pay_locally', 1);
        }
        else {
            formData.append('pay_locally', 0);
        }
        if (fattmerchant === true) {
            formData.append('fattmerchant_payment_form', 1);
        }
        else {
            formData.append('fattmerchant_payment_form', 0);
        }

        paymentsmethodchange(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Payment Method Updated Successfully");
                }
                else {
                    alert(res.data.message)
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }




    return (
        <div>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="payment-list">
                        <div className="payment-list-item">
                            <div className="payment-list-item-left">
                                <div className="payment-list-title">
                                    <h4>Pay Locally</h4>
                                </div>
                            </div>
                            <div className="payment-list-item-right">
                                <div className="payment-list-btn">
                                    <div className="custom-control custom-switch">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={paylocally}
                                            name="paylocally"
                                            onChange={(e) => { setPaylocally(e.target.checked) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="payment-list">
                        <div className="payment-list-item">
                            <div className="payment-list-item-left">
                                <div className="payment-list-title">
                                    <h4>Fattmerchant payment_form</h4>
                                </div>
                            </div>
                            <div className="payment-list-item-right">
                                <div className="payment-list-btn">
                                    <div className="custom-control custom-switch">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={fattmerchant}
                                            name="fattmerchant"
                                            onChange={(e) => { setFattmerchant(e.target.checked) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-3 p-0">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={UpdatePaymentMethod} >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabData4;