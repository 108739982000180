import React from "react";
import { useContext, useEffect, useState } from 'react';
import { SettingContext } from '../../Setting/SettingContext';
import CryptoJS from 'crypto-js';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { updatemasterpassword ,getmasterpass } from '../../Api/settingApi';
import { updateemailtemplate } from '../../Api/settingApi';
import { saveappointment_request ,updatesmstemplatestatus } from '../../Api/settingApi';



function TabData8() {

    const [companyInfo, setCompanyInfo,companyServiceArea , setCompanyServiceArea,generalSettings , setGeneralSettings,paymenetmethod , setPaymenetmethod,emailData, setEmailData,emailTemplate, setEmailTemplate,promocodeData , setPromocodeData,zonesdata , setZonesData , smsstatus , setSmsstatus,masterpassword , setMasterpassword] = useContext(SettingContext);


    const getmasterpassword = () => {
        getmasterpass().then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setMasterpassword(apiResponse.data);
                }
                else {
                    console.log("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });   
    }

    const [Password  , setPassword] = useState("");

    
    const saveMasterPassword = (e) => {
        const formData = new FormData();
        formData.append("masterpassword", Password);

        updatemasterpassword(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    alert("Data updated successfully!");
                    getmasterpassword();
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }


    return (
        <div>
        <form id='form'>
            <div className="row custom-pt-20">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <div className="form-group mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Master Password</label>
                            <input type="text" className="form-control" name="masterpassword" onChange={(e) => setPassword(e.target.value)} id="exampleFormControlInput1" placeholder="" />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-body-form">
                        <div className="form-group mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Your Master Password</label>
                            <input type="text"  disabled className="form-control" name="masterpassword"  value={masterpassword.normal_text} id="exampleFormControlInput1" placeholder="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={saveMasterPassword} >
                                Save Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    )
}

export default TabData8;