import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";


//add service
export const addService = (formData) => apiCall(`${API_URL}addservice`, "POST", formData);

//getService
export const getService = (searchText) => apiCall(`${API_URL}getservices?searchText=${searchText}`, "GET", []);


//getservicesdata
export const getservicesdata = (formData) => apiCall(`${API_URL}getservicesdata`, "POST", formData);

//updateservice
export const updateservice = (formData) => apiCall(`${API_URL}editservice`, "POST", formData);

//deleteservicesdata 
export const deleteservicesdata = (formData) => apiCall(`${API_URL}deleteservice`, "POST", formData);

//add service
export const StoreSubService = (formData) => apiCall(`${API_URL}addsubservice`, "POST", formData);

//getservicesdata
export const getservicesdatabyid = (formData) => apiCall(`${API_URL}getservicesdatabyid`, "POST", formData);

//getsubservice
export const getsubservice = (page, sizePerPage, filters, sortField, sortOrder,searchText,id) => apiCall(`${API_URL}getsubservices?page=${page}&sizePerPage=${sizePerPage}&filters=${filters}&sortField=${sortField}&sortOrder=${sortOrder}&searchText=${searchText}&service_id=${id}`, "GET", []);

//getsubservicebyid
export const getsubservicebyid = (formData) => apiCall(`${API_URL}getsubservice`, "POST", formData);

//updatesubservicedata
export const updatesubservicedata = (formData) => apiCall(`${API_URL}updatesubservice`, "POST", formData);

//deleteSubServiceData 
export const deleteSubServiceData = (formData) => apiCall(`${API_URL}deletesubservice`, "POST", formData);

//activestaff 
export const activeservice = (formData) => apiCall(`${API_URL}activeservice`, "POST", formData);

//deactivatedservice 
export const deactivatedservice = (formData) => apiCall(`${API_URL}deactivatedservice`, "POST", formData);

//activesubservicestatus 
export const activesubservicestatus = (formData) => apiCall(`${API_URL}activesubservicestatus`, "POST", formData);

//activesubservicestatus 
export const deactivetesubservicestatus = (formData) => apiCall(`${API_URL}deactivetesubservicestatus`, "POST", formData);


