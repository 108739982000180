import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Review.css";
import { Button, Form } from 'react-bootstrap';
import '../Components/TimesheetDataTable/table.css';
import { getsinglereview, create_keap_notes, update_keap_notes, reviewtosite,upatereviewtosite } from "../Api/bookingApi";
import { BsStarFill, BsStar } from 'react-icons/bs';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import BeatLoader from 'react-bootstrap/Spinner';




function Singleview() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');

    const [reviewdata, setReviewdata] = useState([]);

    const [reviewtitle, setReviewtitle] = useState("");
    const [reviewtext, setReviewtext] = useState("");
    const [reviewstar, setReviewstar] = useState("");
    const [reviewername, setReviewername] = useState("");
    const [clientid, setClientid] = useState("");
    const [staffid, setStaffid] = useState("");

    const [selecttype, setSelecttype] = useState("");
    const [keap_id, setKeap_id] = useState("");

    const [orderamount, setOrderamount] = useState("");
    const [orderdate, setOrderdate] = useState("");
    const [orderstaffs, setOrderstaffs] = useState("");
    const [orderid, setOrderid] = useState("");
    const [useremail, setUseremail] = useState();
    const [postId, setPostId] = useState();
    const [loadingapi, setLoadingapi] = useState(false);
    const [loadingapikeap, setLoadingapikeap] = useState(false);



    const getreviewlisst = () => {
        const formdata = new FormData();
        formdata.append('id', id);
        getsinglereview(formdata).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setReviewdata(ApiResponse.data);
                setReviewtitle(ApiResponse.data.review_title);
                setReviewtext(ApiResponse.data.review_text);
                setReviewstar(ApiResponse.data.star);
                setReviewername(ApiResponse.data.reviewer_name);
                setSelecttype(ApiResponse.data.type);
                setClientid(ApiResponse.data.client_id);
                setKeap_id(ApiResponse.data.keap_status);
                setStaffid(ApiResponse.data.staff_id);

                setOrderid(ApiResponse.data.order_id);
                setOrderamount(ApiResponse.data.amount);
                setOrderdate(ApiResponse.data.booking_date_time);
                setOrderstaffs(ApiResponse.data.staff_name);
                setUseremail(ApiResponse.data.user_email);
                setPostId(ApiResponse.data.post_id)

            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getreviewlisst();
    }, [id])


    const handleStarClick = (index) => {
        setReviewstar(index + 1);
    };

    const gotokeap = () => {
        if(selecttype === 'Select Type' || selecttype === ""){
            NotificationManager.error("Please Select Type");
            setLoadingapikeap(false);
            return;
        }
        setLoadingapikeap(true);
        const formdata = new FormData();
        formdata.append('reviewtext', reviewtext);
        formdata.append('reviewtitle', reviewtitle);
        formdata.append('client_name', reviewername);
        formdata.append('star_rating', reviewstar);
        formdata.append('orderdate', orderdate);
        formdata.append('type', selecttype);
        formdata.append('client_id', clientid);
        formdata.append('staff_id', staffid);
        formdata.append('id', id);
        create_keap_notes(formdata).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                if (ApiResponse.status === "success") {
                    NotificationManager.success("Review Added Successfully");
                    getreviewlisst(); // 
                } else {
                    NotificationManager.error(ApiResponse.message);
                }
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(()=>{
            setLoadingapikeap(false);
        })
    }

    const updatetokeap = () => {
        if(selecttype === 'Select Type' || selecttype === ""){
            NotificationManager.error("Please Select Type");
            setLoadingapikeap(false);
            return;
        }
        setLoadingapikeap(true);
        const formdata = new FormData();
        formdata.append('reviewtext', reviewtext);
        formdata.append('reviewtitle', reviewtitle);
        formdata.append('type', selecttype);
        formdata.append('client_id', clientid);
        formdata.append('staff_id', staffid);
        formdata.append('id', id);
        formdata.append('keap_id', keap_id);
        formdata.append('client_name', reviewername);
        formdata.append('star_rating', reviewstar);
        formdata.append('orderdate', orderdate);
        update_keap_notes(formdata).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                if (ApiResponse.status === "success") {
                    NotificationManager.success("Review Updated Successfully");
                    getreviewlisst(); // 
                } else {
                    NotificationManager.error(ApiResponse.message);
                }
                // setReviewdata(ApiResponse.data);
                // window.location.href = "/getreviews";
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(()=>{
            setLoadingapikeap(false);
        })
    }

    const gotosite = () => {
        if(selecttype === 'Select Type' || selecttype === ""){
            NotificationManager.error("Please Select Type");
            return;
        }

        setLoadingapi(true);
        const formdata = new FormData();
        formdata.append('content', reviewtext);
        formdata.append('title', reviewtitle);
        formdata.append('name', reviewername);
        formdata.append('email', useremail);
        formdata.append('rating', reviewstar);
        formdata.append('type', selecttype);
        formdata.append('reviewId', id);
        reviewtosite(formdata).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                if (ApiResponse.status === "success") {
                    NotificationManager.success("Review Send Successfully");
                    getreviewlisst(); // 
                } else {
                    NotificationManager.error(ApiResponse.message);
                }
                // setReviewdata(ApiResponse.data);
                // window.location.href = "/getreviews";
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(()=>{
            setLoadingapi(false);
        })
    }
    
    const updatetosite = () => {
        if(selecttype === 'Select Type' || selecttype === ""){
            NotificationManager.error("Please Select Type");
            setLoadingapikeap(false);
            return;
        }
        setLoadingapi(true);
        const formdata = new FormData();
        formdata.append('content', reviewtext);
        formdata.append('title', reviewtitle);
        formdata.append('name', reviewername);
        formdata.append('email', useremail);
        formdata.append('rating', reviewstar);
        formdata.append('postId', postId);
        formdata.append('reviewId', id);
        upatereviewtosite(formdata).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                if (ApiResponse.status === "success") {
                    NotificationManager.success("Review Update Successfully");
                    getreviewlisst(); // 
                } else {
                    NotificationManager.error(ApiResponse.message);
                }
                // setReviewdata(ApiResponse.data);
                // window.location.href = "/getreviews";
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoadingapi(false);
        })
    }

    return (
        <div>

            <div className="dashboard-container d-flex">
                <div className="sidebar-container">
                    <Sidebar />
                </div>
                <div className="main-container">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="container-fulid">
                                    <div className="">
                                        <div className="table-card ">
                                            <div className="card-body table-card-body">
                                                <div className="table-card-header">
                                                    <h5 className="card-title">Review Details</h5>
                                                </div>
                                                <div className="table-card-body">
                                                    <Form >
                                                        <div className='row'>

                                                            <div className='col-md-12'>
                                                                <Form.Label className='custom-lable'>Order Id :- {orderid}</Form.Label>
                                                            </div>

                                                            <div className='col-md-12' style={{display:"none"}}>
                                                                <Form.Label className='custom-lable'>Booking Amount :- {`$${orderamount}`}</Form.Label>
                                                            </div>

                                                            <div className='col-md-12'>
                                                                <Form.Label className='custom-lable'>Technician's  :- {(orderstaffs).replace(/\s*,\s*/g, ',').replace(/^,|,$/g, '')}</Form.Label>
                                                            </div>

                                                            <div className='col-md-12'>
                                                                <Form.Group className="mb-4" controlId="validationCustom01">
                                                                    <Form.Label className='custom-lable'>Booking Title :-  {orderdate}</Form.Label>
                                                                </Form.Group>
                                                            </div>

                                                            <div className='col-md-12'>
                                                                <Form.Group className="mb-4" controlId="validationCustom01">
                                                                    <Form.Label className='custom-lable'>Review Title</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={reviewtitle}
                                                                        onChange={(e) => setReviewtitle(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <Form.Group className="mb-4" controlId="validationCustom01">
                                                                    <Form.Label className='custom-lable'>Review Star</Form.Label>
                                                                    <div className="form-rating">
                                                                        {
                                                                            Array.from({ length: 5 }, (_, i) => (
                                                                                <span key={i} >
                                                                                    {i < reviewstar ? <BsStarFill /> : <BsStar />}
                                                                                </span>
                                                                            ))
                                                                        }
                                                                    </div>

                                                                    {/* <Form.Control type="text" value={reviewstar} readOnly className='custom-input' /> */}
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-md-12 mb-4">
                                                                <Form.Group className="" controlId="validationCustom01">
                                                                    <Form.Label className='custom-lable'>Reviewer Name</Form.Label>
                                                                    <Form.Control type="text" value={reviewername} readOnly />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <Form.Group className="mb-4" controlId="validationCustom01">
                                                                    <Form.Label className='custom-lable'>Review Text</Form.Label>
                                                                    <Form.Control type="text" as={"textarea"} value={reviewtext} onChange={(e) => setReviewtext(e.target.value)} className='did-floating-textarea' />
                                                                    {/* <div className="did-floating-textarea">
                                                                        {reviewtext}
                                                                    </div> */}
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <Form.Group className="mb-4" controlId="validationCustom01">
                                                                    <Form.Label className='custom-lable'>Type</Form.Label>
                                                                    <Form.Select aria-label="Default select example" value={selecttype} onChange={(e) => setSelecttype(e.target.value)}>
                                                                        <option>Select Type</option>
                                                                        <option value="Tech-Award">Tech-Award</option>
                                                                        {/* <option value="Tech-Discount">Tech-Discount</option> */}
                                                                        {/* <option value="Tech-SickDay">Tech-SickDay</option> */}
                                                                        <option value="Tech-Complaint">Tech-Complaint</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div style={{ float: 'inline-end' }}>
                                                                    {
                                                                        keap_id === '0' ? <Button
                                                                            className="btn btn-sm float-right add-user-btn me-2"
                                                                            onClick={gotokeap}
                                                                        >
                                                                            {loadingapikeap ? (<BeatLoader color="#36d7b7" />) : (<span>GO to Keap</span>)}
                                                                            
                                                                        </Button> :

                                                                            <Button
                                                                                className="btn btn-sm float-right add-user-btn me-2"
                                                                                onClick={updatetokeap}
                                                                            >
                                                                                {loadingapikeap ? (<BeatLoader color="#36d7b7" />) : (<span>Update to Keap</span>)}
                                                                                
                                                                            </Button>

                                                                    }

                                                                    {
                                                                        postId === '0' ? <Button
                                                                            className="btn btn-sm float-right add-user-btn"
                                                                            onClick={gotosite}
                                                                        >
                                                                           {loadingapi ? (<BeatLoader color="#36d7b7" />) : (<span>Go  to Site</span>)}

                                                                        </Button> :

                                                                            <Button
                                                                                className="btn btn-sm float-right add-user-btn"
                                                                                onClick={updatetosite}
                                                                            >
                                                                                {loadingapi ? (<BeatLoader color="#36d7b7" />) : (<span>Update to site</span>)}
                                                                                
                                                                            </Button>

                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
}

export default Singleview