import React, { useEffect, useState } from "react";
import Informasi from "../Components/AddonDataTable/Informasi";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Addon.css";
import { RiAddFill } from 'react-icons/ri';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { BsStopwatch } from "react-icons/bs";
import { addAddon } from "../Api/addonsApi";
import { getServices } from "../Api/addonsApi";
import { Roller } from "react-awesome-spinners";



function Addon() {
    const [show03, setShow03] = useState(false);

    const handleClose03 = () => setShow03(false);

    const handleShow03 = () => setShow03(true);

    const [loading, setLoading] = useState(false);

    const [addonImage, setAddonImage] = useState(null);
    const [addonTitle, setAddonTitle] = useState("");
    const [addonHours, setAddonHours] = useState("");
    const [addonMinutes, setAddonMinutes] = useState("");
    const [addonExtraMinutePerUnit, setAddonExtraMinutePerUnit] = useState("");
    const [addonServicesOfThisAddon, setAddonServicesOfThisAddon] = useState("");
    const [addonPrice, setAddonPrice] = useState("");
    const [addonPriceforPerExtraInches, setPriceforPerExtraInches] = useState("");
    const [showError, setShowError] = useState(false)
    


    const handleAddonImage = (e) => {
        const newImage = e.target.files[0];
        setAddonImage(newImage); 
    };
    
    const [services, setServices] = useState([]);


    const savaAddon = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("addon_title", addonTitle);
        formData.append("service_id", 1);
        formData.append("base_hours", addonHours);
        formData.append("base_minutes", addonMinutes);
        formData.append("extra_minute_per_unit", addonExtraMinutePerUnit);
        formData.append("sercives_of_this_addon", addonServicesOfThisAddon);
        formData.append("base_price_for_first_unit", addonPrice);
        formData.append("price_for_per_extra_unit", addonPriceforPerExtraInches);
        formData.append("image", addonImage); 
        if (addonTitle == '' ||
            addonHours == '' ||
            addonMinutes == '' ||
            addonExtraMinutePerUnit == '' ||
            addonServicesOfThisAddon == '' ||
            addonPrice == '' ||
            addonPriceforPerExtraInches == '') {
            setShowError(true)
        } else {
            addAddon(formData).then((res) => {
                if (res.code === 200) {
                    alert("Addon added successfully");
                    setLoading(false);
                    handleClose03();
                }
                else {
                    alert("Something went wrong");
                }
            }).catch((err) => {
                console.log("err", err);
            });
        };
    }

   



    const getservices = () => {
        // setLoading(true);
        getServices().then((res) => {
            if (res.code === 200) {
                setServices(res.data.data); 
            }
            else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            console.log("err", err);
        });
    };


    useEffect(() => {
        setLoading(true);
        getservices();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    return (

        <>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (
                    <>
                        {/* Create Addon modal start here */}
                        <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end">
                            <Modal.Header closeButton>
                                <Modal.Title>Create Addon</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='custum-modal-body'>
                                <form id="Create Addon">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="logo-content">
                                                <div className="add-logo">
                                                    <div className="image-preview">
                                                        {addonImage ? (
                                                            <img
                                                                src={URL.createObjectURL(addonImage)}
                                                                alt="logo"
                                                                className="profile-img"
                                                            />
                                                        ) : (
                                                            <div className="loading-screen">
                                                                <span className="loader">Addon Image</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="form-group block-upload">
                                                            <div className="upload-logo">
                                                                <div className="custom-file"><input type="file" className="custom-file-input" onChange={handleAddonImage} id="customFile" alt="Choose" /><label className="custom-file-label" htmlFor="customFile">Choose</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                                                <Form.Label>Addon Title</Form.Label>
                                                <Form.Control type="text" onChange={
                                                    (e) => {
                                                        setAddonTitle(e.target.value);
                                                    }

                                                } placeholder="" />
                                                {showError && addonTitle == '' ? <p style={{ color: 'red' }}>addon title is required</p> : ''}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Base Duration</Form.Label>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <span className="input-group-text"><BsStopwatch /></span>
                                                            <input type="number" onChange={
                                                                (e) => {
                                                                    setAddonHours(e.target.value);
                                                                }
                                                            } className="form-control" placeholder="10" />
                                                            <span className="input-group-text">Hours</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <input type="number" className="form-control" onChange={
                                                                (e) => {
                                                                    setAddonMinutes(e.target.value);
                                                                }
                                                            } placeholder="30" />
                                                            <span className="input-group-text">Minutes</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {showError && addonHours == '' ? <p style={{ color: 'red' }}>addon hours are required</p> : ''}
                                                {showError && addonMinutes == '' ? <p style={{ color: 'red' }}>addon minutes are required</p> : ''}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Extra Minute Per Unit</Form.Label>
                                                <Form.Control type="text" onChange={
                                                    (e) => {
                                                        setAddonExtraMinutePerUnit(e.target.value);
                                                    }
                                                }
                                                    placeholder="10" />
                                                {showError && addonExtraMinutePerUnit == '' ? <p style={{ color: 'red' }}>this field can't be empty</p> : ''}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Sercives Of This Addon</Form.Label>
                                                {/* <Form.Control type="text" onChange={
                                                    (e) => {
                                                        setAddonServicesOfThisAddon(e.target.value);
                                                    }
                                                } placeholder="Cleaning" /> */}
                                                <select className="form-control" onChange={
                                                    (e) => {
                                                        setAddonServicesOfThisAddon(e.target.value);
                                                    }
                                                }>

                                                    <option value="">Select Service</option>
                                                    {
                                                        services && services.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.id}>{item.title}</option>
                                                            )
                                                        })
                                                    }
                                                </select>


                                                {showError && addonServicesOfThisAddon == '' ? <p style={{ color: 'red' }}>this field can't be empty</p> : ''}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Base Price For First Unit</Form.Label>
                                                <Form.Control type="text" onChange={
                                                    (e) => {
                                                        setAddonPrice(e.target.value);
                                                    }
                                                }
                                                    placeholder="325" />
                                                {showError && addonPrice == '' ? <p style={{ color: 'red' }}>this field can't be empty</p> : ''}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Price For Per Extra Unit</Form.Label>
                                                <Form.Control type="text" onChange={
                                                    (e) => {
                                                        setPriceforPerExtraInches(e.target.value);
                                                    }
                                                }
                                                    placeholder="325" />
                                                {showError && addonPriceforPerExtraInches == '' ? <p style={{ color: 'red' }}>this field can't be empty</p> : ''}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="Active-bttn btn" onClick={savaAddon}> Save </button>
                                <button className="Cancel-bttn btn" onClick={handleClose03}> Cancel </button>
                            </Modal.Footer>
                        </Modal>
                        {/* modal End here */}
                        <div className="dashboard-container d-flex">
                            <div className="sidebar-container">
                                <Sidebar />
                            </div>
                            <div className="main-container">
                                <div className="main-page-body-content">
                                    {/* <div className="main-page-body-content-header">
                                        <Header />
                                    </div> */}
                                    <div className="main-page-body-content-body">
                                        <div className="main-page-body-content-body-container">
                                            <div className="container-fulid">
                                                <div className="">
                                                    <div className="table-card ">
                                                        <div className="card-body table-card-body">
                                                            <div className="table-card-header mb-4 d-flex justify-content-between">
                                                                <h5 className="card-title">Addon List</h5>
                                                                <button className="btn btn-primary d-flex align-items-center btn-sm add-user-btn" onClick={handleShow03} ><RiAddFill />Create Addon </button>
                                                            </div>
                                                            <div className="table-card-body-inner">
                                                                <Informasi />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )
            }
        </>
    );
}

export default Addon;