import React from "react";
import Form from 'react-bootstrap/Form';
import { useContext, useEffect, useState } from 'react';
import { SettingContext } from '../../Setting/SettingContext';
import { updatenotificationsettings } from '../../Api/settingApi';

function TabData5() {

    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings,paymenetmethod , setPaymenetmethod,emailData, setEmailData,emailTemplate, setEmailTemplate,promocodeData , setPromocodeData] = useContext(SettingContext);

    
    const [adminemailnotification, setAdminemailNotifications] = useState(false);
    const [clientemailnotification, setClientemailNotificationsEmail] = useState(false);
    const [technicianemailnotification, setTechnicianemailNotificationsSms] = useState(false);
    
    useEffect(() => {
        EmailDataCheck();
    }, [emailData]);



    const EmailDataCheck = () => {
        if(emailData.admin_email_notifications === "1"){
            setAdminemailNotifications(true);
        }
        else{
            setAdminemailNotifications(false);
        }

        if(emailData.client_email_notifications === "1"){
            setClientemailNotificationsEmail(true);
        }

        else{
            setClientemailNotificationsEmail(false);
        }

        if(emailData.technician_email_notification === "1"){
            setTechnicianemailNotificationsSms(true);
        }
        else{
            setTechnicianemailNotificationsSms(false);
        }
    }

    const handleEditInputChange = (event) => {
        const { name, value } = event.target;
        setEmailData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }));
    };

    const SaveEmailData = (e) => {
        e.preventDefault();


        const formData = new FormData();
        formData.append('administrator_email', emailData.administrator_email);
        formData.append('sender_name', emailData.sender_name);
        formData.append('sender_email', emailData.sender_email);
        formData.append('smtp_host', emailData.smtp_host);
        formData.append('smtp_port', emailData.smtp_port);
        formData.append('smtp_hostname', emailData.smtp_hostname);
        formData.append('smtp_username', emailData.smtp_username);
        formData.append('smtp_password', emailData.smtp_password);
        formData.append('smtp_encryption', emailData.encryption_type);
        formData.append('smtp_authentication', emailData.smtp_authentication);


        if(adminemailnotification === true){
            formData.append('admin_email_notification', 1);
        }
        else{
            formData.append('admin_email_notification', 0);
        }

        if(clientemailnotification === true){
            formData.append('client_email_notification', 1);
        }
        else{
            formData.append('client_email_notification', 0);
        }

        if(technicianemailnotification === true){
            formData.append('technician_email_notification', 1);
        }
        else{
            formData.append('technician_email_notification', 0);
        }

        updatenotificationsettings(formData).then((res) => {
            if (res.code === 200) {
                if(res.data.status === "success"){
                    alert("Data Updated Successfully!");
                }
                else{
                    alert("Data Not Updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });


    }

        

    

    return (
        <div>
            <form id='form'>
                <div className="row custom-pt-20">
                    <div className="col-md-12">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Administrator Email</label>
                                <input type="email" className="form-control" value={emailData.administrator_email || "" } name="administrator_email" onChange={handleEditInputChange} id="exampleFormControlInput1" placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Sender Name</label>
                                <input type="text" className="form-control" value={emailData.sender_name || ""} name="sender_name" onChange={handleEditInputChange} id="exampleFormControlInput1" placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Sender Email</label>
                                <input type="email" className="form-control" value={emailData.sender_email || ""} name="sender_email" onChange={handleEditInputChange} id="exampleFormControlInput1" placeholder="user@gmail.com" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">SMTP Hostname</label>
                                <input type="number" className="form-control" value={emailData.smtp_hostname || ""} name="smtp_hostname" onChange={handleEditInputChange}  id="exampleFormControlInput1" placeholder="901254" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">SMTP Username</label>
                                <input type="text" className="form-control" value={emailData.smtp_username || ""} name="smtp_username" onChange={handleEditInputChange} id="exampleFormControlInput1" placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">SMTP Password</label>
                                <input type="password" className="form-control" value={emailData.smtp_password || ""} name="smtp_password" onChange={handleEditInputChange} id="exampleFormControlInput1" placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">SMTP Port</label>
                                <input type="number" className="form-control" value={emailData.smtp_port || ""} name="smtp_port" onChange={handleEditInputChange} id="exampleFormControlInput1" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Encryption Type</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" name="encryption_type" onChange={handleEditInputChange}>
                                <option>Select Encryption Type</option>
                                <option value="1" selected={emailData.encryption_type == 1 ? true : false}>Plain</option>
                                <option value="2" selected={emailData.encryption_type == 2 ? true : false}>TLS</option>
                                <option value="3" selected={emailData.encryption_type == 3 ? true : false}>SSL</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>SMTP Authentication</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" name="smtp_authentication" onChange={handleEditInputChange}>
                                <option>Select SMTP Authentication</option>
                                <option value="1" selected={emailData.smtp_authentication == 1 ? true : false}>True</option>
                                <option value="2" selected={emailData.smtp_authentication == 1 ? true : false}>False</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="content-body-form">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Admin Email Notifications"
                                checked={adminemailnotification}
                                onChange={(e) => {
                                    setAdminemailNotifications(e.target.checked);
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="content-body-form">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Client Email Notifications"
                                checked={clientemailnotification}
                                onChange={(e) => {
                                    setClientemailNotificationsEmail(e.target.checked);
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="content-body-form">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Technician Email Notification"
                                checked={technicianemailnotification}
                                onChange={(e) => {
                                    setTechnicianemailNotificationsSms(e.target.checked);
                                    }
                                }

                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className='form-btn'>
                            <div className='form-btn-right'>
                                <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={SaveEmailData}>
                                    Save Setting
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default TabData5;