import React ,{ useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './slider.css';
import User from '../../assets/Images/hero/profile.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

import { getreviewlist } from '../../../Api/bookingApi';

function Slider2() {

    const [reviewdata, setReviewdata] = useState([]);

    useEffect(() => {
        getreviewlist().then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setReviewdata(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [])


    return (
        <Carousel>

            {/* {reviewdata && reviewdata.map((item, index) => ( */}
                {/* // <Carousel.Item key={index} interval={4000}>
                //     <div className='slider-content'>
                //         <div className='slider-content-view d-flex align-items-center'>
                //             <div>
                //                 <div className='slider-icon'>
                //                     <FontAwesomeIcon icon={faQuoteLeft} />
                //                 </div>
                //                 <div className='slider-text'>
                //                     <p>{item.review_text}</p> 
                //                 </div>
                //                 <div className='slider-name'>
                //                     <p className='user-name'>{item.reviewer_name}</p> 
                //                 </div>
                //             </div>
                //             <div className='slider-image'>
                //                 {/* <img src={item.userImage} alt='slider' className='user-image' /> Use the actual property from your data */}
                {/* //             </div> */}
                {/* //         </div> */}
                {/* //     </div> */}
                {/* // </Carousel.Item> */} 
            {/* // ))} */}




            <Carousel.Item interval={4000}>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={300}>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default Slider2;