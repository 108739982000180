import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/Css/Style.css';
import reportWebVitals from './reportWebVitals';

import MainRouter from './Router/MainRouter';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <MainRouter />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
