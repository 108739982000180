import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Hand from '../../assets/Images/aboutus/Hand.webp';
import List from '../../assets/Images/aboutus/List.webp';
import Trust from '../../assets/Images/aboutus/Trust.webp';

function Comman() {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };


    return (
        <div>
            <section className="contact-section custom-section-mr">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="content-text-box">
                                <div className="custom-title">
                                    <h2 className="title2" style={{ color: '#fff', fontWeight: '400' }}>Quality <span className="text-bold">service & peace of mind</span></h2>
                                </div>
                                <div className='description'>
                                    <p style={{ color: '#fff', fontSize: '18px' }}>We communicate honestly. No hidden fees and no surprises. Only quality work and trustworthy staff.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12" id='Getaquate'>
                            <div className="contact-form">
                                <div className="form-content">
                                    <div className="form-title">Let's Get Cleaning</div>
                                    <div className='form-section'>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                <Form.Label className='custom-lable'>Full Name</Form.Label>
                                                <Form.Control required type="name" placeholder="Enter Name" className='custom-input' />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your name
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                <Form.Label className='custom-lable'>Zip Code</Form.Label>
                                                <Form.Control required type="zipcode" placeholder="Enter zip code" className='custom-input' />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your zip code
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                <Form.Label className='custom-lable'>Number</Form.Label>
                                                <Form.Control required type="number" placeholder="Enter number" className='custom-input' />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your number
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                <Form.Label className='custom-lable'>Email</Form.Label>
                                                <Form.Control required type="email" placeholder="Email" className='custom-input' />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your email
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Button className='Login-btn-view' type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact section end here  */}

            {/* Reasons to Love Green Frog section start here  */}
            <section className='our-trust-sec custom-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='our-tusrt-tltle'>Reasons to Love Green Frog</div>
                            <div className='description' style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>We're different from your typical cleaners. Many companies try to compete on price but none can compete on service.<br /> Our goal is to WOW you with outstanding treatment and make your life easier.</div>
                        </div>
                    </div>
                    <div className='love-warpper'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='lp-element-box'>
                                    <div className='icon'>
                                        <img src={Hand} />
                                    </div>
                                    <div className='lp-element-text'>
                                        <div className='lp-element-title'>Our process makes it <span className='highlight'>easy for you</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='lp-element-box'>
                                    <div className='icon'>
                                        <img src={List} />
                                    </div>
                                    <div className='lp-element-text'>
                                        <div className='lp-element-title'>Our process makes it <span className='highlight'>easy for you</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='lp-element-box'>
                                    <div className='icon'>
                                        <img src={Trust} />
                                    </div>
                                    <div className='lp-element-text'>
                                        <div className='lp-element-title'>Our process makes it <span className='highlight'>easy for you</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Comman;