import React from "react";

function Leftimage({imgsrc, title, description}) {
    return (
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-md-1">
                <div className="about-text-content">
                    <div className="about-tltle">
                        <h2 className="">{title}</h2>
                    </div>
                    <div className="description">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                <div className="about-image-content">
                    <img src={imgsrc} alt="about" className="product-img" />
                </div>
            </div>
        </div>
    );
}

export default Leftimage;