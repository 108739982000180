// Create React App
import React from "react";
import Header from './camponent/header/Header';
import Social from './camponent/social/Social';
import Rightbar from './camponent/Rightbar';
import Testimonial from "./camponent/Testimonials/Testimonial";
import Usercomment from "./camponent/Testimonials/UserReviews";
import Profile from './assets/Images/profile.jpg';
import Footer from './camponent/footer/Footer';
import './assets/css/Contact.css';
import Header2 from "./camponent/header/Header2";
import Comman from "./camponent/CommanContent/Comman";
import { Helmet } from 'react-helmet';

function Contactus() {

    const generateMetaTags = () => {
        const metaTags = [
            { name: "title", content: `Greenfrog Contact` },
            { name: "description", content: `Greenfrog Contact` },
            { property: "og:title", content: `Greenfrog Contact` },
            { property: "og:description", content: `Greenfrog Contact` },
            { property: "og:url", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@greenfrog" },
            { name: "twitter:title", content: `Greenfrog Contact` },
            { name: "twitter:description", content: `Greenfrog Contact` },
        ];

        metaTags.push({ property: "og:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        metaTags.push({ name: "twitter:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        // if (og_image) {
        // }

        return metaTags;
    };

    return (
        <>
            <div>
                <Helmet>
                    {generateMetaTags().map((tag, index) => (
                        <meta key={index} {...tag} />
                    ))}
                </Helmet>
            </div>

            <section className="contact-us">
                <div className="Header">
                    <Header2 />
                </div>
                <div className="social">
                    <Social />
                </div>

                {/* hero section start here  */}
                <div className="Contact-us-hero custom-section" id="Contact-us-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Contact <span className='bold-title'><span className='bold-title bg-color'>Us</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* contact us form section start here  */}
                <div className="contact-us-section">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-xl-9 col-lg-8 col-md-8 col-sm-12'>
                                <div className="Contact-us-form cstm-mrgn">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">First Name</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Last Name</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Last Name" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Phone Number</label>
                                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Phone Number" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Email Address</label>
                                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlTextarea1" className="form-label">Comments</label>
                                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="submit-btn">
                                                <button type="button" className="btn submit-btn-contact-us">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Testimonial sectiontitle={'Testimonials'} sectiondescription={'Find out why Green Frog Cleaning is San Diego’s favorite cleaning company...'} />
                                <div className="row">
                                    <div className="col-md-6">
                                        <Usercomment imagesrc={Profile} username={'- Jennifer H'} userdiscription1={'We’ve been with Green Frog for a while and have been happy with our housekeeping services. I found them on Yelp and they responded through the app to my message. They always respond to my emails and answer the phone.'} heighlight={'The staff here is awesome!'} />
                                    </div>
                                    <div className="col-md-6">
                                        <Usercomment imagesrc={Profile} username={'Sarah B.'} heighlight={'I love Green Frog! '} userdiscription2={' I can not believe how thorough the initial cleaning was. It was fantastic. I have had my house cleaned by a few different services - no one has cleaned as well as Green Frog. I love that they use natural products, too.'} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12'>
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </div>
                {/* contact us form section end here  */}

                <Comman />

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}


            </section>
        </>
    );
}

export default Contactus;