import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import Header2 from "./camponent/header/Header2";

function CheckList() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="checklist-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Cleaning Services <span className='bold-title'><span className='bold-title bg-color'>Checklist</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Who We Are</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Welcome to Green Frog San Diego where we serve San Diego and the surrounding cities. We’ve built a legacy in premier house cleaning industry. Here at Green Frog Cleaning, we give the gift of time so that you are able to focus on the things that truly matter – Instead of cleaning the house all day. From bathrooms to bedrooms, our trained professional house cleaners are happy to do the dirty work for you. And in addition to regularly scheduled cleanings we also provide move in/move out cleaning services, deep cleanings, rental cleanings, and more. Areas we are proud to serve at this location are San Diego, Escondido, Apline, Pine Valley, Tijuana, Mira Mesa, Rancho, Santa fe, Chula Vista, Santee, Rosarito, Mt. Laguna, Carlsbad, and more. Still have some questions? <a className="action-email" href=""> Get in touch with us today!</a></p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Where You’ll Find Us</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <h2 className="sub-title">Green Frog House Cleaning La Mesa</h2>
                                        <p className="icon-warper">12941 B Poway Road Poway, CA 92064</p>
                                        <p className="icon-warper">Local: 1-(858)-216-2314</p>
                                        <p className="icon-warper">Toll Free: 1-(619)-937 2876</p>
                                        <p className="icon-warper">Fax: 1-(619)-937-2876</p>
                                        <p className="icon-warper">Email: <a className="action-email" href="">sales@greenfrogcleaning.com</a></p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Directions</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>We are located off the 15 freeway. If you are headed north take the Poway Road toward Rancho Penasquitos Blvd.</p>
                                        <p><span className="windowcleaning-bold-text">From San Diego Zoo Safari Park: </span>Follow I-15 S to Camino Del Norte in San Diego. Use the left to lanes to turn left onto Camino Del Norte. Continue on Twin Peaks Rd. Turn right onto Pomerado Rd and the destination will be on the left.</p>
                                        <p><span className="windowcleaning-bold-text">From Mesa Shopping Center: </span>Heading northeast on Mira Mesa Blvd, take a right to merge onto I-15 N. Head north for about 2 miles taking exit 18 toward Rancho Penasquitos Blvd. Keep right at the fork, follow signs for Poway Rd and merge onto Poway Rd. Turn right onto Pomerado Rd. The destination will be on the left.</p>
                                        <p><span className="windowcleaning-bold-text">From Del Mar Shopping Center: </span>Get on I-5 S and take it to exit 33 to Carmel Valley Rd. Then get on CA-56 E/State Hwy 56 E and follow CA-56 E/State Hwy 56 E to Rancho Penasquitos Blvd. Take exit 8 from CA-56 E/State Hwy 56 E for Rancho Penasquitos Blvd. Turn right onto Rancho Penasquitos Blvd and continue onto Poway Rd. Turn Right onto Pomerado Rd and the destination will be on the left.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default CheckList;