//create component for not found page
import React from 'react';

const NotFound = () => {
    return (
        <div className="content_404">
            <div className="connection_error">
                {/* <div className="logo-404">
                <img src={require ('../assets/images/logo_text.png')} alt="logo" />
            </div> */}
                <div className="text-404">
                    <img src={require('../assets/images/404_text.png')} alt="logo" />
                </div>
                <div className="sub-text">
                    Page Not Found
                </div>
                <div className="btn-404">
                    <button
                        className="btn go-back"
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NotFound;