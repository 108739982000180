import React, { useState, useContext, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from "react-bootstrap/Form";
import moment from "moment";
import { ModalPopContext } from "../Technician/ModalPopContext";
import { updateTechnicianAvailabilty } from "../Api/technician";
import { addmonthoffday } from "../Api/technician";



function Aviliti() {


  const [technicianDetails, setTechnicianDetails, serviceList, setServiceList, services, setServices, selectedOptions, setSelectedOptions, technicianAvailabilty, setTechnicianAvailibility, technicianOfftime, setTechnicianOfftime, technicianOffday, setTechnicianOffday, selectYear, setSelectYear] = useContext(ModalPopContext);

  const [selectWeek, setSelectWeek] = useState(1); 

  //set by default current week 
  useEffect(() => {
    var year = new Date().getFullYear();
    var month = new Date().getMonth();

    var weeks = [];
    var firstDate = new Date(year, month, 1);
    var lastDate = new Date(year, month + 1, 0);
    var numDays = lastDate.getDate();

    var start = 1;
    var end = 7 - firstDate.getDay();

    while (start <= numDays) {
      weeks.push({
        start: start,
        end: end
      });
      start = end + 1;
      end = end + 7;
      if (end > numDays) {
        end = numDays;
      }
    }


    var week = [];


    for (var i = 0; i < weeks.length; i++) {
      if (weeks[i].start <= new Date().getDate() && weeks[i].end >= new Date().getDate()) {
        week.push(i + 1);
        break;
      }
    }


    setSelectWeek(week[0]);


  }, []);


  const [Mondaycheck, setMondaycheck] = useState(false);
  const [Tuesdaycheck, setTuesdaycheck] = useState(false);
  const [Wednesdaycheck, setWednesdaycheck] = useState(false);
  const [Thursdaycheck, setThursdaycheck] = useState(false);
  const [Fridaycheck, setFridaycheck] = useState(false);
  const [Saturdaycheck, setSaturdaycheck] = useState(false);
  const [Sundaycheck, setSundaycheck] = useState(false);

  const [SecondMondaycheck, setSecondMondaycheck] = useState(false);
  const [SecondTuesdaycheck, setSecondTuesdaycheck] = useState(false);
  const [SecondWednesdaycheck, setSecondWednesdaycheck] = useState(false);
  const [SecondThursdaycheck, setSecondThursdaycheck] = useState(false);
  const [SecondFridaycheck, setSecondFridaycheck] = useState(false);
  const [SecondSaturdaycheck, setSecondSaturdaycheck] = useState(false);
  const [SecondSundaycheck, setSecondSundaycheck] = useState(false);

  const [ThirdMondaycheck, setThirdMondaycheck] = useState(false);
  const [ThirdTuesdaycheck, setThirdTuesdaycheck] = useState(false);
  const [ThirdWednesdaycheck, setThirdWednesdaycheck] = useState(false);
  const [ThirdThursdaycheck, setThirdThursdaycheck] = useState(false);
  const [ThirdFridaycheck, setThirdFridaycheck] = useState(false);
  const [ThirdSaturdaycheck, setThirdSaturdaycheck] = useState(false);
  const [ThirdSundaycheck, setThirdSundaycheck] = useState(false);

  const [FourthMondaycheck, setFourthMondaycheck] = useState(false);
  const [FourthTuesdaycheck, setFourthTuesdaycheck] = useState(false);
  const [FourthWednesdaycheck, setFourthWednesdaycheck] = useState(false);
  const [FourthThursdaycheck, setFourthThursdaycheck] = useState(false);
  const [FourthFridaycheck, setFourthFridaycheck] = useState(false);
  const [FourthSaturdaycheck, setFourthSaturdaycheck] = useState(false);
  const [FourthSundaycheck, setFourthSundaycheck] = useState(false);

  const [FifthMondaycheck, setFifthMondaycheck] = useState(false);
  const [FifthTuesdaycheck, setFifthTuesdaycheck] = useState(false);
  const [FifthWednesdaycheck, setFifthWednesdaycheck] = useState(false);
  const [FifthThursdaycheck, setFifthThursdaycheck] = useState(false);
  const [FifthFridaycheck, setFifthFridaycheck] = useState(false);
  const [FifthSaturdaycheck, setFifthSaturdaycheck] = useState(false);
  const [FifthSundaycheck, setFifthSundaycheck] = useState(false);

  const [SixthMondaycheck, setSixthMondaycheck] = useState(false);
  const [SixthTuesdaycheck, setSixthTuesdaycheck] = useState(false);
  const [SixthWednesdaycheck, setSixthWednesdaycheck] = useState(false);
  const [SixthThursdaycheck, setSixthThursdaycheck] = useState(false);
  const [SixthFridaycheck, setSixthFridaycheck] = useState(false);
  const [SixthSaturdaycheck, setSixthSaturdaycheck] = useState(false);
  const [SixthSundaycheck, setSixthSundaycheck] = useState(false);


  const [Mondaystart, setMondaystart] = useState("12:00:00");
  const [Mondayend, setMondayend] = useState("12:00:00");
  const [Tuesdaystart, setTuesdaystart] = useState("12:00:00");
  const [Tuesdayend, setTuesdayend] = useState("12:00:00");
  const [Wednesdaystart, setWednesdaystart] = useState("12:00:00");
  const [Wednesdayend, setWednesdayend] = useState("12:00:00");
  const [Thursdaystart, setThursdaystart] = useState("12:00:00");
  const [Thursdayend, setThursdayend] = useState("12:00:00");
  const [Fridaystart, setFridaystart] = useState("12:00:00");
  const [Fridayend, setFridayend] = useState("12:00:00");
  const [Saturdaystart, setSaturdaystart] = useState("12:00:00");
  const [Saturdayend, setSaturdayend] = useState("12:00:00");
  const [Sundaystart, setSundaystart] = useState("12:00:00");
  const [Sundayend, setSundayend] = useState("12:00:00");

  const [SecondMondaystart, setSecondMondaystart] = useState("12:00:00");
  const [SecondMondayend, setSecondMondayend] = useState("12:00:00");
  const [SecondTuesdaystart, setSecondTuesdaystart] = useState("12:00:00");
  const [SecondTuesdayend, setSecondTuesdayend] = useState("12:00:00");
  const [SecondWednesdaystart, setSecondWednesdaystart] = useState("12:00:00");
  const [SecondWednesdayend, setSecondWednesdayend] = useState("12:00:00");
  const [SecondThursdaystart, setSecondThursdaystart] = useState("12:00:00");
  const [SecondThursdayend, setSecondThursdayend] = useState("12:00:00");
  const [SecondFridaystart, setSecondFridaystart] = useState("12:00:00");
  const [SecondFridayend, setSecondFridayend] = useState("12:00:00");
  const [SecondSaturdaystart, setSecondSaturdaystart] = useState("12:00:00");
  const [SecondSaturdayend, setSecondSaturdayend] = useState("12:00:00");
  const [SecondSundaystart, setSecondSundaystart] = useState("12:00:00");
  const [SecondSundayend, setSecondSundayend] = useState("12:00:00");

  const [ThirdMondaystart, setThirdMondaystart] = useState("12:00:00");
  const [ThirdMondayend, setThirdMondayend] = useState("12:00:00");
  const [ThirdTuesdaystart, setThirdTuesdaystart] = useState("12:00:00");
  const [ThirdTuesdayend, setThirdTuesdayend] = useState("12:00:00");
  const [ThirdWednesdaystart, setThirdWednesdaystart] = useState("12:00:00");
  const [ThirdWednesdayend, setThirdWednesdayend] = useState("12:00:00");
  const [ThirdThursdaystart, setThirdThursdaystart] = useState("12:00:00");
  const [ThirdThursdayend, setThirdThursdayend] = useState("12:00:00");
  const [ThirdFridaystart, setThirdFridaystart] = useState("12:00:00");
  const [ThirdFridayend, setThirdFridayend] = useState("12:00:00");
  const [ThirdSaturdaystart, setThirdSaturdaystart] = useState("12:00:00");
  const [ThirdSaturdayend, setThirdSaturdayend] = useState("12:00:00");
  const [ThirdSundaystart, setThirdSundaystart] = useState("12:00:00");
  const [ThirdSundayend, setThirdSundayend] = useState("12:00:00");

  const [FourthMondaystart, setFourthMondaystart] = useState("12:00:00");
  const [FourthMondayend, setFourthMondayend] = useState("12:00:00");
  const [FourthTuesdaystart, setFourthTuesdaystart] = useState("12:00:00");
  const [FourthTuesdayend, setFourthTuesdayend] = useState("12:00:00");
  const [FourthWednesdaystart, setFourthWednesdaystart] = useState("12:00:00");
  const [FourthWednesdayend, setFourthWednesdayend] = useState("12:00:00");
  const [FourthThursdaystart, setFourthThursdaystart] = useState("12:00:00");
  const [FourthThursdayend, setFourthThursdayend] = useState("12:00:00");
  const [FourthFridaystart, setFourthFridaystart] = useState("12:00:00");
  const [FourthFridayend, setFourthFridayend] = useState("12:00:00");
  const [FourthSaturdaystart, setFourthSaturdaystart] = useState("12:00:00");
  const [FourthSaturdayend, setFourthSaturdayend] = useState("12:00:00");
  const [FourthSundaystart, setFourthSundaystart] = useState("12:00:00");
  const [FourthSundayend, setFourthSundayend] = useState("12:00:00");


  const [FifthMondaystart, setFifthMondaystart] = useState("12:00:00");
  const [FifthMondayend, setFifthMondayend] = useState("12:00:00");
  const [FifthTuesdaystart, setFifthTuesdaystart] = useState("12:00:00");
  const [FifthTuesdayend, setFifthTuesdayend] = useState("12:00:00");
  const [FifthWednesdaystart, setFifthWednesdaystart] = useState("12:00:00");
  const [FifthWednesdayend, setFifthWednesdayend] = useState("12:00:00");
  const [FifthThursdaystart, setFifthThursdaystart] = useState("12:00:00");
  const [FifthThursdayend, setFifthThursdayend] = useState("12:00:00");
  const [FifthFridaystart, setFifthFridaystart] = useState("12:00:00");
  const [FifthFridayend, setFifthFridayend] = useState("12:00:00");
  const [FifthSaturdaystart, setFifthSaturdaystart] = useState("12:00:00");
  const [FifthSaturdayend, setFifthSaturdayend] = useState("12:00:00");
  const [FifthSundaystart, setFifthSundaystart] = useState("12:00:00");
  const [FifthSundayend, setFifthSundayend] = useState("12:00:00");

  const [SixthMondaystart, setSixthMondaystart] = useState("12:00:00");
  const [SixthMondayend, setSixthMondayend] = useState("12:00:00");
  const [SixthTuesdaystart, setSixthTuesdaystart] = useState("12:00:00");
  const [SixthTuesdayend, setSixthTuesdayend] = useState("12:00:00");
  const [SixthWednesdaystart, setSixthWednesdaystart] = useState("12:00:00");
  const [SixthWednesdayend, setSixthWednesdayend] = useState("12:00:00");
  const [SixthThursdaystart, setSixthThursdaystart] = useState("12:00:00");
  const [SixthThursdayend, setSixthThursdayend] = useState("12:00:00");
  const [SixthFridaystart, setSixthFridaystart] = useState("12:00:00");
  const [SixthFridayend, setSixthFridayend] = useState("12:00:00");
  const [SixthSaturdaystart, setSixthSaturdaystart] = useState("12:00:00");
  const [SixthSaturdayend, setSixthSaturdayend] = useState("12:00:00");
  const [SixthSundaystart, setSixthSundaystart] = useState("12:00:00");
  const [SixthSundayend, setSixthSundayend] = useState("12:00:00");


  const saveAvailibility = (e) => {
    e.preventDefault();

    const weeks = 6;
    const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const availibility = [];

    for (var i = 1; i <= weeks; i++) {
      for (var j = 0; j < weekdays.length; j++) {
        const weekday = weekdays[j];
        console.log("weekday", weekday);
        const weekday_id = j + 1;
        const isAvailable = eval(`${weekday}check`);
        const startTime = eval(`${weekday}start`);
        const endTime = eval(`${weekday}end`);

        //check isAvailable is true or false 
        availibility.push({
          day: weekday,
          isAvailable,
          startTime,
          endTime,
          weekday_id,
          week_id: i
        });
      }
    }

    const formData = new FormData();

    formData.append("technician_id", technicianDetails.id);
    formData.append("week_id", 1);
    formData.append("availibility", JSON.stringify(availibility));

    updateTechnicianAvailabilty(formData).then((res) => {
      if (res.code === 200) {
        window.location.reload();
      }
      else {
        alert("Something went wrong");
      }
    }).catch((err) => {
      alert("Something went wrong");
    });
  }
  const technicianid = technicianDetails.id;



  useEffect(() => {
    console.log("technicianid", technicianid);
  }, [technicianid]);

  useEffect(() => {
    getAvailibility();
  }, [technicianAvailabilty]);

  const Offtime = () => {
    const dataaa = [];
    technicianOfftime && technicianOfftime.map((item) => {
      dataaa.push(item.off_day);
    });
  }

  useEffect(() => {
    Offtime();
  }, [technicianOfftime]);

  const getAvailibility = () => {
    if (technicianAvailabilty !== null && technicianAvailabilty !== undefined && technicianAvailabilty.length > 0) {
      technicianAvailabilty && technicianAvailabilty.map((item) => {
        if (item.week_id == 1) {
          if (item.weekday_id == 1) {
            setMondaycheck(item.off_day === 'Y' ? true : false);
            setMondaystart(item.day_start_time);
            setMondayend(item.day_end_time);
          }
          if (item.weekday_id == 2) {
            setTuesdaycheck(item.off_day === 'Y' ? true : false);
            setTuesdaystart(item.day_start_time);
            setTuesdayend(item.day_end_time);
          }
          if (item.weekday_id == 3) {
            setWednesdaycheck(item.off_day === 'Y' ? true : false);
            setWednesdaystart(item.day_start_time);
            setWednesdayend(item.day_end_time);
          }
          if (item.weekday_id == 4) {
            setThursdaycheck(item.off_day === 'Y' ? true : false);
            setThursdaystart(item.day_start_time);
            setThursdayend(item.day_end_time);
          }
          if (item.weekday_id == 5) {
            setFridaycheck(item.off_day === 'Y' ? true : false);
            setFridaystart(item.day_start_time);
            setFridayend(item.day_end_time);
          }
          if (item.weekday_id == 6) {
            setSaturdaycheck(item.off_day === 'Y' ? true : false);
            setSaturdaystart(item.day_start_time);
            setSaturdayend(item.day_end_time);
          }
          if (item.weekday_id == 7) {
            setSundaycheck(item.off_day === 'Y' ? true : false);
            setSundaystart(item.day_start_time);
            setSundayend(item.day_end_time);
          }
        }
        if (item.week_id == 2) {
          if (item.weekday_id == 1) {
            setSecondMondaycheck(item.off_day === 'Y' ? true : false);
            setSecondMondaystart(item.day_start_time);
            setSecondMondayend(item.day_end_time);
          }
          if (item.weekday_id == 2) {
            setSecondTuesdaycheck(item.off_day === 'Y' ? true : false);
            setSecondTuesdaystart(item.day_start_time);
            setSecondTuesdayend(item.day_end_time);
          }
          if (item.weekday_id == 3) {
            setSecondWednesdaycheck(item.off_day === 'Y' ? true : false);
            setSecondWednesdaystart(item.day_start_time);
            setSecondWednesdayend(item.day_end_time);
          }
          if (item.weekday_id == 4) {
            setSecondThursdaycheck(item.off_day === 'Y' ? true : false);
            setSecondThursdaystart(item.day_start_time);
            setSecondThursdayend(item.day_end_time);
          }
          if (item.weekday_id == 5) {
            setSecondFridaycheck(item.off_day === 'Y' ? true : false);
            setSecondFridaystart(item.day_start_time);
            setSecondFridayend(item.day_end_time);
          }
          if (item.weekday_id == 6) {
            setSecondSaturdaycheck(item.off_day === 'Y' ? true : false);
            setSecondSaturdaystart(item.day_start_time);
            setSecondSaturdayend(item.day_end_time);
          }
          if (item.weekday_id == 7) {
            setSecondSundaycheck(item.off_day === 'Y' ? true : false);
            setSecondSundaystart(item.day_start_time);
            setSecondSundayend(item.day_end_time);
          }
        }
        if (item.week_id == 3) {
          if (item.weekday_id == 1) {
            setThirdMondaycheck(item.off_day === 'Y' ? true : false);
            setThirdMondaystart(item.day_start_time);
            setThirdMondayend(item.day_end_time);
          }
          if (item.weekday_id == 2) {
            setThirdTuesdaycheck(item.off_day === 'Y' ? true : false);
            setThirdTuesdaystart(item.day_start_time);
            setThirdTuesdayend(item.day_end_time);
          }
          if (item.weekday_id == 3) {
            setThirdWednesdaycheck(item.off_day === 'Y' ? true : false);
            setThirdWednesdaystart(item.day_start_time);
            setThirdWednesdayend(item.day_end_time);
          }
          if (item.weekday_id == 4) {
            setThirdThursdaycheck(item.off_day === 'Y' ? true : false);
            setThirdThursdaystart(item.day_start_time);
            setThirdThursdayend(item.day_end_time);
          }
          if (item.weekday_id == 5) {
            setThirdFridaycheck(item.off_day === 'Y' ? true : false);
            setThirdFridaystart(item.day_start_time);
            setThirdFridayend(item.day_end_time);
          }
          if (item.weekday_id == 6) {
            setThirdSaturdaycheck(item.off_day === 'Y' ? true : false);
            setThirdSaturdaystart(item.day_start_time);
            setThirdSaturdayend(item.day_end_time);
          }
          if (item.weekday_id == 7) {
            setThirdSundaycheck(item.off_day === 'Y' ? true : false);
            setThirdSundaystart(item.day_start_time);
            setThirdSundayend(item.day_end_time);
          }
        }
        if (item.week_id == 4) {
          if (item.weekday_id == 1) {
            setFourthMondaycheck(item.off_day === 'Y' ? true : false);
            setFourthMondaystart(item.day_start_time);
            setFourthMondayend(item.day_end_time);
          }
          if (item.weekday_id == 2) {
            setFourthTuesdaycheck(item.off_day === 'Y' ? true : false);
            setFourthTuesdaystart(item.day_start_time);
            setFourthTuesdayend(item.day_end_time);
          }
          if (item.weekday_id == 3) {
            setFourthWednesdaycheck(item.off_day === 'Y' ? true : false);
            setFourthWednesdaystart(item.day_start_time);
            setFourthWednesdayend(item.day_end_time);
          }
          if (item.weekday_id == 4) {
            setFourthThursdaycheck(item.off_day === 'Y' ? true : false);
            setFourthThursdaystart(item.day_start_time);
            setFourthThursdayend(item.day_end_time);
          }
          if (item.weekday_id == 5) {
            setFourthFridaycheck(item.off_day === 'Y' ? true : false);
            setFourthFridaystart(item.day_start_time);
            setFourthFridayend(item.day_end_time);
          }
          if (item.weekday_id == 6) {
            setFourthSaturdaycheck(item.off_day === 'Y' ? true : false);
            setFourthSaturdaystart(item.day_start_time);
            setFourthSaturdayend(item.day_end_time);
          }
          if (item.weekday_id == 7) {
            setFourthSundaycheck(item.off_day === 'Y' ? true : false);
            setFourthSundaystart(item.day_start_time);
            setFourthSundayend(item.day_end_time);
          }
        }
        if (item.week_id == 5) {
          if (item.weekday_id == 1) {
            setFifthMondaycheck(item.off_day === 'Y' ? true : false);
            setFifthMondaystart(item.day_start_time);
            setFifthMondayend(item.day_end_time);
          }
          if (item.weekday_id == 2) {
            setFifthTuesdaycheck(item.off_day === 'Y' ? true : false);
            setFifthTuesdaystart(item.day_start_time);
            setFifthTuesdayend(item.day_end_time);
          }
          if (item.weekday_id == 3) {
            setFifthWednesdaycheck(item.off_day === 'Y' ? true : false);
            setFifthWednesdaystart(item.day_start_time);
            setFifthWednesdayend(item.day_end_time);
          }
          if (item.weekday_id == 4) {
            setFifthThursdaycheck(item.off_day === 'Y' ? true : false);
            setFifthThursdaystart(item.day_start_time);
            setFifthThursdayend(item.day_end_time);
          }
          if (item.weekday_id == 5) {
            setFifthFridaycheck(item.off_day === 'Y' ? true : false);
            setFifthFridaystart(item.day_start_time);
            setFifthFridayend(item.day_end_time);
          }
          if (item.weekday_id == 6) {
            setFifthSaturdaycheck(item.off_day === 'Y' ? true : false);
            setFifthSaturdaystart(item.day_start_time);
            setFifthSaturdayend(item.day_end_time);
          }
          if (item.weekday_id == 7) {
            setFifthSundaycheck(item.off_day === 'Y' ? true : false);
            setFifthSundaystart(item.day_start_time);
            setFifthSundayend(item.day_end_time);
          }
        }
        if (item.week_id == 6) {
          if (item.weekday_id == 1) {
            setSixthMondaycheck(item.off_day === 'Y' ? true : false);
            setSixthMondaystart(item.day_start_time);
            setSixthMondayend(item.day_end_time);
          }
          if (item.weekday_id == 2) {
            setSixthTuesdaycheck(item.off_day === 'Y' ? true : false);
            setSixthTuesdaystart(item.day_start_time);
            setSixthTuesdayend(item.day_end_time);
          }
          if (item.weekday_id == 3) {
            setSixthWednesdaycheck(item.off_day === 'Y' ? true : false);
            setSixthWednesdaystart(item.day_start_time);
            setSixthWednesdayend(item.day_end_time);
          }
          if (item.weekday_id == 4) {
            setSixthThursdaycheck(item.off_day === 'Y' ? true : false);
            setSixthThursdaystart(item.day_start_time);
            setSixthThursdayend(item.day_end_time);
          }
          if (item.weekday_id == 5) {
            setSixthFridaycheck(item.off_day === 'Y' ? true : false);
            setSixthFridaystart(item.day_start_time);
            setSixthFridayend(item.day_end_time);
          }
          if (item.weekday_id == 6) {
            setSixthSaturdaycheck(item.off_day === 'Y' ? true : false);
            setSixthSaturdaystart(item.day_start_time);
            setSixthSaturdayend(item.day_end_time);
          }
          if (item.weekday_id == 7) {
            setSixthSundaycheck(item.off_day === 'Y' ? true : false);
            setSixthSundaystart(item.day_start_time);
            setSixthSundayend(item.day_end_time);
          }

        }

      });
    }
    else { 
      setMondaycheck(false);
      setMondaystart("12:00:00");
      setMondayend("12:00:00");
      setTuesdaycheck(false);
      setTuesdaystart("12:00:00");
      setTuesdayend("12:00:00");
      setWednesdaycheck(false);
      setWednesdaystart("12:00:00");
      setWednesdayend("12:00:00");
      setThursdaycheck(false);
      setThursdaystart("12:00:00");
      setThursdayend("12:00:00");
      setFridaycheck(false);
      setFridaystart("12:00:00");
      setFridayend("12:00:00");
      setSaturdaycheck(false);
      setSaturdaystart("12:00:00");
      setSaturdayend("12:00:00");
      setSundaycheck(false);
      setSundaystart("12:00:00");
      setSundayend("12:00:00");

      setSecondMondaycheck(false);
      setSecondMondaystart("12:00:00");
      setSecondMondayend("12:00:00");
      setSecondTuesdaycheck(false);
      setSecondTuesdaystart("12:00:00");
      setSecondTuesdayend("12:00:00");
      setSecondWednesdaycheck(false);
      setSecondWednesdaystart("12:00:00");
      setSecondWednesdayend("12:00:00");
      setSecondThursdaycheck(false);
      setSecondThursdaystart("12:00:00");
      setSecondThursdayend("12:00:00");
      setSecondFridaycheck(false);
      setSecondFridaystart("12:00:00");
      setSecondFridayend("12:00:00");
      setSecondSaturdaycheck(false);
      setSecondSaturdaystart("12:00:00");
      setSecondSaturdayend("12:00:00");
      setSecondSundaycheck(false);
      setSecondSundaystart("12:00:00");
      setSecondSundayend("12:00:00");

      setThirdMondaycheck(false);
      setThirdMondaystart("12:00:00");
      setThirdMondayend("12:00:00");
      setThirdTuesdaycheck(false);
      setThirdTuesdaystart("12:00:00");
      setThirdTuesdayend("12:00:00");
      setThirdWednesdaycheck(false);
      setThirdWednesdaystart("12:00:00");
      setThirdWednesdayend("12:00:00");
      setThirdThursdaycheck(false);
      setThirdThursdaystart("12:00:00");
      setThirdThursdayend("12:00:00");
      setThirdFridaycheck(false);
      setThirdFridaystart("12:00:00");
      setThirdFridayend("12:00:00");
      setThirdSaturdaycheck(false);
      setThirdSaturdaystart("12:00:00");
      setThirdSaturdayend("12:00:00");
      setThirdSundaycheck(false);
      setThirdSundaystart("12:00:00");
      setThirdSundayend("12:00:00");

      setFourthMondaycheck(false);
      setFourthMondaystart("12:00:00");
      setFourthMondayend("12:00:00");
      setFourthTuesdaycheck(false);
      setFourthTuesdaystart("12:00:00");
      setFourthTuesdayend("12:00:00");
      setFourthWednesdaycheck(false);
      setFourthWednesdaystart("12:00:00");
      setFourthWednesdayend("12:00:00");
      setFourthThursdaycheck(false);
      setFourthThursdaystart("12:00:00");
      setFourthThursdayend("12:00:00");
      setFourthFridaycheck(false);
      setFourthFridaystart("12:00:00");
      setFourthFridayend("12:00:00");
      setFourthSaturdaycheck(false);
      setFourthSaturdaystart("12:00:00");
      setFourthSaturdayend("12:00:00");
      setFourthSundaycheck(false);
      setFourthSundaystart("12:00:00");
      setFourthSundayend("12:00:00");

      setFifthMondaycheck(false);
      setFifthMondaystart("12:00:00");
      setFifthMondayend("12:00:00");
      setFifthTuesdaycheck(false);
      setFifthTuesdaystart("12:00:00");
      setFifthTuesdayend("12:00:00");
      setFifthWednesdaycheck(false);
      setFifthWednesdaystart("12:00:00");
      setFifthWednesdayend("12:00:00");
      setFifthThursdaycheck(false);
      setFifthThursdaystart("12:00:00");
      setFifthThursdayend("12:00:00");
      setFifthFridaycheck(false);
      setFifthFridaystart("12:00:00");
      setFifthFridayend("12:00:00");
      setFifthSaturdaycheck(false);
      setFifthSaturdaystart("12:00:00");


      setSixthMondaycheck(false);
      setSixthMondaystart("12:00:00");
      setSixthMondayend("12:00:00");
      setSixthTuesdaycheck(false);
      setSixthTuesdaystart("12:00:00");
      setSixthTuesdayend("12:00:00");
      setSixthWednesdaycheck(false);
      setSixthWednesdaystart("12:00:00");
      setSixthWednesdayend("12:00:00");
      setSixthThursdaycheck(false);
      setSixthThursdaystart("12:00:00");
      setSixthThursdayend("12:00:00");
      setSixthFridaycheck(false);
      setSixthFridaystart("12:00:00");
      setSixthFridayend("12:00:00");
      setSixthSaturdaycheck(false);
      setSixthSaturdaystart("12:00:00");
      setSixthSaturdayend("12:00:00");
      setSixthSundaycheck(false);
      setSixthSundaystart("12:00:00");
      setSixthSundayend("12:00:00");




    }
  };

  const getandupdatefirstweek = () => {
    const monday = {
      day: "Monday",
      isAvailable: Mondaycheck,
      startTime: Mondaystart,
      endTime: Mondayend,
      weekday_id: 1,
      week_id: 1
    };

    const tuesday = {
      day: "Tuesday",
      isAvailable: Tuesdaycheck,
      startTime: Tuesdaystart,
      endTime: Tuesdayend,
      weekday_id: 2,
      week_id: 1
    };

    const wednesday = {
      day: "Wednesday",
      isAvailable: Wednesdaycheck,
      startTime: Wednesdaystart,
      endTime: Wednesdayend,
      weekday_id: 3,
      week_id: 1
    };

    const thursday = {
      day: "Thursday",
      isAvailable: Thursdaycheck,
      startTime: Thursdaystart,
      endTime: Thursdayend,
      weekday_id: 4,
      week_id: 1
    };

    const friday = {
      day: "Friday",
      isAvailable: Fridaycheck,
      startTime: Fridaystart,
      endTime: Fridayend,
      weekday_id: 5,
      week_id: 1
    };

    const saturday = {
      day: "Saturday",
      isAvailable: Saturdaycheck,
      startTime: Saturdaystart,
      endTime: Saturdayend,
      weekday_id: 6,
      week_id: 1
    };

    const sunday = {
      day: "Sunday",
      isAvailable: Sundaycheck,
      startTime: Sundaystart,
      endTime: Sundayend,
      weekday_id: 7,
      week_id: 1
    };


    const availibility = [];
    if (monday.isAvailable) {
      availibility.push(monday);
    }
    if (tuesday.isAvailable) {
      availibility.push(tuesday);
    }
    if (wednesday.isAvailable) {
      availibility.push(wednesday);
    }
    if (thursday.isAvailable) {
      availibility.push(thursday);
    }
    if (friday.isAvailable) {
      availibility.push(friday);
    }
    if (saturday.isAvailable) {
      availibility.push(saturday);
    }
    if (sunday.isAvailable) {
      availibility.push(sunday);
    }
    console.log("availibility", availibility);
    return availibility;

  };

  const getandupdatesecondweek = () => {

    const monday = {
      day: "Monday",
      isAvailable: SecondMondaycheck,
      startTime: SecondMondaystart,
      endTime: SecondMondayend,
      weekday_id: 1,
      week_id: 2
    };

    const tuesday = {
      day: "Tuesday",
      isAvailable: SecondTuesdaycheck,
      startTime: SecondTuesdaystart,
      endTime: SecondTuesdayend,
      weekday_id: 2,
      week_id: 2
    };

    const wednesday = {
      day: "Wednesday",
      isAvailable: SecondWednesdaycheck,
      startTime: SecondWednesdaystart,
      endTime: SecondWednesdayend,
      weekday_id: 3,
      week_id: 2
    };

    const thursday = {
      day: "Thursday",
      isAvailable: SecondThursdaycheck,
      startTime: SecondThursdaystart,
      endTime: SecondThursdayend,
      weekday_id: 4,
      week_id: 2
    };

    const friday = {
      day: "Friday",
      isAvailable: SecondFridaycheck,
      startTime: SecondFridaystart,
      endTime: SecondFridayend,
      weekday_id: 5,
      week_id: 2
    };

    const saturday = {
      day: "Saturday",
      isAvailable: SecondSaturdaycheck,
      startTime: SecondSaturdaystart,
      endTime: SecondSaturdayend,
      weekday_id: 6,
      week_id: 2
    };

    const sunday = {
      day: "Sunday",
      isAvailable: SecondSundaycheck,
      startTime: SecondSundaystart,
      endTime: SecondSundayend,
      weekday_id: 7,
      week_id: 2
    };


    const availibility = [];
    if (monday.isAvailable) {
      availibility.push(monday);
    }
    if (tuesday.isAvailable) {
      availibility.push(tuesday);
    }
    if (wednesday.isAvailable) {
      availibility.push(wednesday);
    }
    if (thursday.isAvailable) {
      availibility.push(thursday);
    }
    if (friday.isAvailable) {
      availibility.push(friday);
    }
    if (saturday.isAvailable) {
      availibility.push(saturday);
    }
    if (sunday.isAvailable) {
      availibility.push(sunday);
    }
    console.log("secondweek", availibility);
    return availibility;

  };

  const getandupdatethirdweek = () => {

    const monday = {
      day: "Monday",
      isAvailable: ThirdMondaycheck,
      startTime: ThirdMondaystart,
      endTime: ThirdMondayend,
      weekday_id: 1,
      week_id: 3
    };

    const tuesday = {
      day: "Tuesday",
      isAvailable: ThirdTuesdaycheck,
      startTime: ThirdTuesdaystart,
      endTime: ThirdTuesdayend,
      weekday_id: 2,
      week_id: 3
    };

    const wednesday = {
      day: "Wednesday",
      isAvailable: ThirdWednesdaycheck,
      startTime: ThirdWednesdaystart,
      endTime: ThirdWednesdayend,
      weekday_id: 3,
      week_id: 3
    };

    const thursday = {
      day: "Thursday",
      isAvailable: ThirdThursdaycheck,
      startTime: ThirdThursdaystart,
      endTime: ThirdThursdayend,
      weekday_id: 4,
      week_id: 3
    };

    const friday = {
      day: "Friday",
      isAvailable: ThirdFridaycheck,
      startTime: ThirdFridaystart,
      endTime: ThirdFridayend,
      weekday_id: 5,
      week_id: 3
    };


    const saturday = {
      day: "Saturday",
      isAvailable: ThirdSaturdaycheck,
      startTime: ThirdSaturdaystart,
      endTime: ThirdSaturdayend,
      weekday_id: 6,
      week_id: 3
    };

    const sunday = {
      day: "Sunday",
      isAvailable: ThirdSundaycheck,
      startTime: ThirdSundaystart,
      endTime: ThirdSundayend,
      weekday_id: 7,
      week_id: 3
    };

    const availibility = [];
    if (monday.isAvailable) {
      availibility.push(monday);
    }
    if (tuesday.isAvailable) {
      availibility.push(tuesday);
    }
    if (wednesday.isAvailable) {
      availibility.push(wednesday);
    }
    if (thursday.isAvailable) {
      availibility.push(thursday);
    }
    if (friday.isAvailable) {
      availibility.push(friday);
    }
    if (saturday.isAvailable) {
      availibility.push(saturday);
    }
    if (sunday.isAvailable) {
      availibility.push(sunday);
    }
    console.log("thirdweek", availibility);
    return availibility;
  };

  const getandupdatefourthweek = () => {

    const monday = {
      day: "Monday",
      isAvailable: FourthMondaycheck,
      startTime: FourthMondaystart,
      endTime: FourthMondayend,
      weekday_id: 1,
      week_id: 4
    };

    const tuesday = {
      day: "Tuesday",
      isAvailable: FourthTuesdaycheck,
      startTime: FourthTuesdaystart,
      endTime: FourthTuesdayend,
      weekday_id: 2,
      week_id: 4
    };

    const wednesday = {
      day: "Wednesday",
      isAvailable: FourthWednesdaycheck,
      startTime: FourthWednesdaystart,
      endTime: FourthWednesdayend,
      weekday_id: 3,
      week_id: 4
    };

    const thursday = {
      day: "Thursday",
      isAvailable: FourthThursdaycheck,
      startTime: FourthThursdaystart,
      endTime: FourthThursdayend,
      weekday_id: 4,
      week_id: 4
    };

    const friday = {
      day: "Friday",
      isAvailable: FourthFridaycheck,
      startTime: FourthFridaystart,
      endTime: FourthFridayend,
      weekday_id: 5,
      week_id: 4
    };

    const saturday = {
      day: "Saturday",
      isAvailable: FourthSaturdaycheck,
      startTime: FourthSaturdaystart,
      endTime: FourthSaturdayend,
      weekday_id: 6,
      week_id: 4
    };

    const sunday = {
      day: "Sunday",
      isAvailable: FourthSundaycheck,
      startTime: FourthSundaystart,
      endTime: FourthSundayend,
      weekday_id: 7,
      week_id: 4
    };

    const availibility = [];
    if (monday.isAvailable) {
      availibility.push(monday);
    }
    if (tuesday.isAvailable) {
      availibility.push(tuesday);
    }
    if (wednesday.isAvailable) {
      availibility.push(wednesday);
    }
    if (thursday.isAvailable) {
      availibility.push(thursday);
    }
    if (friday.isAvailable) {
      availibility.push(friday);
    }
    if (saturday.isAvailable) {
      availibility.push(saturday);
    }
    if (sunday.isAvailable) {
      availibility.push(sunday);
    }
    console.log("fourthweek", availibility);
    return availibility;
  };

  const getandupdatefifthweek = () => {

    const monday = {
      day: "Monday",
      isAvailable: FifthMondaycheck,
      startTime: FifthMondaystart,
      endTime: FifthMondayend,
      weekday_id: 1,
      week_id: 5
    };

    const tuesday = {
      day: "Tuesday",
      isAvailable: FifthTuesdaycheck,
      startTime: FifthTuesdaystart,
      endTime: FifthTuesdayend,
      weekday_id: 2,
      week_id: 5
    };

    const wednesday = {
      day: "Wednesday",
      isAvailable: FifthWednesdaycheck,
      startTime: FifthWednesdaystart,
      endTime: FifthWednesdayend,
      weekday_id: 3,
      week_id: 5
    };

    const thursday = {
      day: "Thursday",
      isAvailable: FifthThursdaycheck,
      startTime: FifthThursdaystart,
      endTime: FifthThursdayend,
      weekday_id: 4,
      week_id: 5
    };

    const friday = {
      day: "Friday",
      isAvailable: FifthFridaycheck,
      startTime: FifthFridaystart,
      endTime: FifthFridayend,
      weekday_id: 5,
      week_id: 5
    };

    const saturday = {
      day: "Saturday",
      isAvailable: FifthSaturdaycheck,
      startTime: FifthSaturdaystart,
      endTime: FifthSaturdayend,
      weekday_id: 6,
      week_id: 5
    };

    const sunday = {
      day: "Sunday",
      isAvailable: FifthSundaycheck,
      startTime: FifthSundaystart,
      endTime: FifthSundayend,
      weekday_id: 7,
      week_id: 5
    };

    const availibility = [];
    if (monday.isAvailable) {
      availibility.push(monday);
    }
    if (tuesday.isAvailable) {
      availibility.push(tuesday);
    }
    if (wednesday.isAvailable) {
      availibility.push(wednesday);
    }
    if (thursday.isAvailable) {
      availibility.push(thursday);
    }
    if (friday.isAvailable) {
      availibility.push(friday);
    }
    if (saturday.isAvailable) {
      availibility.push(saturday);
    }
    if (sunday.isAvailable) {
      availibility.push(sunday);
    }
    console.log("fifth", availibility);
    return availibility;
  };

  const getandupdatesixthweek = () => {

    const monday = {
      day: "Monday",
      isAvailable: SixthMondaycheck,
      startTime: SixthMondaystart,
      endTime: SixthMondayend,
      weekday_id: 1,
      week_id: 6
    };

    const tuesday = {
      day: "Tuesday",
      isAvailable: SixthTuesdaycheck,
      startTime: SixthTuesdaystart,
      endTime: SixthTuesdayend,
      weekday_id: 2,
      week_id: 6
    };

    const wednesday = {
      day: "Wednesday",
      isAvailable: SixthWednesdaycheck,
      startTime: SixthWednesdaystart,
      endTime: SixthWednesdayend,
      weekday_id: 3,
      week_id: 6
    };

    const thursday = {
      day: "Thursday",

      isAvailable: SixthThursdaycheck,
      startTime: SixthThursdaystart,
      endTime: SixthThursdayend,
      weekday_id: 4,
      week_id: 6
    };

    const friday = {
      day: "Friday",
      isAvailable: SixthFridaycheck,
      startTime: SixthFridaystart,
      endTime: SixthFridayend,
      weekday_id: 5,
      week_id: 6
    };

    const saturday = {
      day: "Saturday",
      isAvailable: SixthSaturdaycheck,
      startTime: SixthSaturdaystart,
      endTime: SixthSaturdayend,
      weekday_id: 6,
      week_id: 6
    };

    const sunday = {
      day: "Sunday",
      isAvailable: SixthSundaycheck,
      startTime: SixthSundaystart,
      endTime: SixthSundayend,
      weekday_id: 7,
      week_id: 6
    };

    const availibility = [];
    if (monday.isAvailable) {
      availibility.push(monday);
    }
    if (tuesday.isAvailable) {
      availibility.push(tuesday);
    }
    if (wednesday.isAvailable) {
      availibility.push(wednesday);
    }
    if (thursday.isAvailable) {
      availibility.push(thursday);
    }
    if (friday.isAvailable) {
      availibility.push(friday);
    }
    if (saturday.isAvailable) {
      availibility.push(saturday);
    }
    if (sunday.isAvailable) {
      availibility.push(sunday);
    }
    console.log("sixthweek", availibility);
    return availibility;
  };

  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [options, setOptions] = useState([
    { value: "07:00:00", label: "07:00 AM" },
    { value: "07:30:00", label: "07:30 AM" },
    { value: "08:00:00", label: "08:00 AM" },
    { value: "08:30:00", label: "08:30 AM" },
    { value: "09:00:00", label: "09:00 AM" },
    { value: "09:30:00", label: "09:30 AM" },
    { value: "10:00:00", label: "10:00 AM" },
    { value: "10:30:00", label: "10:30 AM" },
    { value: "11:00:00", label: "11:00 AM" },
    { value: "11:30:00", label: "11:30 AM" },
    { value: "12:00:00", label: "12:00 PM" },
    { value: "12:30:00", label: "12:30 PM" },
    { value: "13:00:00", label: "01:00 PM" },
    { value: "13:30:00", label: "01:30 PM" },
    { value: "14:00:00", label: "02:00 PM" },
    { value: "14:30:00", label: "02:30 PM" },
    { value: "15:00:00", label: "03:00 PM" },
    { value: "15:30:00", label: "03:30 PM" },
    { value: "16:00:00", label: "04:00 PM" },
    { value: "16:30:00", label: "04:30 PM" },
    { value: "17:00:00", label: "05:00 PM" },
    { value: "17:30:00", label: "05:30 PM" },
    { value: "18:00:00", label: "06:00 PM" },
    { value: "18:30:00", label: "06:30 PM" },
    { value: "19:00:00", label: "07:00 PM" },
  ]);


  useEffect(() => {
    setFromTime(options[0].value);
    setToTime(options[0].value);
  }, []);

  return (
    <div>
      <Accordion defaultActiveKey="0">
        {/* <form > */}
        <div className="row custom-pt-20">
          <div className="col-md-12 mb-3">
            <div className="schedule-list-item">
              <div className="schedule-list-item-inner">
                <div className="schedule-list-item-inner-left">
                  <div className="schedule-list-item-inner-left-top">
                    <h4 className="schedule-list-item-inner-left-top-title">
                      Monday
                    </h4>
                  </div>
                </div>
                <div className="schedule-list-item-inner-center">
                  <div className="schedule-list-item-inner-center-top">
                    <Form.Check type="switch" id="custom-switch"
                      checked={Mondaycheck}
                      onChange={
                        (e) => {
                          setMondaycheck(e.target.checked);
                        }
                      } />
                  </div>
                </div>
                <div className="schedule-list-item-inner-right">
                  <div className="schedule-list-item-inner-right-top">
                    <div>
                      <Form.Select aria-label="Default select example"
                        onChange={
                          (e) => {
                            setMondaystart(e.target.value);
                          }
                        }
                      >
                        {options && options.map((option) => (
                          <option key={option.value} selected={Mondaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="spacing">to</div>
                    <div>
                      <Form.Select aria-label="Default select example"
                        onChange={
                          (e) => {
                            setMondayend(e.target.value);
                          }
                        }
                      >
                        {options && options.map((option) => (
                          <option key={option.value} selected={Mondayend == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="schedule-list-item">
              <div className="schedule-list-item-inner">
                <div className="schedule-list-item-inner-left">
                  <div className="schedule-list-item-inner-left-top">
                    <h4 className="schedule-list-item-inner-left-top-title">
                      Tuesday
                    </h4>
                  </div>
                </div>
                <div className="schedule-list-item-inner-center">
                  <div className="schedule-list-item-inner-center-top">
                    <Form.Check type="switch" id="custom-switch"
                      checked={Tuesdaycheck}
                      onChange={
                        (e) => {
                          setTuesdaycheck(e.target.checked);
                        }
                      } />
                  </div>
                </div>
                <div className="schedule-list-item-inner-right">
                  <div className="schedule-list-item-inner-right-top">
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setTuesdaystart(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Tuesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}

                      </Form.Select>
                    </div>
                    <div className="spacing">to</div>
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setTuesdayend(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Tuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="schedule-list-item">
              <div className="schedule-list-item-inner">
                <div className="schedule-list-item-inner-left">
                  <div className="schedule-list-item-inner-left-top">
                    <h4 className="schedule-list-item-inner-left-top-title">
                      Wednesday
                    </h4>
                  </div>
                </div>
                <div className="schedule-list-item-inner-center">
                  <div className="schedule-list-item-inner-center-top">
                    <Form.Check type="switch" id="custom-switch"
                      checked={Wednesdaycheck}
                      onChange={
                        (e) => {
                          setWednesdaycheck(e.target.checked);
                        }
                      } />
                  </div>
                </div>
                <div className="schedule-list-item-inner-right">
                  <div className="schedule-list-item-inner-right-top">
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setWednesdaystart(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Wednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="spacing">to</div>
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setWednesdayend(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Wednesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="schedule-list-item">
              <div className="schedule-list-item-inner">
                <div className="schedule-list-item-inner-left">
                  <div className="schedule-list-item-inner-left-top">
                    <h4 className="schedule-list-item-inner-left-top-title">
                      Thursday
                    </h4>
                  </div>
                </div>
                <div className="schedule-list-item-inner-center">
                  <div className="schedule-list-item-inner-center-top">
                    <Form.Check type="switch" id="custom-switch"
                      checked={Thursdaycheck}
                      onChange={
                        (e) => {
                          setThursdaycheck(e.target.checked);
                        }
                      } />
                  </div>
                </div>
                <div className="schedule-list-item-inner-right">
                  <div className="schedule-list-item-inner-right-top">
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setThursdaystart(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Thursdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}

                      </Form.Select>
                    </div>
                    <div className="spacing">to</div>
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setThursdayend(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Thursdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="schedule-list-item">
              <div className="schedule-list-item-inner">
                <div className="schedule-list-item-inner-left">
                  <div className="schedule-list-item-inner-left-top">
                    <h4 className="schedule-list-item-inner-left-top-title">
                      Friday
                    </h4>
                  </div>
                </div>
                <div className="schedule-list-item-inner-center">
                  <div className="schedule-list-item-inner-center-top">
                    <Form.Check type="switch" id="custom-switch"
                      checked={Fridaycheck}
                      onChange={
                        (e) => {
                          setFridaycheck(e.target.checked);
                        }
                      } />
                  </div>
                </div>
                <div className="schedule-list-item-inner-right">
                  <div className="schedule-list-item-inner-right-top">
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setFridaystart(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Fridaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="spacing">to</div>
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setFridayend(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Fridayend == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="schedule-list-item">
              <div className="schedule-list-item-inner">
                <div className="schedule-list-item-inner-left">
                  <div className="schedule-list-item-inner-left-top">
                    <h4 className="schedule-list-item-inner-left-top-title">
                      Saturday
                    </h4>
                  </div>
                </div>
                <div className="schedule-list-item-inner-center">
                  <div className="schedule-list-item-inner-center-top">
                    <Form.Check type="switch" id="custom-switch"
                      checked={Saturdaycheck}
                      onChange={
                        (e) => {
                          setSaturdaycheck(e.target.checked);
                        }
                      } />
                  </div>
                </div>
                <div className="schedule-list-item-inner-right">
                  <div className="schedule-list-item-inner-right-top">
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setSaturdaystart(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Saturdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}

                      </Form.Select>
                    </div>
                    <div className="spacing">to</div>
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setSaturdayend(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Saturdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="schedule-list-item">
              <div className="schedule-list-item-inner">
                <div className="schedule-list-item-inner-left">
                  <div className="schedule-list-item-inner-left-top">
                    <h4 className="schedule-list-item-inner-left-top-title">
                      Sunday
                    </h4>
                  </div>
                </div>
                <div className="schedule-list-item-inner-center">
                  <div className="schedule-list-item-inner-center-top">
                    <Form.Check type="switch" id="custom-switch"
                      checked={Sundaycheck}
                      onChange={
                        (e) => {
                          setSundaycheck(e.target.checked);
                        }
                      } />
                  </div>
                </div>
                <div className="schedule-list-item-inner-right">
                  <div className="schedule-list-item-inner-right-top">
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setSundaystart(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Sundaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="spacing">to</div>
                    <div>
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setSundayend(e.target.value);
                        }
                      }>
                        {options && options.map((option) => (
                          <option key={option.value} selected={Sundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                        ))}

                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Accordion.Item eventKey="0" onClick={getandupdatefirstweek}>
            <Accordion.Header>First Week</Accordion.Header>
            <Accordion.Body>
              <div className="row custom-pt-20">
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Monday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={Mondaycheck}
                            onChange={
                              (e) => {
                                setMondaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example"
                              onChange={
                                (e) => {
                                  setMondaystart(e.target.value);
                                }
                              }
                            >
                              {options && options.map((option) => (
                                <option key={option.value} selected={Mondaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example"
                              onChange={
                                (e) => {
                                  setMondayend(e.target.value);
                                }
                              }
                            >
                              {options && options.map((option) => (
                                <option key={option.value} selected={Mondayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Tuesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={Tuesdaycheck}
                            onChange={
                              (e) => {
                                setTuesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setTuesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Tuesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}

                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setTuesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Tuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Wednesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={Wednesdaycheck}
                            onChange={
                              (e) => {
                                setWednesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setWednesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Wednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setWednesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Wednesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Thursday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={Thursdaycheck}
                            onChange={
                              (e) => {
                                setThursdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThursdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Thursdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}

                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThursdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Thursdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Friday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={Fridaycheck}
                            onChange={
                              (e) => {
                                setFridaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFridaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Fridaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFridayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Fridayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Saturday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={Saturdaycheck}
                            onChange={
                              (e) => {
                                setSaturdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSaturdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Saturdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}

                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSaturdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Saturdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Sunday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={Sundaycheck}
                            onChange={
                              (e) => {
                                setSundaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSundaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Sundaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSundayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={Sundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}

                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" onClick={getandupdatesecondweek}>
            <Accordion.Header>Second Week</Accordion.Header>
            <Accordion.Body>
              <div className="row custom-pt-20">
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Monday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SecondMondaycheck}
                            onChange={
                              (e) => {
                                setSecondMondaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondMondaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondMondaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondMondayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondMondayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Tuesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SecondTuesdaycheck}
                            onChange={
                              (e) => {
                                setSecondTuesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondTuesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondTuesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondTuesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondTuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Wednesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SecondWednesdaycheck}
                            onChange={
                              (e) => {
                                setSecondWednesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondWednesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondWednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondWednesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondWednesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Thursday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SecondThursdaycheck}
                            onChange={
                              (e) => {
                                setSecondThursdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondThursdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondThursdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondThursdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondThursdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Friday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SecondFridaycheck}
                            onChange={
                              (e) => {
                                setSecondFridaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondFridaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondFridaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFridayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondFridayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Saturday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SecondSaturdaycheck}
                            onChange={
                              (e) => {
                                setSecondSaturdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondSaturdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondSaturdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondSaturdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondSaturdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Sunday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SecondSundaycheck}
                            onChange={
                              (e) => {
                                setSecondSundaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondSundaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondSundaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSecondSundayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SecondSundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" onClick={getandupdatethirdweek}>
            <Accordion.Header>Third Week</Accordion.Header>
            <Accordion.Body>
              <div className="row custom-pt-20">
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Monday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={ThirdMondaycheck}
                            onChange={
                              (e) => {
                                setThirdMondaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdMondaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdMondaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdMondayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdMondayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Tuesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={ThirdTuesdaycheck}
                            onChange={
                              (e) => {
                                setThirdTuesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdTuesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdTuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdTuesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdTuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Wednesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={ThirdWednesdaycheck}
                            onChange={
                              (e) => {
                                setThirdWednesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdWednesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdWednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdWednesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdWednesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Thursday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={ThirdThursdaycheck}
                            onChange={
                              (e) => {
                                setThirdThursdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdThursdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdThursdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdThursdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdThursdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Friday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={ThirdFridaycheck}
                            onChange={
                              (e) => {
                                setThirdFridaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdFridaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdFridaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdFridayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdFridayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Saturday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={ThirdSaturdaycheck}
                            onChange={
                              (e) => {
                                setThirdSaturdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdSaturdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdSaturdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSaturdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdSaturdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Sunday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={ThirdSundaycheck}
                            onChange={
                              (e) => {
                                setThirdSundaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdSundaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdSundaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setThirdSundayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={ThirdSundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" onClick={getandupdatefourthweek}>
            <Accordion.Header>Fourth Week</Accordion.Header>
            <Accordion.Body>
              <div className="row custom-pt-20">
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Monday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FourthMondaycheck}
                            onChange={
                              (e) => {
                                setFourthMondaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthMondaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthMondaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthMondayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthMondayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Tuesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FourthTuesdaycheck}
                            onChange={
                              (e) => {
                                setFourthTuesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthTuesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthTuesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthTuesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthTuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Wednesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FourthWednesdaycheck}
                            onChange={
                              (e) => {
                                setFourthWednesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthWednesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthWednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthWednesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthWednesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Thursday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FourthThursdaycheck}
                            onChange={
                              (e) => {
                                setFourthThursdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthThursdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthThursdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthThursdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthThursdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Friday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FourthFridaycheck}
                            onChange={
                              (e) => {
                                setFourthFridaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthFridaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthFridaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthFridayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthFridayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Saturday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FourthSaturdaycheck}
                            onChange={
                              (e) => {
                                setFourthSaturdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthSaturdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthSaturdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthSaturdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthSaturdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Sunday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FourthSundaycheck}
                            onChange={
                              (e) => {
                                setFourthSundaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthSundaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthSundaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFourthSundayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FourthSundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" onClick={getandupdatefifthweek}>
            <Accordion.Header>Fifth Week</Accordion.Header>
            <Accordion.Body>
              <div className="row custom-pt-20">
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Monday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FifthMondaycheck}
                            onChange={
                              (e) => {
                                setFifthMondaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthMondaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthMondaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthMondayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthMondayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Tuesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FifthTuesdaycheck}
                            onChange={
                              (e) => {
                                setFifthTuesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthTuesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthTuesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthTuesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthTuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Wednesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FifthWednesdaycheck}
                            onChange={
                              (e) => {
                                setFifthWednesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthWednesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthWednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthWednesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthWednesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Thursday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FifthThursdaycheck}
                            onChange={
                              (e) => {
                                setFifthThursdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthThursdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthThursdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthThursdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthThursdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Friday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FifthFridaycheck}
                            onChange={
                              (e) => {
                                setFifthFridaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthFridaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthFridaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFridayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthFridayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Saturday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FifthSaturdaycheck}
                            onChange={
                              (e) => {
                                setFifthSaturdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthSaturdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthSaturdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthSaturdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthSaturdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Sunday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={FifthSundaycheck}
                            onChange={
                              (e) => {
                                setFifthSundaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthSundaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthSundaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setFifthSundayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={FifthSundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" onClick={getandupdatesixthweek}>
            <Accordion.Header>Sixth Week</Accordion.Header>
            <Accordion.Body>
              <div className="row custom-pt-20">
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Monday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SixthMondaycheck}
                            onChange={
                              (e) => {
                                setSixthMondaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthMondaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthMondaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthMondayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthMondayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Tuesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SixthTuesdaycheck}
                            onChange={
                              (e) => {
                                setSixthTuesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthTuesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthTuesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthTuesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthTuesdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Wednesday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SixthWednesdaycheck}
                            onChange={
                              (e) => {
                                setSixthWednesdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthWednesdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthWednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthWednesdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthWednesdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Thursday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SixthThursdaycheck}
                            onChange={
                              (e) => {
                                setSixthThursdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthThursdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthThursdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthThursdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthThursdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Friday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SixthFridaycheck}
                            onChange={
                              (e) => {
                                setSixthFridaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthFridaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthFridaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthFridayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthFridayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Saturday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SixthSaturdaycheck}
                            onChange={
                              (e) => {
                                setSixthSaturdaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthSaturdaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthSaturdaystart == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthSaturdayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthSaturdayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="schedule-list-item">
                    <div className="schedule-list-item-inner">
                      <div className="schedule-list-item-inner-left">
                        <div className="schedule-list-item-inner-left-top">
                          <h4 className="schedule-list-item-inner-left-top-title">
                            Sunday
                          </h4>
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-center">
                        <div className="schedule-list-item-inner-center-top">
                          <Form.Check type="switch" id="custom-switch"
                            checked={SixthSundaycheck}
                            onChange={
                              (e) => {
                                setSixthSundaycheck(e.target.checked);
                              }
                            } />
                        </div>
                      </div>
                      <div className="schedule-list-item-inner-right">
                        <div className="schedule-list-item-inner-right-top">
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthSundaystart(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthSundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="spacing">to</div>
                          <div>
                            <Form.Select aria-label="Default select example" onChange={
                              (e) => {
                                setSixthSundayend(e.target.value);
                              }
                            }>
                              {options && options.map((option) => (
                                <option key={option.value} selected={SixthSundayend == option.value ? true : false} value={option.value}>{option.label}</option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item> */}
        <div className="form-btn mt-4 pe-0">
          <div className="form-btn-right">
            <button
              type="submit"
              className="btn bg-green-dark action-btn btn-green"
              onClick={saveAvailibility}
            >
              Save Availability
            </button>
          </div>
        </div>
        {/* </form> */}
      </Accordion>

    </div>
  );
}
export default Aviliti;