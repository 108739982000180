import React from 'react';
import '../Topnavigation/Topnavigation.css';
import Dsicon from '../../../assets/Images/dsicon.webp';
import { Link } from 'react-router-dom';

function Topnavigation() {
    return (
        <div className="App">
            <div className='Topnavigation-container'>
                <div className='dashboard-custom-container'>
                    <div className='Topnavigation-containt-header d-flex justify-content-between align-items-center'>
                        <h1 className='d-flex align-items-center'><img src={Dsicon} alt='###' /> <span className='ps-2'>Dashboard</span></h1>
                        <div className='Topnavigation-container-header-btn'>
                            <Link to="/booking">
                                <button className='btn Book-Appointment'>Book Now</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topnavigation;