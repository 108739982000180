import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Review.css";
import Informasi from "../Components/TimesheetDataTable/Informasi";
import BootstrapTable from 'react-bootstrap-table-next';
import { RiDeleteBin6Line } from 'react-icons/ri';
import pagination from '../Components/TimesheetDataTable/pagination';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../Components/TimesheetDataTable/table.css';
import { FaRegEdit } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { getTimeSheet } from "../Api/timesheetApi";
import { getDataTimeSheet } from "../Api/timesheetApi";
import { updatetimesheet } from "../Api/timesheetApi";
import { deletetimesheet } from "../Api/timesheetApi";
import { getActiveStaff } from "../Api/timesheetApi";
import { getDeactivateStaff } from "../Api/timesheetApi";
import { Roller } from "react-awesome-spinners";
import { CSVLink } from 'react-csv';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import { getreviewlist } from "../Api/bookingApi";
import { savereviewdata } from "../Api/bookingApi";

function Review() {

    const [show11, setShow11] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);

    const [reviewtitle, setReviewtitle] = useState("");
    const [reviewtext, setReviewtext] = useState("");
    const [reviewstar, setReviewstar] = useState("");
    const [reviewername, setReviewername] = useState("");




    const [reviewdata, setReviewdata] = useState([]);

    const getreviewlisst = () => {
        getreviewlist().then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setReviewdata(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getreviewlist().then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setReviewdata(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [])



    const columns = [{
        dataField: 'reviewer_name',
        text: 'REVIEWER NAME',
        sort: true
    },
    {
        dataField: 'review_title',
        text: 'REVIEW TITLE',
        sort: true
    },
    {
        dataField: 'created',
        text: 'REVIEW DATE',
        sort: true,
        formatter: (cell, row) => {
            const options = { month: 'long', day: 'numeric', year: 'numeric' };
            const formattedDate = new Date(cell).toLocaleDateString('en-US', options);
            return formattedDate;
        }
    }];


    const savereview = () => {

        const formData = new FormData();

        formData.append("review_title", reviewtitle);
        formData.append("review_text", reviewtext);
        formData.append("review_star", reviewstar);
        formData.append("reviewer_name", reviewername);
        savereviewdata(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const ApiResponse = res.data;
                if (ApiResponse.status === 1) {
                    getreviewlisst();
                } else {
                    alert("data not saved");
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            handleClose11();
        });
    }












    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">


                                        <div className="pd-26">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-header mb-4 d-flex justify-content-between">
                                                        <h5 className="card-title">Review`s List</h5>
                                                        <div className="d-flex justify-content-end">
                                                            <div className="export-btn">
                                                                <button className="btn btn-primary downloads-btn-preview" onClick={handleShow11}>Add Review</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-card-body-inner">
                                                        {/* Timesheet modal start here */}
                                                        <Modal show={show11} onHide={handleClose11} className="Sidebar-Modal-end">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Add Review</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body className='custum-modal-body'>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Review Title</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => setReviewtitle(e.target.value)} placeholder="" />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Review Text</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => setReviewtext(e.target.value)} as="textarea" rows={3} />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Review Star</Form.Label>
                                                                            <Form.Control type="number" onChange={(e) => setReviewstar(e.target.value)} placeholder="" />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Reviewer Name</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => setReviewername(e.target.value)} placeholder="" />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <button className="Active-bttn btn" onClick={savereview} > Submit </button>
                                                                <button className="Cancel-bttn btn" onClick={handleClose11}> Cancel </button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                        {/* modal End here */}
                                                        <div className="animated fadeIn">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="card">
                                                                        <div className="card-header d-flex table-card-header">
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <BootstrapTable bootstrap4 keyField='id'
                                                                                data={reviewdata}
                                                                                columns={columns}
                                                                                pagination={pagination}
                                                                                noDataIndication="No data available"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Review;