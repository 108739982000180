import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";

//getTimeSheet
// export const getTimeSheet = (staff_id,date1,date2,booking_id) => apiCall(`${API_URL}getTimeSheet22?staff_id=${staff_id}&date1=${date1}&date2=${date2}&booking_id=${booking_id}`, "GET", []);
export const getTimeSheet = (formData) => apiCall(`${API_URL}getTimeSheetlatest`, "POST", formData);

//getDataTimeSheet
export const getDataTimeSheet = (formData) => apiCall(`${API_URL}getDataTimeSheet`, "POST",formData);

//updatetimesheet
export const updatetimesheet = (formData) => apiCall(`${API_URL}updatetimesheet`, "POST",formData);

//deletetimesheet
export const deletetimesheet = (formData) => apiCall(`${API_URL}deletetimesheetData`, "POST",formData);

//getStaff
export const getActiveStaff = () => apiCall(`${API_URL}getallStaffList`, "GET",[]);

//getStaff
export const getDeactivateStaff = () => apiCall(`${API_URL}getDeactivateStaff`, "GET",[]);

//addtimesheetdata
export const addtimesheetdata = (formData) => apiCall(`${API_URL}addtimesheetdata`, "POST",formData);