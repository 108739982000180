import React from "react";
import { useContext, useEffect, useState } from 'react';
import { SettingContext } from '../../Setting/SettingContext';
import CryptoJS from 'crypto-js';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { updatemasterpassword ,getmasterpass ,updatepricedata} from '../../Api/settingApi';
import { updateemailtemplate } from '../../Api/settingApi';
import { saveappointment_request ,updatesmstemplatestatus } from '../../Api/settingApi';



function TabData9() {

    const [companyInfo, setCompanyInfo,companyServiceArea , setCompanyServiceArea,generalSettings , setGeneralSettings,paymenetmethod , setPaymenetmethod,emailData, setEmailData,emailTemplate, setEmailTemplate,promocodeData , setPromocodeData,zonesdata , setZonesData , smsstatus , setSmsstatus,masterpassword , setMasterpassword,searcpricestatus , setSearcpricestatus] = useContext(SettingContext);



    const [searchprice , setSearchprice] = useState(false);

    const savepricedata = (e) => {
        const formData = new FormData();
        if(searchprice === true){
            formData.append("search_price_status", "true");
        }else{
            formData.append("search_price_status", "false");
        }

        updatepricedata(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === 1) {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    useEffect(() => {
        if(searcpricestatus.status === "false"){
            setSearchprice(false);
        }else{
            setSearchprice(true);
        }
    },[searcpricestatus]);

    return (
        <div>
        <form id='form'>
            <div className="row custom-pt-20">
                
                <div className="col-md-12">
                    <div className="payment-list">
                        <div className="payment-list-item">
                            <div className="payment-list-item-left">
                                <div className="payment-list-title">
                                    <h4>Surge Price</h4>
                                </div>
                            </div>
                            <div className="payment-list-item-right">
                                <div className="payment-list-btn">
                                    <div className="custom-control custom-switch">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={searchprice}
                                            name="searcpricestatus"
                                            onChange={(e) => { setSearchprice(e.target.checked) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row custom-pt-20">
                <div className="col-md-12">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={savepricedata}>
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    )
}

export default TabData9;