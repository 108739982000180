import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Review.css";
import BootstrapTable from 'react-bootstrap-table-next';
import pagination from '../Components/TimesheetDataTable/pagination';
import Button from 'react-bootstrap/Button';
import '../Components/TimesheetDataTable/table.css';
import { Roller } from "react-awesome-spinners";
import { getreviewlist } from "../Api/bookingApi";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom"
import { RiGlobalLine } from "react-icons/ri";



function Review() {


    const [loading, setLoading] = useState(false);



    const [reviewdata, setReviewdata] = useState([]);

    const getreviewlisst = () => {
        setLoading(true);
        getreviewlist().then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setReviewdata(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getreviewlisst();
    }, [])


    const optionFormatter2 = (cell, row) => { 
        return (
            <div className="button-group">
                <Link to={`/singleview?id=${row.id}`} className="ct-cal-booking-details-notes">
                    <Button
                        className="confirm-Active-bttn me-2"
                    >
                        <FaEye />
                    </Button>
                </Link>
            </div>
        );
    };


    const optionFormatter3 = (cell, row) => {
        const isKeapStatus = row.keap_status !== "" && row.keap_status !== null && row.keap_status !== "0";
        return (
            <div className="button-group">
              {/* <Button
                variant={isKeapStatus ? "outline-success" : "outline-danger"}
                className="btn-sm"
              >
                {isKeapStatus ? "Sent" : "In review"}
              </Button> */}
              {isKeapStatus ? "Sent" : "In review" }
            </div>
          );
    };

    const optionFormatter4 = (cell, row) => {
        const isPostStatus = row.post_id !== 0 && row.post_id !== "0" && row.post_id !== "" && row.post_id !== null;
        return (
            <div className="button-group">
              {/* <Button
                variant={isPostStatus ? "outline-success" : "outline-danger"}
                className="btn-sm"
              >
                {isPostStatus ? <RiGlobalLine /> : <RiGlobalLine />}
              </Button> */}
              {isPostStatus ? "Sent" : "In review" }
            </div>
          );
    };


    const columns = [{
        dataField: 'reviewer_name',
        text: 'REVIEWER NAME',
        sort: true
    },
    {
        dataField: 'review_title',
        text: 'REVIEW TITLE',
        sort: true
    },
    {
        dataField: 'created',
        text: 'REVIEW DATE',
        sort: true,
        formatter: (cell, row) => {
            const options = { month: 'long', day: 'numeric', year: 'numeric' };
            const formattedDate = new Date(cell).toLocaleDateString('en-US', options);
            return formattedDate;
        }
    },{
        text: "KEAP STATUS",
        isDummy: true,
        formatter: optionFormatter3,
    },{
        text: "SITE STATUS",
        isDummy: true,
        formatter: optionFormatter4,
    },
    {
        //action
        dataField: 'action',
        text: 'ACTION',
        formatter: optionFormatter2,
    }];



    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">


                                        <div className="pd-26">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-header mb-4 d-flex justify-content-between">
                                                        <h5 className="card-title">Review`s List</h5>
                                                        <div className="d-flex justify-content-end">
                                                            <div className="export-btn">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-card-body-inner">

                                                        <div className="animated fadeIn">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="card">
                                                                        <div className="card-header d-flex table-card-header">
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <BootstrapTable bootstrap4 keyField='id'
                                                                                data={reviewdata}
                                                                                columns={columns}
                                                                                pagination={pagination}
                                                                                noDataIndication="No data available"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Review;