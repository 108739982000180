import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import { FaStar } from 'react-icons/fa';
import './assets/css/Service.css'
import Header2 from "./camponent/header/Header2";

function WindowCleaningCost() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="windowcleaning-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Window Cleaning <span className='bold-title'><span className='bold-title bg-color'>Cost</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">How Much Does Window Cleaning Cost?</h2>
                                    </div>
                                    <div className="title_bold">
                                        <h3>Most professional window cleaners charge per pane, but a number of variables can affect the final price.</h3>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>You have windows so that you can look outside. When you can no longer tell the windows from the walls, it’s time to think about <a href="#" className="link">window cleaning</a>.</p>
                                        <p>Let’s face it: No one likes to wash windows. And if your house is two stories or taller, making your glass gleam again can be dangerous. That’s why it may be a good idea to <a href="#" className="link">hire a professional window cleaner.</a></p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Got clean windows?</h2>
                                    </div>
                                    <div className="window-cleaning-video">
                                        <video src="https://media.angieslist.com/s3fs-public/window%20cleaning.mp4" type="video/mp4" loop muted playsInline className={'windowcleaning-video'} controls></video>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Paying by the pane</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Window cleaning generally is priced by the number of panes of glass. An ordinary double-hung window consists of two panes.</p>
                                        <p>The typical window washing cost runs from $2 to $7 per pane, according to CostHelper. Alternatively, some companies charge an hourly rate. Angie’s List members report paying an average of $266 for a full house window cleaning, which may include screens and doors.</p>
                                        <p>For best results, it’s generally a good idea to have your windows cleaned twice every year. However, a number of factors, including whether many windows face the ocean, or your house is exposed to frequent storms, can affect how often your windows will need cleaning.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Figuring the cost of window washing</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Although window cleaning may seem like a relatively straightforward job, there are variables that can affect the final cost:</p>
                                    </div>
                                    <div className="list-about">
                                        <ul className="list">
                                            <li className="list-item">How dirty is the glass? Dirtier windows generally require more tools and equipment, not to mention time.</li>
                                            <li className="list-item">Number of windows — The more that need washing, the more you can expect to pay.</li>
                                            <li className="list-item">Ease of access — Ladders, special tools and extra time may be required to clean some hard-to-reach windows.</li>
                                            <li className="list-item">Inside, outside or both? Selecting interior-only or exterior-only service may save you a little money today, but like most services, when you opt for a la carte pricing you pay more per service. If you think you’ll eventually want both, it’s best to get them done at the same time.</li>
                                        </ul>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>There can be extra charges for:</p>
                                    </div>
                                    <div className="list-about">
                                        <ul className="list">
                                            <li className="list-item">Screen cleaning, at 50 cents to $5 each, and sliding glass doors, which run $2.50 to $8 per door, according to CostHelper.</li>
                                            <li className="list-item">Cleaning windowsills and tracks; the cost can range from 50 cents to $5.</li>
                                            <li className="list-item">Paint or stain removal, which can cost $3.50 to $6 for a window of average size. Mineral deposit removal can cost up to $20 extra per pane if it’s not included in the company’s price.</li>
                                            <li className="list-item">Going up: The cost of window work on the first and second floors of a home is generally the same, but additional floors can cost up to $3 to $5 more for every window.</li>
                                        </ul>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p></p>
                                        <p>Originally posted by <a href="https://www.angieslist.com/" target="_blank">Angie’s List</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default WindowCleaningCost;