// Create react app
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faLocationDot,faPhoneVolume } from '@fortawesome/free-solid-svg-icons'

function Rightbar() {
    return (
        <div className="Right-bar">
            <div className="Right-bar-content">
                <div className="Right-bar-content-header">
                    <h3>Get a quote</h3>
                </div>
                <div className="Right-bar-content-body">
                    <div className="Right-bar-content-body-form">
                        <form>
                            <div className="form-group mb-4">
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Full Name" />
                            </div>
                            <div className="form-group mb-4">
                                <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Zip Code" />
                            </div>
                            <div className="form-group mb-4">
                                <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Phone Number" />
                            </div>
                            <div className="form-group mb-4">
                                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn rightbar-submite-btn">GET A QUOTE</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="Right-bar-content-Footer">
                    <h3>Contact us</h3>
                </div>
                <div className="Right-bar-content-body-button mb-3">
                    <a href="#">
                        <div className="Rightbar-bg-color d-flex align-items-center p-2">
                            <div className="Rightbar-icon">
                                <FontAwesomeIcon icon={faLocationDot} />
                            </div>
                            <div className="Rightbar-text">
                                <h4>Office Address</h4>
                                <p>1234, Main Street, New York, USA</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="Right-bar-content-body-button mb-3">
                    <a href="tel:6197622934">
                        <div className="Rightbar-bg-color d-flex align-items-center p-2">
                            <div className="Rightbar-icon">
                                <FontAwesomeIcon icon={faPhoneVolume} />
                            </div>
                            <div className="Rightbar-text">
                                <h4>Telephone Number</h4>
                                <p>619-762-2934</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="Right-bar-content-body-button">
                    <a href="mailto:greenfrog@mail.com">
                        <div className="Rightbar-bg-color d-flex align-items-center p-2">
                            <div className="Rightbar-icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div className="Rightbar-text">
                                <h4>Mail Address</h4>
                                <p>greenfrog@mail.com</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Rightbar;