import React, { useState, useContext, useEffect } from "react";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Technician.css";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import User from "../assets/images/Avatar2.png";
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import TabData from "../Tab/Tab";
import { getactiveStaff } from "../Api/technician";
import { getinactivestaff } from "../Api/technician";
import { ModalPopContext } from "./ModalPopContext";
import { getstaffbookings } from "../Api/technician";
import { getservicesmethods } from '../Api/technician';
import { getTechnicianAvailabilty } from '../Api/technician';
import { getTechnicianOffTime } from '../Api/technician';
import { getoffdaytechnician } from '../Api/technician';
import { TechnicianOffTimerequest } from '../Api/technician';
import { addTechnician } from '../Api/technician';
import { Roller } from "react-awesome-spinners";
import { SITE_URL } from "../Api/config";
import Form from 'react-bootstrap/Form';
import { Modal } from "react-bootstrap";

function Technician() {

    const [technicianDetails, setTechnicianDetails, serviceList, setServiceList, services, setServices, selectedOptions, setSelectedOptions, technicianAvailabilty, setTechnicianAvailibility, technicianOfftime, setTechnicianOfftime, technicianOffday, setTechnicianOffday, selectYear, setSelectYear, technicianofftimerequest, setTechnicianofftimerequest,reviews , setReviews] = useContext(ModalPopContext);

    const [activestafflist, setActiveStaffList] = React.useState(null);
    const [stafflist, setStaffList] = React.useState(null);
    const [stafflistoriginal, setSatffOriginale] = React.useState(null);
    const [inactivestafflist, setInactiveStaffList] = React.useState([]);
    const [spinnerloading, setSpinnerLoading] = useState(false);
    const [spinnerloading2, setSpinnerLoading2] = useState(false);


    const [staffCount, setStaffCount] = React.useState(0);
    const [currentStaff, setCurrentStaff] = React.useState(0);
    const [loading, setLoading] = useState(false)

    const [techname, setTechname] = useState('');
    const [techemail, setTechemail] = useState('');
    const [techpassword, setTechpassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');

    const [keap_id , setKeap_id] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [validated, setValidated] = useState(false);

    const [role, setRole] = useState('staff');

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const getservices = () => {
        setLoading(true);
        getservicesmethods().then((res) => {
            setLoading(false);
            if (res.code === 200) {
                const apiResponse = res.data;
                setServices(apiResponse.data);
                // const services_ids = technicianDetails.service_ids;
                // const service_ids_array = services_ids.split(',');
                // const service_ids_index = [];
                // service_ids_array.forEach((service_id) => {
                //     const index = services.findIndex((service) => service.value === service_id);
                //     service_ids_index.push(index);
                // });
                // const selectedSevices = [];
                // service_ids_index.forEach((item) => {
                //     selectedSevices.push(services[item]);
                // });
                // setSelectedOptions(selectedSevices);
            }
            else { 
                alert("No data found");
            }
        });
    };

    useEffect(() => {
        getservices();

    }, []);


    const activeStaff = () => {
        setSpinnerLoading(true);
        getactiveStaff().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                setActiveStaffList(apiResponse.data);
                setStaffList(apiResponse.data.activestafflist);
                setSatffOriginale(apiResponse.data.activestafflist);
                var count = apiResponse.data.activestaff;
                setStaffCount(count);
            }
            else { 
                alert("No data found");
            }
        }).finally(() => {
            setSpinnerLoading(false);
        })

        document.querySelector(".btn-green").classList.remove("disabled");
        document.querySelector(".btn-red").classList.add("disabled");
    };

    const inactiveStaff = () => {
        // setLoading(true);
        setSpinnerLoading2(true);
        getinactivestaff().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                setInactiveStaffList(apiResponse.data);
                setStaffList(apiResponse.data.inactivestafflist);
                setSatffOriginale(apiResponse.data.inactivestafflist);
                //change the count of active staff
                // var count = inactivestafflist.inactivestaff;
                setStaffCount(apiResponse.data.inactivestaff);
                // document.getElementById("countData").innerHTML = "(" + count + ")";
            }
            else { 
                alert("No data found");
            }
        }).finally(() => {
            setSpinnerLoading2(false);
        })

        document.querySelector(".btn-red").classList.remove("disabled");
        document.querySelector(".btn-green").classList.add("disabled");
    };


    const handleClick = (item) => {
        document.getElementById("tech_title").innerHTML = item.fullname;
        setCurrentStaff(item.id);
        setTechnicianDetails(item);
        getStaffBookings(item.id);
        getservices();
        getAvailibility(item.id);
        getOffTimeTechnician(item.id);
        getdaysoffTechnician(item.id);
        getTechnicianOffTimerequest(item.id);
    }

    const getAvailibility = (id) => { 
        getTechnicianAvailabilty(id).then((res) => {
            if (res.code === 200) {
                const availibility = res.data.data;
                setTechnicianAvailibility(availibility);
            }
            else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            alert("Something went wrong");
        });
    }

    const getOffTimeTechnician = (id) => {
        setLoading(true);
        getTechnicianOffTime(id).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                const OffTime = res.data;
                setTechnicianOfftime(OffTime.data);
            }
            else { 
                alert("No data found");
            }
        });
    };

    const getdaysoffTechnician = (id) => {
        setLoading(true);
        getoffdaytechnician(id).then((res) => {
            setLoading(false);
            if (res.code === 200) {
                const Offdays = res.data;
                setTechnicianOffday(Offdays.data);
            }
            else { 
                alert("No data found");
            }

        });
    };

    const getTechnicianOffTimerequest = (id) => {
        setLoading(true);
        TechnicianOffTimerequest(id).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                const OffTime = res.data;
                setTechnicianofftimerequest(OffTime.data);
            }
            else { 
                alert("No data found");
            }
        });
    };

    const submittechnichan = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation(); // Prevent the form from submitting
        } else {
            if (confirmpassword !== techpassword) {
                alert("Password does not match");
                return;
            }
            const formData = new FormData();
            formData.append("name", techname);
            formData.append("email", techemail);
            formData.append("password", techpassword);
            formData.append("keap_id", keap_id);
            formData.append('role', role);

            addTechnician(formData)
                .then((res) => {
                    if (res.code === 200) {
                        if (res.data.status === 'success') {
                            handleClose();
                            activeStaff();
                        } else {
                            alert(res.data.message);
                        }
                    } else {
                        
                        alert("No data found");
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
        setValidated(true); // Move this line outside of the else block
    };


    useEffect(() => {
        getoffdaytechnician(technicianDetails.id, selectYear).then((res) => {
            if (res.code === 200) {
                const Offdays = res.data;
                setTechnicianOffday(Offdays.data);
            }
            else { 
                alert("No data found");
            }

        });
    }, [selectYear]);


    const getStaffBookings = (id) => {
        setLoading(true);
        let searchtext = "";
        getstaffbookings(id, searchtext).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                const apiResponse = res.data;
                setServiceList(apiResponse.data); 
            }
            else { 
                alert("No data found");
            }
        });
    };


    useEffect(() => {
        activeStaff();
        getAvailibility();
        getOffTimeTechnician();
    }, []);

    const changesearch = (e) => {
        if (e !== "" && e !== undefined && e !== null) {
            const searchtext = e.toLowerCase();

            const filteredStaff = stafflistoriginal.filter((item) =>
                item.fullname.toLowerCase().includes(searchtext)
            );

            setStaffList(filteredStaff);

        }
    }

    return (
        <>
            {
                loading ? (
                    <div >
                        <div className="loader">
                            <Roller />
                        </div>
                    </div>
                ) : (<>

                    <Modal show={show} onHide={handleClose} className="Sidebar-Modal-end">
                        <Modal.Header closeButton>
                            <Modal.Title>Add new staff member</Modal.Title>
                        </Modal.Header>
                        <Form noValidate validated={validated} onSubmit={submittechnichan}>
                            <Modal.Body>
                                <Form.Group className="mb-3" controlId="ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" required onChange={(e) => setTechname(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="ControlInput2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" required onChange={(e) => setTechemail(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="ControlInput3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" required onChange={(e) => setTechpassword(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a valid password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="ControlInput4">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" required onChange={(e) => setConfirmPassword(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a valid confirm password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="ControlInput6">
                                    <Form.Label>Keap Id</Form.Label>
                                    <Form.Control type="number" required onChange={(e) => setKeap_id(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a keap id.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="ControlInput5">
                                    <Form.Label>Select Role</Form.Label>
                                    <Form>
                                        {["radio"].map((type) => (
                                            <div
                                                key={`inline-${type}`}
                                                className="popup-radios mb-3"
                                            >
                                                <Form.Check
                                                    inline
                                                    label="Staff"
                                                    name="group1"
                                                    defaultChecked
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                    onClick={(e) => setRole('staff')}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Trainee"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                    onClick={(e) => setRole('trainee')}
                                                />

                                            </div>
                                        ))}
                                    </Form>
                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="Cancel-bttn btn" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button className="Active-bttn btn" type="submit">
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    <div className="technician-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="tab-content-body">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                <Row>
                                                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                        <div className="tab-card">
                                                            <div className="tab-card-header d-flex">
                                                                <h5 className="tab-card-title">Technician</h5>
                                                                <p className="tab-card-text" id="countData">({
                                                                    staffCount ? staffCount : 0
                                                                })</p>
                                                            </div>
                                                            <div className="btn-list">
                                                                {
                                                                    spinnerloading ? (
                                                                        <Button className="btn bg-green-dark action-btn btn-green" disabled>
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Button>
                                                                    ) : (
                                                                        <button type="button" onClick={activeStaff} className="btn bg-green-dark action-btn btn-green">
                                                                            Active
                                                                        </button>
                                                                    )
                                                                }
                                                                {
                                                                    spinnerloading2 ? (
                                                                        <Button className="btn bg-red-dark action-btn btn-red " disabled>
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Button>
                                                                    ) : (
                                                                        <button type="button" onClick={inactiveStaff} className="btn bg-red-dark action-btn btn-red disabled">
                                                                            Inactive
                                                                        </button>
                                                                    )
                                                                }

                                                                <button type="button" onClick={handleShow} className="btn bg-green-dark action-btn btn-green">
                                                                    Add New
                                                                </button>
                                                            </div>
                                                            <div className="mt-3">
                                                                <Form.Group controlId="formBasicEmail">
                                                                    <Form.Label>Technician Name</Form.Label>

                                                                    <Form.Control type="text" placeholder="Keywords..." onChange={(e) => {
                                                                        changesearch(e.target.value);
                                                                    }
                                                                    } />

                                                                </Form.Group>
                                                            </div>
                                                            <div className="tab-card-body">
                                                                <div className="tab-content pd-26">
                                                                    {stafflist && stafflist.map((item) => {
                                                                        return (
                                                                            <Nav variant="pills" className="flex-column">
                                                                                <Nav.Item>
                                                                                    <Nav.Link className={currentStaff == item.id ? "technician-tab-link active" : "technician-tab-link"} onClick={
                                                                                        () => {
                                                                                            handleClick(item)
                                                                                        }
                                                                                    }> <div className="user-profile-box">{
                                                                                        item.image ? <img src={SITE_URL + "uploads/photo/" + item.image} alt="User" className="profile-image" /> : <img src={User} alt="User" className="profile-image" />
                                                                                    }</div>
                                                                                        <div className="">
                                                                                            <span className="tab-user-name name-new" >{item.fullname}</span> <br />
                                                                                            <span className="name-newline" >{"Award- " + item.award_count + " , complaint - " + item.complaint_count + ""}</span>
                                                                                        </div>
                                                                                    </Nav.Link>
                                                                                </Nav.Item>
                                                                            </Nav>
                                                                        )
                                                                    }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                                        <div className="tab-content bx-1">
                                                            <Tab.Content className="card-custom-mr">
                                                                <Tab.Pane eventKey="first">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title" id="tech_title"></h5>
                                                                    </div>
                                                                    <div className="">
                                                                        {
                                                                            technicianDetails && technicianDetails.id ?
                                                                                <TabData /> : <div className="no-technication">Select a technician</div>
                                                                        }

                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </>
    );
}

export default Technician;