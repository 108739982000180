import React, { useState, useEffect } from "react";
import { Roller } from "react-awesome-spinners";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Form, Button, Modal } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { RiAddFill } from "react-icons/ri";
import { getblogtags } from "../Api/blogsapi";
import { addblogtags } from "../Api/blogsapi";
import { editblogtags } from "../Api/blogsapi";

// Custom Components
import Sidebar from "../Components/Sidebar/Sidebar";


const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true
};

const pagination = paginationFactory(options);


function BlogTag() {

    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Edit-Active-bttn" onClick={() => {handleShow1();setEdittagname(row.tagname);setEdittagid(row.id)}}>
                    <FaRegEdit />
                </Button>
            </div>
        );
    }

    

    const columns = [
        { dataField: 'id', text: '#', sort: true },
        { dataField: 'name', text: 'TAG NAME', sort: true },
        { text: 'ACTION', isDummy: true, formatter: optionFormatter }
    ];

    
    const handleSearch = (e) => {
        console.log(e.target);
        // Add your search logic here
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [tagname ,  setTagname] = useState("");
    const [tagdata ,  setTagdata] = useState([]);
    const [edittagname ,  setEdittagname] = useState("");
    const [edittagid ,  setEdittagid] = useState("");

    useEffect(() => {
        getblogtagsdata();
    },[]);
    
    const getblogtagsdata = () => {
        getblogtags().then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if (apiResponse.status === 1) {
                    setTagdata(apiResponse.data);
                } else {
                    alert(apiResponse.message);
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            alert(err.message);
        });
    }

    const handlesubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", tagname);

        addblogtags(formData).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if (apiResponse.status === 1) {
                    alert("Tag added successfully");
                    handleClose();
                    getblogtagsdata();
                } else {
                    alert(apiResponse.message);
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            alert(err.message);
        });
    }

    const handleEditData = (e) => {

        const formData = new FormData();
        formData.append("name", edittagname);
        formData.append("id", edittagid);
        

        editblogtags(formData).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if (apiResponse.status === 1) {
                    alert("Tag updated successfully");
                    handleClose1();
                    getblogtagsdata();
                } else {
                    alert(apiResponse.message);
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            alert(err.message);
        });
        
    }


    

    return (
        <>

            <Modal show={show} onHide={handleClose} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Add Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setTagname(e.target.value)}
                                        className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="Active-bttn" onClick={handlesubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={edittagname}
                                        onChange={(e) => setEdittagname(e.target.value)}
                                        className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="Active-bttn" onClick={handleEditData}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>



            <div>
                {loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* ... Your other components */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fluid">
                                            <div className="">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header">
                                                            <h5 className="card-title">Blogs Tag</h5>
                                                        </div>
                                                        <div className="table-card-body-inner">
                                                            <div className="animated fadeIn" >
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="card">
                                                                            <div className="card-header d-flex table-card-header">
                                                                                <Form.Group>
                                                                                    <div className='d-flex'>
                                                                                        <div addonType="prepend">
                                                                                        </div>
                                                                                        <Form.Control type="text" placeholder="Keywords..." onSearch={handleSearch} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                                <Button
                                                                                    onClick={handleShow}
                                                                                    className="btn btn-sm float-right add-user-btn"
                                                                                >
                                                                                    {" "}
                                                                                    <RiAddFill /> Add New Tag
                                                                                </Button>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <BootstrapTable bootstrap4 keyField='id' data={tagdata} noDataIndication="No data available" columns={columns} pagination={pagination} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default BlogTag;
