import React, { useEffect, useState } from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import Review from './camponent/Reviews/Review';
import Profile from '../Booking/assets/Images/profile.jpg';
import Header2 from "./camponent/header/Header2";
import Comman from "./camponent/CommanContent/Comman";
import { Helmet } from 'react-helmet';
import { getreviewlist } from "../Api/bookingApi";

function Reviews() {


    const [review, setReview] = useState([]);

    useEffect(() => {
        getreviewlist().then((res) => {
            if (res.code === 200) {
                if (res.data.status === 1) {
                    const apiResponse = res.data;
                    setReview(apiResponse.data);
                } else {
                    setReview([]);
                }
            } else {
                alert("Something went wrong");
            }
        });

    }, [])


    const generateMetaTags = () => {
        const metaTags = [
            { name: "title", content: `Greenfrog Review` },
            { name: "description", content: `Greenfrog Review` },
            { property: "og:title", content: `Greenfrog Review` },
            { property: "og:description", content: `Greenfrog Review` },
            { property: "og:url", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@greenfrog" },
            { name: "twitter:title", content: `Greenfrog Review` },
            { name: "twitter:description", content: `Greenfrog Review` },
        ];

        metaTags.push({ property: "og:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        metaTags.push({ name: "twitter:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        // if (og_image) {
        // }

        return metaTags;
    };

    return (
        <>
            <div>
                <Helmet>
                    {generateMetaTags().map((tag, index) => (
                        <meta key={index} {...tag} />
                    ))}
                </Helmet>
            </div>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="review-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Greenfrog <span className='bold-title'><span className='bold-title bg-color'>Reviews</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="reviews-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="review-box">
                                    <div className="review-box-inner">
                                        <div className="row">
                                            {
                                                review && review.length > 0 && review.map((item, index) => (
                                                    <div key={index} className="col-xl-6 col-lg-12">
                                                        <Review
                                                            clientheadline={item.review_title}
                                                            clientcomment={item.review_text}
                                                            clientname={item.reviewer_name}
                                                            clientstar={item.star}
                                                        />
                                                    </div>
                                                ))
                                            }


                                            {/* // <div className="col-xl-6 col-lg-12">
                                            //     <Review clientimage={Profile} clientheadline={'Jalyn is great!'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            // </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="review-box-inner">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Consistently great'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Jalyn is great!'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-box-inner">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Consistently great'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Jalyn is great!'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                <Comman />

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default Reviews;