import React, { useState, useEffect, useContext } from "react";
import {SettingProvider} from "./SettingContext";
import Setting from "./Setting";


const SettingMain = () => {
    
        return (
            <>
                <SettingProvider>
                <Setting />
                </SettingProvider>

            </>
        )
            
}

export default SettingMain;

