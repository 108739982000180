
import React from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
// import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useState } from 'react';
import './mapview.css'
import Sidebar from '../Components/Sidebar/Sidebar';
import Header from '../Components/Header/Header';
import { Form } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "./pagination";
import { useEffect } from 'react';
import { getcoordinatesData } from '../Api/bookingApi';
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import moment from "moment";
import { getactivestaff } from "../Api/bookingApi";
import { getZone } from "../Api/bookingApi";
import { getstafflistwithlatlang } from "../Api/bookingApi";
import { getziplistbyzone } from "../Api/bookingApi";
import MarkerIcon from "../assets/images/greenfrog.png";
import { SITE_URL } from '../Api/config';
import Modal from "react-bootstrap/Modal";
import { DateRange } from 'react-date-range';
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';

const columns = [
    {
        dataField: "id",
        text: "ID",
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
            <div>{rowIndex + 1}</div>
        ),
    },
    {
        dataField: "order_id",
        text: "ORDER ID",
        sort: true,
    },
    {
        dataField: "order_date",
        text: "ORDER DATE",
        isDummy: true,
        sort: true,
    },
    {
        dataField: "name",
        text: "CLIENT NAME",
        sort: true,
    },
    {
        dataField: "user_email",
        text: "CLIENT EMAIL",
        sort: true,
    },
    {
        dataField: "address",
        text: "ADDRESS",
        sort: true,
        formatter: (cell, row) => (
            <div>
                {row.address},{row.city},{row.zip},{row.state}
            </div>
        ),
    },
    {
        dataField: "distanceFromPrevApp",
        text: "Distance from Prev. App"
    },
];


function MapView() {

    const [staffList, setStaffList] = useState(null);
    const [selectedStaff, setSelectedStaff] = useState(0);

    const [changestaffzone, setchangesstaffzone] = useState('');
    const [bookingzonelist, setBookingzonelist] = useState([]);

    const [loadingsppiner, setLoadingSpinner] = useState(false);

    const today = new Date();
    const [sdate, setSdate] = useState(today);
    const sevenDaysLater = new Date();
    const [edate, setEdate] = useState(sevenDaysLater.setDate(sevenDaysLater.getDate() + 7));

    const [show9, setShow9] = useState(false);
    
    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);



    const [showDateDisplay, setShowDateDisplay] = useState(false);

    const [dates, setDates] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);



   


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA9PRZ4LU9yTqlpV6d7-GH11B70BZKVJOU"
    })

    const center = { lat: 32.7460124, lng: -117.1274174 };

    const [activeMarker, setActiveMarker] = useState(null);

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    const getStaffList = () => {
        getactivestaff().then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                setStaffList(apiResponse.data);
            }
        })
            .catch((err) => {
                console.log(err);
            });
    };

    const getallzone = () => {
        getZone().then((response) => {
            if (response.code === 200) {
                if (response.data.status === "1") {
                    setBookingzonelist(response.data.data);
                } else {
                    console.log("response: error");
                }
            } else {
                console.log("response: error");
            }
        }).catch((error) => {
            console.log("response: error");
        });
    }

    const [staffscoordinates, setStaffsCoordinates] = useState([]); 


    const getstafflistwithlatlangfunc = () => {
        const formData = new FormData();
        formData.append("selectedStaff", selectedStaff);
        getstafflistwithlatlang(formData).then((response) => {
            if (response.code === 200) {
                if (response.data.status === "1") {
                    setStaffsCoordinates(response.data.data); 
                } else {
                    console.log("response: error");
                }
            } else {
                console.log("response: error");
            }
        }).catch((error) => {
            console.log("response: error");
        });
    }

    useEffect(() => {
        getStaffList();
        getallzone();
        getstafflistwithlatlangfunc();
    }, []);



    const [coordinates, setCoordinates] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [staffhoveredMarker, setStaffHoveredMarker] = useState(null);


    const coordinatesData = () => {
        setLoadingSpinner(true);
        const startdate = moment(sdate).format("YYYY-MM-DD")
        const endtdate = moment(edate).format("YYYY-MM-DD")
        getcoordinatesData(selectedStaff, startdate, endtdate, searchValue, changestaffzone).then((res) => {
            if (res.code === 200) {
                const coordinatsss = res.data;
                setCoordinates(coordinatsss.data);
            }
            else { 
                alert("No data found");
            }
        }).catch((err) => {
            console.log("getTechnicianOffTime error");
        }).finally(() => {
            setLoadingSpinner(false);
            handleClose9();
        });
    };

    useEffect(() => {
        coordinatesData();

        const filteredStaff = staffscoordinates.filter(staff => staff.id === selectedStaff);

        if (filteredStaff.length > 0) {
            setStaffsCoordinates(filteredStaff);
        } else {
            setStaffsCoordinates([])
        }

    }, [selectedStaff, searchValue, changestaffzone]);

    const handleButtonClick = () => {
        coordinatesData();
    }

    const onMarkerLoad = (marker) => {
        console.log('marker: ', marker)
    }

    const coordinateIcon = {
        url: { url: { MarkerIcon } },
        scaledSize: { width: 30, height: 30 },
        origin: { x: 0, y: 0 },
        anchor: { x: 0, y: 0 }
    }

    // const coordinateIcon = {
    //     url: 'https://cdn-icons-png.flaticon.com/512/25/25231.png', // Replace with the URL to your staff icon image
    //     scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
    //   };




    // useEffect(() => {
    // }, [staffscoordinates, coordinates])


    //   const handleOnLoad = (map) => {
    //     const bounds =  new window.google.maps.LatLngBounds();
    //     coordinates.forEach(({ position }) => bounds.extend(position));
    //     map.fitBounds(bounds);
    //   };

    const handleOnLoad = (map) => { 
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach(({ lat, lng }) => {
            console.log(`{lat: ${lat}, lng: ${lng}}`);
            const position = new window.google.maps.LatLng(lat, lng);
            bounds.extend(position);
        });
        map.fitBounds(bounds);
    };


    const handleActiveMarker = (marker) => {
        // if (marker === activeMarker) {
        //   return;
        // }
        setActiveMarker(marker);
    };
    const handleStaffMarker = (marker) => {
        // if (marker === activeMarker) {
        //   return;
        // } 
        setStaffHoveredMarker(marker);
    };



    return (
        <>

            <Modal show={show9} onHide={handleClose9} centered className="modal-center">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Filter data
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="date-picker">
                                <Form.Group
                                    className="booking-custom-lable"
                                    controlId="formBasicEmail"
                                >
                                    <div className="row">
                                        {/* <div className="col-md-12">

                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                disabled
                                                onClick={() => setShowDateDisplay(true)}
                                                placeholder="Click to select dates"
                                                value={
                                                    `${moment(sdate).format('DD-MM-YYYY')} - ${moment(edate).format('DD-MM-YYYY')}`
                                                }
                                            />
                                            <DateRange
                                                editableDateInputs={true}
                                                onChange={(item) => {
                                                    setSdate(item.selection.startDate);
                                                    setEdate(item.selection.endDate);
                                                    setDates([item.selection]);
                                                }}
                                                shownDate={dates[0]?.startDate}
                                                moveRangeOnFirstSelection={false}
                                                ranges={dates}
                                            />
                                        </div> */}
                                        <div className="col-md-12">
                                            <DateRangePicker
                                                onChange={(item) => {
                                                    setSdate(item.selection.startDate);
                                                    setEdate(item.selection.endDate);
                                                    setDates([item.selection]);
                                                }}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                // months={2}
                                                ranges={dates}
                                                direction="horizontal"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                        </div> */}
                        <div className="date-picker">
                            <Form.Group
                                className="booking-custom-lable"
                                controlId="formBasicEmail"
                            >
                                {
                                    loadingsppiner ? (
                                        <Button className="resize_btn btn" disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button>
                                    ) : (
                                        <div className="toggle_btn">
                                            <button className="resize_btn btn" onClick={
                                                () => {
                                                    setCoordinates([]);
                                                    handleButtonClick();
                                                }
                                            }>
                                                <div className="btn-text">
                                                    Submit
                                                </div>
                                            </button>
                                        </div>
                                    )
                                }
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="dashboard-container d-flex">
                <div className="sidebar-container">
                    <Sidebar />
                </div>
                <div className="main-container">
                    <div className="main-page-body-content">
                        {/* <div className="main-page-body-content-header">
                            <Header />
                        </div> */}
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="container-fulid">
                                    <div className="">
                                        <div className="table-card ">
                                            <div className="card-body table-card-body">
                                                <div className="table-card-header d-flex justify-content-between">
                                                    <h5 className="card-title">MapView</h5>
                                                </div>
                                                <div className="row">
                                                    <div className='col-lg-3'>
                                                        <div className="form-group mb-4">
                                                            {/* <Form.Label htmlFor="inputPassword5">Password</Form.Label> */}
                                                            <Form.Select aria-label="Default select example" onChange={
                                                                (e) => {
                                                                    setchangesstaffzone(e.target.value)
                                                                }
                                                            }>
                                                                <option value="0">All Zone</option>
                                                                {bookingzonelist && bookingzonelist.map((zone) => (
                                                                    <option value={zone.id} >{zone.zone_name}</option>
                                                                ))}

                                                            </Form.Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <div className="form-group mb-4">
                                                            {/* <Form.Label htmlFor="inputPassword5">Password</Form.Label> */}
                                                            <Form.Select aria-label="Default select example" onChange={
                                                                (e) => {
                                                                    setSelectedStaff(e.target.value);
                                                                }
                                                            }>
                                                                <option value="0">All Staff</option>
                                                                {
                                                                    staffList && staffList.map((staff) => {
                                                                        return (
                                                                            <option value={staff.value}>{staff.label}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                            </Form.Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <div className="date-picker">
                                                            <button class="resize_btn btn" onClick={handleShow9}><div class="btn-text">Filter</div></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-card-body-inner">
                                                    <div className=''>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className='map-box'>
                                                                    {isLoaded && coordinates.length > 0 ? (
                                                                        // <GoogleMap
                                                                        //     mapContainerStyle={{ width: '100%', height: '100%' }}
                                                                        //     center={center}
                                                                        //     // onLoad={onLoad}
                                                                        //     // onUnmount={onUnmount}
                                                                        //     scrollwheel={true}
                                                                        //     zoom={8}
                                                                        // >

                                                                        <GoogleMap
                                                                            onLoad={handleOnLoad}
                                                                            //center={center}
                                                                            onClick={() => setActiveMarker(null)}
                                                                            mapContainerStyle={{ width: "100%", height: "100%" }}
                                                                        >
                                                                            {coordinates && coordinates.map((mp) => (
                                                                                <Marker
                                                                                    key={mp.id}
                                                                                    position={{ lat: parseFloat(mp.lat), lng: parseFloat(mp.lng), name: mp.name }}
                                                                                    className="marker"
                                                                                    // onMouseOver={() => setHoveredMarker(mp)}
                                                                                    // onMouseOut={() => setHoveredMarker(null)}
                                                                                    onClick={() => handleActiveMarker(mp)}
                                                                                />
                                                                            ))}
                                                                            {staffscoordinates && staffscoordinates.map((mp) => (
                                                                                mp.staff_view === 1 && (
                                                                                    <Marker
                                                                                        key={`staff_id-${mp.id}`}
                                                                                        position={{ lat: parseFloat(mp.lat), lng: parseFloat(mp.lang), name: mp.fullname }}
                                                                                        icon={MarkerIcon}
                                                                                        animation={{ duration: 1000, easing: "ease-in" }}
                                                                                        // icon={{
                                                                                        //     url:SITE_URL + "/uploads/photo/"+mp.image,
                                                                                        //     scaledSize: new window.google.maps.Size(30, 30),
                                                                                        //     origin: new window.google.maps.Point(0, 0),
                                                                                        //     anchor: new window.google.maps.Point(0, 32),
                                                                                        //     coords : [1, 1, 1, 20, 18, 20, 18, 1]
                                                                                        // }}
                                                                                        // options = {{
                                                                                        //     draggable: false,
                                                                                        //     borderRadius: 10

                                                                                        // }}
                                                                                        onClick={() => handleStaffMarker(mp)}
                                                                                    />
                                                                                )
                                                                            ))}
                                                                            {activeMarker && (
                                                                                <InfoWindow
                                                                                    position={{ lat: parseFloat(activeMarker.lat), lng: parseFloat(activeMarker.lng) }}
                                                                                    onCloseClick={() => setActiveMarker(null)}
                                                                                >
                                                                                    <div className="info-window-content">
                                                                                        <p className="info-window-title">{activeMarker.name}</p>
                                                                                        <p className="info-window-description">
                                                                                            Latitude: {activeMarker.lat}<br />
                                                                                            Longitude: {activeMarker.lng}
                                                                                        </p>
                                                                                    </div>
                                                                                </InfoWindow>
                                                                            )}
                                                                            {staffhoveredMarker && (
                                                                                <InfoWindow
                                                                                    position={{ lat: parseFloat(staffhoveredMarker.lat), lng: parseFloat(staffhoveredMarker.lang) }}
                                                                                    onCloseClick={() => setStaffHoveredMarker(null)}
                                                                                >
                                                                                    <div className="info-window-content">
                                                                                        <p className="info-window-title">{staffhoveredMarker.fullname}</p>
                                                                                        <p className="info-window-description">
                                                                                            Latitude: {staffhoveredMarker.lat}<br />
                                                                                            Longitude: {staffhoveredMarker.lang}
                                                                                        </p>
                                                                                    </div>
                                                                                </InfoWindow>
                                                                            )}
                                                                        </GoogleMap>

                                                                    ) : <>Loading...</>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div className="animated fadeIn">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="card">
                                                                                <div className="card-header d-flex table-card-header">
                                                                                    <Form.Group>
                                                                                        <div className="d-flex">
                                                                                            <Form.Control
                                                                                                placeholder='search'
                                                                                                value={searchValue}
                                                                                                onChange={handleSearch.bind(this)}
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <BootstrapTable
                                                                                        bootstrap4
                                                                                        keyField="id"
                                                                                        data={coordinates}
                                                                                        columns={columns}
                                                                                        // selectRow={selectRow}
                                                                                        pagination={pagination}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )

}

export default React.memo(MapView)