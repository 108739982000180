import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import Header2 from "./camponent/header/Header2";

function LaMase() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="lamase-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>La Mesa <span className='bold-title'><span className='bold-title bg-color'>House Cleaning</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Who We Are</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Welcome to Green Frog La Mesa where we serve La Mesa and the surrounding cities. We’ve built a legacy in premier house cleaning industry. Here at Green Frog Cleaning, we give the gift of time so that you are able to focus on the things that truly matter – Instead of cleaning the house all day.</p>
                                        <p>From countertops to dusty living rooms, our trained professional house cleaners are happy to do the dirty work for you. And in addition to regularly scheduled cleanings we also provide move in/move out cleaning services, deep cleanings, rental cleanings, and more.</p>
                                        <p>Areas we are proud to serve at this location are La Mesa, Kensington, El Cajon, Rolando, Lemon Grove, Spring Valley, San Carlos, Calavo Gardens, and more.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Where You’ll Find Us</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <h2 className="sub-title">Green Frog House Cleaning La Mesa</h2>
                                        <p className="icon-warper">7171 Alvarado Road #200, La Mesa, CA 91942</p>
                                        <p className="icon-warper">Local: 1-(619)-337-0722</p>
                                        <p className="icon-warper">Toll Free: 1-(619)-937-2876</p>
                                        <p className="icon-warper">Fax: 1-(619)-937-2876</p>
                                        <p className="icon-warper">Email: <a className="action-email" href="">sales@greenfrogcleaning.com</a></p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Directions</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>We are located off 70th Street near the corner of 70th and Alvarado Road. If you’re coming from I-8 E you’ll want to get off on exit 11 toward 70th Street/Lake Murray Boulevard. Make a left off the freeway onto Alvarado Rd. We are located on the right side of the street.</p>
                                        <p><span className="windowcleaning-bold-text">From Navajo Shopping Center: </span>Head south on Navajo Rd toward Lake Murray Blvd turning left onto Lake Murray Blvd. Turn right at the 2nd cross street onto Lake Murray Blvd. Turn Left onto Alvarado Rd. We are located on the right side of the street.</p>
                                        <p><span className="windowcleaning-bold-text">From La Mesa Springs Shopping Center: </span>Turn right onto University then make a left onto Baltimore Rd. Make a left onto El Cajon Blvd. Turn right on Guava Ave. Make a left onto Alvarado Rd and the Destination will be on the left just past the Trolley Stop.</p>
                                        <p><span className="windowcleaning-bold-text">From Northgate Gonzalez Markets: </span>Hop on I-8 E from 54th St, Collwood Blvd and Montezuma Rd. Continue on I-8 E to exit exit 11 toward 70th Street/Lake Murray Boulevard. Make a left off the freeway onto Alvarado Rd. We are located on the right side of the street.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default LaMase;