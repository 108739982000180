import react from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/Images/logo/footer-logo.png';
import './Footer.css';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';


function Footer() {
    return (
        <section className='footer-section '>
            <div className='custom-container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="footer-logo">
                            <a href="/"><img src={Logo} alt="logo" /></a>
                        </div>
                        <div className="footer-text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas orci gravida pretium, sem pharetra, pretium. Blandit sed aenean habitant senectus tempor.</p>
                        </div>
                        <div className='footer-social-link'>
                            <ul className='footer-social-links'>
                                <li className='footer-social-links-item'><a href="#" className='footer-social-links-item-btn'><FaFacebook /></a></li>
                                <li className='footer-social-links-item'><a href="#" className='footer-social-links-item-btn'><FaInstagram /></a></li>
                                <li className='footer-social-links-item'><a href="#" className='footer-social-links-item-btn'>< FaTwitter /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="footer-title">
                            <h3>Company</h3>
                        </div>
                        <div className="footer-links">
                            <ul className='footer-links-list'>
                                <li className='footer-links-list-item'><a href="/about" className='footer-links-list-item-btn'>About Us</a></li>
                                <li className='footer-links-list-item'><a href="/HouseCleaning" className='footer-links-list-item-btn'>Services</a></li>
                                <li className='footer-links-list-item'><a href="/OurProduct" className='footer-links-list-item-btn'>Our Product</a></li>
                                <li className='footer-links-list-item'><a href="/Contact" className='footer-links-list-item-btn'>Contact</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="footer-title">
                            <h3>Contact</h3>
                        </div>
                        <div className="footer-contact">
                            <ul className='footer-links-list'>
                                <li className='footer-links-list-item'><a href="tel:16196506924" className='footer-links-list-item-btn'>1-619-762-3458</a></li>
                                <li className='footer-links-list-item'><a href="mailto:greenfrog@mail.com" className='footer-links-list-item-btn'>greenfrog@mail.com</a></li>
                                <li className='footer-links-list-item'><a href="#" className='footer-links-list-item-btn'>4517 Washington Ave. Manchester, Kentucky</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="footer-title">
                            <h3>Working Hours</h3>
                        </div>
                        <div className="footer-newsletter">
                            <p>Mon – Fri: 8am – 5pm</p>
                            <p>Sat – Sun: 9am – 2pm</p>
                        </div>
                    </div>
                </div>

                <hr className='custom-line' />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="footer-copy-right text-center">
                            <p>© 2022 Green Frog House Cleaning All Rights Reserved. Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;
