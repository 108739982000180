import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import { FaStar } from 'react-icons/fa';
import Header2 from "./camponent/header/Header2";

function Scheduled() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="scheduled-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Scheduled Maid <span className='bold-title'><span className='bold-title bg-color'>Service</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Why schedule regular maid service?</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <h2 className="sub-title">Keep it clean!</h2>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Once you’ve had our team out for your initial house cleaning and things are looking clean and tidy you’ll want to keep it that way.</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>We’ll work hard to keep your home in tip-top shape. We progressively work trough rotational tasks at your request to keep your home clean.</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>With scheduled maid service we can we match you with the same cleaner every visit so you’ll get to know each other over time. Of course things happen and if for some reason we can’t send the same technician on a specific visit we will contact you to see if you prefer to reschedule or continue with a different technician.</p>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <h2 className="sub-title">It’s about having more time for what’s important.</h2>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>We’ll work hard to keep your home in tip-top shape. We progressively work trough rotational tasks at your request to keep your home clean. We’ll match you with one of our highly trained professional cleaners.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">First Time DELUXE Cleaning</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Our most popular first time house cleaning. This is a deep “Spring Cleaning”. It encompasses all of our General Cleaning tasks plus Rotational Tasks. We generally send a team of two or three cleaners on the DELUXE clean. Great for move out cleaning and move in cleaning. This is house cleaning San Diego at it’s best.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">First Time General Cleaning</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>This covers all the tasks on our regular maintenance schedule. On our first visit we will spend more time focusing on “catch up” cleaning. This brings your home to a “maintenance level” and prepares it for regularly scheduled house cleaning San Diego. This is generally done by a team of two cleaners</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">ON OUR FIRST VISIT</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Before we can even begin to perform routine weekly or bi-weekly maintenance cleaning on a home, there are usually a variety of “first-time” tasks which require extra attention on our first visit. Our first cleaning visit is more like a spring cleaning (especially the DELUXE), or what we call a heavy, deep-cleaning. In fact, it’s not uncommon for our teams to spend from four to eight times longer on a first-time cleaning than it takes us on regular, repeat maintenance visits. While the situation in every home is unique and different, following are some examples of what we mean.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">BATHROOMS</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>It is not unusual for us to spend as much time cleaning one bathroom on our first visit as it takes to clean the entire home on subsequent visits. For example, if we don’t spend the time and effort usually needed to get shower doors, shower-door tracks, shower and bathtub walls, bathtub surfaces, plumbing fixtures and porcelain artifices deep-cleaned, these objects will never look clean no matter how often we come. If built-up mineral rings or hard-water stains are present we’ll need to get these surfaces back to “ground zero”.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">THE KITCHEN</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Kitchen cabinets, counter tops and appliances often require extra attention on our first visit. Grease, fingerprints and other dirt seems to make its way through everyone’s home and gravitates toward the kitchen (and seems to think that the top of the fridge is an especially good place to hide!). It doesn’ take long for kitchen dust to become impregnated with grease ~ certainly a lot more difficult to clean then a week’s accumulation of ordinary surface dust. Some clients ask us to clean the oven or inside the refrigerator on our first visit, too (this is an extra task, just ask your cleaner or our office).</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">ALL ROOMS</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>The amount of time and effort required to bring all rooms back to ground zero depends on many factors. Most of our clients hire us because they simply don’t have time to clean the in-depth cleaning that we are really good at. Perhaps no one has vacuumed behind or beneath the sofa for months (and moving furniture to clean isn’t as easy for one person as it is for a team of cleaners). Bookshelves, baseboards, window sills, windows, chandeliers or other furnishings and fixtures are often neglected for long periods of time and may require extra initial .TLC..</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">See what’s included in every Green Frog cleaning</h2>
                                    </div>
                                    <div className="service-list-box-button">
                                        <a href="/#" className="btn custom-btn2">Green Frog Cleaning Scope</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default Scheduled;