import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import './assets/css/Responsive.css'
import Facebook from '../../src/Booking/assets/Images/social/facebook.png';
import Yelp from '../Booking/assets/Images/social/yelp.webp';
import Angi from '../Booking/assets/Images/social/angi.webp';
import Nextdoor from '../Booking/assets/Images/social/nextdoor.webp';
import Tabs from './camponent/Tabs/Tabs';
import Footer from './camponent/footer/Footer';
import Aboutusprofile02 from '../Booking/assets/Images/profile02.webp';
import Blogimg from '../Booking/assets/Images/blogimage.webp';
import User from '../Booking/assets/Images/blogs.webp';
import Social from './camponent/social/Social';
import { Link } from 'react-router-dom';
import Hero from '../Booking/assets/Images/hero/hero.webp';
import Slider2 from './camponent/slider/Slider2';
import Accordion from './camponent/Accordion/Accordion';
import Header2 from './camponent/header/Header2';
import Comman from './camponent/CommanContent/Comman';
import { Helmet } from 'react-helmet';



function Home() {


    const generateMetaTags = () => {
        const metaTags = [
            { name: "title", content: `Greenfrog` },
            { name: "description", content: `Greenfrog` },
            { property: "og:title", content: `Greenfrog` },
            { property: "og:description", content: `Greenfrog` },
            { property: "og:url", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@greenfrog" },
            { name: "twitter:title", content: `Greenfrog` },
            { name: "twitter:description", content: `Greenfrog` },
        ];

        metaTags.push({ property: "og:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        metaTags.push({ name: "twitter:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        // if (og_image) {
        // }

        return metaTags;
    };

    return (
        <>

            <div>
                {/* <Helmet>
                    {generateMetaTags().map((tag, index) => (
                        <meta key={index} {...tag} />
                    ))}
                </Helmet> */}
                <Helmet>
                    <title>Greenfrog</title>
                    <meta name="description" content="Greenfrog" />
                </Helmet>
            </div>

            <div className="Header">
                <Header2 />
            </div>
            <Social />
            {/* hero section start here  */}
            <div className="hero-section mobile-hide">
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="hero-image">
                                <div className="hero-text">
                                    <h1 className='hero-title'>Everybody Loves <br /> A Clean Home <br /> <span className='bold-title'>Greenfrog</span></h1>
                                    <Link to='/booking'><button className="btn hero-btn">Booking Now</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hero-section-mobile mobile-show'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className="hero-image">
                                <div className="hero-text">
                                    <h1 className='hero-title'>Everybody Loves <br /> A Clean Home <br /> <span className='bold-title'>Greenfrog</span></h1>
                                    <Link to='/booking'><button className="btn hero-btn">Booking Now</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className="hero-image">
                                <img src={Hero} alt="hero" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero section end here  */}

            {/* our review section start here  */}

            <section className="review-section custom-section" id='review-section'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="custom-title">
                                <h2 className='title'>Our <span className='bold-title'>Reviews</span></h2>
                            </div>
                            <div className="review-social-link">
                                <div className='row'>
                                    <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                        <a href="#" className='social-custom-effect'>
                                            <div className="review-link">
                                                <div className='review-link-btn'> <img className="review-btn-img" alt="001img" src={Facebook} /> Facebook</div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                        <a href="#" className='social-custom-effect'>
                                            <div className="review-link">
                                                <div className='review-link-btn'> <img className="review-btn-img" alt="001img" src={Yelp} /> Yelp</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                        <a href="#" className='social-custom-effect'>
                                            <div className="review-link">
                                                <div className='review-link-btn'> <img className="review-btn-img" alt="001img" src={Angi} /> Angiest List</div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                        <a href="#" className='social-custom-effect'>
                                            <div className="review-link">
                                                <div className='review-link-btn'> <img className="review-btn-img" alt="001img" src={Nextdoor} /> Nestdoor</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='review-btn'>
                                <a className="btn review-link-btn" href='/Reviews'>View All Reviews</a>
                            </div>
                            {/* <Silder1 /> */}
                            <Slider2 />
                        </div>
                    </div>
                </div>
            </section>

            {/* our review section end here  */}

            {/* our service section start here  */}
            <section className="service-section custom-section" id='service-section'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="custom-title">
                                <h2 className='title'>Our <span className='bold-title'>Services</span></h2>
                            </div>
                            <div className="row">
                                <Tabs />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our service section end here  */}

            {/* our service section start here  */}
            <section className="mobile-service-section custom-section" id='service-section'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="custom-title">
                                <h2 className='title'>Our <span className='bold-title'>Services</span></h2>
                            </div>
                            <div className="row">
                                <Accordion />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our service section end here  */}

            {/* about us section start here  */}
            <section className='about-us-section custom-section' id='about-us-section'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="custom-title">
                                <h2 className='title'>About <span className='bold-title'>Us</span></h2>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-md-5'>
                                    <div className='user-img' >
                                        <img src={Aboutusprofile02} alt='about-us' className='profile02-image' />
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div>
                                        <div className='about-us-title'>
                                            <h3>About Green Frog Cleaning</h3>
                                        </div>
                                        <div className='about-us-content'>
                                            <p>We are on a mission to provide the highest quality, punctual, and friendliest house cleaning in San Diego. We have a great group of technicians here at Green Frog that have been with us for a long time. We’ve been locally owned and operated by husband and wife team Jaime and Brian Nilles since 2004. They would be happy to speak with you anytime about how we can best serve you because, it’s all about you.</p>
                                        </div>
                                        <div className='about-us-btn'>
                                            <a href='/About' className="btn about-us-link-btn custom-hover-effect">Discover More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* about us section end here  */}

            {/* Our blog section start here */}
            <section className='our-blog-section custom-section' id='our-blog-section'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="custom-title">
                                <h2 className='title'>Our <span className='bold-title'>Blog </span></h2>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-12 col-md-12'>
                            <img src={Blogimg} alt='our-blog' className='img-fluid blog-sec-image' />
                            <div className='our-blog-title mt-4'>
                                <h3>How to Clean and Disinfect Your Home’s Most Touched Areas</h3>
                            </div>
                            <div className='our-blog-content'>
                                <p>Germs can be found in almost every area of our homes. While this might be normal, there are high-touch areas in our homes that are covered in thousands of bacteria that can easily spread diseases or cause infections to us and our families. That is why it’s very important to disinfect our homes regularly, especially the areas we touch the most every day.</p>
                            </div>
                            <div className='our-blog-btn'>
                                <a href='/CleaningTip' className="btn our-blog-link-btn">Read More</a>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-12 col-md-12'>
                            <div className='blog-short align-items-center cstm-padding'>
                                <div className='user-img' >
                                    <img src={User} alt='#00' className='blog-img' />
                                </div>
                                <div className='ms-4'>
                                    <div className='our-blog-title'>
                                        <h3>How to Keep Your Floors Clean Everyday</h3>
                                    </div>
                                    <div className='our-blog-content'>
                                        <p>Oct 13, 2022</p>
                                    </div>
                                    <div className='ourblog-btn'>
                                        <a href='/booking' className="btn ourblog-link-btn">Book This Service</a>
                                    </div>
                                </div>
                            </div>
                            <div className='blog-short align-items-center cstm-padding'>
                                <div className='user-img' >
                                    <img src={User} alt='#00' className='blog-img' />
                                </div>
                                <div className='ms-4'>
                                    <div className='our-blog-title'>
                                        <h3>How to Keep Your Floors Clean Everyday</h3>
                                    </div>
                                    <div className='our-blog-content'>
                                        <p>Oct 13, 2022</p>
                                    </div>
                                    <div className='ourblog-btn'>
                                        <a href='/booking' className="btn ourblog-link-btn">Book This Service</a>
                                    </div>
                                </div>
                            </div>
                            <div className='blog-short align-items-center cstm-padding'>
                                <div className='user-img' >
                                    <img src={User} alt='#00' className='blog-img' />
                                </div>
                                <div className='ms-4'>
                                    <div className='our-blog-title'>
                                        <h3>How to Keep Your Floors Clean Everyday</h3>
                                    </div>
                                    <div className='our-blog-content'>
                                        <p>Oct 13, 2022</p>
                                    </div>
                                    <div className='ourblog-btn'>
                                        <a href='/booking' className="btn ourblog-link-btn">Book This Service</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our blog section end here  */}

            <Comman />

            {/* footer section start here  */}
            <Footer />
            {/* footer section end here  */}

        </>
    );
}

export default Home;
