import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import { FaStar } from 'react-icons/fa';
import Header2 from "./camponent/header/Header2";

function MoveInOut() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="housecleaning-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Move In/Out <span className='bold-title'><span className='bold-title bg-color'>Cleaning Service</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-text">
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>It’s no secret that moving is a big job. There’s a lot of steps involved in moving from packing to changing your address. But there’s one step that doesn’t have to be as hard as it may seem and that step my friends is cleaning. Whether you’re moving in a new home or out of an old home, you using a professional cleaning service will take a load off your plate. </p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Cleaning your previously inhabited space when moving out will help to ensure that you get your security deposit back if you put one down. Or that your house will sell if it’s on the market. No one wants to buy a dirty home. And if you’re just moving into a place a good cleaning ensures that your home has been disinfected and is ready for you to occupy.</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Whether you’re moving into a new place or out of an old place hiring a professional cleaner can help eliminate and reduce your stress level.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Benefits of Move In Cleaning Services</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Now that you’re out of your old place and partially into your new place, there are a few things you need to do before you unpack. It’s important to clean and disinfect surfaces in your new home before settling in. You want to make sure the kitchen is cleaned as well as the bathroom. A professional cleaning service can have this taken care of before you get the last box in the door making sure that the only thing you have left to do is unpack.</p>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <h2 className="sub-title">The benefits of move in cleaning include:</h2>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Starting with a clean sheet</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Having the service completed before you’re finished moving</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Getting a deep clean</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Being able to know what was cleaned and what wasn’t</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Benefits of Move In Cleaning Services</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>When it comes to moving out, one thing you should splurge on is a professional house cleaner to help you with your dirty work (literally). Moving out is more stressful than moving in for many reasons, but the most common one is cleaning. In order to complete your move as soon as possible consider hiring a professional to clean for you. </p>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <h2 className="sub-title">The benefits of move out cleaning include:</h2>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Getting your full security deposit back if you rent</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Sell your place quickly if you own</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Being able to build a good reputation as a tenant </p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Call Us</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Perhaps one of the most important benefits of move in/out cleaning services is the freedom you experience when you don’t have to worry about cleaning. It allows you to focus your attention, time, and energy in places that really need it. That’s where we come in. Let us help. If you have any additional questions or would like a free quote, fill out the form on our site to get started today.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default MoveInOut;