import React, { useState, useEffect } from "react";
import { useRef } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import Spinner from 'react-bootstrap/Spinner';
import { Roller } from "react-awesome-spinners";
import Form from "react-bootstrap/Form";
import "./Appointment.css";
import Calendar from "./Calendar";
import campaigns from "./campaigns";
import BootstrapTable from 'react-bootstrap-table-next';
import pagination from './pagination';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { TbHotelService, TbCalendar, TbClock, TbDental, TbDelta } from "react-icons/tb";
import { FaRegEdit, FaCheck, FaThumbsDown, FaRegTimesCircle, FaCheckCircle, FaTimesCircle, FaMoneyBillAlt, FaToggleOn, FaRegWindowMinimize } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { MdAdd, MdClose } from "react-icons/md";
import { RiDeleteBin6Line, RiCloseCircleFill } from "react-icons/ri";
import { BsStopwatch } from "react-icons/bs";
import { TbMinimize } from "react-icons/tb";
import Staxs from "../Components/StaxsDataTable/Informasi";
import { ModalPopContext } from "./ModalPopContext";
import { useContext } from "react";
import { userslist } from "../Api/bookingApi";
import 'react-notifications/lib/notifications.css';
import Select from "react-select";
import { getdata } from "../Api/bookingApi";
import { getactivestaff } from "../Api/bookingApi";
import { getbookingservices } from "../Api/bookingApi";
import { getsubservicebyservice } from "../Api/bookingApi";
import { addbooking } from "../Api/bookingApi";
import { getuserData } from "../Api/bookingApi";
import { SITE_URL } from "../Api/config";
import { getusershippingaddress, updateUser, getusershippingaddressbyid } from "../Api/crm";
import { getEditbookingData } from "../Api/bookingApi";
import { getStaff } from "../Api/bookingApi";
import { updateBookingData } from "../Api/bookingApi";
import { calculatePriceApi } from "../Api/bookingApi";
import moment from "moment";
import { confirmbookingstatus } from "../Api/bookingApi";
import { rejectbookingstatus } from "../Api/bookingApi";
import { deletebookingApi } from "../Api/bookingApi";
import { reccuringdeletebooking } from "../Api/bookingApi";
import { getrescheduledata } from "../Api/bookingApi";
import { saverescheduledata } from "../Api/bookingApi";
import { getbookings } from "../Api/bookingApi";
import { getDates } from "../Api/bookingApi";
import { getaddon } from "../Api/bookingApi";
import { getZone } from "../Api/bookingApi";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { createStaxId } from "../Api/bookingApi";
import Card from 'react-bootstrap/Card';
import { BsPlusLg } from "react-icons/bs";
import { usePaymentInputs } from 'react-payment-inputs';

import { unconfirmbookingstatus } from "../Api/bookingApi";
import { complatebookingstatus, addbookingbookingstatus } from "../Api/bookingApi";
import { uncomplatebookingstatus } from "../Api/bookingApi";
import { activebookingstatus } from "../Api/bookingApi";
import { createStaxIdApi } from "../Api/bookingApi";
import { createinvoiceApi } from "../Api/bookingApi";

import { getsubserviceaddonsApi } from "../Api/bookingApi";
import { getinvoiceApi } from "../Api/bookingApi";
import { createPaymentMethod } from "../Api/bookingApi";
import { getpaymentMethod } from "../Api/bookingApi";
import { payinvoiceApi } from "../Api/bookingApi";
import { checkemailApi } from "../Api/bookingApi";
import { getpreferredstaffApi, getsearchpricestatus } from "../Api/bookingApi";
import { updateservicepreferenceApi } from "../Api/bookingApi";
import { getbookingservicesApi, updaterecurringbookingAPi, findzonebyzipApi, getproductlistdataApi, updatecrmdataApi, getdefaultpayment, updatepaymentMethod } from "../Api/bookingApi";
import ChildComponent from '../Components/EditUser/Informasi';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getreport } from "../Api/technician";
import Draggable from "react-draggable";
import { CgMaximizeAlt } from "react-icons/cg";
import InputMask from 'react-input-mask';

// import {date_time}  from "../helper/timezone";

import { Link } from "react-router-dom";



function Appointment() {

  const [
    showNewBooking,
    setShowNewBooking,
    showBookingDetails,
    setShowBookingDeatils,
    bookingDetails,
    setBookingDetails,
    dateData,
    setDateData,
    booking,
    setBooking, staffId, setStaffId,
    updateCalendarData
  ] = useContext(ModalPopContext);

  const [bookingDate, setBookingDate] = useState(dateData);
  const [recurringtime, setRecurringtime] = useState(dateData);
  const [editstart, setEditstart] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBookingDate(dateData);
    setRecurringtime(moment(dateData).format("HH:mm:ss"));
  }, [dateData]);

  const [users, setUsers] = useState([]);
  const [activeStaff, setActiveStaff] = useState([]);
  const [dammyactivestaff, setDammyactivestaff] = useState([]);
  const [service, setService] = useState([]);
  const [dummyservice, setDummyService] = useState([]);
  const [subService, setSubService] = useState([]);
  const [ChooseService, setChooseService] = useState([]);
  const [ChooseStaff, setChooseStaff] = useState([staffId]);
  const [Choosezone, setChoosezone] = useState([]);
  const [appointmentNotes, setAppointmentNotes] = useState([]);
  const [editbooking, setEditbooking] = useState([]);
  const [staffList, setStaffList] = useState(null);
  const [staffListedit, setStaffListEdit] = useState(null);
  const [assignStaff, setAssignStaff] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [validated, setValidated] = useState(false);
  const [servicename, setServicename] = useState('');
  const [serviceTabValidation, setServiceTabValidation] = useState('nodanger');
  const [userType, setUserType] = useState(0);

  const [bookingLat, setBookingLat] = useState(0);
  const [bookingLong, setBookingLong] = useState(0);


  const [loadingspinner, setLoadingSpinner] = useState(false);

  const [usershippingaddress, setUsershippingaddress] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [defaultshippingaddressid, setDefaultshippingaddressid] = useState(null);
  const [default_shipping_address_id, setDefault_shipping_address_id] = useState("");
  const [discount, setDiscount] = useState(0);
  const [billing_notes, setBilling_notes] = useState('');
  const [defaultpaymentmethod, setDefaultpaymentmethod] = useState(null);



  const getStaffList = () => {
    getactivestaff().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setStaffList(apiResponse.data);
        setStaffListEdit(apiResponse.data);
      }
    })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStaffList();
    if (staffId != null && staffId != undefined && staffId != '') {
      setChooseStaff(staffId.value);
    }
  }, []);

  const [existingUser, setExistingUser] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    bedrooms: "",
    bathrooms: "",
    billedhours: "",
    password: "",
    type: "",
    zone_name: "",
    shipping_zip: "",
    shipping_state: "",
    shipping_city: "",
  });

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setExistingUser((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const [searchkeyword, setSearchkeyword] = useState('');
  const [pageno, setPageno] = useState(1);

  const userdata = (type) => {

    userslist(searchkeyword, type == "search" ? 1 : pageno)
      .then((res) => {
        if (res.code === 200) {
          const apiResponse = res.data;
          if (apiResponse.data.length > 0) {

            if (type === "scroll") {
              setUsers([...users, ...apiResponse.data]);
              setPageno(pageno + 1);
            }
            else {
              setUsers(apiResponse.data);
            }

          }
          else {
            if (type === "search") {
              setUsers([]);
            }
          }
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

  };

  useEffect(() => {
    userdata();
    getstaff();
    productlistdata()
  }, []);

  useEffect(() => {
    getservices(userType);

    if (userType == 0) {
      const insertdata = {
        value: "0",
        label: "Same As Above",
      };
      setSelectedOptions(insertdata);
      setUsershippingaddress([insertdata]);

      setAppointmentaddress(existingUser.address);
      setAppointmentcity(existingUser.city);
      setAppointmentstate(existingUser.state);
      setAppointmentzip(existingUser.zip);
      setDefault_shipping_address_id(0);
      setDiscount(existingUser.discount);
      setBilling_notes(existingUser.billing_notes);
    }

  }, [userType]);

  useEffect(() => {
  }, [selectedOptions]);

  useEffect(() => {
    if (searchkeyword !== '') {
      setPageno(1);
      userdata("search");
    }
  }, [searchkeyword]);



  // create a function to convert hh:mm in minutes 
  const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }



  useEffect(() => {
    // convert hh:mm in minutes 
  }, [ChooseStaff]);

  const [collapse, setCollapse] = useState(false);

  const [show, setShow] = useState(false);

  const [minimized, setMinimized] = useState(false);

  const handleClose = () => {
    setShowNewBooking(false);
    clearFormData();
  }

  const clearFormData = () => {
    setExistingUser({
      id: "",
      firstname: "",
    });
    setAppointmentaddress('');
    setAppointmentcity('');
    setAppointmentstate('');
    setAppointmentzip('');
    setServicepercentage(0);
    setChooseService([]);
    setSubService([]);
    setAddons([]);
    setServicename('');
    setFrequency([]);
    setBookingStartDate(null);
    setBookingEndDate(null);
    setChooseStaff([staffId]);
    setValidated(false);
    setCheckemailbooking(false);
    setUsershippingaddress([]);
    setUserbilledhours(0);
    setUserbilledamount(0);
    setUserType(0);
  }
  const handleShow = () => setShow(true);

  const handleMinimize = () => setMinimized(true);
  const handleMaximize = () => setMinimized(false);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShowBookingDeatils(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [hidetimedisplay, setHidetimedisplay] = useState(true);

  // const closeModal = () => {
  //   document.querySelector(".recurring-modal-content").classList.remove("active");
  // };

  const addActive = () => {
    document.querySelector(".recurring-content").classList.add("active");
    document.querySelector(".once-content").classList.remove("active");
    document.querySelector(".recurring-modal-content").classList.add("active");
    // document.querySelector(".btn-group").style.display = "none";
    setHidetimedisplay(false);
  };
  const removeActive = () => {
    document.querySelector(".recurring-content").classList.remove("active");
    document.querySelector(".once-content").classList.add("active");
    document.querySelector(".recurring-modal-content").classList.remove("active");
    setRecurringbookingdate(null);
    setHidetimedisplay(true);
    setBookingEndDate(null);
    setBookingStartDate(null);
  };

  const addClass = () => {
    // document.querySelector(".view-more-content-hide").classList.add("active");
    // document.querySelector(".less-btn").classList.add("active");
    // document.querySelector(".more-btn").classList.add("active");
    // setCollapse(!collapse);
  };

  const removeClass = () => {
    // document
    //   .querySelector(".view-more-content-hide")
    //   .classList.remove("active");
    // document.querySelector(".less-btn").classList.remove("active");
    // document.querySelector(".more-btn").classList.remove("active");
    // setCollapse(!collapse);
  };

  const [checkemailbooking, setCheckemailbooking] = useState(false);
  const showExistingUser = () => {
    document.querySelector(".existing-user").classList.add("active");
    // document.querySelector(".bookingData").classList.add("active");
    // document.querySelector(".bookingData_2").classList.add("active");
    document.querySelector(".userdata").classList.remove("newuser");
    document.querySelector(".password").classList.remove("clicknone");
    document.querySelector(".appointment_details").classList.add("active");
    setUserType(1);
    setCheckemailbooking(false);
  };

  const showNewUser = () => {
    document.querySelector(".existing-user").classList.remove("active");
    document.querySelector(".userdata").classList.add("newuser");
    document.querySelector(".password").classList.add("clicknone");
    // document.querySelector(".bookingData").classList.add("active");
    // document.querySelector(".bookingData_2").classList.add("active");
    document.querySelector(".appointment_details").classList.add("active");

    setExistingUser({
      id: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      bedrooms: "",
      bathrooms: "",
      billedhours: "",
      password: "",
      type: "new",
    });
    setUserType(0);
    setUserbilledhours(0);
    setUserbilledamount(0);
  };

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [showCustomer, setShowCustomer] = useState(false);

  const handleCloseCustomer = () => setShowCustomer(false);
  const handleShowCustomer = () => setShowCustomer(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [myState, setMyState] = React.useState(0);
  const [myState2, setMyState2] = React.useState(0);
  const [addons, setAddons] = React.useState([]);
  const [servicepercentage, setServicepercentage] = React.useState(0);
  const [userbilledhours, setUserbilledhours] = useState(0);
  const [userbilledamount, setUserbilledamount] = useState(0);


  function changeCounter(value) {
    if (value === "increment") {
      setMyState((prevState) => prevState + 1);
    } else if (value === "decrement") {
      setMyState((prevState) => (prevState - 1 < 0 ? 0 : prevState - 1));
    }
  }

  const getandsetdata = (user) => {
    document.querySelector(".userdata").classList.add("newuser");

    const formData = new FormData();
    formData.append("user_id", user.value);
    getdata(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setExistingUser(apiResponse.data);
        setAppointmentaddress(apiResponse.data.address);
        setAppointmentcity(apiResponse.data.shipping_city);
        setAppointmentstate(apiResponse.data.shipping_state);
        setAppointmentzip(apiResponse.data.shipping_zip);
        setAppointmnetzonename(apiResponse.data.zone_name);
        setUserbilledhours(apiResponse.data.billedhours);
        setUserbilledamount(apiResponse.data.billed_amount);
        setuserbilledamountEdit(apiResponse.data.billed_amount);
        setuserbilledhoursEdit(apiResponse.data.billedhours);
        setDefaultshippingaddressid(apiResponse.data.default_shipping_address_id);
        setDiscount(apiResponse.data.discount);
        setBilling_notes(apiResponse.data.billing_notes);

        if (apiResponse.data.discount !== null && apiResponse.data.discount !== undefined && apiResponse.data.discount !== 0) {
          const discount = parseInt(apiResponse.data.billed_amount) - parseInt(apiResponse.data.discount);
          setDiscountuser(discount);
        } else {
          setDiscountuser(apiResponse.data.billedhours);
        }



      } else {
        alert("Something went wrong");
      }
    });

    // setUsers(user.value);
  };


  const getusershipppingaddress = (user) => {
    const formData = new FormData();
    formData.append("user_id", user.value);
    formData.append("keyword", "");

    //get user data
    getusershippingaddress(formData).then((response) => {
      if (response.code === 200) {
        const apiResponse = response.data;

        const data = apiResponse.data;
        const insertdata = {
          value: "0",
          label: "Same As Above",
        };

        const uniqueAddresses = [insertdata, ...data];

        setUsershippingaddress(uniqueAddresses);
      }
    }).catch((err) => {
      console.log(err);
    });
  };


  useEffect(() => {
    if (defaultshippingaddressid) {
      const bydefault = usershippingaddress.find(option =>
        option.value.trim() === defaultshippingaddressid.trim()
      );
      setSelectedOption(bydefault ? [bydefault] : []);

      // setAppointmentaddress , setAppointmentcity , setAppointmentstate , setAppointmentzip will be done in setappointmentData

      //now split string to get address , city , state , zip
      if (bydefault) {
        if (bydefault.value == 0) {
          setAppointmentaddress(existingUser.address);
          setAppointmentcity(existingUser.city);
          setAppointmentstate(existingUser.state);
          setAppointmentzip(existingUser.zip);
        } else {
          const addressStr = bydefault.label;
          const [, addressPart] = addressStr.split(" - ");

          const addressComponents = addressStr ? addressPart.split(", ") : [];

          setAppointmentaddress(addressComponents[0]);
          setAppointmentcity(addressComponents[1]);
          setAppointmentstate(addressComponents[3]);
          setAppointmentzip(addressComponents[2]);
        }

      }
    }
  }, [defaultshippingaddressid, usershippingaddress]);

  const getusershippingaddressdata = (shippingdata) => {
    if (shippingdata.length > 0 && shippingdata[0].value) {
      setDefault_shipping_address_id(shippingdata[0].value);
      const formData = new FormData();
      formData.append("id", shippingdata[0].value);
      if (shippingdata[0].value == 0) {
        setSelectedOption([{
          value: "0",
          label: "Same As Above",
        }]);
        setAppointmentaddress(existingUser.address);
        setAppointmentcity(existingUser.city);
        setAppointmentstate(existingUser.state);
        setAppointmentzip(existingUser.zip);
      } else {
        getusershippingaddressbyid(formData).then((res) => {
          if (res.code === 200) {
            const apiResponse = res.data;
            setAppointmentaddress(apiResponse.data.shipping_address);
            setAppointmentcity(apiResponse.data.shipping_city);
            setAppointmentstate(apiResponse.data.shipping_state);
            setAppointmentzip(apiResponse.data.shipping_zip);
            setSelectedOption([{
              value: shippingdata[0].value, // Keep consistent with the structure of your options
              label: `${apiResponse.data.address_title} - ${apiResponse.data.shipping_address}, ${apiResponse.data.shipping_city}, ${apiResponse.data.shipping_state} ${apiResponse.data.shipping_zip}`,
            }]);
          } else {
            alert("Something went wrong");
          }
        });
      }
    } else {
      setSelectedOption([]);
    }
  }

  const getstaff = () => {
    getactivestaff().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setActiveStaff(apiResponse.data);
        setDammyactivestaff(apiResponse.data)
      } else {
        alert("Something went wrong");
      }
    });
  };


  const getservices = (userType) => {
    getbookingservices(userType).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setService(apiResponse.data);
        setDummyService(apiResponse.data);
        fiterserivedata();
      } else {
        alert("Something went wrong");
      }
    });
  };

  const getsubservice = (service) => {
    // setServicepercentage(service.service_percentage);
    if (bookedpercentage > 0) {
      const extraAmount = (bookedpercentage / 100) * service.service_percentage;
      const finalPercentage = parseInt(service.service_percentage) + parseInt(extraAmount);
      setServicepercentage(finalPercentage);
    } else {
      setServicepercentage(service.service_percentage);
    }

    const formData = new FormData();
    formData.append("service_id", service.id);
    formData.append("user_id", existingUser.id);
    getsubservicebyservice(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setSubService(apiResponse.data);
      } else {
        alert("Something went wrong");
      }
    });
  };

  const getaddonlist = (id) => {
    const formData = new FormData();
    formData.append("service_id", id);
    formData.append("user_id", existingUser.id);
    getaddon(formData).then((res) => {
      if (res.code === 200) {
        if (res.data.status === "1") {
          const apiResponse = res.data;
          setAddons(apiResponse.data);

        }
        else {
          setAddons([]);
        }
      } else {
        alert("Something went wrong");
      }
    });
  }

  const getHoursandMinutes = (billedhours) => {
    const hours = Math.floor(billedhours / 60);
    const minutes = billedhours % 60;
    return `${hours}:${minutes}`;
  };

  const [appointmentaddress, setAppointmentaddress] = useState([]);
  const [appointmentcity, setAppointmentcity] = useState([]);
  const [appointmentstate, setAppointmentstate] = useState([]);
  const [appointmentzip, setAppointmentzip] = useState([]);
  const [basewithextra, setBasewithextra] = useState(0);
  const [appointmentzonename, setAppointmnetzonename] = useState('');
  const [LoadingSpinnerquote, setLoadingSpinnerquote] = useState(false);

  const [eventcount, setEventcount] = useState(0);

  const AddBookingData = (event) => {

    setLoadingSpinner(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setLoadingSpinner(false);
      setValidated(true);
      NotificationManager.error("FILL ALL REQUIRED FIELDS");
      return false;
    }

    if (checkemailbooking === true) {
      setLoadingSpinner(false);
      NotificationManager.error("Email already exists");
      return false;
    }


    if (existingUser.phone.length < 10) {
      setLoadingSpinner(false);
      NotificationManager.error("Please enter phone number between 10 digits to 15 digits");
      return false;
    }


    const checktime = moment(bookingDate).format("HH:mm");
    if (checktime < "07:00" || checktime > "20:00") {
      setLoadingSpinner(false);
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    if (appointmentaddress === "" || appointmentaddress === null || appointmentaddress === undefined) {
      setLoadingSpinner(false);
      alert("Please enter appointment address");
      return;
    }

    if (appointmentcity === "" || appointmentcity === null || appointmentcity === undefined) {
      setLoadingSpinner(false);
      alert("Please enter appointment city");
      return;
    }

    if (appointmentstate === "" || appointmentstate === null || appointmentstate === undefined) {
      setLoadingSpinner(false);
      alert("Please enter appointment state");
      return;
    }

    if (appointmentzip === "" || appointmentzip === null || appointmentzip === undefined) {
      setLoadingSpinner(false);
      alert("Please enter appointment zip");
      return;
    }


    if ((ChooseService.length < 1)) {
      // setServiceTabValidation('danger');
      setLoadingSpinner(false);
      NotificationManager.error("Please select service");
      return;
    }
    if ((ChooseStaff.length < 1)) {
      // setServiceTabValidation('danger');
      setLoadingSpinner(false);
      NotificationManager.error("Please select staff");
      return;
    }
    else {

      var userTypedata = "";
      if (userType === 0) {
        userTypedata = "new";
      } else {
        userTypedata = "old";
      }

      const formData = new FormData();
      formData.append("user_id", existingUser.id);
      formData.append("type", userTypedata);
      formData.append("service_id", ChooseService);
      formData.append("book_by", 0);
      if (ChooseService.length === 0) {
        document.querySelector(".selectservice").classList.add("ct-cal-error");
        return;
      }
      else {
        document.querySelector(".selectservice").classList.remove("ct-cal-error");
      }

      if (ChooseStaff.length === 0) {
        document.querySelector(".selectstaff").classList.add("ct-cal-error");
        return;
      }
      else {
        document.querySelector(".selectstaff").classList.remove("ct-cal-error");
      }

      const staffIds = [];

      if (ChooseStaff.length === 1) {
        staffIds.push(staffId.value);
      } else {
        for (let i = 0; i < ChooseStaff.length; i++) {
          staffIds.push(ChooseStaff[i].value);
        }
      }

      formData.append("sub_service_id", JSON.stringify(subService));
      formData.append("staff_id", staffIds);
      formData.append("app_notes", appointmentNotes);

      if (ChooseService === "1") {
        if (userbilledamount != 0 && userbilledamount !== '') {
          formData.append("amount", Math.round(userbilledamount));
        } else {
          formData.append("amount", Math.round(expectedtotalAmount));
        }
      } else {
        formData.append("amount", Math.round(expectedtotalAmount));
      }

      formData.append(
        "client_name",
        existingUser.firstname + " " + existingUser.lastname
      );
      formData.append("firstname", existingUser.firstname);
      formData.append("lastname", existingUser.lastname);
      formData.append("client_email", existingUser.email);
      formData.append("client_phone", existingUser.phone);



      formData.append("client_address", appointmentaddress);
      formData.append("client_city", appointmentcity);
      formData.append("client_state", appointmentstate);
      formData.append("client_zip", appointmentzip);
      formData.append("default_shipping_address_id", default_shipping_address_id);

      formData.append("booking_discount", discount);
      formData.append("booking_notes", billing_notes);



      formData.append("client_zone", Choosezone);

      if (ChooseService === "1") {
        if (userbilledhours != 0 && userbilledhours !== '') {
          formData.append("billed_hours", convertToHHMM(userbilledhours));
        } else {
          formData.append("billed_hours", convertToHHMM(basewithextra));
        }
      } else {
        formData.append("billed_hours", convertToHHMM(basewithextra));
      }

      formData.append("bedrooms", existingUser.bedrooms);
      formData.append("bathrooms", existingUser.bathrooms);
      formData.append("user_pwd", existingUser.password);
      const booking_date_time = moment(bookingDate).format("YYYY-MM-DD HH:mm:ss");
      const order_date = moment(bookingDate).format("YYYY-MM-DD");
      formData.append("booking_date_time", booking_date_time);
      formData.append("order_date", order_date);
      formData.append("payment_date", order_date);
      formData.append("recurring_booking_date", recurringbookingdate);
      formData.append("addons", JSON.stringify(addons));


      // if (ChooseService === "1") {
      //   if (userbilledamount !== 0 && userbilledamount !== '') {
      //     formData.append("estimated_price", "$" + (userbilledamount) + " to $" + Math.round(userbilledamount));
      //   } else {
      //     formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
      //   }
      // } else {
      //   formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
      // }

      if (discount > 0) {
        if (ChooseService === "1") {
          if (!userbilledamount || userbilledamount == 0 && userbilledhours == 0) {
            formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(discountuser));
          } else {
            formData.append("estimated_price", "$" + (discountuser) + " to $" + Math.round(discountuser));
          }
        } else {
          formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(discountuser));
        }
      } else {
        if (ChooseService === "1") {
          if (!userbilledamount || userbilledamount == 0 && userbilledhours == 0) {
            formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
          } else {
            formData.append("estimated_price", "$" + (userbilledamount) + " to $" + Math.round(userbilledamount));
          }
        } else {
          formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
        }
      }

      if (ChooseService === "1") {
        if (userbilledhours != 0 && userbilledhours !== '') {
          formData.append("estimated_duration", convertToHHMM(userbilledhours) + " to " + convertToHHMM(userbilledhours));
        } else {
          formData.append("estimated_duration", convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra));
        }
      } else {
        formData.append("estimated_duration", convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra));
      }

      // formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
      // formData.append("estimated_duration", convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra));

      if (recurringbookingdate !== "" && recurringbookingdate !== null) {
        formData.append("recurring_status", "Y");
        if (recurringtime === null || recurringtime === "") {
          setLoadingSpinner(false);
          alert("Please select recurring time");
          return;
        }
        if (recurringtime < "07:00" || recurringtime > "20:00") {
          setLoadingSpinner(false);
          NotificationManager.error("⚠ Please select recurring time between 07:00 AM to 08:00 PM");
          return;
        }

        else {

          // const recurringtimee = moment(recurringtime).format("HH:mm");
          console.log("recurringtimee", recurringtime)

          if (recurringtime < "07:00" || recurringtime > "20:00") {
            setLoadingSpinner(false);
            NotificationManager.error("⚠ Please select recurring time between 07:00 AM to 08:00 PM");
            return;
          }
          else {
            formData.append("recurring_time_to", recurringtime);
          }
        }
      } else {
        formData.append("recurring_status", "N");
      }



      addbooking(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "1") {
            // alert("Booking Added Successfully");
            handleClose();
            setEventcount(eventcount + 1);
            setLoadingSpinner(false);
            // window.location.reload();
          } else {
            alert(res.data.message);
            setLoadingSpinner(false);
          }
        } else {
          alert("Something went wrong");
          setLoadingSpinner(false);
        }
      }).catch((error) => {
        console.log(error.message);
      }).finally(() => {
        setExistingUser({
          id: "",
          firstname: "",
        });
        setAppointmentaddress('');
        setAppointmentcity('');
        setAppointmentstate('');
        setAppointmentzip('');
        setServicepercentage(0);
        setChooseService([]);
        setSubService([]);
        setAddons([]);
        setServicename('');
        setFrequency([]);
        setBookingStartDate(null);
        setBookingEndDate(null);
        setChooseStaff([staffId]);
        setValidated(false);
        setCheckemailbooking(false);
        setUsershippingaddress([]);
        setUserbilledhours(0);
        setUserbilledamount(0);
        setUserType(0);
        setAppointmentNotes('');
      })


    }
    // }
    setValidated(true);
  };

  const savequotetolillypad = (event) => {

    setLoadingSpinnerquote(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setLoadingSpinnerquote(false);
      setValidated(true);
      NotificationManager.error("FILL ALL REQUIRED FIELDS");
      return false;
    }

    if (checkemailbooking === true) {
      setLoadingSpinnerquote(false);
      NotificationManager.error("Email already exists");
      return false;
    }


    if (existingUser.phone.length < 10) {
      setLoadingSpinnerquote(false);
      NotificationManager.error("Please enter phone number between 10 digits to 15 digits");
      return false;
    }


    const checktime = moment(bookingDate).format("HH:mm");
    if (checktime < "07:00" || checktime > "20:00") {
      setLoadingSpinnerquote(false);
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    if (appointmentaddress === "" || appointmentaddress === null || appointmentaddress === undefined) {
      setLoadingSpinnerquote(false);
      alert("Please enter appointment address");
      return;
    }

    if (appointmentcity === "" || appointmentcity === null || appointmentcity === undefined) {
      setLoadingSpinnerquote(false);
      alert("Please enter appointment city");
      return;
    }

    if (appointmentstate === "" || appointmentstate === null || appointmentstate === undefined) {
      setLoadingSpinnerquote(false);
      alert("Please enter appointment state");
      return;
    }

    if (appointmentzip === "" || appointmentzip === null || appointmentzip === undefined) {
      setLoadingSpinnerquote(false);
      alert("Please enter appointment zip");
      return;
    }

    if (appointmentNotes === true) {
      setLoadingSpinnerquote(false);
      NotificationManager.error("Booking Nnotes");
      return false;
    }


    if ((ChooseService.length < 1)) {
      // setServiceTabValidation('danger');
      setLoadingSpinnerquote(false);
      NotificationManager.error("Please select service");
      return;
    }
    if ((ChooseStaff.length < 1)) {
      // setServiceTabValidation('danger');
      setLoadingSpinnerquote(false);
      NotificationManager.error("Please select staff");
      return;
    }
    else {

      var userTypedata = "";
      if (userType === 0) {
        userTypedata = "new";
      } else {
        userTypedata = "old";
      }

      const formData = new FormData();
      formData.append("user_id", existingUser.id);
      formData.append("type", userTypedata);
      formData.append("service_id", ChooseService);
      formData.append("book_by", 0);
      if (ChooseService.length === 0) {
        document.querySelector(".selectservice").classList.add("ct-cal-error");
        return;
      }
      else {
        document.querySelector(".selectservice").classList.remove("ct-cal-error");
      }

      if (ChooseStaff.length === 0) {
        document.querySelector(".selectstaff").classList.add("ct-cal-error");
        return;
      }
      else {
        document.querySelector(".selectstaff").classList.remove("ct-cal-error");
      }

      const staffIds = [];

      if (ChooseStaff.length === 1) {
        staffIds.push(staffId.value);
      } else {
        for (let i = 0; i < ChooseStaff.length; i++) {
          staffIds.push(ChooseStaff[i].value);
        }
      }

      formData.append("sub_service_id", JSON.stringify(subService));
      formData.append("staff_id", staffIds);
      formData.append("app_notes", appointmentNotes);

      if (ChooseService === "1") {
        if (userbilledamount != 0 && userbilledamount !== '') {
          formData.append("amount", Math.round(userbilledamount));
        } else {
          formData.append("amount", Math.round(expectedtotalAmount));
        }
      } else {
        formData.append("amount", Math.round(expectedtotalAmount));
      }

      formData.append(
        "client_name",
        existingUser.firstname + " " + existingUser.lastname
      );
      formData.append("firstname", existingUser.firstname);
      formData.append("lastname", existingUser.lastname);
      formData.append("client_email", existingUser.email);
      formData.append("client_phone", existingUser.phone);



      formData.append("client_address", appointmentaddress);
      formData.append("client_city", appointmentcity);
      formData.append("client_state", appointmentstate);
      formData.append("client_zip", appointmentzip);
      formData.append("default_shipping_address_id", default_shipping_address_id);

      formData.append("booking_discount", discount);
      formData.append("booking_notes", billing_notes);



      formData.append("client_zone", Choosezone);

      if (ChooseService === "1") {
        if (userbilledhours != 0 && userbilledhours !== '') {
          formData.append("billed_hours", convertToHHMM(userbilledhours));
        } else {
          formData.append("billed_hours", convertToHHMM(basewithextra));
        }
      } else {
        formData.append("billed_hours", convertToHHMM(basewithextra));
      }

      formData.append("bedrooms", existingUser.bedrooms);
      formData.append("bathrooms", existingUser.bathrooms);
      formData.append("user_pwd", existingUser.password);
      const booking_date_time = moment(bookingDate).format("YYYY-MM-DD HH:mm:ss");
      const order_date = moment(bookingDate).format("YYYY-MM-DD");
      formData.append("booking_date_time", booking_date_time);
      formData.append("order_date", order_date);
      formData.append("payment_date", order_date);
      formData.append("recurring_booking_date", recurringbookingdate);
      formData.append("addons", JSON.stringify(addons));

      formData.append("booking_status", "Q");
      // if (ChooseService === "1") {
      //   if (userbilledamount !== 0 && userbilledamount !== '') {
      //     formData.append("estimated_price", "$" + (userbilledamount) + " to $" + Math.round(userbilledamount));
      //   } else {
      //     formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
      //   }
      // } else {
      //   formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
      // }

      if (discount > 0) {
        if (ChooseService === "1") {
          if (!userbilledamount || userbilledamount == 0 && userbilledhours == 0) {
            formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(discountuser));
          } else {
            formData.append("estimated_price", "$" + (discountuser) + " to $" + Math.round(discountuser));
          }
        } else {
          formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(discountuser));
        }
      } else {
        if (ChooseService === "1") {
          if (!userbilledamount || userbilledamount == 0 && userbilledhours == 0) {
            formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
          } else {
            formData.append("estimated_price", "$" + (userbilledamount) + " to $" + Math.round(userbilledamount));
          }
        } else {
          formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
        }
      }

      if (ChooseService === "1") {
        if (userbilledhours != 0 && userbilledhours !== '') {
          formData.append("estimated_duration", convertToHHMM(userbilledhours) + " to " + convertToHHMM(userbilledhours));
        } else {
          formData.append("estimated_duration", convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra));
        }
      } else {
        formData.append("estimated_duration", convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra));
      }

      // formData.append("estimated_price", "$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount));
      // formData.append("estimated_duration", convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra));

      if (recurringbookingdate !== "" && recurringbookingdate !== null) {
        formData.append("recurring_status", "Y");
        if (recurringtime === null || recurringtime === "") {
          setLoadingSpinnerquote(false);
          alert("Please select recurring time");
          return;
        }
        if (recurringtime < "07:00" || recurringtime > "20:00") {
          setLoadingSpinnerquote(false);
          NotificationManager.error("⚠ Please select recurring time between 07:00 AM to 08:00 PM");
          return;
        }

        else {

          // const recurringtimee = moment(recurringtime).format("HH:mm");
          console.log("recurringtimee", recurringtime)

          if (recurringtime < "07:00" || recurringtime > "20:00") {
            setLoadingSpinnerquote(false);
            NotificationManager.error("⚠ Please select recurring time between 07:00 AM to 08:00 PM");
            return;
          }
          else {
            formData.append("recurring_time_to", recurringtime);
          }
        }
      } else {
        formData.append("recurring_status", "N");
      }



      addbooking(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "1") {
            // alert("Booking Added Successfully");
            handleClose();
            setEventcount(eventcount + 1);
            setLoadingSpinnerquote(false);
            // window.location.reload();
          } else {
            alert(res.data.message);
            setLoadingSpinnerquote(false);
          }
        } else {
          alert("Something went wrong");
          setLoadingSpinnerquote(false);
        }
      }).catch((error) => {
        console.log(error.message);
      }).finally(() => {
        setExistingUser({
          id: "",
          firstname: "",
        });
        setAppointmentaddress('');
        setAppointmentcity('');
        setAppointmentstate('');
        setAppointmentzip('');
        setServicepercentage(0);
        setChooseService([]);
        setSubService([]);
        setAddons([]);
        setServicename('');
        setFrequency([]);
        setBookingStartDate(null);
        setBookingEndDate(null);
        setChooseStaff([staffId]);
        setValidated(false);
        setCheckemailbooking(false);
        setUsershippingaddress([]);
        setUserbilledhours(0);
        setUserbilledamount(0);
        setUserType(0);
        setAppointmentNotes('');
      })


    }
    // }
    setValidated(true);
  };


  const getStatusofbookings = (bookingstatus = "") => {
    if (bookingstatus === "A") {
      return "Unconfirmed";
    } else if (bookingstatus === "C") {
      return "Confirm";
    } else if (bookingstatus === "R") {
      return "Reject";
    } else if (bookingstatus === "CC") {
      return "Cancel by Client";
    } else if (bookingstatus === "CS") {
      return "Cancel by service provider";
    } else if (bookingstatus === "CO") {
      return "Completed";
    } else if (bookingstatus === "MN") {
      return "Mark as no show";
    } else if (bookingstatus === "UC") {
      return "Confirmed by Client";
    } else {
      return "";
    }
  };

  const getDateFormate = (appointmentstarttime = "") => {
    if (appointmentstarttime === null) {
      return "";
    } else {
      const date = appointmentstarttime.split(" ");
      const dateformate = date[0].split("-");
      const month = new Date(
        dateformate[0],
        dateformate[1] - 1,
        dateformate[2]
      );
      const monthname = month.toLocaleString("default", { month: "short" });
      return `${monthname}-${dateformate[2]}-${dateformate[0]}`;
    }
  };

  const getassigendstaff = (staff = "") => {
    if (staff === null) {
      return "";
    } else {
      const staffname = staff.replace(/(<([^>]+)>)/gi, "");
      return staffname;
    }
  };

  const getNotes = (bookingnotes = "") => {
    if (bookingnotes === null) {
      return "";
    } else {
      const notes = bookingnotes.replace(/(?:\r\n|\r|\n)/g, "<br />");
      const notes1 = notes.replace(/(<([^>]+)>)/gi, "");
      return notes1;
    }
  };

  const [edituser, setEdituser] = useState({
    id: "",
    image: null,
    user_email: "",
    user_pwd: "",
    first_name: "",
    last_name: "",
    phone: "",
    zip: "",
    address: "",
    city: "",
    state: "",
    notes: "",
    preferences: "",
    family_info: "",
    parking_access: "",
    preferrend_technician: "",
    special_instructions: "",
    technician_notes: "",
    appointment_note_by_staff: "",
    bathrooms: "",
    bedrooms: "",
    billed_amount: "",
    key_number: "",
    billed_hours: "",
    work_phone: "",
    message_phone: "",
    special_instructions_admin: "",
    payment_method_id: "",
    shipping_address: "",
    shipping_zip: "",
    shipping_city: "",
    shipping_state: "",
    customer_stage: "",
    zone: "",
    client_owner: "",
    email_status: "E",
    sms_status: "E",
    stax_payment_method: "",
    customer_id: "",
    estimated_duration: "",
    estimated_price: "",
  });
  const [profileImagePreview, setProfileImagePreview] = useState("");

  const handleEditInputChange2 = (event) => {
    const { name, value } = event.target;
    setEdituser((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));

  };

  const handleEditProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEdituser((prevUserData) => ({
        ...prevUserData,
        image: file,
      }));
      setProfileImagePreview(reader.result);
    };
  };

  const updatedUser = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("client_id", edituser.id);
    formData.append("first_name", edituser.first_name);
    formData.append("last_name", edituser.last_name);
    formData.append("user_email", edituser.user_email);
    formData.append("phone_number", edituser.phone);
    formData.append("zipcode", edituser.zip);
    formData.append("city", edituser.city);
    formData.append("address", edituser.address);
    formData.append("technician_notes", edituser.technician_notes);
    formData.append("state", edituser.state);
    formData.append("preferences", edituser.preferences);
    formData.append("family_info", edituser.family_info);
    formData.append("parking_access", edituser.parking_access);
    formData.append("preferrend_technician", edituser.preferrend_technician);
    formData.append("special_instructions", edituser.special_instructions);
    formData.append(
      "special_instructions_admin",
      edituser.special_instructions_admin
    );
    formData.append("billed_amount", edituser.billed_amount);
    formData.append("billed_hours", edituser.billed_hours);
    formData.append("bathrooms", edituser.bathrooms);
    formData.append("bedrooms", edituser.bedrooms);
    formData.append("zone", edituser.zone);
    formData.append("key_number", edituser.key_number);
    formData.append("customer_stage", edituser.customer_stage);
    formData.append("shipping_address", edituser.shipping_address);
    formData.append("shipping_zip", edituser.shipping_zip);
    formData.append("shipping_state", edituser.shipping_state);
    formData.append("shipping_city", edituser.shipping_city);

    //update user
    updateUser(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "success") {
          alert("User updated successfully");
          setShowCustomer(false);
          // window.location.reload();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    });
  };

  const [preferredStaff, setPreferredStaff] = useState([]);

  const getpreferredstaff = () => {
    getpreferredstaffApi().then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setPreferredStaff(response.data.data);
        } else {
          alert("data not found");
        }
      } else {
        alert("Something went wrong");
      }

    }
    ).catch((error) => {
      console.log(error);
    }
    );
  }

  useEffect(() => {
    getpreferredstaff();
  }, [edituser]);

  const getUserdata = () => {
    //show popup model showCustomer
    setShowCustomer(true);

    //get user data
    const formData = new FormData();
    formData.append("user_id", bookingDetails.client_id);

    getuserData(formData)
      .then((res) => {
        if (res.code === 200) {
          const apiResponse = res.data;
          setEdituser(apiResponse.data);
          setProfileImagePreview(apiResponse.data.image);
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  function getUserName(bookingclientname = " ") {
    if (bookingclientname === " ") {
      return " ";
    }
    else {
      const str = bookingclientname.replace(":", "");
      return str;
    }

  }

  function getClientNotes(clientNotes = " ") {
    if (clientNotes === " ") {
      return " ";
    }
    else {
      const str = clientNotes.replace(":", "");
      return str;
    }
  }

  function convertTimeTo24Hour(time) {
    // Split the time into hours, minutes, and AM/PM components
    const [timeString, modifier] = time.split(' ');
    const [hours, minutes] = timeString.split(':');

    // Convert hours to 24-hour format
    let hours24 = parseInt(hours, 10);
    if ((modifier == 'pm' || modifier == 'PM') && hours24 !== 12) {
      hours24 += 12;
    } else if ((modifier == 'am' || modifier == 'AM') && hours24 === 12) {
      hours24 = 0;
    }

    // Pad the hours and minutes with leading zeros if necessary
    const formattedHours = String(hours24).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return the time in 24-hour format
    return `${formattedHours}:${formattedMinutes}`;
  }


  const [EditbookingStartTime, setEditbookingStartTime] = useState(null);
  const [EditbookingEndTime, setEditbookingEndTime] = useState(null);

  const [EditbookingStartDate, setEditbookingStartDate] = useState(null);
  const [EditbookingEndDate, setEditbookingEndDate] = useState(null);
  const [EditSubServices, setEditSubServices] = useState([]);
  const [editaddons, setEditAddons] = useState([]);
  const [updatestaff, setUpdatestaff] = useState([]);

  const [bookingeditOrderdata, setbookingeditOrderdata] = useState(null);

  const getEditbooking = () => {


    setLoading(true);
    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    formData.append("client_id", bookingDetails.client_id);

    getEditbookingData(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          const apiResponse = response.data;
          setEditbooking(apiResponse.data);
          setUpdatestaff(apiResponse.data.staff_ids);
          const staff_ids = apiResponse.data.staff_ids;
          const staff_ids_array = staff_ids.split(",");
          const staff_ids_index = [];
          staff_ids_array.forEach((staff_id) => {
            const index = staffList.findIndex((staff) => staff.value === staff_id);
            staff_ids_index.push(index);
          });
          const selectedStaff = [];
          staff_ids_index.forEach((index) => {
            selectedStaff.push(staffList[index]);
          });
          setSelectedOptions(selectedStaff);

          const start_time = apiResponse.data.start_time;
          // const start_time = getbookingStartTime(apiResponse.data.start_time);

          setEditbookingStartTime(convertTimeTo24Hour(start_time));

          // setEditbookingStartTime(start_time);

          const end_time = getbookingStartTime(apiResponse.data.end_time);
          setEditbookingEndTime(end_time);

          const start_d_time = getbookingStartDate(apiResponse.data.booking_date_time);
          setEditbookingStartDate(start_d_time);

          const end_d_time = getbookingStartDate(apiResponse.data.booking_date_time);
          setEditbookingEndDate(end_d_time);

          setEditSubServices(apiResponse.data.subservices);
          setEditAddons(apiResponse.data.addons);
          const staff = apiResponse.data.staff_ids;
          setAssignStaff(selectedStaff);
          setbookingeditOrderdata(apiResponse.data.order_date);
          setEditbookingdiscount(apiResponse.data.booking_discount);
          setBookingLat(apiResponse.data.lat);
          setBookingLong(apiResponse.data.long);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
      handleShow3();
    })
  }

  const handleStaffChange = (event) => {
    const { name, value } = event.target;
    setEditbooking((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const getbookingStartDate = (bookingdatetime = "") => {
    const bookingDateTime = bookingdatetime.split(" ");
    const bookingDate = bookingDateTime[0];
    return bookingDate;
  }

  const getbookingStartTime = (start_time = "") => {
    var start_time = start_time.split(" ");
    var start_time1 = start_time[0].split(":");
    var starttime = start_time1[0] + ":" + start_time1[1];
    return starttime;
  }

  const handleEditInputChange3 = (event) => {
    const { name, value } = event.target;
    setEditbooking((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };


  const [editbookingstate, setEditbookingstate] = useState(" ");




  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [baseTime, setBaseTime] = useState(0);

  const [expectedtotalAmount, setExpectedTotalAmount] = useState(0);

  const [discountuser, setDiscountuser] = useState(0);


  const [editbookingestimatedPrice, setEditbookingestimatedPrice] = useState(0);
  const [editbookingestimatedDuration, setEditbookingestimatedDuration] = useState("00:00");

  const [editbookingdiscount, setEditbookingdiscount] = useState(0);


  // useEffect(() => {
  //   setEditbookingestimatedPrice(editbooking.estimated_price);
  //   setEditbookingestimatedDuration(editbooking.estimated_duration);
  // }, [editbooking]);



  const handleAmontChange = (value) => {
    if (value != "") {
      var value = parseInt(value);
      setExpectedTotalAmount(value);

      // const totalAmountvar1 = (10 * value) / 11;
      const totalAmountvar1 = ((100 - servicepercentage) * value) / 100;
      //const totalAmountvar1 = value + percentage;
      setTotalAmount(parseInt(totalAmountvar1));


      if (discount > 0) {
        const discountvalue = parseInt(value) - parseInt(discount);
        setDiscountuser(discountvalue);
      } else {
        setDiscountuser(0);
      }

      // const discountvalue = parseInt(totalAmountvar1) - parseInt(discount);
      // setDiscountuser(discountvalue);

    }
    else {
      setTotalAmount(0);
      setExpectedTotalAmount(0);
    }

  }
  const handleAmontChange22 = (value) => {
    if (value != "") {
      var value = parseInt(value);
      setUserbilledamount(value);

      // const totalAmountvar1 = (10 * value) / 11;
      const totalAmountvar1 = ((100 - servicepercentage) * value) / 100;
      //const totalAmountvar1 = value + percentage;
      setTotalAmount(parseInt(totalAmountvar1));

      if (discount > 0) {
        const discountvalue = parseInt(value) - parseInt(discount);
        setDiscountuser(discountvalue);
      } else {
        setDiscountuser(0);
      }
    }
    else {
      setTotalAmount(0);
      setExpectedTotalAmount(0);
    }

  }

  const handleChangeDiscount = (value) => {
    if (value !== "" && value > 0) {
      value = parseInt(value);

      setDiscount(value);

      let discountvalue;
      if (discount > 0) {
        if (ChooseService === "1") {
          if (!userbilledamount || userbilledamount == 0) {
            discountvalue = parseInt(expectedtotalAmount) - value;
            const totalAmountvar1 = ((100 - servicepercentage) * expectedtotalAmount) / 100;
            setTotalAmount(totalAmountvar1);
          } else {
            discountvalue = parseInt(userbilledamount) - value;
            // setUserbilledamount(discountvalue);
          }
        } else {
          discountvalue = parseInt(expectedtotalAmount) - value;
          const totalAmountvar2 = ((100 - servicepercentage) * expectedtotalAmount) / 100;
          setTotalAmount(totalAmountvar2);
        }
        setDiscountuser(discountvalue);
      } else {
        setDiscountuser(value);
        setTotalAmount(0);
      }

      const totalAmountvar1 = ((100 - servicepercentage) * discountvalue) / 100;
      setTotalAmount(parseInt(totalAmountvar1));
    } else {
      setDiscountuser(0);
      setTotalAmount(0);
      setDiscount(0);
    }
  }

  const handlechageeditamount = (value) => {
    if (value != "") {
      var value = parseInt(value);
      setEditbookingestimatedPrice(value);
      // const totalAmountvar1 = (10 * value) / 11;



      const discountvalue = parseInt(value) - parseInt(editbooking.booking_discount);
      setEditbookingChangesAmount(discountvalue);

      const service_percentage = parseInt(editbooking.service_percentage);
      const totalAmountvar1 = ((100 - service_percentage) * discountvalue) / 100;
      setEditTotalAmount(parseInt(totalAmountvar1));

    }
    else {
      setEditTotalAmount(0);
      setEditbookingestimatedPrice(0);
    }
  }



  const handleInputChange55 = (event) => {
    const inputValue = event.target.value;
    const [hours, minutes] = inputValue.split(':');
    const validMinutes = Math.min(Math.max(parseInt(minutes || 0, 10), 0), 59);
    const formattedValue = `${hours || '00'}:${validMinutes.toString().padStart(2, '0')}`;
    let totalDurationvar = convertToMinutes(formattedValue)
    setBasewithextra(totalDurationvar);
    setUpdatedendtime(totalDurationvar);

    //coonvert hh:mm in minutes

    // const totalDurationvarwithextra = (totalDurationvar * servicepercentage) / 11;
    const totalDurationvarwithextra = ((100 - servicepercentage) * totalDurationvar) / 100;
    // const totalDurationvarwithextra = totalDurationvar + extrarange;
    setBaseTime(parseInt(totalDurationvarwithextra));
    // setBaseTime(parseInt(totalDurationvarwithextra / ChooseStaff.length));
  };

  const handleInputChange555 = (event) => {
    const inputValue = event.target.value;
    const [hours, minutes] = inputValue.split(':');
    const validMinutes = Math.min(Math.max(parseInt(minutes || 0, 10), 0), 59);
    const formattedValue = `${hours || '00'}:${validMinutes.toString().padStart(2, '0')}`;
    let totalDurationvar = convertToMinutes(formattedValue)
    setUserbilledhours(totalDurationvar);
    setUpdatedendtime(totalDurationvar);

    //coonvert hh:mm in minutes

    // const totalDurationvarwithextra = (totalDurationvar * servicepercentage) / 11;
    const totalDurationvarwithextra = ((100 - servicepercentage) * totalDurationvar) / 100;
    // const totalDurationvarwithextra = totalDurationvar + extrarange;
    setBaseTime(parseInt(totalDurationvarwithextra));
    // setBaseTime(parseInt(totalDurationvarwithextra / ChooseStaff.length));
  };

  const handlechageedittime = (event) => {
    const inputValue = event.target.value;
    const [hours, minutes] = inputValue.split(':');
    const validMinutes = Math.min(Math.max(parseInt(minutes || 0, 10), 0), 59);
    const formattedValue = `${hours || '00'}:${validMinutes.toString().padStart(2, '0')}`;
    let totalDurationvar = convertToMinutes(formattedValue)
    setEditbookingestimatedDuration(totalDurationvar);
    //setExpedtedendtime(totalDurationvar)


    const service_percentage = parseInt(editbooking.service_percentage);
    //const totalDurationvarwithextra = (totalDurationvar * 11) / servicepercentage;
    const totalDurationvarwithextra = ((100 - service_percentage) * totalDurationvar) / 100;


    // setEditBaseTime(parseInt(totalDurationvarwithextra / ChooseStaff.length));
    setEditBaseTime(parseInt(totalDurationvarwithextra));

  };

  const handleuserbilledhours = (event) => {
    const inputValue = event.target.value;
    const [hours, minutes] = inputValue.split(':');
    const validMinutes = Math.min(Math.max(parseInt(minutes || 0, 10), 0), 59);
    const formattedValue = `${hours || '00'}:${validMinutes.toString().padStart(2, '0')}`;
    let totalDurationvar = convertToMinutes(formattedValue)
    setuserbilledhoursEdit(totalDurationvar);

  };

  useEffect(() => {
    getTimeData();
  }, [totalDuration]);


  const getTimeData = () => {
    // const start_time = totalDuration;
    // const hours = Math.floor(start_time / 60);
    // const minutes = start_time % 60;

    // // Format hours and minutes as two-digit numbers
    // const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    // const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

    // // Combine hours and minutes in the "00:00" format
    // const formattedTime = formattedHours + ':' + formattedMinutes;
    setBaseTime(totalDuration);

    const expectedtime = basewithextra;
    setBasewithextra(expectedtime);

  }

  useEffect(() => {
    calculatePrice();
  }, [subService, addons, ChooseService]);

  useEffect(() => {
    if (Array.isArray(ChooseStaff) && ChooseStaff.length > 0) {
      // Filter ChooseStaff to exclude items with the role "trainee"
      const staffOnly = ChooseStaff.filter(item => item && item.role !== "trainee");

      if (staffOnly.length > 0) {
        setUpdatedendtime(parseInt(basewithextra / staffOnly.length));
      } else {
        // Handle the case when there are no staff members (all are trainees)
        // You can set a default value or perform some other action here.
      }
    } else {
      // Handle the case when ChooseStaff is not an array or is empty
      // You can set a default value or perform some other action here.
    }

    // setUpdatedendtime(parseInt(basewithextra / ChooseStaff.length));
  }, [ChooseStaff]);


  function changeCounter2(value, id) {

    if (value === "increment") {
      const updatedArray = subService.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) + 1,
          };
        }

        return item;
      });
      setSubService(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = subService.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) - 1,
          };
        }

        return item;
      });
      setSubService(updatedArray);
    }
  }
  function changeAddon(value, id) {
    if (value === "increment") {
      const updatedArray = addons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) + 1,
          };
        }

        return item;
      });

      setAddons(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = addons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) - 1,
          };
        }

        return item;
      });
      setAddons(updatedArray);
    }
  }

  const [edittotalAmount, setEditTotalAmount] = useState(0);
  const [edittotalDuration, setEditTotalDuration] = useState(0);
  const [editbaseTime, setEditBaseTime] = useState(0);
  const [updatedendtime, setUpdatedendtime] = useState();
  const [expedtedendtime, setExpedtedendtime] = useState();


  useEffect(() => {
    getEditTimeData();
  }, [edittotalDuration]);

  const getEditTimeData = () => {
    // const start_time = edittotalDuration;
    // // var hours = Math.floor(start_time / 60);
    // // var minutes = start_time % 60;

    // // if (hours < 10) {
    // //   hours = "0" + hours;
    // // }
    // // else {
    // //   hours = hours;
    // // }
    // // if (minutes < 10) {
    // //   minutes = "0" + minutes;
    // // }
    // // else {
    // //   minutes = minutes;
    // // }

    // // var time = hours + ":" + minutes;
    setEditBaseTime(edittotalDuration);


  }


  useEffect(() => {
    // if (editstart) {

    const hours = parseInt(editbooking.hours) * 60;
    const mints = parseInt(editbooking.mints);
    const totalDuration = hours + mints;
    if (editbooking.estimated_price == null || editbooking.estimated_price == "") {
      setEditbookingestimatedPrice(editbooking.billed_amount);

      var Servicepercentage = parseInt(editbooking.service_percentage);
      const percentage = (editbooking.billed_amount * Servicepercentage) / 100;
      const totalAmountvar1 = totalDuration + percentage;
      setEditTotalAmount(totalAmountvar1);

    } else {
      const priceRange = editbooking.estimated_price;
      // const [minPriceStr, maxPriceStr] = priceRange.split(' to ');

      const [minPriceStr, maxPriceStr] = priceRange.includes(' to ') ? priceRange.split(' to ') : priceRange.split(' - ');

      // Remove the dollar sign and convert the strings to numbers
      const minPrice = parseFloat(minPriceStr.replace('$', ''));
      const maxPrice = parseFloat(maxPriceStr.replace('$', ''));

      setEditTotalAmount(minPrice)
      setEditbookingestimatedPrice(maxPrice);
    }

    if (editbooking.estimated_duration == null || editbooking.estimated_duration == "") {
      setEditbookingestimatedDuration(editbooking.order_duration);

      var Servicepercentage = parseInt(editbooking.service_percentage);
      const percentage = (totalDuration * Servicepercentage) / 100;
      const totalAmountvar1 = totalDuration + percentage;
      setEditBaseTime(totalAmountvar1);

    } else {
      const estimated_duration = editbooking.estimated_duration;


      // const [startTime, endTime] = estimated_duration.split(' to ');
      const [startTime, endTime] = estimated_duration.includes(' to ') ? estimated_duration.split(' to ') : estimated_duration.split(' - ');

      // Convert start and end times to minutes
      const [startHours, startMinutes] = startTime.split(':');
      const [endHours, endMinutes] = endTime.split(':');
      const startInMinutes = parseInt(startHours) * 60 + parseInt(startMinutes);
      const endInMinutes = parseInt(endHours) * 60 + parseInt(endMinutes);

      setEditbookingestimatedDuration(endInMinutes);

      setEditBaseTime(startInMinutes);

    }


    // setEditTotalDuration(editbaseTime);


    // }
  }, [editbooking.hours, editbooking.mints, editbooking.billed_amount, editbooking.estimated_duration]);


  useEffect(() => {
    const staffids = editbooking.staff_ids;

    if (Array.isArray(staffids)) {
      const staff_length = staffids.length > 0 ? staffids.length : 1;
      setUpdatestaff(staffids);
      setExpedtedendtime(parseInt(editbookingestimatedDuration / staff_length));
    } else {
      console.error("editbooking.staff_ids is not an array or is undefined.");
    }
  }, [editbooking.staff_ids, editbookingestimatedDuration]);


  useEffect(() => {
    if (editstart) {
      calculateEditPriceforbooking();
    }
  }, [EditSubServices, editaddons]);

  useEffect(() => {
    // const today = "2024-01-29";
    // const today = new Date("2024-01-29");
    // const date = new Date(bookingeditOrderdata);
    // if (date <= today) {
    //   setExpedtedendtime(parseInt(editbookingestimatedDuration));
    // } else {
    // const staff_length = assignStaff.length > 0 ? assignStaff.length : 1;
    // setExpedtedendtime(parseInt(editbookingestimatedDuration / staff_length));

    const staff_length = assignStaff.filter(staff => staff.role === "staff").length;

    if (staff_length > 0) {
      setExpedtedendtime(parseInt(editbookingestimatedDuration / staff_length));
    }


    // }
  }, [assignStaff, editbookingestimatedDuration]);

  // 
  function changeeditaddons(value, id) {

    if (value === "increment") {
      const updatedArray = editaddons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            addons_service_qty: parseInt(item.addons_service_qty) + 1,
          };
        }

        return item;
      });

      setEditAddons(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = editaddons.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            addons_service_qty: parseInt(item.addons_service_qty) - 1,
          };
        }

        return item;
      });
      setEditAddons(updatedArray);
    }
  }

  function changeCounter3(value, id) {
    if (value === "increment") {
      const updatedArray = EditSubServices.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) + 1,
          };
        }

        return item;
      });
      setEditSubServices(updatedArray);
    } else if (value === "decrement") {
      const updatedArray = EditSubServices.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) - 1,
          };
        }

        return item;
      });
      setEditSubServices(updatedArray);
    }
  }


  const calculateEditPriceforbooking = () => {
    var totalAmountvar = 0;
    var totalDurationvar = 0;
    EditSubServices.map((item) => {
      const basePrice = parseInt(item.base_price);
      const baseCount = 1;
      const baseDuration = parseInt(item.base_duration);
      const extraCount = parseInt(item.quantity) - baseCount;
      const extraPrice = parseInt(item.base_price_extra_unit);
      const extraDuration = parseInt(item.extra_duration_per_unit_in_minute);
      const baseAmount = basePrice * baseCount;
      const extraAmount = extraPrice * extraCount;
      totalAmountvar = totalAmountvar + baseAmount + extraAmount;
      totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
    });
    editaddons.map((item) => {
      if (item.addons_service_qty == 0 || item.addons_service_qty == null) {

      }
      else {

        const basePrice = parseInt(item.base_price);
        const baseCount = 1;
        const baseDuration = parseInt(item.base_duration);;
        const extraCount = parseInt(item.addons_service_qty) - baseCount;
        const extraPrice = parseInt(item.price_for_per_extra_unit);
        const extraDuration = parseInt(item.extra_minute_per_unit);
        const baseAmount = basePrice * baseCount;
        const extraAmount = extraPrice * extraCount;
        totalAmountvar = totalAmountvar + baseAmount + extraAmount;
        totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;

      }
    });
    setEditTotalAmount(totalAmountvar);
    const staff_length = assignStaff.length > 0 ? assignStaff.length : 1;
    // setEditTotalDuration(parseInt(totalDurationvar / staff_length));

    setEditTotalDuration(parseInt(totalDurationvar));


    //get percentage from totalAmount
    var Servicepercentage = parseInt(editbooking.service_percentage);
    const percentage = (totalAmountvar * Servicepercentage) / 100;
    const totalAmountvar1 = totalAmountvar + percentage;
    setEditbookingestimatedPrice(totalAmountvar1);

    if (editbooking.booking_discount > 0) {
      const discountvalue = totalAmountvar1 - editbooking.booking_discount;
      setEditbookingChangesAmount(discountvalue)
    } else {
      setEditbookingChangesAmount(totalAmountvar1)
    }


    //get percentage from totalDurationvar
    const extrarange = (totalDurationvar * Servicepercentage) / 100;
    const totalDurationvarwithextra = totalDurationvar + extrarange;
    // setEditbookingestimatedDuration(parseInt(totalDurationvarwithextra / staff_length));
    setEditbookingestimatedDuration(parseInt(totalDurationvarwithextra));
    setExpedtedendtime(parseInt(totalDurationvarwithextra / staff_length))
  }

  const UpdateBookigData = () => {

    if (EditbookingStartTime < "06:00" || EditbookingStartTime > "21:00") {
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    formData.append("client_id", bookingDetails.client_id);
    formData.append("start_date", EditbookingStartDate);
    formData.append("start_d_time", EditbookingStartTime);
    formData.append("service_amount", Math.round(editbookingestimatedPrice));
    formData.append("duration", editbookingestimatedDuration);
    formData.append("appointment_note_by_staff", editbooking.appointment_note);
    formData.append("appointment_zip", editbooking.appointment_zip);
    formData.append("appointment_address", editbooking.client_address);
    formData.append("appointment_city", editbooking.appointment_city);
    formData.append("appointment_state", editbookingstate);
    formData.append("client_name", editbooking.client_name);

    formData.append("booking_discount", editbookingdiscount);
    formData.append("lat", bookingLat);
    formData.append("long", bookingLong);


    formData.append("estimated_price", "$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice));
    formData.append("estimated_duration", convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration));



    const subServices = [];
    for (let i = 0; i < EditSubServices.length; i++) {
      // subServices.push(EditSubServices[i].quantity);
      subServices.push({ id: EditSubServices[i].booking_id, quantity: EditSubServices[i].quantity })
    }
    formData.append("method_unit_selected_values", JSON.stringify(subServices));

    const staffIds = [];

    if (assignStaff != null && assignStaff != "" && assignStaff != undefined && assignStaff.length > 0) {

      for (let i = 0; i < assignStaff.length; i++) {
        staffIds.push(assignStaff[i].value);
      }
      formData.append("staff_ids", staffIds);

    }
    else {
      // const staffIds = [];

      // updatestaff.map((item) => {
      //   staffIds.push(item);
      // });

      formData.append("staff_ids", 79);
    }




    const addons = [];
    for (let i = 0; i < editaddons.length; i++) {
      addons.push({ id: editaddons[i].id, quantity: editaddons[i].addons_service_qty, type: editaddons[i].new });
    }
    formData.append("addons_service_qty", JSON.stringify(addons));

    updateBookingData(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          // alert("Booking updated successfully");
          handleClose3();
          handleClose2();
          // window.location.reload();
          setEventcount(eventcount + 1);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      //console.log(error);
    }).finally(() => {
      setStartDate(EditbookingStartDate);
    });
  }

  const updaterecurringbooking = () => {

    if (EditbookingStartTime < "07:00" || EditbookingStartTime > "21:00") {
      NotificationManager.error("⚠ Please select time between 07:00 AM to 08:00 PM");
      return;
    }

    const formData = new FormData();
    formData.append("recurring_id", bookingDetails.recurring_id);
    formData.append("client_id", bookingDetails.client_id);
    formData.append("start_date", EditbookingStartDate);
    formData.append("start_d_time", EditbookingStartTime);
    formData.append("service_amount", Math.round(editbookingestimatedPrice));
    formData.append("duration", editbookingestimatedDuration);
    formData.append("appointment_note_by_staff", editbooking.appointment_note);
    formData.append("appointment_zip", editbooking.appointment_zip);
    formData.append("appointment_address", editbooking.client_address);
    formData.append("appointment_city", editbooking.appointment_city);
    formData.append("appointment_state", editbookingstate);
    formData.append("client_name", editbooking.client_name);


    formData.append("estimated_price", "$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice));
    formData.append("estimated_duration", convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration));



    const subServices = [];
    for (let i = 0; i < EditSubServices.length; i++) {
      // subServices.push(EditSubServices[i].quantity);
      subServices.push({ id: EditSubServices[i].booking_id, method_id: EditSubServices[i].id, quantity: EditSubServices[i].quantity })
    }
    formData.append("method_unit_selected_values", JSON.stringify(subServices));

    const staffIds = [];

    if (assignStaff != null && assignStaff != "" && assignStaff != undefined && assignStaff.length > 0) {

      for (let i = 0; i < assignStaff.length; i++) {
        staffIds.push(assignStaff[i].value);
      }
      formData.append("staff_ids", staffIds);

    }
    else {
      // const staffIds = [];

      // updatestaff.map((item) => {
      //   staffIds.push(item);
      // });

      formData.append("staff_ids", 79);
    }




    const addons = [];
    for (let i = 0; i < editaddons.length; i++) {
      addons.push({ id: editaddons[i].addon_id, quantity: editaddons[i].addons_service_qty, type: editaddons[i].new });
    }
    formData.append("addons_service_qty", JSON.stringify(addons));

    updaterecurringbookingAPi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          // alert("Booking updated successfully");
          handleClose3();
          // handleClose3();
          handleClose2();
          setEventcount(eventcount + 1);
          // window.location.reload();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      //console.log(error);
    });

  }

  const calculatePrice = () => {
    var totalAmountvar = 0;
    var totalDurationvar = 0;
    subService.map((item) => {
      const basePrice = parseInt(item.base_price);
      const baseCount = 1;
      const baseDuration = parseInt(item.base_duration);
      const extraCount = item.quantity - baseCount;
      const extraPrice = parseInt(item.base_price_extra_unit);
      const extraDuration = parseInt(item.extra_duration_per_unit_in_minute);
      const baseAmount = basePrice * baseCount;
      const extraAmount = extraPrice * extraCount;
      totalAmountvar = totalAmountvar + baseAmount + extraAmount;
      totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
    });
    addons.map((item) => {
      if (item.quantity == 0) {
      }
      else {
        const basePrice = parseInt(item.base_price);
        const baseCount = 1;
        const baseDuration = parseInt(item.base_duration);;
        const extraCount = item.quantity - baseCount;
        const extraPrice = parseInt(item.price_for_per_extra_unit);
        const extraDuration = parseInt(item.extra_minute_per_unit);
        const baseAmount = basePrice * baseCount;
        const extraAmount = extraPrice * extraCount;
        totalAmountvar = totalAmountvar + baseAmount + extraAmount;
        totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
      }

    });

    setTotalAmount(totalAmountvar);
    setTotalDuration(parseInt(totalDurationvar));
    // setTotalDuration(parseInt(totalDurationvar / ChooseStaff.length));

    //get percentage from totalAmount 
    const percentage = (totalAmountvar * servicepercentage) / 100;
    const totalAmountvar1 = totalAmountvar + percentage;
    setExpectedTotalAmount(totalAmountvar1);


    if (discount > 0 && discount > "0") {
      if (ChooseService !== "1") {
        const discountvalue = totalAmountvar1 - discount;
        setDiscountuser(discountvalue);
      } else {
        const discountvalue = userbilledamount - discount;
        setDiscountuser(discountvalue);
      }

      // const discountvalue = totalAmountvar1 - discount;
      // setDiscountuser(discountvalue);
    } else {
      if (ChooseService === "1") {
        setDiscountuser(userbilledamount);
      } else {
        setDiscountuser(totalAmountvar1);
      }
    }

    //get percentage from totalDurationvar 
    const extrarange = (totalDurationvar * servicepercentage) / 100;
    const totalDurationvarwithextra = totalDurationvar + extrarange;

    setBasewithextra(parseInt(totalDurationvarwithextra));
    setUpdatedendtime(parseInt(totalDurationvarwithextra));

  }

  const confirmbooking = () => {
    //confirm alert
    if (window.confirm("Are you sure you want to confirm this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      confirmbookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking confirmed successfully");
            handleClose2();
            setEventcount(eventcount + 1);
            // getbookingsfunc();
            // window.location.reload();
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const activequotebooking = () => {
    //confirm alert
    if (window.confirm("Are you sure you want to active this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      addbookingbookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking confirmed successfully");
            handleClose2();
            setEventcount(eventcount + 1);
            // getbookingsfunc();
            // window.location.reload();
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const rejectbooking = () => {
    if (window.confirm("Are you sure you want to reject this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      rejectbookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking remove successfully");
            // handleClose4();
            setEventcount(eventcount + 1);
            // window.location.reload();
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const [addClasss, setAddClasss] = useState(false);

  const [deleteloader, setDeleteloader] = useState(false);

  const deletebooking = () => {
    // if (window.confirm("Are you sure you want to delete this booking?")) {
    setDeleteloader(true);
    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    deletebookingApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          // alert("Booking delete successfully");
          NotificationManager.success("Booking delete successfully");
          handleClose2();
          setEventcount(eventcount + 1);
          // window.location.reload();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setDeleteloader(false);
      setAddClasss(false);
    })
    // }
  }

  const deletereccuringbooking = () => {
    if (window.confirm("Are you sure you want to delete this booking?")) {
      const formData = new FormData();
      formData.append("recurring_id", bookingDetails.recurring_id);
      formData.append("booking_date", bookingDetails.appointment_starttime_test);

      reccuringdeletebooking(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            NotificationManager.success("Booking delete successfully");
            // handleClose4();
            window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const [rescheduleDetails, setRescheduleDetails] = useState([]);
  const [selectstaffs, setSelectstaffs] = useState([]);
  const [rescheduleDate, setRescheduleDate] = useState(rescheduleDetails.order_date);
  const [reschedunotes, setReschedulenotes] = useState(rescheduleDetails.order_time);
  const [reschedulestaff, setReschedulestaff] = useState(rescheduleDetails.staff_ids);

  useEffect(() => {
  }, [selectstaffs]);

  useEffect(() => {
    setRescheduleDate(rescheduleDetails.order_date);
    setReschedulenotes(rescheduleDetails.app_notes);
  }, [rescheduleDetails]);


  useEffect(() => {
    // setSelectstaffs(rescheduleDetails.staff_ids);
    //set select staff ids in array
    const staff_ids = rescheduleDetails.staff_ids;
    if (staff_ids > 0) {
      const staff_ids_array = staff_ids.split(",");
      const staff_ids_index = [];
      staff_ids_array.forEach((staff_id) => {
        const index = staffList.findIndex((staff) => staff.value === staff_id);
        staff_ids_index.push(index);
      }
      );
      const selectedStaff = [];
      staff_ids_index.forEach((index) => {
        selectedStaff.push(staffList[index]);
      }
      );
      setSelectstaffs(selectedStaff);
    }

  }, [rescheduleDetails]);

  const rescheduleData = () => {
    handleShow5();

    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);

    getrescheduledata(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setRescheduleDetails(response.data.data);
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const savereschedule = () => {
    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    formData.append("order_date", rescheduleDate);
    formData.append("app_notes", reschedunotes);
    const staffIds = [];
    for (let i = 0; i < reschedulestaff.length; i++) {
      staffIds.push(reschedulestaff[i].value);
    }
    formData.append("staff_ids", staffIds);

    saverescheduledata(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          alert("Booking reschedule successfully");
          handleClose5();
          window.location.reload();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const [ServiceId, setServiceId] = useState([]);
  const [zoneId, setZoneId] = useState([]);
  const date = new Date();
  const [startDate, setStartDate] = useState(moment(dateData).format("YYYY-MM-DD"));
  // const [startDate, setStartDate] = useState('');
  const [appOption, setAppOption] = useState([]);
  const [changestaffzone, setchangesstaffzone] = useState('');
  const [staffListtttt, setStaffListtttt] = useState(null);

  // useEffect(() => {
  //   setStartDate(moment(dateData).format("YYYY-MM-DD"));
  // }, [dateData]);



  // useEffect(() => {
  //   getbookingsfunc(startDate);
  // }, [startDate]);

  const getbookingsfunc = () => {
    const type = "day";
    // getbookings(startDate, type).then((response) => {
    //   if (response.code === 200) {
    //     if (response.data.status === "1") {
    //       setBooking(response.data.data);
    //       setDateData(startDate);
    //       updateCalendarData(response.data.data);
    //     } else {
    //       setBooking([]);
    //       setDateData(startDate);
    //       updateCalendarData([]);
    //     }
    //   } else {
    //     setBooking([]);
    //     setDateData(startDate);
    //     updateCalendarData([]);
    //   }
    // }).catch((error) => {
    //   console.log(error);
    // });
  }

  useEffect(() => {
    getStaffListtt();
  }, [changestaffzone]);

  const getStaffListtt = () => {
    getStaff(changestaffzone).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        if (res.data.status === "1") {
          setStaffListtttt(apiResponse.data);
        }
        else {
          alert("data not found");
          setStaffListtttt([]);
        }

      }
    })
      .catch((err) => {
        console.log(err);
      });
  };



  const [Frequency, setFrequency] = useState([]);

  const [CustomWeekmonth, setCustomWeekmonth] = useState([]);
  const [CustomWeeksmonths, setCustomWeeksmonths] = useState([]);


  useEffect(() => {
    if (CustomWeeksmonths === "weeks") {
      document.getElementById("my_time_week1").style.display = "block";
      document.getElementById("my_time_week2").style.display = "none";
    }
    if (CustomWeeksmonths === "months") {
      document.getElementById("my_time_week2").style.display = "block";
      document.getElementById("my_time_week1").style.display = "none";
    }

  }, [CustomWeeksmonths]);

  useEffect(() => {
    if (Frequency === "weekly") {
      document.getElementById("weekly").style.display = "block";
      document.getElementById("monthly").style.display = "none";
      document.getElementById("custom").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }
    if (Frequency === "monthly") {
      document.getElementById("monthly").style.display = "block";
      document.getElementById("weekly").style.display = "none";
      document.getElementById("custom").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }
    if (Frequency === "0") {
      document.getElementById("monthly").style.display = "none";
      document.getElementById("weekly").style.display = "none";
      document.getElementById("custom").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }
    if (Frequency === "custom") {
      document.getElementById("custom").style.display = "block";
      document.getElementById("monthly").style.display = "none";
      document.getElementById("weekly").style.display = "none";
      document.getElementById("hide-sec").style.display = "none";
      setSummary([]);
      setCustomWeekmonth([]);
    }

  }, [Frequency]);

  const [summary, setSummary] = useState([]);
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [bookingEndDate, setBookingEndDate] = useState(null);
  const [recurringbookingdate, setRecurringbookingdate] = useState(null);


  const [selectedDays, setSelectedDays] = useState([]);


  const showDate = (e) => {
    const value = e.target.getAttribute('data-value');


    if (e.target.style.backgroundColor === "rgb(0, 123, 255)") {
      e.target.style.backgroundColor = "white";
      e.target.style.color = "black";
    } else {
      e.target.style.backgroundColor = "#007bff";
      e.target.style.color = "white";
    }

    if (CustomWeekmonth.includes(value)) {
      const index = CustomWeekmonth.indexOf(value);
      if (index > -1) {
        CustomWeekmonth.splice(index, 1);
      }
    } else {
      CustomWeekmonth.push(value);
    }

    const summaryData = [];
    for (let i = 0; i < CustomWeekmonth.length; i++) {
      if (CustomWeekmonth[i] === "1") {
        summaryData.push("Monday");
      }

      if (CustomWeekmonth[i] === "2") {
        summaryData.push("Tuesday");
      }

      if (CustomWeekmonth[i] === "3") {
        summaryData.push("Wednesday");
      }

      if (CustomWeekmonth[i] === "4") {
        summaryData.push("Thursday");
      }

      if (CustomWeekmonth[i] === "5") {
        summaryData.push("Friday");
      }

      if (CustomWeekmonth[i] === "6") {
        summaryData.push("Saturday");
      }
      if (CustomWeekmonth[i] === "0") {
        summaryData.push("Sunday");
      }
    }
    setSummary(summaryData);

    const date = new Date(dateData);
    const year = date.getFullYear();
    const month = date.getMonth();
    const today = date.getDate(dateData);

    const days = [];
    for (let i = 0; i < 183; i++) {

      const day = date.getDay();
      const newDate = new Date(year, month, today + i);


      //check newDate is today or not if today then add time recuringtime
      //newDate is Fri Aug 18 2023 00:00:00 GMT+0530 please first remove time from newDate and remove from date also and then compare
      const newDate1 = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
      const date1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());

      if (newDate1.getTime() === date1.getTime()) {
        const time = recurringtime.split(" ");
        const time1 = time[0].split(":");
        const hours = time1[0];
        const minutes = time1[1];
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        // console.log("newDate:", newDate);

      }

      const newday = newDate.getDay();

      if (newDate >= date) {
        if ((CustomWeekmonth.toString()).includes(newday.toString())) {
          days.push(newDate);
        }
      }
    }
    const daysData = [];
    for (let i = 0; i < days.length; i++) {
      const date = days[i];
      const day = date.getDate();
      const month = date.getMonth() + 1; // Add 1 since getMonth() returns zero-based index
      const year = date.getFullYear();
      const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      daysData.push(formattedDate);
    }

    //check days is empty or not
    if (daysData.length === 0) {
      setBookingStartDate(null);
      setBookingEndDate(null);
    } else {
      //date fomrat like 2021-08-01
      const bookingStartDate1 = daysData[0];
      const bookingEndDate1 = daysData[daysData.length - 1];
      setBookingStartDate(bookingStartDate1);
      setBookingEndDate(bookingEndDate1);
    }

    setRecurringbookingdate(daysData);


    document.getElementById("hide-sec").style.display = "block";
  };

  const saverecurringData = () => {

    if (recurringbookingdate === null) {
      alert("Please select date");
      return false;
    }
    if (recurringtime === null) {
      alert("Please select time");
      return false;
    }

    const data = {
      recurring_booking_date: recurringbookingdate,
      recurring_time: recurringtime
    }
    alert("Your booking start from" + " " + bookingStartDate + " " + "to" + " " + bookingEndDate + " " + "on" + " " + summary + " " + "at" + " " + recurringtime);


    //close modal
    // closeModal();

  }

  const showMonth = (e) => {
    if (e.target.style.backgroundColor === "rgb(0, 123, 255)") {
      e.target.style.backgroundColor = "white";
      e.target.style.color = "black";
    } else {
      e.target.style.backgroundColor = "#007bff";
      e.target.style.color = "white";
    }

    const value = e.target.getAttribute('data-days');
    const week_count = parseInt(e.target.getAttribute('week-count'));
    const day = parseInt(e.target.getAttribute('data-days'));


    const day_data = {};
    day_data['week_count'] = week_count;
    day_data['week_day'] = day + 1;
    day_data['selected_start_date'] = "0000-00-00";
    day_data['selected_end_date'] = "0000-00-00";
    day_data['total_month'] = 12;

    const index = selectedDays.findIndex((item) => item.week_day === day + 1);

    if (index > -1) {
      const newSelectedDays = selectedDays.filter((item) => item.week_day !== day + 1);
      setSelectedDays(newSelectedDays);
    }
    else {
      setSelectedDays([...selectedDays, day_data]);
    }


    if (CustomWeekmonth.includes(value)) {
      const index = CustomWeekmonth.indexOf(value);
      if (index > -1) {
        CustomWeekmonth.splice(index, 1);
      }
    } else {
      CustomWeekmonth.push(value);
    }
    const dayMappings = {
      0: "Monday",
      1: "Tuesday",
      2: "Wednesday",
      3: "Thursday",
      4: "Friday",
      5: "Saturday",
      6: "Sunday",
    };

    const summaryData = [];

    for (let i = 0; i < CustomWeekmonth.length; i++) {
      const weekDay = CustomWeekmonth[i];
      const weekIndex = Math.floor(weekDay / 7) + 1;
      const dayOfWeek = dayMappings[weekDay % 7];

      summaryData.push(`${weekIndex}st ${dayOfWeek}`);
    }

    setSummary(summaryData);




    document.getElementById("hide-sec").style.display = "block";
  }

  useEffect(() => {
    if (selectedDays.length > 0) {
      console.log("selectedDays:", selectedDays);
      let data = new FormData();
      data.append('selectedDays', JSON.stringify(selectedDays));
      getDates(data).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            const apiResponse = response.data.data;
            apiResponse.map((item, index) => {
              if (index === 0) {
                setBookingStartDate(item);
              }
              if (index === response.data.data.length - 1) {
                setBookingEndDate(item);
              }
            });

            setRecurringbookingdate(response.data.data);
          } else {
            console.log("response: error");
          }
        } else {
          console.log("response: error");
        }
      }).catch((error) => {
        console.log("response: error");
      });
    }
  }, [selectedDays]);

  const repatecountweek = useRef(null);
  const repatecountmonth = useRef(null);
  const [customedateobj, setCustomedateobj] = useState([]);
  const [bookingzonelist, setBookingzonelist] = useState([]);

  useEffect(() => {
    if (customedateobj.length > 0) {
      let data = new FormData();
      data.append('selectedDays', JSON.stringify(customedateobj));
      getDates(data).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            const apiResponse = response.data.data

            apiResponse.map((item, index) => {
              if (index === 0) {
                setBookingStartDate(item);
              }
              if (index === response.data.data.length - 1) {
                setBookingEndDate(item);
              }
            });

            setRecurringbookingdate(response.data.data);

          } else {
            console.log("response: error");
          }
        } else {
          console.log("response: error");
        }
      }).catch((error) => {
        console.log("response: error");
      });
    }

  }, [customedateobj]);


  const updateRecurringBookingDates = (startDate) => {
    // const startDateMoment = new Date(startDate);
    // const originalStartDate = new Date(recurringbookingdate[0]);
    // const dayDifference = (startDateMoment - originalStartDate) / (1000 * 3600 * 24);

    // // Update each date in the array based on the day difference
    // const updatedDates = recurringbookingdate.map(date => {
    //   const dateMoment = new Date(date);
    //   const newDate = new Date(dateMoment.setDate(dateMoment.getDate() + dayDifference));
    //   return newDate.toISOString().split('T')[0]; // Converts date to 'YYYY-MM-DD' format
    // });

    // // Update state with new dates
    // setRecurringbookingdate(updatedDates);

    // const startDateMoment = new Date(startDate).toISOString().split('T')[0];

    // // Find the index of the new start date in the array
    // const startIndex = recurringbookingdate.indexOf(startDateMoment);

    // let updatedDates;
    // if (startIndex !== -1) {
    //   // If the new start date exists, create a new array starting from that date
    //   updatedDates = recurringbookingdate.slice(startIndex);
    // } else {
    //   // If the new start date does not exist, update only the first date in the array
    //   updatedDates = recurringbookingdate.map((date, index) => {
    //     if (index === 0) {
    //       // Return the new start date in 'YYYY-MM-DD' format
    //       return startDateMoment;
    //     }
    //     // Return the original date for all other elements
    //     return date;
    //   });
    // }

    // setRecurringbookingdate(updatedDates);

    if (Array.isArray(customedateobj) && customedateobj.length > 0) {
      let data = new FormData();
      let day = startDate.split('-')[2];
      let month = startDate.split('-')[1];
      let year = startDate.split('-')[0];
      customedateobj[0].start_date = day;
      customedateobj[0].start_month = month;
      customedateobj[0].start_year = year;
      data.append('selectedDays', JSON.stringify(customedateobj));
      getDates(data).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            const apiResponse = response.data.data

            apiResponse.map((item, index) => {
              if (index === 0) {
                setBookingStartDate(item);
              }
              if (index === response.data.data.length - 1) {
                setBookingEndDate(item);
              }
            });

            setRecurringbookingdate(response.data.data);

          } else {
            console.log("response: error");
          }
        } else {
          console.log("response: error");
        }
      }).catch((error) => {
        console.log("response: error");
      });
    } else {
      const startDateMoment = new Date(startDate).toISOString().split('T')[0];

      const startIndex = recurringbookingdate.indexOf(startDateMoment);

      let updatedDates;
      if (startIndex !== -1) {
        updatedDates = recurringbookingdate.slice(startIndex);
      } else {
        updatedDates = recurringbookingdate.map((date, index) => {
          if (index === 0) {
            return startDateMoment;
          }
          return date;
        });
      }
    }
  }

  function getDateForDay(dayName) {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayAbbreviations = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']; // Abbreviations for each day

    const today = new Date();
    const currentDay = today.getDay();
    const targetDay = daysOfWeek.indexOf(dayName.toLowerCase());

    if (targetDay === -1) {
      return 'Invalid day name';
    }

    const daysToAdd = (targetDay - currentDay + 7) % 7;
    today.setDate(today.getDate() + daysToAdd);

    const formattedDate = `${dayAbbreviations[targetDay]}`; // Use the abbreviation
    return formattedDate;
  }

  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  function getDateFormatedateData(dayName, dateData) {
    // const today = new Date(dateData); // Use the provided dateData
    // const currentDay = today.getDay();
    // const targetDay = daysOfWeek.indexOf(dayName.toLowerCase());

    // if (targetDay === -1) {
    //   return 'Invalid day name';
    // }

    // let daysToAdd = (targetDay - currentDay + 7) % 7;
    // if (daysToAdd === 0) {
    //   daysToAdd = 7; // If it's the same day, move to the next week
    // }
    // today.setDate(today.getDate() + daysToAdd);
    // // today.setDate(today.getDate());

    // const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    // return formattedDate;
    const inputDate = new Date(dateData);
    const targetDay = daysOfWeek.indexOf(dayName.toLowerCase());

    if (targetDay === -1) {
      return 'Invalid day name';
    }

    const currentDay = inputDate.getDay();
    let daysToAdd = (targetDay - currentDay + 7) % 7;

    // Check if inputDate and today's date are the same
    const today = new Date();
    const isSameDate = inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear();

    if (isSameDate) {
      if (daysToAdd === 0) {
        daysToAdd = 7; // If it's the same day and dayName matches, move to the next week
      }
    } else {
      if (daysToAdd === 0) {
        daysToAdd = 0; // If it's not the same date and dayName matches, keep the same day
      }
    }

    inputDate.setDate(inputDate.getDate() + daysToAdd);

    const formattedDate = `${inputDate.getDate()}/${inputDate.getMonth() + 1}/${inputDate.getFullYear()}`;
    return formattedDate;
  }

  const showCustome = (e) => {
    if (CustomWeeksmonths.length === 0) {
      NotificationManager.error("Please select mode type");
      return false;
    }

    document.getElementById("hide-sec").style.display = "block";
    if (e.target.style.backgroundColor === "rgb(0, 123, 255)") {
      e.target.style.backgroundColor = "white";

      e.target.style.color = "black";
    } else {
      e.target.style.backgroundColor = "#007bff";
      e.target.style.color = "white";

    }

    const week_day = e.target.getAttribute('data-value');
    const day = e.target.getAttribute('week-name');
    const total_month = "12";

    const selected_start_date = "0000-00-00";
    const selected_end_date = "0000-00-00";




    const dateForDay = getDateFormatedateData(day, dateData);

    //my dateForDay is like 11/12/2023 and i want to get start_date is 11 and start_month is 12 and start_year is 2023
    var start_date = dateForDay.split("/")[0];
    const start_month = dateForDay.split("/")[1];
    const start_year = dateForDay.split("/")[2];

    //check start_date is less than 10 then add 0
    if (start_date.length === 1) {
      start_date = `0${start_date}`;
    }

    const data = {};
    data['week_day'] = week_day;
    data['start_date'] = start_date;
    data['week_interval'] = repatecountweek.current.value;
    data['month_interval'] = repatecountmonth.current.value;
    data['start_month'] = start_month;
    data['start_year'] = start_year;
    data['total_month'] = total_month;
    data['day'] = getDateForDay(day);
    data['mode_type'] = CustomWeeksmonths;
    data['selected_start_date'] = selected_start_date;
    data['selected_end_date'] = selected_end_date;

    const index = customedateobj.findIndex((item) => item.week_day === week_day);
    if (index === -1) {
      setCustomedateobj([...customedateobj, data]);
    } else {
      const newCustomedateobj = [...customedateobj];
      newCustomedateobj.splice(index, 1);
      setCustomedateobj(newCustomedateobj);
    }

    if (CustomWeekmonth.includes(week_day)) {
      const index = CustomWeekmonth.indexOf(week_day);
      if (index > -1) {
        CustomWeekmonth.splice(index, 1);
      }
    } else {
      CustomWeekmonth.push(week_day);
    }

    const dayMappings = {
      0: "Monday",
      1: "Tuesday",
      2: "Wednesday",
      3: "Thursday",
      4: "Friday",
      5: "Saturday",
      6: "Sunday",
    };

    const summaryData = [];

    for (let i = 0; i < CustomWeekmonth.length; i++) {
      const weekDay = CustomWeekmonth[i];
      const weekIndex = Math.floor(weekDay / 7) + 1;
      const dayOfWeek = dayMappings[weekDay % 7];


      summaryData.push(`${dayOfWeek}`);
    }


    setSummary(summaryData);

  }

  useEffect(() => {
    getallzone();
  }, []);

  const getallzone = () => {
    getZone().then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setBookingzonelist(response.data.data);
        } else {
          console.log("response: error");
        }
      } else {
        console.log("response: error");
      }
    }).catch((error) => {
      console.log("response: error");
    });
  }




  const [cardNumber, setCardNumber] = useState('');
  const [cardExpDate, setCardExpDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [fullName, setFullName] = useState('');
  const [addCardValidate, setAddCardValidate] = useState(false)
  const { getExpiryDateProps } = usePaymentInputs();


  const [cardNumbererror, setCardNumbererror] = useState(false);
  const [cardCVVerror, setCardCVVerror] = useState(false);
  const [cardExpDateerror, setCardExpDateerror] = useState(false);
  const [fullNamerror, setFullNamerror] = useState(false);
  const [cardExpYearerror, setCardExpYearerror] = useState(false);

  const handleAddCard = () => {

    if (cardNumber == '' || cardNumber.length != 19) {
      setCardNumbererror(true);
    } else {
      setCardNumbererror(false);
    }

    if (cardCVV == '' || cardCVV.length != 3) {
      setCardCVVerror(true);
    } else {
      setCardCVVerror(false);
    }

    if (cardExpDate == '') {
      setCardExpDateerror(true);
    }

    if (fullName == '') {
      setFullNamerror(true);
    } else {
      setFullNamerror(false);
    }

    const expDateParts = cardExpDate.split('/');

    const expMonth = parseInt(expDateParts[0]);
    const expYear = parseInt(expDateParts[1]);

    const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed, so add 1
    const currentYear = new Date().getFullYear() % 100; // Get the last two digits of the current year

    if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
      // alert("Card expiration date should be in the future.");
      setCardExpYearerror(true);
      return;
    } else {
      setCardExpYearerror(false);
    }

    const name = fullName.split(' ');
    if (name.length < 2) {
      setFullNamerror(true);
      return;
    } else {
      setFullNamerror(false);
    }



    const formData = new FormData();
    formData.append('customer_id', bookingDetails.customer_id);
    formData.append("card_number", cardNumber);
    formData.append("person_name", fullName);
    formData.append("card_cvv", cardCVV);
    formData.append("card_exp", cardExpDate);

    createPaymentMethod(formData).then((res) => {
      if (res.code === 200) {
        alert("credentials added successfully");
        handleClose6();
      }
      else {

        alert("Something went wrong");
      }
    }).catch((err) => {
      console.log("err", err);
    });

  }


  const handleInputChange = (e) => {
    //trim space
    const value = e.trim();
    setSearchkeyword(value);
  }
  const handleMenuScrollToBottom = () => {
    userdata("scroll",)
  }

  const [checkboxappointment, setCheckboxappointment] = useState(true);

  const setappointmentData = (e) => {
    if (e == true) {
      setAppointmentaddress(existingUser.address);
      setAppointmentcity(existingUser.city);
      //get state name from state by existingUser.state
      // const index = states.findIndex((item) => item.stateCode === existingUser.state);
      // if (index > -1) {
      //   setAppointmentstate(states[index].name);
      // }
      setAppointmentstate(existingUser.state);
      setAppointmentzip(existingUser.zip);
    } else {
      setAppointmentaddress("");
      setAppointmentcity("");
      setAppointmentstate("");
      setAppointmentzip("");
    }
  }

  const complatebooking = () => {
    if (window.confirm("Are you sure you want to complete this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      complatebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking completed successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const uncomplatebooking = () => {
    if (window.confirm("Are you sure you want to uncomplete this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      uncomplatebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking uncompleted successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }


  const unconfirmbooking = () => {
    if (window.confirm("Are you sure you want to unconfirm this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      unconfirmbookingstatus(formData).then((response) => {
        if (response.code === 200) {

          if (response.data.status === "1") {
            // alert("Booking unconfirmed successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  const activebooking = () => {
    if (window.confirm("Are you sure you want to active this booking?")) {
      const formData = new FormData();
      formData.append("order_id", bookingDetails.order_id);
      activebookingstatus(formData).then((response) => {
        if (response.code === 200) {
          if (response.data.status === "1") {
            // alert("Booking active successfully");
            // getbookingsfunc();
            handleClose2();
            // window.location.reload();
            setEventcount(eventcount + 1);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }).catch((error) => {
        console.log(error);
      });
    }

  }

  const createStaxId = () => {

    const formData = new FormData();
    formData.append("client_name", bookingDetails.client_name);
    formData.append("client_id", bookingDetails.client_id);
    formData.append("client_phone", bookingDetails.client_phone);
    formData.append("client_address", bookingDetails.client_address);
    formData.append("clientemail", bookingDetails.client_email);

    createStaxIdApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          NotificationManager.success("Stax Id created successfully");
          //get today booking
          window.location.reload();
          // getbookingsfunc();
          // handleClose2();
        } else {
          NotificationManager.error(response.data.message);
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
  }, [staffId]);

  const [invoiceprice, setInvoiceprice] = useState();
  const [invoicenotes, setInvoicenotes] = useState('');
  const [invoiceservice, setInvoiceservice] = useState([]);

  const [cleaningtime, setCleaningTime] = useState();
  const [bookingtimesheetcleaningtime, setBookingtimesheetcleaningtime] = useState();
  const [bookingtimenote, setBookingtimenote] = useState();


  const getStatus = (status) => {
    if (status === "0") {
      return "(Admin Booked)";
    }
    if (status === "1") {
      return "(Client Booked)";
    }
    if (status === "2") {
      return "(Technician Booked)";
    }
  }


  const getsubserviceaddons = () => {
    const formData = new FormData();
    formData.append("order_id", bookingDetails.order_id);
    getsubserviceaddonsApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setInvoiceservice(response.data.data);

          handleShow4();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });
  }


  const [invoiceList, setInvoiceList] = useState([]);

  //invoice loader state
  const [invoiceLoader, setInvoiceLoader] = useState(false);

  const getinvoice = () => {
    // setLoading(true);
    setInvoiceLoader(true);
    const customer_id = bookingDetails.order_id;
    getinvoiceApi(customer_id).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setInvoiceList(response.data.data);
        } else {
          setInvoiceList([]);
          NotificationManager.info("No invoice found");
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      // setLoading(false);
      setInvoiceLoader(false);
    });
  }

  useEffect(() => {
    getinvoice();
  }, [bookingDetails]);

  const [keywords, setKeywords] = useState('');

  const handleChange = (e) => {
    setKeywords(e.target.value);
  }


  const columns = [{
    dataField: 'id',
    text: 'Id',
    formatter: (cell, row, rowIndex, formatExtraData) => (
      <div>{rowIndex + 1}</div>
    ),
    sort: true
  }, {
    dataField: 'id',
    text: 'INVOICE ID',
    sort: true
  },
  {
    dataField: 'created_at',
    text: 'INVOICE DATE',
    sort: true,
    formatter: (cell, row) => {
      const date = row.created_at;
      const newDate = new Date(date);
      var day = newDate.getDate();
      day = day < 10 ? '0' + day : day;
      const month = newDate.toLocaleString('default', { month: 'short' });
      const year = newDate.getFullYear();
      var hours = newDate.getHours();
      hours = hours < 10 ? '0' + hours : hours;
      var minutes = newDate.getMinutes();
      minutes = minutes < 10 ? '0' + minutes : minutes;
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedDate = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
      return (
        <div>{formattedDate}</div>
      )
    }
  },
  {
    dataField: 'total',
    text: 'AMOUNT',
    sort: true
  },
  {
    text: 'STATUS',
    isDummy: true,
    formatter: (cell, row) => {
      const status = row.status;
      const amount = row.total;
      if (amount === 0) {
        return null; // Return null to render nothing
      }
      else if (status === "DRAFT" || status === "PENDING") {
        return (
          <Button className="Enable-Active-bttn invoice-list-btn" onClick={(id) => { getpaymentCards(row.id, row.total); getdefaultPaymentMethod(); }}>PAY Now</Button>
        )
      }
      else {
        return (
          status
        )
      }
    }
  },
  {
    text: 'ACTION',
    isDummy: true,
    formatter: (cell, row) => {
      const status = row.status;
      if (status === "PAID") {
        return (
          "PAID"
        )
      }
      else if (status === "ATTEMPTED") {
        return;
      }
      else {
        return (
          <div className="button-group">
            <Button className="Delete-Active-bttn me-2"><RiDeleteBin6Line /></Button>
          </div>
        )
      }
    }
  }];

  const [showModal7, setShowModal] = useState(false);

  const handleClose7 = () => setShowModal(false);
  const handleShow7 = () => setShowModal(true);


  const [selectedCard, setSelectedCard] = useState('');
  const [paymentMethod, setPaymentMethod] = useState([]);

  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [invoicetotal, setInvoicetotal] = useState('');

  const getpaymentCards = (id, total) => {
    setShowModal(true);
    setInvoiceId(id);
    setInvoicetotal(total);
    const formData = new FormData();
    formData.append("customer_id", bookingDetails.customer_id);

    getpaymentMethod(formData).then((res) => {
      if (res.code == 200) {
        if (res.data.status === "1") {
          const paymentMethod = res.data.data;
          setPaymentMethod(paymentMethod);
          if (paymentMethod && paymentMethod.length != 0 && !selectedCard) {
            if (bookingDetails.card_id != null) {
              const selectedPaymentMethod = paymentMethod.find((item) => item.id === bookingDetails.card_id);
              if (selectedPaymentMethod) {
                setPaymentMethodId(selectedPaymentMethod.id);
                setSelectedCard(`cardOption${selectedPaymentMethod.id}`);
              }
            }
            else {
              setSelectedCard(`cardOption${paymentMethod[0].id}`);
              setPaymentMethodId(paymentMethod[0].id);
            }
          }

        } else {
          alert("Please Add card first");
          handleClose7();
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const payinvoice = () => {
    const formData = new FormData();
    formData.append("payment_method_id", paymentMethodId);
    formData.append("apply_balance", invoicetotal);
    formData.append("invoiceId", invoiceId);

    payinvoiceApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          const status = response.data.data.status;
          if (status === "DRAFT" || status === "PENDING") {
            NotificationManager.error("Your invoice payment was unsuccessful. Try again");
          } else {

            if (response && response.data && response.data.data && response.data.data.status) {
              const messages = "YOUR INVOICE" + " " + response.data.data.status;
              // if (messages) {

              //   getinvoice();
              // } else {
              //   NotificationManager.success("Your invoice has been paid");
              //   getinvoice();
              // }
              NotificationManager.success(messages);
              getinvoice();
            } else {
              NotificationManager.success("Your invoice has been paid");
              getinvoice();
            }
          }

          handleClose7();

        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }

    }
    ).catch((error) => {
      console.log(error);
    }
    );
  }


  const states = [
    { name: "Alabama", cities: ["Birmingham", "Montgomery", "Mobile"], stateCode: "AL" },
    { name: "Alaska", cities: ["Anchorage", "Fairbanks", "Juneau"], stateCode: "AK" },
    { name: "Arizona", cities: ["Phoenix", "Tucson", "Mesa"], stateCode: "AZ" },
    { name: "Arkansas", cities: ["Little Rock", "Fort Smith", "Fayetteville"], stateCode: "AR" },
    { name: "California", cities: ["Los Angeles", "San Diego", "San Jose"], stateCode: "CA" },
    { name: "Colorado", cities: ["Denver", "Colorado Springs", "Aurora"], stateCode: "CO" },
    { name: "Connecticut", cities: ["Bridgeport", "New Haven", "Hartford"], stateCode: "CT" },
    { name: "Delaware", cities: ["Wilmington", "Dover", "Newark"], stateCode: "DE" },
    { name: "Florida", cities: ["Jacksonville", "Miami", "Tampa"], stateCode: "FL" },
    { name: "Georgia", cities: ["Atlanta", "Augusta", "Columbus"], stateCode: "GA" },
    { name: "Hawaii", cities: ["Honolulu", "Pearl City", "Hilo"], stateCode: "HI" },
    { name: "Idaho", cities: ["Boise", "Nampa", "Meridian"], stateCode: "ID" },
    { name: "Illinois", cities: ["Chicago", "Aurora", "Rockford"], stateCode: "IL" },
    { name: "Indiana", cities: ["Indianapolis", "Fort Wayne", "Evansville"], stateCode: "IN" },
    { name: "Iowa", cities: ["Des Moines", "Cedar Rapids", "Davenport"], stateCode: "IA" },
    { name: "Kansas", cities: ["Wichita", "Overland Park", "Kansas City"], stateCode: "KS" },
    { name: "Kentucky", cities: ["Louisville", "Lexington", "Bowling Green"], stateCode: "KY" },
    { name: "Louisiana", cities: ["New Orleans", "Baton Rouge", "Shreveport"], stateCode: "LA" },
    { name: "Maine", cities: ["Portland", "Lewiston", "Bangor"], stateCode: "ME" },
    { name: "Maryland", cities: ["Baltimore", "Frederick", "Rockville"], stateCode: "MD" },
    { name: "Massachusetts", cities: ["Boston", "Worcester", "Springfield"], stateCode: "MA" },
    { name: "Michigan", cities: ["Detroit", "Grand Rapids", "Warren"], stateCode: "MI" },
    { name: "Minnesota", cities: ["Minneapolis", "Saint Paul", "Rochester"], stateCode: "MN" },
    { name: "Mississippi", cities: ["Jackson", "Gulfport", "Southaven"], stateCode: "MS" },
    { name: "Missouri", cities: ["Kansas City", "Saint Louis", "Springfield"], stateCode: "MO" },
    { name: "Montana", cities: ["Billings", "Missoula", "Great Falls"], stateCode: "MT" },
    { name: "Nebraska", cities: ["Omaha", "Lincoln", "Bellevue"], stateCode: "NE" },
    { name: "Nevada", cities: ["Las Vegas", "Henderson", "Reno"], stateCode: "NV" },
    { name: "New Hampshire", cities: ["Manchester", "Nashua", "Concord"], stateCode: "NH" },
    { name: "New Jersey", cities: ["Newark", "Jersey City", "Paterson"], stateCode: "NJ" },
    { name: "New Mexico", cities: ["Albuquerque", "Las Cruces", "Rio Rancho"], stateCode: "NM" },
    { name: "New York", cities: ["New York", "Buffalo", "Rochester"], stateCode: "NY" },
    { name: "North Carolina", cities: ["Charlotte", "Raleigh", "Greensboro"], stateCode: "NC" },
    { name: "North Dakota", cities: ["Fargo", "Bismarck", "Grand Forks"], stateCode: "ND" },
    { name: "Ohio", cities: ["Columbus", "Cleveland", "Cincinnati"], stateCode: "OH" },
    { name: "Oklahoma", cities: ["Oklahoma City", "Tulsa", "Norman"], stateCode: "OK" },
    { name: "Oregon", cities: ["Portland", "Salem", "Eugene"], stateCode: "OR" },
  ];

  const [selectedState, setSelectedState] = useState("");
  const [servicepreferences, setServicepreferences] = useState([]);

  const checkemail = (email) => {
    const formData = new FormData();
    formData.append("email", email);
    checkemailApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setCheckemailbooking(true);
        } else {
          setCheckemailbooking(false);
        }
      } else {
        console.log("response: error");
      }
    }).catch((error) => {
      console.log("response: error");
    });
  }

  useEffect(() => {
    getservicesforuser();
  }, [edituser]);


  const getservicesforuser = () => {
    const formData = new FormData();
    formData.append("user_id", edituser.id);
    getbookingservicesApi(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setServicepreferences(apiResponse.data);
      } else {
        alert("Something went wrong");
      }
    });
  };


  const changeCounter222 = (value, id, serviceid) => {

    const updatedArray = servicepreferences.map((item) => {
      if (item.id === serviceid) {
        const subservices = item.subservices.map((subservice) => {
          if (subservice.id === id) {
            if (value === "increment") {
              return {
                ...subservice,
                quantity: parseInt(subservice.quantity) + 1,
              };
            } else if (value === "decrement") {
              return {
                ...subservice,
                quantity: parseInt(subservice.quantity) - 1,
              };
            }
          }
          return subservice;
        }
        );
        return {
          ...item,
          subservices: subservices,
        };

      }

      return item;
    });

    setServicepreferences(updatedArray);

  };

  const changeAddon222 = (value, id, serviceid) => {
    const updatedArray = servicepreferences.map((item) => {
      if (item.id === serviceid) {
        const addons = item.addons.map((addon) => {
          if (addon.id === id) {
            if (value === "increment") {
              return {
                ...addon,
                quantity: parseInt(addon.quantity) + 1,
              };
            } else if (value === "decrement") {
              return {
                ...addon,
                quantity: parseInt(addon.quantity) - 1,
              };
            }
          }
          return addon;
        }
        );
        return {
          ...item,
          addons: addons,
        };

      }

      return item;
    });

    setServicepreferences(updatedArray);
  };


  const updateservicepreference = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", edituser.id);
    formData.append("service_preference", JSON.stringify(servicepreferences));
    updateservicepreferenceApi(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        if (apiResponse.status === "1") {
          alert("Service preference updated successfully");
          // getservices();
          handleCloseCustomer();
        } else {
          alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    });
  };

  const bookingEndTime = (startTime, endTime) => {

    const date = moment(startTime, 'HH:mm:ss');
    //and endtime is 2 hours 32 minute
    const endtime = moment(endTime, 'HH:mm:ss');

    //add time 

    return moment(date).add(endtime.hours(), 'hours').add(endtime.minutes(), 'minutes').format('HH:mm');
  }

  const getscheduledtime = (booking_duration, staff_ids, order_date) => {

    if (booking_duration != "" && booking_duration != null && staff_ids != "" && staff_ids != null) {

      // Extract hours and minutes from booking_duration
      const durationParts = booking_duration.split(" ");
      const hours = parseInt(durationParts[0]);
      const minutes = parseInt(durationParts[2]);

      // Calculate total duration in minutes
      const totalMinutes = hours * 60 + minutes;

      //check order_date is less than and equal to today 
      // const today = "2024-01-29";
      // const date = new Date(order_date);
      // if (date <= today) {
      //   return `${hours} hours ${minutes} minutes`;
      // }
      // else {
      const staffIdsArray = staff_ids.split(",");


      //now check ChooseStaff is array 
      const filteredStaffIDs = [];

      staffIdsArray.forEach((staffID) => {
        activeStaff.forEach((item) => {
          if (item.value == staffID && item.role === 'staff') {
            filteredStaffIDs.push(staffID);
            return; // Exit the inner loop once a match is found
          }
        });
      });

      if (filteredStaffIDs.length != 0) {
        const resultHours = Math.floor(totalMinutes / filteredStaffIDs.length / 60);
        const resultMinutes = totalMinutes / filteredStaffIDs.length % 60;

        return `${resultHours} hours ${parseInt(resultMinutes)} minutes`;
      } else {
        return "0 hours 0 minutes"
      }

      // }
    }

  }

  const gettechnicialtime = (booking_timesheet_cleaning_time) => {
    if (Array.isArray(booking_timesheet_cleaning_time) && booking_timesheet_cleaning_time.length > 0) {
      const formattedNotes = booking_timesheet_cleaning_time.map(item => `${item.fullname} notes: ${convertToHHMM(item.time_difference)}`);
      return formattedNotes.join(', ');
    }
  }

  const gettechtimesheetnotes = (bookingNotes) => {
    if (Array.isArray(bookingNotes) && bookingNotes.length > 0) {
      const formattedNotes = bookingNotes.map(item => `${item.fullname} notes: ${item.note}`);
      return formattedNotes.join(', ');
    }
  }

  const getdividetime = (startTime, booking_duration, staff_ids, order_date) => {

    if (booking_duration != "" && booking_duration != null && staff_ids != "" && staff_ids != null) {
      const durationParts = booking_duration.split(" ");
      const hours = parseInt(durationParts[0]);
      const minutes = parseInt(durationParts[2]);

      const totalMinutes = hours * 60 + minutes;

      // const today = "2024-01-29";
      // const date = new Date(order_date);
      // const today = moment("2024-01-29");
      // const orderMoment  = moment(order_date);
      // if (orderMoment.isSameOrBefore(today, 'day')) {
      //   return moment(startTime, 'HH:mm:ss').add(totalMinutes, 'minutes').format('HH:mm');
      // } {

      const numStaff = staff_ids.split(",").length;
      const timePerStaff = totalMinutes / numStaff;

      const hoursPerStaff = Math.floor(timePerStaff / 60).toString().padStart(2, '0');

      //i want to time like 01:45
      const minutesPerStaff = (timePerStaff % 60).toString().padStart(2, '0');


      const date = moment(startTime, 'HH:mm:ss');

      return moment(date).add(hoursPerStaff, 'hours').add(minutesPerStaff, 'minutes').format('HH:mm');
      // }


    }
  }

  const [editbookingtimeEnd, setEditbookingtimeEnd] = useState('');

  useEffect(() => {
    getCalculatedEndTime(editbooking.start_time, expedtedendtime);
  }, [editbooking.start_time, expedtedendtime]);


  function getCalculatedEndTime(starttime, endtimeInMinutes) {
    const startMoment = moment(starttime, 'hh:mm A');
    const endMoment = startMoment.clone().add(endtimeInMinutes, 'minutes');

    // Format the result as "HH:mm"
    const formattedEndTime = endMoment.format('HH:mm');

    // return formattedEndTime;
    setEditbookingtimeEnd(formattedEndTime);
  }

  const handleStartTimeChange = (e) => {
    const newStartTime = e.target.value;
    setEditbookingStartTime(newStartTime);

    const startMoment = moment(newStartTime, 'hh:mm A');
    const endMoment = startMoment.clone().add(expedtedendtime, 'minutes');

    const formattedEndTime = endMoment.format('HH:mm');
    setEditbookingtimeEnd(formattedEndTime);
  }

  const findzonebyzip = (value) => {
    const formData = new FormData();
    formData.append("zip_code", value);
    findzonebyzipApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          setAppointmnetzonename(response.data.data.zonename);
        } else {
          // alert("Something went wrong");
          setAppointmnetzonename('');
        }
      } else {
        alert("Something went wrong");
      }
    });
  }

  const [usercrmid, setUsercrmid] = useState(0);


  const callChildFunction = (userid) => {
    setUsercrmid(userid);
  };


  const [filterbooking, setfilterbooking] = useState([]);

  const [rows, setRows] = useState([
    {
      id: '',
      service: '',
      notes: '',
      quantity: 1,
      price: 0,
    },
  ]);

  console.log("rows", rows);
  const [discountinvoice, setDiscountinoice] = useState(0);

  useEffect(() => {

    const booking_discount = bookingDetails.booking_discount;

    if (Array.isArray(bookingtimesheetcleaningtime)) {
      // Map the services according to your conditions
      const serviceMapping = {
        "House Cleaning": "Scheduled Cleaning",
        "First Time General Cleaning": "First Time Cleaning",
        "First Time Deluxe Cleaning": "First Time Cleaning",
        "Move In/Out Service": "TBD",
        "Window Cleaning": "Extra Service",
        "Area Fee": "Area Fee"
      };

      const cleanedServiceTitle = bookingDetails.service_title.replace(':', '').trim();

      const service = serviceMapping[cleanedServiceTitle];

      const updatedRows = bookingtimesheetcleaningtime.map((staff, index) => {


        const rowobject = {
          id: bookingDetails.service_stax_id,
          service: service,
          notes: staff.fullname,
          quantity: convertToHM(staff.time_difference),
          price: 65,
          total: 65 * convertToHM(staff.time_difference),
        }
        return rowobject;
      });

      const rowobject2 = {
        // service: 'e92b0e79-55b6-4920-a7fa-16641bd2ee0e',
        id: bookingDetails.service_stax_id,
        service: service,
        notes: 'Base Fee',
        quantity: 1,
        price: 30,
        total: 30
      }
      const updatedRowsWithRowObject2 = [...updatedRows, rowobject2];

      // setRows(updatedRowsWithRowObject2);

      if (booking_discount !== undefined && booking_discount > 0 && booking_discount !== null) {
        const rowobject3 = {
          id: "f5e8658e-d19e-43b6-9cbb-33ba7c86ec7c",
          service: 'Discount',
          notes: '',
          quantity: 1,
          price: -booking_discount,
          total: -booking_discount
        };
        updatedRowsWithRowObject2.push(rowobject3);
      }

      setRows(updatedRowsWithRowObject2);

    } else {
      const services = [{
        service: '',
        notes: '',
        quantity: 1,
        price: 0,
      }];
      setRows(services);
    }
  }, [bookingtimesheetcleaningtime]);

  const addRow = () => {
    setRows([...rows, {
      service: '',
      notes: '',
      quantity: 0,
      price: 0,
    }]);
  };

  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const [productlist, setproductlist] = useState([]);

  const productlistdata = () => {
    getproductlistdataApi().then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data.data;
        setproductlist(apiResponse);
      } else {
        alert("Something went wrong");
      }
    });
  };


  const createinvoice = () => {

    if (invoicenotes == '') {
      alert("Please enter invoice notes");
      return false;
    }

    const formData = new FormData();
    var totalprice = 0;

    const lineItems = [];

    // for (let i = 0; i < invoiceservice.subservices.length; i++) {
    //   const data = {
    //     "id": invoiceservice.subservices[i].subservice_id,
    //     "item": invoiceservice.subservices[i].units_title,
    //     "details": invoiceservice.subservices[i].units_title,
    //     "quantity": invoiceservice.subservices[i].quantity,
    //     "price": invoiceservice.subservices[i].base_price
    //   }
    //   lineItems.push(data);
    // }

    // for (let i = 0; i < invoiceservice.addons.length; i++) {
    //   const data = {
    //     "id": invoiceservice.addons[i].id,
    //     "item": invoiceservice.addons[i].addon_service_name,
    //     "details": invoiceservice.addons[i].addon_service_name,
    //     "quantity": invoiceservice.addons[i].addons_service_qty,
    //     "price": invoiceservice.addons[i].base_price
    //   }
    //   lineItems.push(data);
    // }

    for (let i = 0; i < rows.length; i++) {
      const serviceParts = rows[i].service.split(' ');


      const data = {
        "id": rows[i].id || serviceParts[0], // Check if id exists; if not, use the first part of service
        "item": rows[i].id ? rows[i].service : serviceParts.slice(1).join(' '), // Use full service if id exists, otherwise exclude id part
        "details": rows[i].notes,
        "quantity": parseInt(rows[i].quantity, 10),
        "price": parseFloat(rows[i].price)
      };
      lineItems.push(data);

      // Calculate the total price for this item and add it to the totalprice
      totalprice += data.quantity * data.price;
    }


    formData.append("customer_id", bookingDetails.customer_id);
    formData.append("total", totalprice);
    formData.append("subtotal", calculateTotal(rows, discountinvoice));
    formData.append("notes", invoicenotes);
    formData.append("order_id", bookingDetails.order_id);

    formData.append("line_item", JSON.stringify(lineItems));

    createinvoiceApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === "1") {
          NotificationManager.success("Invoice created successfully");
          getinvoice();
        } else {
          NotificationManager.error(response.data.message);
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    });

  }

  const onCardNumberChange = (event) => {
    let { value, name } = event.target;
    let cardNumber = value;
    value = value.replace(/\D/g, '');
    if (/^3[47]\d{0,13}$/.test(value)) {
      cardNumber = value
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
      // diner's club, 14 digits
      cardNumber = value
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    } else if (/^\d{0,16}$/.test(value)) {
      // regular cc number, 16 digits
      cardNumber = value
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
        .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
    }

    setCardNumber(cardNumber.trimRight());
  };


  const [userbilledamountedit, setuserbilledamountEdit] = useState('');
  const [userbilledhoursedit, setuserbilledhoursEdit] = useState('');

  const [crmshow, setCrmShow] = useState(false);

  const CrmhandleClose = () => setCrmShow(false);
  const CrmhandleShow = () => setCrmShow(true);

  const handleAnotherAction = (event) => {
    event.preventDefault();
    CrmhandleShow();
  }

  const updatecrmdata = () => {
    const formData = new FormData();
    formData.append("billed_amount", userbilledamountedit);
    formData.append("billed_hours", userbilledhoursedit);
    formData.append("user_id", existingUser.id);

    updatecrmdataApi(formData).then((response) => {
      if (response.code === 200) {
        if (response.data.status === 1) {
          NotificationManager.success("User update successfully");
          setUserbilledhours(userbilledhoursedit);
          setUserbilledamount(userbilledamountedit);
        } else {
          NotificationManager.error(response.data.message);
        }
      } else {
        alert("Something went wrong");
      }

    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      CrmhandleClose();
    })

  }

  const [editbookingchangesamount, setEditbookingChangesAmount] = useState('');

  useEffect(() => {

    getCalculateddiscount2(editbooking.billed_amount, editbooking.booking_discount);
  }, [editbooking]);

  const getCalculateddiscount2 = (booking_price, booking_discount) => {
    if (booking_price != 0 && booking_discount != 0) {

      const discount = parseInt(booking_price) - parseInt(booking_discount);
      setEditbookingChangesAmount(discount);
    } else {
      setEditbookingChangesAmount("00");
    }
  }

  const handlechageeditdiscountamount = (value) => {
    if (value !== "") {
      var parsedValue = parseInt(value);
      setEditbookingdiscount(parsedValue);
      const discountprice = parseInt(editbookingestimatedPrice) - parsedValue;
      setEditbookingChangesAmount(parseInt(discountprice));

      var Servicepercentage = parseInt(editbooking.service_percentage);
      let totalAmountvar1;
      if (Servicepercentage > 0) {
        totalAmountvar1 = ((100 - Servicepercentage) * editbookingchangesamount) / 100;
      } else {
        totalAmountvar1 = parseInt(editbooking.billed_amount) - parsedValue;
      }

      setEditTotalAmount(parseInt(totalAmountvar1));
    } else {
      setEditbookingdiscount(0);
    }
  }

  const [searchpricestatus, setsearchpricestatus] = useState(false);
  const [bookedpercentage, setbookedpercentage] = useState(0);


  useEffect(() => {
    getsearchpricestatus().then((res) => {
      if (res.code === 200) {
        if (res.data.status === 1) {
          const apiResponse = res.data;
          if (apiResponse.data.status === "true") {
            setsearchpricestatus(true);
            const formData = new FormData();
            formData.append("start_date", moment(startDate).format('YYYY-MM-DD'));
            formData.append("end_date", moment(startDate).add(1, 'days').format('YYYY-MM-DD'));
            getreport(formData).then((res) => {
              if (res.code === 200) {
                const ApiResponse = res.data;
                getprecentagebooked(ApiResponse.data);
              } else {
                console.log("Something went wrong");
              }
            })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setsearchpricestatus(false);
            setbookedpercentage(0);
          }
        }
        else {
          console.log("Data didn`t not found!");
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [startDate])

  const getprecentagebooked = (filteredData) => {
    const totalSlots = 14;
    const totalSlotsFilled = filteredData.reduce((acc, person) => acc + person.total_avalibility, 0);
    const totalPercentage = Math.floor((totalSlotsFilled / (filteredData.length * totalSlots)) * 100);
    setbookedpercentage(totalPercentage);
    // return totalPercentage.toFixed(2);
  }

  const getdefaultPaymentMethod = () => {
    const formData = new FormData();
    formData.append("user_id", bookingDetails.client_id);

    getdefaultpayment(formData).then((res) => {
      if (res.code === 200) {
        if (res.data.status == '1') {
          const apiResponse = res.data;
          setDefaultpaymentmethod(apiResponse.data.payment_id);
        }
        else {
          console.log("Something went wrong");
        }

      } else {
        alert("Something went wrong");
      }
    });
  }

  const updatepaymentdetails = () => {
    const formData = new FormData();
    formData.append("payment_id", paymentMethodId);
    formData.append("user_id", bookingDetails.client_id);

    updatepaymentMethod(formData).then((res) => {
      if (res.code === 200) {
        if (res.data.status == 1) {
          const apiResponse = res.data;
          handleClose7();
          setDefaultpaymentmethod(paymentMethodId);
          NotificationManager.success("Default card set successfully!");
        }
        else {
          console.log("Something went wrong");
        }

      } else {
        alert("Something went wrong");
      }
    });

  }

  const filterstaff = (serviceIds) => {

    // const filteredStaff = dammyactivestaff.filter((staff) => {
    //   const serviceIdsArray = staff.service_ids.split(',').map(Number);
    //   return serviceIdsArray.some((serviceId) => serviceIds.includes(serviceId));
    // });

    // setActiveStaff(filteredStaff);

    const filteredStaff = dammyactivestaff.filter(person =>
      person.service_ids?.split(",").includes(serviceIds)
    );
    setActiveStaff(filteredStaff);
  };

  useEffect(() => {
    if (staffId) {
      const serviceIdsArray = staffId.service_ids; // This array is already in numerical form
      const filterservices = dummyservice.filter((service) => serviceIdsArray.includes(Number(service.id)));
      setService(filterservices);

      const filteredStaff = dammyactivestaff.filter((staff) => staff.service_ids.split(',').map(Number).some((serviceId) => serviceIdsArray.includes(serviceId)));

      setActiveStaff(filteredStaff);

    } else {
      console.log("serviceIdsArray false");
    }
  }, [staffId])

  const fiterserivedata = () => {

    if (staffId) {
      const serviceIdsArray = staffId.service_ids; // This array is already in numerical form
      const filterservices = dummyservice.filter((service) => serviceIdsArray.includes(Number(service.id)));
      setService(filterservices);

      const filteredStaff = dammyactivestaff.filter((staff) => staff.service_ids.split(',').map(Number).some((serviceId) => serviceIdsArray.includes(serviceId)));

      setActiveStaff(filteredStaff);

    } else {
      console.log("serviceIdsArray false");
    }
  }

  useEffect(() => {
    const serviceIds = editbooking.service_id;

    if (Array.isArray(staffListedit) && serviceIds) {
      const filteredStaff = staffListedit.filter(person => {
        const serviceIdArray = person.service_ids?.split(",");
        return serviceIdArray.includes(serviceIds);
      });

      setStaffListEdit(filteredStaff);
    }
  }, [editbooking]);


  return (
    <div>

      {/* {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (
          <> */}

      {/* Edit CRM modal start here */}
      <Modal show={crmshow} onHide={CrmhandleClose} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Add new staff member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Billed Amount</Form.Label>
              <Form.Control type="text" value={userbilledamountedit} onChange={(e) => setuserbilledamountEdit(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Billed Hours (HH:mm)</Form.Label>
              <InputMask
                mask="99:99"
                maskChar=""
                alwaysShowMask
                name="billed_hours"
                formatChars={{ '9': '[0-9]', ':': ':' }}
                placeholder="HH:mm"
                className="form-control"
                value={convertToHHMM(userbilledhoursedit)}
                onChange={handleuserbilledhours}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="Cancel-bttn btn" onClick={CrmhandleClose}>
            Close
          </Button>
          <Button className="Active-bttn btn" onClick={updatecrmdata}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit CRM modal start here */}

      {/* Add Card modal start here */}
      <Modal show={show6} className="Sidebar-Modal-end" onHide={handleClose6} >
        <Modal.Header closeButton>
          <Modal.Title>New Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custum-modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Card Number</Form.Label>
                <Form.Control
                  type="tel"
                  className='custom-input'
                  value={cardNumber}
                  onChange={onCardNumberChange}
                  maxLength="19" />
                {cardNumbererror == true ? <p style={{ color: 'red' }}>card number is required</p> : null}
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input'
                  value={cardExpDate}
                  {...getExpiryDateProps({ onChange: (e) => setCardExpDate(e.target.value) })} />
                {cardExpDateerror == true ? <p style={{ color: 'red' }}>please enter card exipry date</p> : ''}
                {cardExpYearerror == true ? <p style={{ color: 'red' }}>Card expiration date should be in the future.</p> : ''}
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>CVV</Form.Label>
                <Form.Control required type="number" placeholder="CVV" className='custom-input'
                  value={cardCVV}
                  onInput={
                    (e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }
                  }
                  onChange={(e) => setCardCVV(e.target.value)} />
                {cardCVVerror == '' ? <p style={{ color: 'red' }}>please enter cvv</p> : ''}
                {cardCVV.length != 3 ? <p style={{ color: 'red' }}>cvv must have 3 digits</p> : ''}
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className='custom-lable'>Full Name</Form.Label>
                <Form.Control required type="text" placeholder="Full Name" className='custom-input'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)} />
                {fullNamerror == true ? <p style={{ color: 'red' }}>please enter full name</p> : ''}
              </Form.Group>
            </div>
            {/* <div className='col-md-6'>
            <Form.Group className="mb-4" controlId="validationCustom01">
              <Form.Label className='custom-lable'>Last Name</Form.Label>
              <Form.Control required type="text" placeholder="Full Name" className='custom-input'
                value={fullName}
                onChange={(e) => setFullName(e.target.value)} />
              {addCardValidate && fullName == '' ? <p style={{ color: 'red' }}>please enter full name</p> : ''}
            </Form.Group>
          </div> */}
          </div>

          <div className='row'>
            <div className='modal-btn justify-content-end'>
              <button href="#" className='btn g-btn me-3' type="submit" onClick={handleAddCard} >Save</button>
              <button href="#" className='btn btn-danger' onClick={handleClose6}>Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* modal End here */}

      {/* Payment Method modal End here */}
      <Modal show={showModal7} className="Sidebar-Modal-end" onHide={handleClose7}>
        <Modal.Header closeButton>
          <Modal.Title>Fattmerchant Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custum-modal-body'>

          {paymentMethod && paymentMethod.length !== 0 ? paymentMethod.map((item, index) => {
            const cardOptionId = `cardOption${index}`;
            const isFirstCard = index === 0;
            const isChecked = selectedCard === cardOptionId || (isFirstCard && !selectedCard);
            const isCardExpired = checkCardExpiration(item.card_exp) === "Your Card is Expired";
            return (
              <div class="form-check mb-3">
                <div className="d-flex align-items-center">
                  {/* <input className="form-check-input me-3" type="radio" name="cardOption" id={`cardOption${index}`}
                    value={`cardOption${index}`} checked={isChecked} defaultChecked={index === 0} onChange={(e) => {
                      setSelectedCard(e.target.value);
                      setPaymentMethodId(item.id);
                    }
                    }
                  /> */}
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="cardOption"
                    id={`cardOption${index}`}
                    value={`cardOption${index}`}
                    defaultChecked={defaultpaymentmethod ? item.id === defaultpaymentmethod : index === 0}
                    // checked={defaultpaymentmethod ? item.id === defaultpaymentmethod : index === 0}
                    onChange={
                      (e) => {
                        setSelectedCard(e.target.value);
                        setPaymentMethodId(item.id);
                      }
                    }
                    disabled={isCardExpired}
                  />
                  <label class="form-check-label payment-card-details" for="flexRadioDefault1">
                    <div className="card payment-card-details">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <div>
                            <div className="card-title h5">****-****-****-{
                              item.card_last_four || " "
                            }</div>
                            <p className="card-text">{
                              item.nickname || " "
                            }</p>
                            <p className="card-text">{
                              checkCardExpiration(item.card_exp)
                            }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            );
          }) : (
            <div>No data found</div>
          )}
          <div className='row'>
            <div className='modal-btn justify-content-end'>
              <button href="#" className='btn g-btn me-3' type="submit" onClick={updatepaymentdetails}>Make as Default</button>
              <button href="#" className='btn g-btn me-3' type="submit" onClick={payinvoice}>Pay</button>
              <button href="#" className='btn btn-danger' onClick={handleClose7}>Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Payment Method modal End here */}


      {/* edit appointment modal start */}
      <Modal show={show4} onHide={handleClose4} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Stax Id:</Form.Label>
              </Form.Group>
              <div className="">
                <div className="create-stax-id mb-3">
                  {
                    bookingDetails.customer_id == "" ?
                      <button className="btn btn-primary actions-btn" onClick={createStaxId}>
                        Create Stax Id
                      </button>
                      :
                      <p>{bookingDetails.customer_id}</p>

                  }
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="payment-settings-content-header create-stax-id-btn mb-3">
                <button href="#" class="btn Add-bttn ms-auto" onClick={handleShow6}>
                  <span>
                    <BsPlusLg />
                  </span>
                  Add Card
                </button>
              </div>
            </div>
          </div>
          <div
            className=""
            style={{
              display: bookingDetails.customer_id === "" ? "none" : "block",
            }}
          >
            <div className="booking-details-deta-inner">
              <ul className="ct-cal-booking-details">
                <li className="ct-cal-booking-details-item">
                  <label>Price</label>
                  <span>: {`$` + invoiceprice}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Booking Discount Price</label>
                  <span>: {"$" + getCalculateddiscount(bookingDetails.booking_price_test, bookingDetails.booking_discount)}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Booking Discount</label>
                  <span>:{"$" + bookingDetails.booking_discount}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Billing Notes</label>
                  <span>: {bookingDetails && bookingDetails.booking_notes ? bookingDetails.booking_notes : " "}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Notes</label>
                  <span>{invoicenotes === " "
                    ? " "
                    : getNotes(invoicenotes)}{" "}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Team Cleaning Time</label>
                  <span>: {cleaningtime}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Technician Time</label>
                  <span>: {gettechnicialtime(bookingtimesheetcleaningtime)}</span>
                </li>
                <li className="ct-cal-booking-details-item">
                  <label>Timesheet Notes</label>
                  <span>: {gettechtimesheetnotes(bookingDetails.booking_time_note)}</span>
                </li>
              </ul>
            </div>

            <div className="payment-invoice mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="card-title-invoice">
                    <h3>Invoice</h3>
                    <div className="add-invoice-btn">
                      <button className="btn-invoice" onClick={addRow}>< MdAdd />Add More</button>
                    </div>
                  </div>
                  <div className="invoice-list">
                    <table className="table table-invoice">
                      <thead>
                        <tr>
                          <th>DETAILS</th>
                          <th>QUANTITY</th>
                          <th>PRICE</th>
                          <th>AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index} className="mt-3">
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group">
                                <Form.Select
                                  // value={row.service}
                                  aria-label="Default select example"
                                  onChange={(e) => updateRow(index, 'service', e.target.value)}
                                >
                                  <option>Service Name</option>
                                  {productlist.map((item, itemIndex) => (
                                    <option key={itemIndex} value={`${item.id} ${item.item}`} selected={row.id === item.id}>
                                      {item.item}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                              <div className="my-1 flex-nowrap input-group">
                                <input
                                  name="notes"
                                  type="text"
                                  className="form-control"
                                  value={row.notes}
                                  onChange={(e) => updateRow(index, 'notes', e.target.value)}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group">
                                <input
                                  name="quantity"
                                  type="text"
                                  className="form-control"
                                  value={row.quantity}
                                  onChange={(e) => {
                                    updateRow(index, 'quantity', e.target.value)
                                    const pricequantity = e.target.value;
                                    const total = parseFloat(pricequantity) * row.price;
                                    updateRow(index, 'total', total);
                                  }}
                                  disabled={row.service === "Discount"}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <div className="my-1 flex-nowrap input-group price-width">
                                <input
                                  name="Price"
                                  type="text"
                                  className="form-control"
                                  value={row.price}
                                  onChange={(e) => {
                                    const priceValue = e.target.value;
                                    updateRow(index, 'price', priceValue);
                                    const total = parseFloat(priceValue) * row.quantity;
                                    updateRow(index, 'total', total);
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100%' }}>
                              <b>{row.total ? `$${row.total}` : '$0'}</b>
                            </td>
                            {index === 0 ? (
                              <></>
                            ) : (
                              <button className="remove-row-btn" onClick={() => removeRow(index)}>
                                <MdClose />
                              </button>
                            )}
                          </tr>
                        ))}
                        <tr>

                        </tr>
                      </tbody>
                    </table>
                    {/* <div className="d-flex justify-content-between align-items-center gap-4 mb-3">
                      <div className="discount">
                        <b>Discount : </b>
                      </div>
                      <div className="w-50">
                        <div style={{ width: '100%' }}>
                          <div className="my-1 flex-nowrap input-group">
                            <input
                              name="discount"
                              type="text"
                              className="form-control"
                              value={discountinvoice}
                              onChange={(e) => setDiscountinoice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center gap-4 mb-3">
                      <div className="total">
                        <b>Total :</b>
                      </div>
                      <div className="w-50">
                        <div style={{ width: '100%' }}>
                          <div className="my-1 flex-nowrap input-group">
                            <input
                              name="discount"
                              type="text"
                              className="form-control"
                              value={`$${calculateTotal(rows, discountinvoice)}`}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="payment-settings-content-header d-flex justify-content-beetwin align-items-center mb-4">
              <button href="#" class="btn Add-bttn ms-auto" onClick={createinvoice} disabled={isNaN(calculateTotal(rows, discountinvoice)) || calculateTotal(rows, discountinvoice) < 0} >
                <span>
                  <BsPlusLg />
                </span>
                Create Invoice
              </button>
            </div>
            <hr />
            <div className="row">
              <h3>Invoice List</h3>
              <div className="col-md-12 mt-4">
                <div className="animated fadeIn">
                  {
                    invoiceLoader ? <Spinner animation="grow" variant="success" /> : (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-header d-flex table-card-header">
                              <Form.Group>
                                <div className='d-flex'>
                                  <div addonType="prepend">
                                  </div>
                                  <Form.Control type="text" placeholder="Keywords..." onChange={handleChange} />
                                </div>
                              </Form.Group>
                            </div>
                            <div className="card-body">
                              <BootstrapTable bootstrap4 keyField='id' data={invoiceList} columns={columns} pagination={pagination} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit appointment modal end */}

      {/* add appointment modal start */}
      <div className={`modal-container ${minimized ? "minimized" : ""}`}>
        <Modal show={showNewBooking && !minimized} onHide={handleClose} className="Sidebar-Modal-end">
          <Modal.Header closeButton>
            <div className="header-icon">
              <Button className="minimize-btn-header" onClick={handleMinimize}>
                <TbMinimize />
              </Button>
            </div>
            <Modal.Title>Add Manual Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id="add-appointment-form" noValidate validated={validated} onSubmit={AddBookingData} autoComplete="off">
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="mb-3 appointment-accordion"
                >
                  <Accordion.Header>Your Personal Details</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <Form>
                        {["radio"].map((type) => (
                          <div
                            key={`inline-${type}`}
                            className="popup-radios mb-3"
                          >
                            <Form.Check
                              inline
                              label="New User"
                              name="group1"
                              // defaultChecked
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={showNewUser}
                              checked={userType === 0}
                            />
                            <Form.Check
                              inline
                              label="Existing User"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              onClick={showExistingUser}
                              checked={userType === 1}
                            />

                          </div>
                        ))}
                      </Form>
                    </div>
                    <div className="existing-user">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <Select
                            options={users}
                            onChange={(e) => {
                              getandsetdata(e);
                              getusershipppingaddress(e);
                            }}
                            onInputChange={(e) => {
                              handleInputChange(e);
                            }}
                            onMenuScrollToBottom={handleMenuScrollToBottom}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="userdata newuser">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="mb-3 email" controlId="validationCustom01">
                            <Form.Label>Preferred Email</Form.Label>
                            <Form.Control
                              required
                              type="email"
                              placeholder="Enter email"
                              value={existingUser.email}
                              onChange={(e) => {
                                handleEditInputChange(e);
                                checkemail(e.target.value);
                              }}
                              name="email"
                            />
                            {
                              checkemailbooking ? <p style={{ color: 'red' }}>Email already exist</p> : null
                            }
                            <Form.Control
                              type="hidden"
                              placeholder="Enter email"
                              value={existingUser.id}
                              onChange={handleEditInputChange}
                              name="id"
                            />
                          </Form.Group>
                          <Form.Group className="password clicknone mb-3" controlId="validationCustom02">
                            <Form.Label>Preferred Password</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              placeholder="Enter password"
                              value={existingUser.password}
                              onChange={handleEditInputChange}
                              name="password"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="mb-3 firstname" controlId="validationCustom03">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="First Name"
                              value={existingUser.firstname}
                              onChange={handleEditInputChange}
                              name="firstname"
                            />
                          </Form.Group>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid state.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-3"
                            controlId="validationCustom04"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Last Name"
                              value={existingUser.lastname}
                              onChange={handleEditInputChange}
                              name="lastname"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group
                            className="mb-3"
                            controlId="validationCustom05"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Phone Number"
                              value={existingUser.phone}
                              onChange={handleEditInputChange}
                              name="phone"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <FloatingLabel
                            controlId="validationCustom06"
                            label="Street Address"
                            className="mb-3"
                          >
                            <Form.Control
                              required
                              as="textarea"
                              placeholder="Leave a comment here"
                              value={existingUser.address}
                              onChange={handleEditInputChange}
                              name="address"
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3" controlId="validationCustom07">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="City"
                              value={existingUser.city}
                              onChange={handleEditInputChange}
                              name="city"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-3"
                            controlId="validationCustom08"
                          >
                            <Form.Label>State</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={handleEditInputChange} name="state">
                              {
                                states.map((state) => {
                                  return (
                                    // <option value={state.stateCode} selected={state.stateCode == existingUser.state || state.name == existingUser.state }>{state.name}</option>
                                    <option value={state.stateCode} selected={state.stateCode === existingUser.state || state.name === existingUser.state || (state.stateCode === 'CA')}>{state.name}</option>
                                  )
                                })
                              }
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3" controlId="validationCustom09">
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Zip Code"
                              value={existingUser.zip}
                              onChange={
                                (e) => {
                                  handleEditInputChange(e);
                                  findzonebyzip(e.target.value);
                                }
                              }
                              name="zip"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3" controlId="validationCustom09">
                            <Form.Label>Zone</Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              placeholder="Zone"
                              value={appointmentzonename}
                              name="zip"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="row appointment_details" style={
                      {
                        display: "none !important",
                      }
                    }>
                      <Form.Group style={{ display: "none" }}>
                        {["checkbox"].map((type) => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                              inline
                              label="(Same As Above)"
                              name="group1"
                              type={type}
                              //bydefalt checked
                              checked={checkboxappointment}
                              // onChange={
                              //   (e) => {
                              //     setappointmentData(e.target.checked);
                              //   }
                              // }
                              onChange={(e) => { setCheckboxappointment(!checkboxappointment); setappointmentData(e.target.checked); }}
                              // onClick={setappointmentData}
                              id={`inline-${type}-1`}
                            />

                          </div>
                        ))}
                      </Form.Group>
                      <div className="col-md-12">
                        <div className="form-title">
                          <h4 className="">Appointment Details</h4>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="validationCustom11">
                          <Form.Label>Select Default Shipping Address</Form.Label>
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="label"
                            onChange={(e) => {
                              getusershippingaddressdata(e);
                            }}
                            options={usershippingaddress}
                            placeholder="Select Default Shipping Address"
                            selected={selectedOption}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <FloatingLabel
                          controlId="validationCustom10"
                          label="Street Address"
                          className="mb-3"
                        >
                          <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            value={appointmentaddress}
                            onChange={
                              (e) => {
                                setAppointmentaddress(e.target.value);
                              }
                            }
                          // name="appointmentaddress"
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="validationCustom11">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            value={appointmentcity}
                            onChange={
                              (e) => {
                                setAppointmentcity(e.target.value);
                              }
                            }
                          // name="appointmentcity"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          className="mb-3"
                          controlId="validationCustom12"
                        >
                          <Form.Label>State</Form.Label>
                          {/* <Form.Control
                          required
                          type="text"
                          placeholder="State"
                          value={appointmentstate}
                          onChange={
                            (e) => {
                              setAppointmentstate(e.target.value);
                            }
                          }
                        // name="appointmentstate"
                        /> */}
                          <Form.Select aria-label="Default select example" onChange={
                            (e) => {
                              setAppointmentstate(e.target.value);
                            }
                          } name="state">
                            {
                              states.map((state) => {
                                return (
                                  <option value={state.stateCode} selected={state.stateCode == appointmentstate || state.name == appointmentstate || (state.name === 'CA')}>{state.name}</option>
                                )
                              })
                            }
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="validationCustom13">
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Zip Code"
                            value={appointmentzip}
                            onChange={
                              (e) => {
                                setAppointmentzip(e.target.value);
                              }
                            }
                          // name="appointmentzip"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="validationCustom13">
                          <Form.Label>Zone</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Zone Name"
                            value={appointmentzonename}
                          // name="appointmentzip"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <div className={`bookingData ${serviceTabValidation}`}>
                  <Accordion.Item
                    eventKey="1"
                    className="appointment-accordion mb-3"
                  >

                    <Accordion.Header>Choose Service</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="Service-details-box">
                          <div className="row selectservice">
                            <Form.Group className="mb-3" controlId="validationCustomservice">
                              <Form>
                                {['radio'].map((type) => (
                                  <div key={`inline-${type}`} className="mb-3">
                                    <div className="Service-details-box-inner" >
                                      <div className="d-flex align-items-center">
                                        <div className="selexted-service-check" >
                                          {service && service.map((data) => (
                                            <Form.Check
                                              inline
                                              required
                                              label={data.title === "Training - Meeting" ? "Training" : data.title}
                                              name="group2"
                                              type={type}
                                              id={`inline-${type}-${data.id}`}
                                              value={data.id}
                                              checked={ChooseService === data.id}
                                              onClick={(e) => {
                                                getsubservice(data);
                                                setChooseService(data.id);
                                                getaddonlist(data.id);
                                                setServicename(data.title);
                                                filterstaff(data.id);
                                              }}
                                              className="Service-details-box-inner-title"
                                            />
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Form>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="Service-details-box booking-warrper-20">
                          <div className="row">

                            <div className="btn-group" style={{ display: "block" }}>
                              <button type="button" className="btn modal-button-content active once-content" onClick={removeActive} > Once</button>
                              <button type="button" className="btn modal-button-content recurring-content" onClick={addActive}> Recurring</button>
                            </div>

                            <div className="recurring-modal-content">
                              <div className="modal-content-inner">
                                <div className="modal-content-inner-title">
                                  <h3>REPEAT</h3>
                                  {/* <div className="close-icon">
                                <button type="button" className="btn-close" onClick={closeModal}> </button>
                              </div> */}
                                </div>
                                <div className="modal-content-inner-body">
                                  <div className="form-group mb-3">
                                    <label for="exampleFormControlSelect1" className="mb-2">Frequency</label>
                                    <select className="form-control" id="exampleFormControlSelect1" onChange={
                                      (e) => {
                                        setFrequency(e.target.value);
                                      }
                                    }>
                                      <option value="0">Select</option>
                                      <option value="weekly">Weekly</option>
                                      <option value="monthly">Monthly</option>
                                      <option value="custom">Custom Recurrence</option>
                                    </select>
                                  </div>
                                  <div className="form-group mb-3">
                                    <label for="exampleFormControlSelect1" className="mb-2">Booking Start Time</label>
                                    <input type="time"
                                      className="form-control" value={recurringtime || ""} onChange={
                                        (e) => {
                                          setRecurringtime(e.target.value);
                                          showDate(e);
                                        }
                                      } id="exampleFormControlSelect1" />
                                  </div>

                                  <div id="hide-sec" style={{ display: 'none' }}>
                                    <div className="form-group mb-3">
                                      <label for="exampleFormControlSelect1" className="mb-2">Booking Start Date</label>
                                      <input type="date" className="form-control" value={bookingStartDate || ""} onChange={(e) => {
                                        setBookingStartDate(e.target.value);
                                        updateRecurringBookingDates(e.target.value);
                                      }} id="exampleFormControlSelect1" />
                                    </div>
                                    <div className="form-group mb-3">
                                      <label for="exampleFormControlSelect1" className="mb-2">Booking End Date</label>
                                      <input type="date" className="form-control" value={bookingEndDate || ""} onChange={
                                        (e) => {
                                          setBookingEndDate(e.target.value);
                                        }
                                      } id="exampleFormControlSelect1" />
                                    </div>
                                  </div>

                                  <div class="week-days" id="weekly" style={{ display: 'none' }}>
                                    <h5>Week days</h5>
                                    <a href="#" class="weeks" id="mo1" data-value="1" onClick={showDate} >Mo</a>
                                    <a href="#" class="weeks" id="tu1" data-value="2" onClick={showDate} >Tu</a>
                                    <a href="#" class="weeks" id="we1" data-value="3" onClick={showDate}>We</a>
                                    <a href="#" class="weeks" id="th1" data-value="4" onClick={showDate}>Th</a>
                                    <a href="#" class="weeks" id="fr1" data-value="5" onClick={showDate}>Fr</a>
                                    <a href="#" class="weeks" id="sa1" data-value="6" onClick={showDate}>Sa</a>
                                    <a href="#" class="weeks" id="su1" data-value="0" onClick={showDate}>Su</a>
                                  </div>
                                  <div class="month-list" id="monthly" style={{ display: 'none' }}>
                                    <h5>Day of week in Months</h5>
                                    <a href="#" week-name="Mo" class="month count" week-count="1" onClick={showMonth} data-days="0" data-value="0">Mo</a>
                                    <a href="#" week-name="Tu" class="month count" week-count="1" onClick={showMonth} data-days="1" data-value="1">Tu</a>
                                    <a href="#" week-name="We" class="month count" week-count="1" onClick={showMonth} data-days="2" data-value="2">We</a>
                                    <a href="#" week-name="Th" class="month count" week-count="1" onClick={showMonth} data-days="3" data-value="3">Th</a>
                                    <a href="#" week-name="Fr" class="month count" week-count="1" onClick={showMonth} data-days="4" data-value="4">Fr</a>
                                    <a href="#" week-name="Sa" class="month count" week-count="1" onClick={showMonth} data-days="5" data-value="5">Sa</a>
                                    <a href="#" week-name="Su" class="month count" week-count="1" onClick={showMonth} data-days="6" data-value="6">Su</a>
                                    <br />
                                    <a href="#" week-name="Mo" class="month count" week-count="2" onClick={showMonth} data-days="7" data-value="0">Mo</a>
                                    <a href="#" week-name="Tu" class="month count" week-count="2" onClick={showMonth} data-days="8" data-value="1">Tu</a>
                                    <a href="#" week-name="We" class="month count" week-count="2" onClick={showMonth} data-days="9" data-value="2">We</a>
                                    <a href="#" week-name="Th" class="month count" week-count="2" onClick={showMonth} data-days="10" data-value="3">Th</a>
                                    <a href="#" week-name="Fr" class="month count" week-count="2" onClick={showMonth} data-days="11" data-value="4">Fr</a>
                                    <a href="#" week-name="Sa" class="month count" week-count="2" onClick={showMonth} data-days="12" data-value="5">Sa</a>
                                    <a href="#" week-name="Su" class="month count" week-count="2" onClick={showMonth} data-days="13" data-value="6">Su</a>
                                    <br />
                                    <a href="#" week-name="Mo" class="month count" week-count="3" onClick={showMonth} data-days="14" data-value="0">Mo</a>
                                    <a href="#" week-name="Tu" class="month count" week-count="3" onClick={showMonth} data-days="15" data-value="1">Tu</a>
                                    <a href="#" week-name="We" class="month count" week-count="3" onClick={showMonth} data-days="16" data-value="2">We</a>
                                    <a href="#" week-name="Th" class="month count" week-count="3" onClick={showMonth} data-days="17" data-value="3">Th</a>
                                    <a href="#" week-name="Fr" class="month count" week-count="3" onClick={showMonth} data-days="18" data-value="4">Fr</a>
                                    <a href="#" week-name="Sa" class="month count" week-count="3" onClick={showMonth} data-days="19" data-value="5">Sa</a>
                                    <a href="#" week-name="Su" class="month count" week-count="3" onClick={showMonth} data-days="20" data-value="6">Su</a>
                                    <br />
                                    <a href="#" week-name="Mo" class="month count" week-count="4" onClick={showMonth} data-days="21" data-value="0">Mo</a>
                                    <a href="#" week-name="Tu" class="month count" week-count="4" onClick={showMonth} data-days="22" data-value="1">Tu</a>
                                    <a href="#" week-name="We" class="month count" week-count="4" onClick={showMonth} data-days="23" data-value="2">We</a>
                                    <a href="#" week-name="Th" class="month count" week-count="4" onClick={showMonth} data-days="24" data-value="3">Th</a>
                                    <a href="#" week-name="Fr" class="month count" week-count="4" onClick={showMonth} data-days="25" data-value="4">Fr</a>
                                    <a href="#" week-name="Sa" class="month count" week-count="4" onClick={showMonth} data-days="26" data-value="5">Sa</a>
                                    <a href="#" week-name="Su" class="month count" week-count="4" onClick={showMonth} data-days="27" data-value="6">Su</a>
                                    <br />
                                    <a href="#" week-name="Mo" class="month count" week-count="5" onClick={showMonth} data-days="28" data-value="0">Mo</a>
                                    <a href="#" week-name="Tu" class="month count" week-count="5" onClick={showMonth} data-days="29" data-value="1">Tu</a>
                                    <a href="#" week-name="We" class="month count" week-count="5" onClick={showMonth} data-days="30" data-value="2">We</a>
                                    <a href="#" week-name="Th" class="month count" week-count="5" onClick={showMonth} data-days="31" data-value="3">Th</a>
                                    <a href="#" week-name="Fr" class="month count" week-count="5" onClick={showMonth} data-days="32" data-value="4">Fr</a>
                                    <a href="#" week-name="Sa" class="month count" week-count="5" onClick={showMonth} data-days="33" data-value="5">Sa</a>
                                    <a href="#" week-name="Su" class="month count" week-count="5" onClick={showMonth} data-days="34" data-value="6">Su</a>
                                    <br />
                                  </div>
                                  <div class="custom-recu" id="custom" style={{ display: 'none' }}>
                                    <h5>Reapet On</h5>
                                    <div class="cust_recurning">
                                      <select class="my_time_week week_selection" name="my_time_week" id="my_time_week1" style={{ display: 'flex' }} ref={repatecountweek}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                      </select>
                                      <select class="my_time_week month_selection" name="my_time_week" id="my_time_week2" style={{ display: 'none' }} ref={repatecountmonth}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                      </select>
                                      <span className="ms-3 w-100">
                                        <select
                                          class="mode_type"
                                          name="mode_type"
                                          id="mode_type"
                                          onChange={(e) => {
                                            setCustomWeeksmonths(e.target.value);
                                          }}
                                        >

                                          <option value="weeks">Please select mode type</option>
                                          <option value="weeks">Weeks</option>
                                          <option value="months">Months</option>
                                        </select>


                                      </span>
                                    </div>
                                    <br />
                                    <a href="#" class="cus_weeks" week-name="Monday" onClick={showCustome} data-value="0">Mo</a>
                                    <a href="#" class="cus_weeks" week-name="Tuesday" onClick={showCustome} data-value="1">Tu</a>
                                    <a href="#" class="cus_weeks" week-name="Wednesday" onClick={showCustome} data-value="2">We</a>
                                    <a href="#" class="cus_weeks" week-name="Thursday" onClick={showCustome} data-value="3">Th</a>
                                    <a href="#" class="cus_weeks" week-name="Friday" onClick={showCustome} data-value="4">Fr</a>
                                    <a href="#" class="cus_weeks" week-name="Saturday" onClick={showCustome} data-value="5">Sa</a>
                                    <a href="#" class="cus_weeks" week-name="Sunday" onClick={showCustome} data-value="6">Su</a>
                                  </div>

                                  <hr />
                                  <div className="form-group">
                                    <div className="summary-text">Summary:
                                      {
                                        summary && summary.map((item, index) => {
                                          return (
                                            //add comma after every item
                                            <span key={index}>{item}{index < summary.length - 1 ? ', ' : ''}</span>
                                          )
                                        })

                                      }
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="modal-content-inner-footer">
                              <button type="button" className="btn btn-primary" onClick={saverecurringData} >Save</button>
                              <button type="button" className="btn btn-secondary ms-3" onClick={closeModal}>Cancel</button>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="extra-service booking-warrper-20">
                          <h3 className="Service-details-box-inner-title">Sub Services</h3>
                          <div className="row">
                            {subService.map((subservice, index) => {
                              return (
                                <div className="col-md-12">
                                  <div className="Service-details-box-inner extra-service mb-3">
                                    <div className="left-side">
                                      <div className="d-flex align-items-center">
                                        <h3 className="Service-details-box-inner-title">
                                          {subservice.units_title}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="right-side">
                                      <div className="d-flex align-items-center">
                                        <div className="extra-service-icon">
                                          <Button
                                            href="#"
                                            className="add-item-btn"
                                            disabled={subservice.quantity == 1 ? true : false}
                                            onClick={(e) => {
                                              changeCounter2("decrement", subservice.id);
                                            }
                                            }
                                          >
                                            -
                                          </Button>
                                        </div>
                                        <div className="extra-service-value">
                                          {subservice.quantity}
                                        </div>
                                        <div className="extra-service-icon">
                                          <Button
                                            href="#"
                                            className="add-item-btn"
                                            disabled={subservice.quantity == 6 ? true : false}
                                            onClick={(e) => {
                                              changeCounter2("increment", subservice.id);
                                            }
                                            }
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="extra-service booking-warrper-20">
                          <h3 className="Service-details-box-inner-title">Addons</h3>
                          <div className="row">
                            {addons.map((value, index) => {
                              return (
                                <div className="col-md-12">
                                  <div className="Service-details-box-inner extra-service mb-3">
                                    <div className="left-side">
                                      <div className="d-flex align-items-center">
                                        <h3 className="Service-details-box-inner-title">
                                          {value.addon_service_name}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="right-side">
                                      <div className="d-flex align-items-center">
                                        <div className="extra-service-icon">
                                          <Button
                                            href="#"
                                            className="add-item-btn"
                                            disabled={value.quantity == 0 ? true : false}
                                            onClick={(e) => {
                                              changeAddon("decrement", value.id);
                                            }
                                            }
                                          >
                                            -
                                          </Button>
                                        </div>
                                        <div className="extra-service-value">
                                          {value.quantity}
                                        </div>
                                        <div className="extra-service-icon">
                                          <Button
                                            href="#"
                                            className="add-item-btn"
                                            disabled={value.quantity == 6 ? true : false}
                                            onClick={(e) => {
                                              changeAddon("increment", value.id);
                                            }
                                            }
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="Service-details-box booking-warrper-20">
                          <div className="col-md-12">
                            <div className="col-md-12 mb-3" style={{ display: "none" }}>
                              <Form.Group
                                className="booking-custom-lable"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Please Select Technician Zone</Form.Label>
                                <Form.Select

                                  aria-label="Default select example"
                                  className="custom-select-box"
                                  onChange={(e) => {
                                    setChoosezone(e.target.value);
                                  }}
                                >
                                  <option>All Zone</option>
                                  {bookingzonelist.map((value, index) => {
                                    return (
                                      <option value={value.zone_name}>{value.zone_name}</option>
                                    );
                                  })}

                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 mb-3">
                              <Form.Label>Please Select Technician</Form.Label>
                              <Form.Group className="booking-custom-lable selectstaff" controlId="formstaffids">
                                <Select
                                  isMulti
                                  defaultValue={staffId}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={activeStaff}
                                  // defaultValue={staffId}
                                  onChange={(e) => {
                                    setChooseStaff(e);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </Accordion>

              <div className="bookingData_2">
                <div className="row" style={{ display: "none" }}>
                  <div className="col-md-12">
                    <Form.Group controlId="validationCustom14" className="">
                      <Form.Label>Zone</Form.Label>
                      <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => { setChoosezone(e.target.value); }}>
                        {bookingzonelist && bookingzonelist.map((zone) => (
                          //if zone.id or existingUser.zone_name not found then it will show other zone name

                          <option value={zone.id} selected={zone.id == existingUser.zone_name ? true : false} >{zone.zone_name}</option>


                        ))}
                      </Form.Select>


                    </Form.Group>
                  </div>
                </div>
                <div className="row booking-warrper-20">
                  <div className="col-md-12">
                    <FloatingLabel
                      controlId="validationCustom14"
                      label="Appointment Notes"
                      className=""
                    >
                      <Form.Control
                        required
                        as="textarea"
                        placeholder="Leave a comment here"
                        value={appointmentNotes}
                        onChange={(e) => {
                          setAppointmentNotes(e.target.value);
                        }}
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div className="estimated-box">
                  <div className="estimated-box-inner">
                    <div className="estimated-title">
                      <h5>Estimated Price</h5>
                    </div>
                    <div className="estimated-top">
                      <div className="estimated d-flex">
                        <div className="icon">
                          <TbHotelService />
                        </div>
                        <div className="title">{servicename || ""}</div>
                      </div>

                      {/* <div className="estimated d-flex" style={recurringtime != null ? { display: "none !important" } : { display: "block !important" }}> */}
                      {/* <div className={`estimated d-flex ${recurringtime != null ? 'd-none' : 'd-block'}`}> */}
                      <div className={`estimated d-flex ${hidetimedisplay ? 'd-block' : 'd-none'}`}>
                        <div className="icon">
                          <TbCalendar />
                        </div>
                        <div className="form-group">
                          <label>Booking Start Date/Time</label>
                          <input type="datetime-local" className="form-control" placeholder="Choose Date" onChange={
                            (e) => {
                              setBookingDate(e.target.value);
                            }
                          } name="bookingDate" value={moment(bookingDate).format("YYYY-MM-DD HH:mm:ss") || ""} />
                        </div>
                        <div className="form ms-2">
                          <label>Booking End Date/Time</label>

                          <p>{moment(moment(bookingDate, "YYYY-MM-DD HH:mm:ss").add(updatedendtime, 'minutes').format("YYYY-MM-DD HH:mm:ss")).format("DD-MM-YYYY hh:mm A")}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="estimated-bottom">
                      <div className="estimated-head">
                        <h5>Estimated Price</h5>
                        {
                          ChooseService === "1" ? (
                            userType === 1 ? (
                              <button className="btn b-btn" onClick={handleAnotherAction}>Edit CRM Details</button>
                            ) : null
                          ) : null
                        }
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Change Billed Amount</Form.Label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              {
                                ChooseService === "1" ? (
                                  !userbilledamount || userbilledamount == 0 ? (
                                    <Form.Control
                                      type="text"
                                      placeholder="Testingg11"
                                      value={Math.round(expectedtotalAmount)}
                                      onChange={(e) => handleAmontChange(e.target.value)}
                                    />
                                  ) : (
                                    <Form.Control
                                      type="text"
                                      placeholder="Testingg22"
                                      disabled
                                      value={Math.round(userbilledamount)}
                                      onChange={(e) => handleAmontChange22(e.target.value)}
                                    />
                                  )
                                ) : (
                                  <Form.Control
                                    type="text"
                                    placeholder="Testingg33"
                                    value={Math.round(expectedtotalAmount)}
                                    onChange={(e) => handleAmontChange(e.target.value)}
                                  />
                                )
                              }

                              {/* <Form.Control
                              type="text"
                              placeholder=""
                              value={Math.round(expectedtotalAmount)}
                              onChange={(e) => {
                                handleAmontChange(e.target.value);
                              }}
                            /> */}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Total Discount</Form.Label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <Form.Control
                                type="text"
                                placeholder="0"
                                // value={Math.round(discount)}
                                value={isNaN(discount) ? '' : Math.round(discount)}
                                onChange={(e) => handleChangeDiscount(e.target.value)}
                              />

                              {/* <Form.Control
                              type="text"
                              placeholder=""
                              value={Math.round(expectedtotalAmount)}
                              onChange={(e) => {
                                handleAmontChange(e.target.value);
                              }}
                            /> */}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Total Discount Billed Amount</Form.Label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              {
                                ChooseService === "1" ? (
                                  !userbilledamount || userbilledamount == 0 ? (
                                    <Form.Control
                                      type="text"
                                      placeholder="Testingg11"
                                      disabled
                                      value={Math.round(discountuser)}
                                      onChange={(e) => handleAmontChange(e.target.value)}
                                    />
                                  ) : (
                                    <Form.Control
                                      type="text"
                                      placeholder="Testingg22"
                                      disabled
                                      value={Math.round(discountuser)}
                                      onChange={(e) => handleAmontChange22(e.target.value)}
                                    />
                                  )
                                ) : (
                                  <Form.Control
                                    type="text"
                                    placeholder="Testingg33"
                                    disabled
                                    value={Math.round(discountuser)}
                                    onChange={(e) => handleAmontChange(e.target.value)}
                                  />
                                )
                              }

                              {/* <Form.Control
                              type="text"
                              placeholder=""
                              value={Math.round(expectedtotalAmount)}
                              onChange={(e) => {
                                handleAmontChange(e.target.value);
                              }}
                            /> */}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Change Billed Hours</Form.Label>
                            {
                              ChooseService === "1" ? (
                                !userbilledhours || userbilledhours == 0 ? (
                                  <InputMask
                                    mask="99:99"
                                    maskChar=""
                                    alwaysShowMask
                                    formatChars={{ '9': '[0-9]', ':': ':' }}
                                    placeholder="userbilledhours"
                                    className="form-control"
                                    value={convertToHHMM(basewithextra)}
                                    onChange={handleInputChange55}
                                  />
                                ) : (
                                  <InputMask
                                    mask="99:99"
                                    maskChar=""
                                    alwaysShowMask
                                    formatChars={{ '9': '[0-9]', ':': ':' }}
                                    placeholder="userbilledhours false"
                                    className="form-control"
                                    disabled
                                    value={convertToHHMM(userbilledhours)}
                                    onChange={handleInputChange555}
                                  />
                                )
                              ) : (
                                <InputMask
                                  mask="99:99"
                                  maskChar=""
                                  alwaysShowMask
                                  formatChars={{ '9': '[0-9]', ':': ':' }}
                                  placeholder="userbilledhours Main"
                                  className="form-control"
                                  value={convertToHHMM(basewithextra)}
                                  onChange={handleInputChange55}
                                />
                              )
                            }
                            {/* // <InputMask
                          //   mask="99:99"
                          //   maskChar=""
                          //   alwaysShowMask
                          //   formatChars={{ '9': '[0-9]', ':': ':' }}
                          //   placeholder="HH:mm"
                          //   className="form-control"
                          //   value={convertToHHMM(basewithextra)}
                          //   onChange={handleInputChange55}
                          // /> */}
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Estimated Billed Amount</Form.Label>: <span className="input-group-text">
                              {
                                discount > 0 ? (
                                  ChooseService === "1" ? (
                                    !userbilledamount || userbilledamount == 0 ? (
                                      <b>{"$" + (totalAmount) + " to $" + Math.round(discountuser)}</b>
                                    ) : (
                                      <b>{"$" + (discountuser) + " to $" + Math.round(discountuser)}</b>
                                    )
                                  ) : (
                                    <b>{"$" + (totalAmount) + " to $" + Math.round(discountuser)}</b>
                                  )
                                ) : (
                                  ChooseService === "1" ? (
                                    !userbilledamount || userbilledamount == 0 ? (
                                      <b>{"$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount)}</b>
                                    ) : (
                                      <b>{"$" + (userbilledamount) + " to $" + Math.round(userbilledamount)}</b>
                                    )
                                  ) : (
                                    <b>{"$" + (totalAmount) + " to $" + Math.round(expectedtotalAmount)}</b>
                                  )
                                )
                              }
                            </span>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Estimated Billed Hours===</Form.Label> : <span className="input-group-text">
                              {
                                ChooseService === "1" ? (
                                  !userbilledhours || userbilledhours == 0 ? (
                                    <b>{convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra)}</b>
                                  ) : (
                                    <b>{convertToHHMM(userbilledhours) + " to " + convertToHHMM(userbilledhours)}</b>
                                  )
                                ) : (
                                  <b>{convertToHHMM(baseTime) + " to " + convertToHHMM(basewithextra)}</b>
                                )
                              }
                            </span>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                {
                  ChooseService === "1" ? (
                    !userbilledhours || userbilledhours === 0 ? (
                      <div className="estimated-head"><h5>Price Per hour :{"$" + getpriceperhours(Math.round(expectedtotalAmount), basewithextra)}</h5></div>
                    ) : (
                      <div className="estimated-head"><h5>Price Per hour :{"$" + getpriceperhours(Math.round(userbilledamount), userbilledhours)}</h5></div>
                    )
                  ) : (
                    <div className="estimated-head"><h5>Price Per hour :{"$" + getpriceperhours(Math.round(expectedtotalAmount), basewithextra)}</h5></div>
                  )
                }

                <div>
                  <Button className="Cancel-bttn btn" onClick={handleClose}>
                    Close
                  </Button>
                  {/* <Button variant="primary" onClick={AddBookingData}> */}
                  {
                    loadingspinner ? (
                      <Button className="Active-bttn btn" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <Button className="Active-bttn btn" type="submit" >
                        Booking
                      </Button>
                    )
                  }
                  {
                    LoadingSpinnerquote ? (
                      <Button className="Active-bttn btn" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <Button className="Active-bttn btn" onClick={savequotetolillypad}>
                        Save quote to lillypad
                      </Button>
                    )
                  }

                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {minimized && (
          <Draggable>
            <div className="minimize-btn">
              <button className="minimize-btn-inner" onClick={handleMaximize}>
                <CgMaximizeAlt />
                {/* <span>Modal Minimized</span> */}
              </button>
            </div>
          </Draggable>
        )}
      </div>
      {/* add appointment modal end */}

      {/* edit appointment modal start */}
      <Modal show={showBookingDetails} onHide={handleClose2} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="btn-header-list">
              <button style={{ display: "none" }} className="btn g-btn" onClick={
                (e) => {
                  getsubserviceaddons();
                  getinvoice();
                  setInvoiceprice(bookingDetails.price);
                }}>
                Fattmerchant Payment
              </button>
              {
                bookingDetails.booking_status != "CO" &&
                (
                  <button className="btn b-btn" onClick={getEditbooking}>
                    Edit Booking Detail
                  </button>
                )
              }

            </div>
            <div className="booking-details-deta">
              <div className="booking-details-deta-inner">
                <div className="booking-status">
                  <div className="w-40">Booking Status:</div>
                  <div className="booking-status-view">
                    <p>{getStatusofbookings(bookingDetails.booking_status)}{getStatus(bookingDetails.book_by)}</p>
                    <div className="time-list d-flex">

                      <div className="date-list-inner">
                        <TbCalendar />{" "}
                        {getDateFormate(bookingDetails.appointment_starttime)}
                      </div>
                      <div className="time-list-inner">
                        <TbClock /> {bookingDetails.appointment_start_time}
                      </div>
                    </div>
                    <div className="time-list d-flex">
                      <div className="date-list-inner">
                        <TbCalendar />{" "}
                        {getDateFormate(bookingDetails.appointment_starttime)}
                      </div>
                      <div className="time-list-inner">
                        {/* <TbClock /> {bookingEndTime(bookingDetails.appointment_start_time, bookingDetails.booking_duration)} */}
                        <TbClock /> {getdividetime(bookingDetails.appointment_start_time, bookingDetails.booking_duration, bookingDetails.staff_ids, bookingDetails.order_date)}
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="ct-cal-booking-details">
                  <li className="ct-cal-booking-details-item">
                    <label>Service</label>
                    <span>{bookingDetails.service_title}</span>
                  </li>
                  {/* <li className="ct-cal-booking-details-item">
                  <label>Methods</label>
                  <span>: Home Size</span>
                </li> */}
                  <li className="ct-cal-booking-details-item">
                    <label>Units</label>
                    <span>{bookingDetails.unit_title} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Addons</label>
                    <span>: {bookingDetails.addons_title ? bookingDetails.addons_title : "No Addons"}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Appointment notes</label>
                    <span>
                      {bookingDetails.notes === " "
                        ? " "
                        : getNotes(bookingDetails.notes)}{" "}
                    </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Preferences</label>
                    <span>: {bookingDetails.client_preferences}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Billing Notes</label>
                    <span>: {bookingDetails && bookingDetails.booking_notes ? bookingDetails.booking_notes : " "}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Billed Amount</label>
                    <span>{bookingDetails.billed_amt}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Billed Hours</label>
                    <span>: {bookingDetails.booking_duration} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Booking Price</label>
                    <span>: {bookingDetails.booking_price}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Booking Discount Price</label>
                    <span>: {"$" + getCalculateddiscount(bookingDetails.booking_price_test, bookingDetails.booking_discount)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Booking Discount</label>
                    <span>:{"$" + bookingDetails.booking_discount}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Estimated Billed Amount</label>
                    <span>: {bookingDetails.estimated_price} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Estimated Billed Hours</label>
                    <span>: {bookingDetails.estimated_duration} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Scheduled Duration</label>
                    <span>: {getscheduledtime(bookingDetails.booking_duration, bookingDetails.staff_ids, bookingDetails.order_date)} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Team Cleaning Time</label>
                    <span>: {bookingDetails.cleaning_time} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Technician Time</label>
                    <span>: {gettechnicialtime(bookingDetails.booking_timesheet_cleaning_time)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Timesheet Notes</label>
                    <span>: {gettechtimesheetnotes(bookingDetails.booking_time_note)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>GPS arrival time</label>
                    <span>: {bookingDetails.arrival_time}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>GPS departure time</label>
                    <span>: {bookingDetails.departure_time}</span>
                  </li>
                  <li className="ct-cal-booking-details-item" >
                    <label></label>
                    <Link to={`/timesheet?oid=${bookingDetails.order_id}`} className="ct-cal-booking-details-notes" target="_blank">
                      <button className="btn b-btn">Edit Timesheet Detail</button>
                    </Link>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <h6 class="ct-customer-details-hr">Customer</h6>
                      <button
                        className="btn b-btn"
                        onClick={() => {
                          // getUserdata();
                          callChildFunction(bookingDetails.client_id);
                        }}
                      >
                        Edit Customer Detail
                      </button>
                    </div>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Name</label>
                    <span>{bookingDetails.client_name}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Email</label>
                    <span>{bookingDetails.client_email} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Phone</label>
                    <span>{bookingDetails.client_phone}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Address</label>
                    <span>: {bookingDetails.client_address}</span>
                  </li>
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <button
                      className="btn view-more-content less-btn"
                      onClick={removeClass}
                    >
                      See Less
                    </button>
                  </li>
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <button
                      className="btn view-more-content more-btn"
                      onClick={addClass}
                    >
                      See More
                    </button>
                  </li>
                  {/* <div className="view-more-content-hide"> */}
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <label>Payment</label>
                    <span>: Pay Locally</span>
                  </li>
                  <li className="ct-cal-booking-details-item" style={{ display: "none" }}>
                    <label>Notes</label>
                    <span>: {bookingDetails.client_notes} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Family Info</label>
                    <span>: {bookingDetails.client_family_info} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Parking Access</label>
                    <span>: {bookingDetails.client_parking_access} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Client notes from tech</label>
                    <span>: {bookingDetails.client_technician_notes} </span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Special Instructions from Client</label>
                    <span>: {bookingDetails.client_special_instructions}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Special Instructions from Admin</label>
                    <span>: {bookingDetails.admin_special_instructions}</span>
                  </li>
                  {/* </div> */}
                  <hr />
                  <li className="ct-cal-booking-details-item">
                    <label>Assiged Staff</label>
                    <span>{getassigendstaff(bookingDetails.staff)}</span>
                  </li>
                  <li className="ct-cal-booking-details-item">
                    <label>Preferred Technician</label>
                    <span>: {bookingDetails.client_preferrend_technician == null && bookingDetails.client_preferrend_technician == "null" ? "" : bookingDetails.client_preferrend_technician}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group1">
            {bookingDetails.customer_id !== 0 && bookingDetails.customer_id !== null && bookingDetails.customer_id !== "" ? (
              <Button className="pay-Active-bttn" onClick={(e) => {
                getsubserviceaddons();
                getinvoice();
                setInvoiceprice(bookingDetails.price);
                setInvoicenotes(bookingDetails.notes)
                setCleaningTime(bookingDetails.cleaning_time);
                setBookingtimesheetcleaningtime(bookingDetails.booking_timesheet_cleaning_time);
                setBookingtimenote(bookingDetails.booking_time_note);
              }}>
                <FaMoneyBillAlt /> Bill client
              </Button>
            ) : (
              <Button className="pay-Active-bttn" onClick={createStaxId}>
                <FaMoneyBillAlt /> Create Stax Id
              </Button>
            )}
            {bookingDetails.booking_status === "CO" && bookingDetails.booking_status !== "" && (
              <>
                <Button className="Delete-Active-bttn" onClick={uncomplatebooking}>
                  <FaTimesCircle /> Uncomplete
                </Button>
              </>
            )}
            {bookingDetails.booking_status === "UC" && bookingDetails.booking_status !== "" && (
              <> 
                { 
                  invoiceList && invoiceList.length > 0 ? (
                    <Button className="confirm-Active-bttn" onClick={complatebooking}>
                      <FaCheckCircle /> Complete
                    </Button>) : (
                    <Button className="confirm-Active-bttn" disabled>
                      <FaCheckCircle /> Complete
                    </Button>
                  )
                }

                <Button className="unconfirm-Active-bttn" onClick={unconfirmbooking}>
                  <FaRegTimesCircle /> Unconfirm
                </Button>

                <Button className="Delete-Active-bttn" onClick={deletebooking}>
                  <RiDeleteBin6Line /> Delete
                </Button>
              </>
            )}
            {
              bookingDetails.booking_status === "C" && bookingDetails.booking_status !== "" ? (

                <>
                  {

                    invoiceList && invoiceList.length > 0 ? (
                      <Button className="confirm-Active-bttn" onClick={complatebooking}>
                        <FaCheckCircle /> Complete
                      </Button>) : (
                      <Button className="confirm-Active-bttn" disabled>
                        <FaCheckCircle /> Complete
                      </Button>
                    )
                  }


                  {/* {bookingDetails.customer_id !== 0 && bookingDetails.customer_id !== null && bookingDetails.customer_id !== "" ? (
                    <Button className="pay-Active-bttn" onClick={(e) => {
                      getsubserviceaddons();
                      getinvoice();
                      setInvoiceprice(bookingDetails.price);
                      setInvoicenotes(bookingDetails.notes)
                      setCleaningTime(bookingDetails.cleaning_time);
                      setBookingtimesheetcleaningtime(bookingDetails.booking_timesheet_cleaning_time);
                      setBookingtimenote(bookingDetails.booking_time_note);
                    }}>
                      <FaMoneyBillAlt /> Pay Now
                    </Button>
                  ) : (
                    <Button className="pay-Active-bttn" onClick={createStaxId}>
                      <FaMoneyBillAlt /> Create Stax Id
                    </Button>
                  )} */}

                  <Button className="unconfirm-Active-bttn" onClick={unconfirmbooking}>
                    <FaRegTimesCircle /> Unconfirm
                  </Button>

                  <Button className="Delete-Active-bttn" onClick={deletebooking}>
                    <RiDeleteBin6Line /> Delete
                  </Button>
                </>
              ) : (
                ""
              )
            }
            {
              bookingDetails.booking_status === "A" && bookingDetails.booking_status !== "" && (
                <>

                  <Button className="confirm-Active-bttn" onClick={confirmbooking}>
                    <FaCheck /> Confirm
                  </Button>
                  <Button className="reschedule-Active-bttn" onClick={rescheduleData} style={{ display: "none" }}>
                    <FaRegEdit /> Reschedule
                  </Button>

                  <Button className="reject-Active-bttn" onClick={rejectbooking} style={{ display: "none" }}>
                    <FaThumbsDown /> Reject
                  </Button>

                  <div className="delete-btn">
                    <Button className="Delete-Active-bttn" onClick={(e) => { setAddClasss(true) }}>
                      <RiDeleteBin6Line /> Delete
                    </Button>
                    <div className={"confirm-delete-modal" + (addClasss ? " show" : "")}>
                      <h3 class="popover-title">Delete this appointment?</h3>
                      <div className="popover-content">
                        <div className="btn-list">
                          <div className="btn-delete">
                            <Button className="confirm-delete" onClick={deletebooking}>
                              {
                                deleteloader ? (
                                  <Spinner animation="border" className="border-black" />
                                ) : (
                                  "Delete"
                                )
                              }
                            </Button>
                          </div>
                          <div className="btn-cancel">
                            <Button className="cancel-delete" onClick={(e) => { setAddClasss(false) }}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            {
              bookingDetails.booking_status === "Q" && bookingDetails.booking_status !== "" && (
                <>
                  <Button className="confirm-Active-bttn" onClick={activequotebooking}>
                    <FaCheck /> Add to booking
                  </Button>

                  <div className="delete-btn">
                    <Button className="Delete-Active-bttn" onClick={(e) => { setAddClasss(true) }}>
                      <RiDeleteBin6Line /> Delete
                    </Button>
                    <div className={"confirm-delete-modal" + (addClasss ? " show" : "")}>
                      <h3 class="popover-title">Delete this appointment?</h3>
                      <div className="popover-content">
                        <div className="btn-list">
                          <div className="btn-delete">
                            <Button className="confirm-delete" onClick={deletebooking}>
                              {
                                deleteloader ? (
                                  <Spinner animation="border" className="border-black" />
                                ) : (
                                  "Delete"
                                )
                              }
                            </Button>
                          </div>
                          <div className="btn-cancel">
                            <Button className="cancel-delete" onClick={(e) => { setAddClasss(false) }}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            {bookingDetails.booking_status === "R" && bookingDetails.booking_status !== "" && (
              <>
                <Button className="confirm-Active-bttn" onClick={activebooking}>
                  <FaToggleOn /> Active
                </Button>
              </>
            )}
            {bookingDetails.recurring_id !== 0 && bookingDetails.recurring_id !== null && bookingDetails.recurring_id !== ""
              ? (
                <Button className="Delete-Active-bttn" onClick={deletereccuringbooking}> <RiDeleteBin6Line /> Delete all recurring </Button>
              ) : (
                // <Button className="Delete-Active-bttn" onClick={deletereccuringbooking} disabled> <RiDeleteBin6Line /> Delete all recurring </Button>
                ""
              )}



          </div>
        </Modal.Footer>
      </Modal>
      {/* edit appointment modal end */}

      <Modal show={show3} onHide={handleClose3} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Edit Booking Details1</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit-booking-form">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Service : {editbooking.service_name}</Form.Label>
                </Form.Group>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Method : Home Size</Form.Label>
                </Form.Group>
              </div>
            </div>
            <div className="row" style={{ display: "none" }}>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Billed Amount : </Form.Label>
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <input
                      type="number"
                      placeholder="0"
                      className="form-control"
                      disabled
                      value={editbooking.billed_amount || totalAmount || 0}
                      name="billed_amount"
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Price : </Form.Label>
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      className="form-control"
                      value={editbooking.billed_amount}
                      name="billed_amount"
                      onChange={handleEditInputChange3}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row" style={{ display: "none" }}>
              <div className="col-md-12">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Duration : </Form.Label>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <BsStopwatch />
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="10"
                    disabled
                    value={editbooking.hours}
                  />
                  <span className="input-group-text">Hours</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="30"
                    disabled
                    value={editbooking.mints}
                  />
                  <span className="input-group-text">Minutes</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="Service-details-box-inner-title">
                  <h5>Sub Services</h5>
                </div>
              </div>
              {EditSubServices.map((subservice, index) => {
                return (
                  <div className="col-md-12">
                    <div className="Service-details-box-inner extra-service mb-3">
                      <div className="left-side">
                        <div className="d-flex align-items-center">
                          <h3 className="Service-details-box-inner-title">
                            {subservice.units_title}
                          </h3>
                        </div>
                      </div>
                      <div className="right-side">
                        <div className="d-flex align-items-center">
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={subservice.quantity == 1 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeCounter3("decrement", subservice.id);
                              }
                              }
                            >
                              -
                            </Button>
                          </div>
                          <div className="extra-service-value">
                            {subservice.quantity}
                          </div>
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={subservice.quantity == 6 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeCounter3("increment", subservice.id);
                                // calculatePrice(e);
                                //setChooseSubService(subservice.id);
                              }
                              }
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="Service-details-box-inner-title">
                  <h5>Addons</h5>
                </div>
              </div>
              {editaddons.map((value, index) => {
                return (
                  <div className="col-md-12">
                    <div className="Service-details-box-inner extra-service mb-3">
                      <div className="left-side">
                        <div className="d-flex align-items-center">
                          <h3 className="Service-details-box-inner-title">
                            {value.addon_service_name}
                          </h3>
                        </div>
                      </div>
                      <div className="right-side">
                        <div className="d-flex align-items-center">
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={value.addons_service_qty == 0 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeeditaddons("decrement", value.id);
                              }
                              }
                            >
                              -
                            </Button>
                          </div>
                          <div className="extra-service-value">
                            {value.addons_service_qty}
                          </div>
                          <div className="extra-service-icon">
                            <Button
                              href="#"
                              className="add-item-btn"
                              disabled={value.addons_service_qty == 6 ? true : false}
                              onClick={(e) => {
                                setEditstart(true);
                                changeeditaddons("increment", value.id);
                              }
                              }
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="estimated-bottom">
                <div className="estimated-head">
                  <h5>Estimated Price && Duration</h5>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Change Billed Amount</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={Math.round(editbookingestimatedPrice)}
                          onChange={(e) => {
                            handlechageeditamount(e.target.value);
                          }
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Change Discount</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={Math.round(editbookingdiscount)}
                          onChange={(e) => {
                            handlechageeditdiscountamount(e.target.value);
                          }
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Discount Amount</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          placeholder=""
                          disabled
                          value={Math.round(editbookingchangesamount)}
                          onChange={(e) => {
                            handlechageeditamount(e.target.value);
                          }
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Change Billed Hours</Form.Label>
                      <InputMask
                        mask="99:99"
                        maskChar=""
                        alwaysShowMask
                        formatChars={{ '9': '[0-9]', ':': ':' }}
                        placeholder="HH:mm"
                        className="form-control"
                        value={convertToHHMM(editbookingestimatedDuration)}
                        onChange={
                          (e) => {
                            handlechageedittime(e);
                          }
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Estimated Billed Amount...</Form.Label>

                      {
                        editbooking.booking_discount > 0 ? (
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={"$" + Math.round(edittotalAmount) + " to $" + Math.round(editbookingchangesamount) || ""}
                            disabled
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={"$" + Math.round(edittotalAmount) + " to $" + Math.round(editbookingestimatedPrice) || ""}
                            disabled
                          />
                        )
                      }



                      {/* // <Form.Control
                          //   type="text"
                          //   placeholder=""
                          //   value={("$" + edittotalAmount + " to " + "$" + Math.round(editbookingestimatedPrice)) || ""}
                          //   disabled
                          // /> */}


                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Estimated Billed Hours==</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        disabled
                        value={(convertToHHMM(editbaseTime) + " to " + convertToHHMM(editbookingestimatedDuration)) || ""}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Client Name: </Form.Label>
                  <Form.Control type="text" placeholder="Enter Client Name" value={editbooking.client_name} name="client_name" onChange={handleEditInputChange3} />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Client Email: </Form.Label>
                  <Form.Control type="email" placeholder="Enter Client Email" value={editbooking.client_email} name="client_email" onChange={handleEditInputChange3} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Client Address: </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    aria-label="With textarea"
                    value={editbooking.client_address}
                    name="client_address"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment City: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Appointment City"
                    value={editbooking.appointment_city}
                    name="appointment_city"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment State: </Form.Label>
                  {/* <Form.Control
                  type="text"
                  placeholder="Enter Appointment State"
                  value={editbooking.appointment_state}
                  name="appointment_state"
                  onChange={handleEditInputChange3}
                /> */}

                  <Form.Select aria-label="Default select example" className="custom-select-box" onChange={
                    (e) => { setEditbookingstate(e.target.value) }
                  } name="state">
                    {states.map((state) => (
                      <option value={state.stateCode} selected={editbooking.appointment_state == state.stateCode}>{state.name}</option>
                    ))}

                  </Form.Select>

                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment Zip: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Appointment Zip"
                    value={editbooking.appointment_zip}
                    name="appointment_zip"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Assign Appointment to Staff</Form.Label>
                  {
                    selectedOptions && (
                      <Select
                        isMulti
                        defaultValue={selectedOptions}
                        options={staffListedit}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          setAssignStaff(e)
                        }}
                      />

                    )
                  }


                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Appointment Notes: </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    aria-label="With textarea"
                    value={editbooking.appointment_note}
                    name="appointment_note"
                    onChange={handleEditInputChange3}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Start Date: </Form.Label>
                  <Form.Control type="date" value={EditbookingStartDate || ""} onChange={
                    (e) => {
                      setEditbookingStartDate(e.target.value)
                    }
                  } />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Start Time: </Form.Label>
                  <Form.Control type="time" value={EditbookingStartTime || ""} onChange={
                    // (e) => {
                    //   setEditbookingStartTime(e.target.value)
                    // }
                    handleStartTimeChange
                  } />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>End Date: </Form.Label>
                  <Form.Control type="date" value={EditbookingStartDate || ""} onChange={
                    (e) => {
                      setEditbookingStartDate(e.target.value)
                    }
                  } />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>End Time: </Form.Label>
                  <Form.Control type="time" value={
                    // moment(editbooking.start_time, "HH:mm:ss").add(expedtedendtime, "minutes").format("HH:mm:ss")
                    // getCalculatedEndTime(editbooking.start_time, expedtedendtime)
                    editbookingtimeEnd
                  } />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Lat: </Form.Label>
                  <Form.Control type="text" value={bookingLat || ""} onChange={
                    (e) => {
                      setBookingLat(e.target.value)
                    }
                  } />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Long: </Form.Label>
                  <Form.Control type="text" value={bookingLong || ""} onChange={
                    (e) => {
                      setBookingLong(e.target.value)
                    }
                  } />
                </Form.Group>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="primary" onClick={UpdateBookigData}>
            Update
          </Button>
          {editbooking.recurring_id && editbooking.recurring_id != 0 && editbooking.recurring_id !== null && editbooking.recurring_id !== "" ? (
            <Button className="primary btn-big-width" onClick={updaterecurringbooking}>Update all recurring</Button>
          ) : ''}
        </Modal.Footer>
      </Modal>

      {/* reschedule appointment modal start  */}
      <Modal show={show5} onHide={handleClose5} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>Reschedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={rescheduleDetails.amount} placeholder="$128" disabled />
            </Form.Group>
            <div className="mb-3">
              <Select
                isMulti
                defaultValue={selectstaffs}
                options={staffList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setReschedulestaff(e)
                }}
              />
            </div>


            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date" value={rescheduleDate} name="rescheduleDate" onChange={(e) => {
                setRescheduleDate(e.target.value)
              }} />
            </Form.Group>

            <FloatingLabel
              controlId="floatingTextarea"
              label="Note"
              className="mb-3"
            >
              <Form.Control as="textarea" placeholder="Leave a comment here" value={reschedunotes} onChange={
                (e) => {
                  setReschedulenotes(e.target.value)
                }
              } name="app_notes" />
            </FloatingLabel>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="primary" onClick={savereschedule}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* reschedule appointment modal end  */}

      {/* Edit Customer Details  */}
      <Modal show={showCustomer} onHide={handleCloseCustomer} className="Sidebar-Modal-end">
        <Modal.Header closeButton>
          <Modal.Title>
            {edituser &&
              edituser.first_name +
              " " +
              edituser.last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custum-modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="user-profile-details">
                <div className="user-profile-details-inner">
                  <div className="user-profile-img">
                    {profileImagePreview && (
                      <img
                        src={SITE_URL + "uploads/photo/" + profileImagePreview}
                        alt="Profile Preview"
                        className="profile-img"
                      />
                    )}
                  </div>
                  <div className="user-profile-name">
                    <h4>
                      {edituser &&
                        edituser.first_name + " " + edituser.last_name}
                    </h4>
                    <p>
                      {edituser && edituser.user_email}{" "}
                      <button className="copy-btn">
                        <FiCopy />
                      </button>
                    </p>
                    <p>
                      {edituser.phone}{" "}
                      <button className="copy-btn">
                        <FiCopy />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row custom-pt-20">
            <div className="col-md-12">
              <Accordion defaultActiveKey="0">
                <form id="viewuser">
                  <Accordion.Item
                    eventKey="0"
                    className="custom-accordion-item"
                  >
                    <Accordion.Header className="accordion-card-header">
                      About This Customer
                    </Accordion.Header>
                    <Accordion.Body className="accordion-card-body">
                      <div className="row custom-pt-20">
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.first_name || ""}
                                name="first_name"
                                onChange={handleEditInputChange2}
                                placeholder="First Name"
                                required={true}
                              />
                              <input
                                type="hidden"
                                className="form-control"
                                value={edituser.id || ""}
                                name="id"
                                onChange={handleEditInputChange2}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.last_name || ""}
                                onChange={handleEditInputChange2}
                                name="last_name"
                                placeholder="Last Name"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.phone || ""}
                                onChange={handleEditInputChange2}
                                name="phone"
                                placeholder="Phone Number"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                value={edituser.user_email || ""}
                                onChange={handleEditInputChange2}
                                name="user_email"
                                placeholder="Email Address"
                                readOnly={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Password</Form.Label>
                            <input
                              type="password"
                              className="form-control"
                              value={edituser.user_pwd || ""}
                              onChange={handleEditInputChange2}
                              name="user_pwd"
                              placeholder="Password"
                              readOnly={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Customer Stage</Form.Label>
                            {/* <input
                            type="text"
                            className="form-control"
                            value={edituser.customer_stage || ""}
                            onChange={handleEditInputChange2}
                            name="customer_stage"
                            placeholder=""
                          /> */}

                            <Form.Select aria-label="Default select example" value={edituser.customer_stage || ""} onChange={handleEditInputChange2} name="customer_stage">
                              <option value="">Select Customer Stage</option>
                              <option value="New Lead">New Lead</option>
                              <option value="2nd Lead Contact Attempt">
                                2nd Lead Contact Attempt
                              </option>
                              <option value="3nd Lead Contact Attempt">
                                3nd Lead Contact Attempt
                              </option>
                              <option value="Uncontacted Lead 1st Month">
                                Uncontacted Lead 1st Month
                              </option>
                              <option value="Quoted Not Booked">
                                Quoted Not Booked
                              </option>
                              <option value="Not Quoted Not Booked">
                                Not Quoted Not Booked
                              </option>
                              <option value="Unconverted Lead Long Term">
                                Unconverted Lead Long Term
                              </option>
                              <option value="No Availability Wait List">
                                No Availability Wait List
                              </option>
                              <option value="Booked 1st">Booked 1st</option>
                              <option value="Booked 2nd">Booked 2nd</option>
                              <option value="Booked 3rd">Booked 3rd</option>
                              <option value="Maturing">Maturing</option>
                              <option value="Fanatic">Fanatic</option>
                              <option value="Irregular">Irregular</option>
                              <option value="Innactive">Innactive</option>

                            </Form.Select>


                          </Form.Group>
                        </div>
                      </div>

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="1"
                    className="custom-accordion-item mt-2"
                  >
                    <Accordion.Header>Billing Details</Accordion.Header>
                    <Accordion.Body>
                      <div className="row custom-pt-20">
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Billing Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.address || ""}
                                onChange={handleEditInputChange2}
                                name="address"
                                required={true}
                                placeholder="Self Street"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Billing Zip Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="zip"
                                value={edituser.zip || ""}
                                onChange={handleEditInputChange2}
                                placeholder="910257"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Billing City</Form.Label>
                            <input
                              type="text"
                              className="form-control"
                              value={edituser.city || ""}
                              onChange={handleEditInputChange2}
                              name="shipping_city"
                              placeholder="City"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Billing State</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" value={edituser.state || ""} onChange={handleEditInputChange2} name="state">
                              {states.map((state) => (
                                <option value={state.stateCode} selected={edituser.state == state.stateCode}>{state.name}</option>
                              ))}

                            </Form.Select>

                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Default Shipping Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.shipping_address || edituser.address}
                                onChange={handleEditInputChange2}
                                name="shipping_address"
                                placeholder="Self Street"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Shipping Zip Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.shipping_zip || edituser.zip}
                                onChange={handleEditInputChange2}
                                name="shipping_zip"
                                placeholder="910257"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Shipping City</Form.Label>
                            <input
                              type="text"
                              className="form-control"
                              value={edituser.shipping_city || edituser.city}
                              onChange={handleEditInputChange2}
                              name="shipping_city"
                              placeholder="City"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="booking-custom-lable">
                              Shipping State
                            </Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" value={edituser.shipping_state || edituser.state} onChange={handleEditInputChange2} name="shipping_state">
                              {states.map((state) => (
                                <option value={state.stateCode} selected={edituser.state == state.stateCode}>{state.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="2"
                    className="custom-accordion-item mt-2"
                  >
                    <Accordion.Header>Booking Data</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Default Payment Method</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="custom-select-box"
                            >
                              <option value="1">Fattmerchant</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Billed Amount
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                value={edituser.billed_amount || ""}
                                onChange={handleEditInputChange2}
                                name="billed_amount"
                                placeholder="500"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{
                        display: "none"
                      }}>
                        <div className="col-md-6">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Bathrooms</Form.Label>
                            <input
                              type="text"
                              className="form-control "
                              value={edituser.bathrooms || ""}
                              onChange={handleEditInputChange2}
                              name="bathrooms"
                              placeholder="500"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Bedrooms</Form.Label>
                            <input
                              type="text"
                              className="form-control "
                              value={edituser.bedrooms || ""}
                              onChange={handleEditInputChange2}
                              name="bedrooms"
                              placeholder="500"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="booking-custom-lable"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Zone</Form.Label>
                            {/* <input
                            type="text"
                            className="form-control "
                            value={edituser.zip || ""}
                            onChange={handleEditInputChange2}
                            name="zone"
                            placeholder="500"
                          /> */}
                            <Form.Select aria-label="Default select example" className="custom-select-box" onChange={handleEditInputChange2} name="zone"
                            >
                              {/* <option>Select Zone</option>
                            {

                              //if no zone match then show  other zone
                              bookingzonelist && bookingzonelist.map((zone) => 
                              //check here if edituser.zone_name is null then show other zone 

                              (
                                <option value={zone.id} selected={edituser.zone_name == zone.zone_name}>{zone.zone_name}</option>
                              )

                              )

                            } */}

                              <option>Select Zone</option>
                              {bookingzonelist &&
                                bookingzonelist.map((zone) => (
                                  <option
                                    key={zone.id}
                                    value={zone.id}
                                    selected={edituser.zone_name === zone.zone_name}
                                  >
                                    {zone.zone_name}
                                  </option>
                                ))}
                              {/* If edituser.zone_name is null or doesn't match any zone, display "Other Zone" */}
                              {edituser.zone_name === null && <option value="other" selected>Other Zone</option>}

                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Billed Hours
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.billed_hours || ""}
                                onChange={handleEditInputChange2}
                                name="billed_hours"
                                placeholder="500"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12" style={{ display: "none" }}>
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Key Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="key_number"
                                value={edituser.key_number || ""}
                                onChange={handleEditInputChange2}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Preferences
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="preferences"
                                value={edituser.preferences || ""}
                                onChange={handleEditInputChange2}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Family Info
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.family_info || ""}
                                onChange={handleEditInputChange2}
                                name="family_info"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Parking Access
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="parking_access"
                                value={edituser.parking_access || ""}
                                onChange={handleEditInputChange2}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Preferred Technician
                              </label>
                              {/* <input
                              type="text"
                              className="form-control"
                              name="preferrend_technician"
                              value={edituser.preferrend_technician || ""}
                              onChange={handleEditInputChange2}
                              placeholder=""
                            /> */}
                              <select className="form-control" name="preferred_technician" onChange={
                                (event) => {
                                  setEdituser({ ...edituser, preferrend_technician: event.target.value })
                                }
                              }>
                                <option value="">Select Technician</option>
                                {preferredStaff && preferredStaff.map((data) => {
                                  return (
                                    <option value={data.fullname} selected={edituser.preferrend_technician == data.fullname}>{data.fullname}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Client notes from tech
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={edituser.technician_notes || ""}
                                name="technician_notes"
                                onChange={handleEditInputChange2}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Special instructions from the client
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="special_instructions"
                                value={edituser.special_instructions || ""}
                                onChange={handleEditInputChange2}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Special instructions from the admin
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  edituser.special_instructions_admin || ""
                                }
                                onChange={handleEditInputChange2}
                                name="special_instructions_admin"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="3"
                    className="custom-accordion-item mt-3"
                  >
                    <Accordion.Header>Service Preference</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="extra-service booking-warrper-20">
                          <div className="row">
                            {servicepreferences && servicepreferences.map((data) => {
                              return (
                                <>
                                  <div className="col-md-12">
                                    <h3 className="Service-details-box-inner-title">
                                      Service: {data.title}
                                    </h3>
                                    <div className="row mb-3 bg-light-color">
                                      <h3 className="Service-details-box-inner-title2">
                                        SubService
                                      </h3>
                                      {data.subservices.map((subservicess, innerIndex) => {
                                        //const innerObject = Object.values(subservicess);
                                        return (
                                          <div className="col-md-12" key={innerIndex}>
                                            <div className="Service-details-box-inner extra-service mb-0" key={innerIndex}>
                                              <div className="left-side">
                                                <div className="d-flex align-items-center">
                                                  <h3 className="Service-details-box-inner-title2">{subservicess.units_title}</h3>
                                                </div>
                                              </div>
                                              <div className="right-side">
                                                <div className="d-flex align-items-center">
                                                  <div className="extra-service-icon">
                                                    <Button
                                                      href="#"
                                                      className="add-item-btn"
                                                      disabled={subservicess.quantity == 1}
                                                      onClick={(e) => {
                                                        // Assuming changeCounter2 is a function that handles quantity changes
                                                        changeCounter222("decrement", subservicess.id, data.id);
                                                      }}
                                                    >
                                                      -
                                                    </Button>
                                                  </div>
                                                  <div className="extra-service-value">{subservicess.quantity}</div>
                                                  <div className="extra-service-icon">
                                                    <Button
                                                      href="#"
                                                      className="add-item-btn"
                                                      disabled={subservicess.quantity == 6}
                                                      onClick={(e) => {
                                                        // Assuming changeCounter2 is a function that handles quantity changes
                                                        changeCounter222("increment", subservicess.id, data.id);
                                                      }}
                                                    >
                                                      +
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div className="row mb-3 bg-light-color">
                                      <h3 className="Service-details-box-inner-title2">
                                        Addons Service
                                      </h3>
                                      {data.addons.map((value) => {
                                        return (
                                          <div className="col-md-12">
                                            <div className="Service-details-box-inner extra-service mb-0">
                                              <div className="left-side">
                                                <div className="d-flex align-items-center">
                                                  <h3 className="Service-details-box-inner-title2">
                                                    {value.addon_service_name}
                                                  </h3>
                                                </div>
                                              </div>
                                              <div className="right-side">
                                                <div className="d-flex align-items-center">
                                                  <div className="extra-service-icon">
                                                    <Button
                                                      href="#"
                                                      className="add-item-btn"
                                                      disabled={value.quantity == 0 ? true : false}
                                                      onClick={(e) => {
                                                        changeAddon222("decrement", value.id, data.id);
                                                      }
                                                      }
                                                    >
                                                      -
                                                    </Button>
                                                  </div>
                                                  <div className="extra-service-value">
                                                    {value.quantity}
                                                  </div>
                                                  <div className="extra-service-icon">
                                                    <Button
                                                      href="#"
                                                      className="add-item-btn"
                                                      disabled={value.quantity == 6 ? true : false}
                                                      onClick={(e) => {
                                                        changeAddon222("increment", value.id, data.id);
                                                      }
                                                      }
                                                    >
                                                      +
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="content-body-form">
                            <div className="form-group mb-3" controlId="validationCustom043">
                              <button className="Active-bttn btn" onClick={updateservicepreference} > Update </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </form>
              </Accordion>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="Active-bttn btn" onClick={updatedUser}>
            {" "}
            Update{" "}
          </button>
          <button className="Cancel-bttn btn" onClick={handleCloseCustomer}>
            {" "}
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
      {/* Modal End */}

      <div className="dashboard-container d-flex">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="main-container">
          <div className="main-page-body-content">
            <div className="main-page-body-content-body">
              <div className="main-page-body-content-body-container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="select-box-list-item">
                      <Form.Select aria-label="Default select example" onChange={
                        (e) => {
                          setchangesstaffzone(e.target.value)
                        }
                      }>
                        <option value="0">All Zone</option>
                        {bookingzonelist && bookingzonelist.map((zone) => (
                          <option value={zone.id} >{zone.zone_name}</option>
                        ))}

                      </Form.Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="select-box-list-item">
                      <Form.Control
                        type="Date"
                        placeholder=" "
                        value={startDate}
                        className=""
                        onChange={(e) => {
                          setStartDate(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      {/* <Form.Control
                              type="Date"
                              placeholder=" "
                              value={startDate}
                              className=""
                              onChange={(e) => {
                                setStartDate(e.target.value)
                              }}
                            /> */}

                      <Select
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={activeStaff}
                        onChange={(e) => {
                          setfilterbooking(e);
                        }}
                      // menuIsOpen={true} // Set this prop to true to keep the dropdown open
                      />
                    </div>
                  </div>
                </div>

                <div className="row pd-26">
                  <div className="col-md-12">
                    <div className="card dashboard-card">
                      <div className="appointment-card-header">
                        <div className="card-hrader-btn">
                        </div>
                      </div>
                      <div className="card-body">
                        <Calendar campaigns={campaigns} gotodate={startDate} zonesdata={staffListtttt} eventcount={eventcount} filterbooking={filterbooking} />
                      </div>
                      {
                        usercrmid != 0 ? (
                          <ChildComponent id={usercrmid} onCloseidChange={(editid) => setUsercrmid(editid)} />
                        ) : (
                          <></>
                        )

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />

      {/* </>
        )
      } */}

    </div>
  );
}

const calculateTotal = (rows, discount) => {
  let total = rows.reduce((acc, row) => acc + row.total, 0);
  total -= parseFloat(discount) || 0;
  // setcalculateTotal(total);
  return total;
};


function convertToHHMM(value) {
  const start_time = value;
  const hours = Math.floor(start_time / 60);
  const minutes = start_time % 60;

  // Format hours and minutes as two-digit numbers
  const formattedHours = hours < 10 ? '0' + hours : hours.toString();
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

  // Combine hours and minutes in the "00:00" format
  const formattedTime = formattedHours + ':' + formattedMinutes;
  return formattedTime;
}

function convertToHM(value) {
  const start_time = value;
  const hours = Math.floor(start_time / 60);
  const minutes = start_time % 60;

  // Calculate the total time in hours with decimal representation for minutes
  const totalHours = hours + (minutes / 60);

  return totalHours;
}

function getpriceperhours(amount, baseminutes) {
  if (amount != 0 && baseminutes != 0) {
    const hours = baseminutes / 60;

    const priceperhours = amount / hours.toFixed(2);
    return priceperhours.toFixed(2);
  } else {
    return "0.00";
  }
}

function getCalculateddiscount(booking_price, booking_discount) {
  if (booking_price != 0 && booking_discount != 0) {
    const discount = parseInt(booking_price) - parseInt(booking_discount);
    return discount;
  } else {
    return "0.00";
  }
}


function checkCardExpiration(card_exp_datetime) {
  const thresholdDays = 90;

  // Get the current date and time
  const now = new Date();
  const expDate = new Date(card_exp_datetime);
  const thresholdDate = new Date(now.getTime() + thresholdDays * 24 * 60 * 60 * 1000);

  if (expDate < now) {
    return "Your Card is Expired";
  } else if (expDate <= thresholdDate) {
    return "Your Card is About to expire";
  } else {
    return;
  }
}

export default Appointment;