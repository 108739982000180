import React from "react";
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import './assets/css/Service.css'
import Header2 from "./camponent/header/Header2";

function Housecleaning() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="housecleaning-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>House Cleaning <span className='bold-title'><span className='bold-title bg-color'>Services</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="service-list">
                                    <div className="service-list-inner mb-4">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="service-list-box">
                                                    <div className="service-list-box-inner">
                                                        <div className="service-list-box-image">
                                                            <img src={require ('../Booking/assets/Images/House/image01.webp')} alt="service-list-1" />
                                                        </div>
                                                        <div className="service-list-box-warpper">
                                                            <div className="service-list-box-content">
                                                                <h3 className="title">Initial House Cleaning</h3>
                                                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                            </div>
                                                            <div className="service-list-box-button">
                                                                <a href="/project/initial-house-cleaning/" className="btn custom-btn">Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="service-list-box">
                                                    <div className="service-list-box-inner">
                                                        <div className="service-list-box-image">
                                                            <img src={require ('../Booking/assets/Images/House/image02.webp')} alt="service-list-1" />
                                                        </div>
                                                        <div className="service-list-box-warpper">
                                                            <div className="service-list-box-content">
                                                                <h3 className="title">Scheduled Maid Service</h3>
                                                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                            </div>
                                                            <div className="service-list-box-button">
                                                                <a href="/project/maid-service/" className="btn custom-btn">Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="service-list-box">
                                                    <div className="service-list-box-inner">
                                                        <div className="service-list-box-image">
                                                            <img src={require ('../Booking/assets/Images/House/image03.webp')} alt="service-list-1" />
                                                        </div>
                                                        <div className="service-list-box-warpper">
                                                            <div className="service-list-box-content">
                                                                <h3 className="title">Move In/Out Cleaning Service</h3>
                                                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                            </div>
                                                            <div className="service-list-box-button">
                                                                <a href="/project/move-in-out-cleaning-service/" className="btn custom-btn">Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="service-list-box">
                                                    <div className="service-list-box-inner">
                                                        <div className="service-list-box-image">
                                                            <img src={require ('../Booking/assets/Images/House/image04.webp')} alt="service-list-1" />
                                                        </div>
                                                        <div className="service-list-box-warpper">
                                                            <div className="service-list-box-content">
                                                                <h3 className="title">La Mesa House Cleaning</h3>
                                                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                            </div>
                                                            <div className="service-list-box-button">
                                                                <a href="/project/la-mesa-house-cleaning/" className="btn custom-btn">Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="service-list-box">
                                                    <div className="service-list-box-inner">
                                                        <div className="service-list-box-image">
                                                            <img src={require ('../Booking/assets/Images/House/image05.webp')} alt="service-list-1" />
                                                        </div>
                                                        <div className="service-list-box-warpper">
                                                            <div className="service-list-box-content">
                                                                <h3 className="title">San Diego House Cleaning</h3>
                                                                <p className="description">Lorem`` ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                            </div>
                                                            <div className="service-list-box-button">
                                                                <a href="/project/san-diego-house-cleaning/" className="btn custom-btn">Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="service-list-box">
                                                    <div className="service-list-box-inner">
                                                        <div className="service-list-box-image">
                                                            <img src={require ('../Booking/assets/Images/House/image06.webp')} alt="service-list-1" />
                                                        </div>
                                                        <div className="service-list-box-warpper">
                                                            <div className="service-list-box-content">
                                                                <h3 className="title">Cleaning Services Checklist</h3>
                                                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                            </div>
                                                            <div className="service-list-box-button">
                                                                <a href="/project/cleaning-services-checklist/" className="btn custom-btn">Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default Housecleaning;