import React from 'react';
import '../Herosection/Herosection.css';

function Herosection() {
    return (
        <div className="App">
            <div className='Herosection-container dashboard-custom-container'>
                <div className="Herosection-container-containt ">
                    <div className="hero-text">
                        <div className="bg-custom-title">
                            <h2 className='title mb-0'>Dashboard</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Herosection;