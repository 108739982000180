import React, { useState, useEffect } from "react";
import { Roller } from "react-awesome-spinners";
import { Form, Button, Modal } from "react-bootstrap";
import Sidebar from "../Components/Sidebar/Sidebar";
import { getgoogleleads } from "../Api/blogsapi";

// Custom hook for fetching Google leads data
const useGoogleLeads = () => {
    const [loading, setLoading] = useState(true);
    const [apidata, setApidata] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchGoogleLeadsData = async () => {
            try {
                const res = await getgoogleleads();
                if (res.code === 200 && res.data.status === 1) {
                    setApidata(res.data.data);
                } else {
                    setError(res.data.message || "Failed to fetch data");
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchGoogleLeadsData();
    }, []);

    return { loading, apidata, error };
};

function GoogleLeads() {
    const { loading, apidata, error } = useGoogleLeads();
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    if (loading) {
        return (
            <div className="loading">
                <Roller />
            </div>
        );
    }



    return (
        <div className="dashboard-container d-flex">
            <div className="sidebar-container">
                <Sidebar />
            </div>
            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            {/* Simplify structure for clarity */}
                            <div className="container-fulid">
                                <div className="table-card ">
                                    <div class="table-card-header"><h5 class="card-title">Google Leads</h5></div>
                                    <div className="table-card-body-inner">
                                        <div className="leads-list row">
                                            {apidata.length > 0 ? (
                                                <>
                                                    {apidata.map((data, index) => {
                                                        const fullJson = JSON.parse(data.fulljson);
                                                        const fullName = fullJson.user_column_data.find(item => item.column_id === "FULL_NAME").string_value;
                                                        const phoneNumber = fullJson.user_column_data.find(item => item.column_id === "PHONE_NUMBER").string_value;

                                                        return (
                                                            <div className="col-md-3 mb-3" key={index}>
                                                                <div className="leads-list-box card ">
                                                                    <div className="card-body">
                                                                        <div className="mb-2"><span className="text-bold-2">Full Name:</span> {fullName}</div>
                                                                        <div><span className="text-bold-2">Phone Number:</span> {phoneNumber}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <div>No data available</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Modal and other interactive elements here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GoogleLeads;
