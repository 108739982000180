import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import moment from "moment-timezone";
import "./newcontact.css";
import BootstrapTable from 'react-bootstrap-table-next';
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import pagination from '../Components/TimesheetDataTable/pagination';
import Form from 'react-bootstrap/Form';
import '../Components/TimesheetDataTable/table.css';
import { getActiveStaff } from "../Api/timesheetApi";
import { getnewncontactapi } from '../Api/technician';
import { approveTechnicianOffTimerquest } from "../Api/technician";
import { rejectTechnicianOffTimerquest } from "../Api/technician";
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import { Roller } from "react-awesome-spinners";

function Newcontact() {


    const [newcontent, setNewContect] = useState([]);

    const [technicianofftimerequest, setTechnicianofftimerequest] = useState([]);

    const [ActiveStaff, setActiveStaff] = useState([]);

    const [loading, setLoading] = useState(false)

    const [StaffId, setStaffId] = useState('');
    const [techname, setTechName] = useState('');
    const [statustype, setStatustype] = useState('');



    const getActiveStaffList = () => {
        setLoading(true);
        getActiveStaff().then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                console.log("getActiveStaff", ApiResponse.data);
                setActiveStaff(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const getnewcontect = () => {
        setLoading(true);
        getnewncontactapi().then((res) => {
            if (res.code === 200) {
                const Newcontact = res.data;
                setNewContect(Newcontact.data);
            }
            else {
                alert("No data found");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    };



    useEffect(() => {
        setLoading(false);
        getActiveStaffList();
        getnewcontect()
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const statusFormatter = (cell, row) => {
        return (
            <div>
                {row.status == "0" ? "Pending" : row.status == "1" ? "Approved" : "Rejected"}
            </div>
        );
    }

    const optionFormatter = (cell, row) => {
        return (
            <div>
                {row.status == "0" ?
                    <div className="button-group">
                        <Button className="Approve-bttn me-2" style={{ backgroundColor: '#75B317', color: 'white' }} onClick={() => handleApprove(row)}>
                            <RiCheckLine />
                        </Button>
                        <Button className="Reject-bttn" style={{ backgroundColor: 'red', color: 'white' }} onClick={() => handleReject(row)}>
                            <RiCloseLine />
                        </Button>
                    </div>
                    : row.status == "1" ? "APPROVED" : "REJECTED"}
            </div>

        );
    }


    const columns = [{
        dataField: 'id',
        text: '#',
        sort: true
    },
    {
        dataField: 'first_name',
        text: 'FIRST NAME',
        sort: true
    },
    {
        dataField: 'last_name',
        text: 'LAST NAME',
        sort: true
    },
    {
        dataField: 'email',
        text: 'EMAIL',
        sort: true
    },
    {
        dataField: 'phone',
        text: 'PHONE',
        sort: true
    },
    {
        dataField: 'address',
        text: 'ADDRESS',
        sort: true
    },
    {
        dataField: 'working_area',
        text: 'WORKING AREA',
        sort: true
    }];

    const handleApprove = (data) => {
        console.log("data", data);
        if (window.confirm("Are you sure you want to approve this record?")) {
            const formData = new FormData();
            formData.append("off_time_id", data.id);
            formData.append("technician_id", data.staff_id);
            approveTechnicianOffTimerquest(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status == "1") {
                        alert(res.data.message);
                        // const newTechnicianOfftime = technicianofftimerequest.filter((item) => item.id !== data.id);
                        // setTechnicianofftimerequest(newTechnicianOfftime);
                        const newTechnicianOfftime = technicianofftimerequest.map((item) => {
                            if (item.id == data.id) {
                                item.status = "1";
                            }
                            return item;
                        }
                        );
                        setTechnicianofftimerequest(newTechnicianOfftime);
                    }
                }
                else {
                    alert("Something went wrong");
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const handleReject = (data) => {
        if (window.confirm("Are you sure you want to reject this record?")) {
            const formData = new FormData();
            formData.append("off_time_id", data.id);
            formData.append("technician_id", data.staff_id);
            rejectTechnicianOffTimerquest(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status == "1") {
                        alert(res.data.message);
                        //update technicianofftimerequest state and dont remove the rejected record
                        const newTechnicianOfftime = technicianofftimerequest.map((item) => {
                            if (item.id == data.id) {
                                item.status = "Rejected";
                            }
                            return item;
                        }
                        );
                        setTechnicianofftimerequest(newTechnicianOfftime);

                    }
                }
                else {
                    alert("Something went wrong");
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const [start, setStart] = useState(moment());
    const [end, setEnd] = useState(moment(start).add(5, "days").subtract(1, "second"));

    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment(start).add(5, "days").subtract(1, "second"));


    const applyCallback = (startDate, endDate) => {
        console.log("Apply Callback");
        console.log(startDate.format("DD-MM-YYYY HH:mm:ss"));
        console.log(endDate.format("DD-MM-YYYY HH:mm:ss"));


        setStart(startDate);
        setEnd(endDate);

        const start_date = startDate.format("YYYY-MM-DD");
        const end_date = endDate.format("YYYY-MM-DD");

        console.log("start_date", start_date);
        console.log("end_date", end_date);

        setStartTime(start_date);
        setEndTime(end_date);



    };

    const rangeCallback = (index, value) => {
        console.log(index, value);
    };

    const onClick = () => {
        const newStart = moment(start).subtract(3, "days");
        setStart(newStart);
    };

    const renderVanillaPicker = (ranges, local, maxDate) => {
        const value = `${start.format("DD-MM-YYYY HH:mm")} - ${end.format("DD-MM-YYYY HH:mm")}`;
        const disabled = false;



        return (
            <div>
                {/* <br /> */}
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    // maxDate={maxDate}
                    applyCallback={applyCallback}
                    rangeCallback={rangeCallback}
                    smartMode
                >
                    <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                        style={{ cursor: "pointer" }}
                        disabled={disabled}
                        value={value}
                    />
                </DateTimeRangeContainer>
                {/* <br /> */}
            </div>
        );
    };

    let now = new Date();

    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)],
        "5 Days": [moment(start).subtract(5, "days"), moment(end)],
        "1 Week": [moment(start).subtract(7, "days"), moment(end)],
        "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
        "1 Month": [moment(start).subtract(1, "months"), moment(end)],
        "1st August 18": [
            moment("2018-08-01 00:00:00"),
            moment("2018-08-02 23:59:59")
        ],
        "1 Year": [moment(start).subtract(1, "years"), moment(end)]
    };

    let local = {
        format: "DD-MM-YYYY HH:mm",
        sundayFirst: false
    };

    let maxDate = moment(end).add(24, "hour");

    let pickersRender = (
        <div>
            {/* <br /> */}
            {renderVanillaPicker(ranges, local, maxDate)}
        </div>
    );









    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="row">
                                            <div className="select-box-list" style={{ display: "none" }}>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Date Range</Form.Label>
                                                        {pickersRender}
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Technician</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={
                                                            (e) => {
                                                                setStaffId(e.target.value);
                                                            }
                                                        }>
                                                            <option value="0">Select Technician</option>
                                                            {ActiveStaff.map((item, index) => (
                                                                <option value={item.id} onChange={() => setStaffId(item.id)} selected={item.id === StaffId}>{item.fullname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>

                                                <div className="select-box-list-item" style={{ display: "none" }}>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Technician Name</Form.Label>
                                                        <Form.Control type="text" value={techname} onChange={(e) => { setTechName(e.target.value) }} placeholder="" />
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Request Status</Form.Label>
                                                        <Form.Select aria-label="Default select example" value={statustype} onChange={
                                                            (e) => {
                                                                setStatustype(e.target.value)
                                                            }
                                                        }>
                                                            <option >All Status</option>
                                                            <option value="0">Pending</option>
                                                            <option value="1">Approved</option>
                                                            <option value="2">Rejected</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="pd-26">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-header mb-4 d-flex justify-content-between">
                                                        <h5 className="card-title">New Contacts</h5>
                                                        <div className="d-flex justify-content-end">
                                                            <div className="export-btn">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-card-body-inner">

                                                        {/* modal End here */}
                                                        <div className="animated fadeIn">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="card">
                                                                        <div className="card-header d-flex table-card-header">
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <BootstrapTable bootstrap4 keyField='id'
                                                                                data={newcontent}
                                                                                columns={columns}
                                                                                pagination={pagination}
                                                                                noDataIndication="No data available"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Newcontact;