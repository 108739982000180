import React,{useState} from "react";
// import { useRef } from 'react';
import Loginbg from "../../assets/images/Login/login-bg.jpg";
import Logo from "../../assets/images/logo/Logo.png";
import './Login.css';
import { loginApi } from "../../Api/auth";
import { useEffect } from "react";


const Login = () => {


    
    const [email , setEmail] = useState("");
    const [password , setPassword] = useState("");


    const handelSubmit = ()  => {
        // event.preventDefault();

        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        if(email === "" || password === ""){
            alert("Please enter email and password");
            return;
        }

       loginApi(formData).then((response) => {
        if (response.code === 200) {
            if(response.data.status == 0){
                alert(response.data.message);
                return;
            }
            else{
                localStorage.setItem("user", JSON.stringify(response.data.data));
                localStorage.setItem("token", response.data.data.token);
                window.location.href = "/dashboard";
            }
        }
        }).catch((error) => {
            alert(error);
        });
    }


    useEffect(() => {   
        console.log("Login Page");
        if (localStorage.getItem("user") != null) {
            window.location.href = "/dashboard";
        }
    }, []);

    

    return (
        <div className="Login">
            <section className="vh-100" style={{ backgroundColor: '#9A616D' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-12">
                            <div className="card" style={{ borderRadius: '1rem' }}>
                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-6 d-none d-md-block">
                                        <img src={Loginbg}
                                            alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem' }} />
                                    </div>
                                    <div className="col-md-6 col-lg-6 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">
                                            <form id="loginform" 
                                            // ref={formRef}
                                            >
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    {/* <i className="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i> */}
                                                    <span className="h1 fw-bold mb-0"><img src={Logo} alt="logo" className="logo-icon" /></span>
                                                </div>

                                                <h3 className=" login-head" style={{ letterSpacing: '1px' }}>Welcome to <span className="color-text">Green Frog!</span> 👋</h3>

                                                <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>Please sign in to your account and start the adventure</h5>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlhtmlFor="form2Example17">Email address</label>
                                                    <input type="email" id="email" name="email" onChange={
                                                        (e) => {
                                                            setEmail(e.target.value);
                                                        }
                                                    }  className="form-control form-control-lg" />
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlhtmlFor="form2Example27">Password</label>
                                                    <input type="password" id="password" name="password" onChange={
                                                        (e) => {
                                                            setPassword(e.target.value);
                                                        }
                                                    } className="form-control form-control-lg" />
                                                </div>

                                                <div className="pt-1 mb-4">
                                                    {/* <Link to="/" className="btn custom-login-btn btn-lg btn-block" type="button">Sign In</Link> */}
                                                    <button type="button" className="btn custom-login-btn btn-lg btn-block"
                                                    onClick={() => {
                                                          handelSubmit() 
                                                    }}
                                                    >Sign In</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default Login;