import React, { useState, useContext, useEffect,useRef } from "react";
import Calendar from "rc-year-calendar";
import "rc-year-calendar/locales/rc-year-calendar.ru";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { ModalPopContext } from "../../Technician/ModalPopContext";
import { addmonthoffday, deleteTechnicianOffTime } from "../../Api/technician";
import { offdaytechnician } from "../../Api/technician";
import { deleteoffdaytechnician } from "../../Api/technician";
// import { getStaffDatabyId } from "../../Api/technician";

//deleteoffdaytechnician

function MyNewCalendar(props) {
  console.log("propspropsprops", props.staffid);

  const currentYear = new Date().getFullYear();

  const [dataSource, setDataSource] = useState(
    [
      {
        id: 0,
        name: "Google I/O",
        location: "San Francisco, CA",
        startDate: new Date(currentYear, 4, 28),
        endDate: new Date(currentYear, 4, 29),
      },
      {
        id: 1,
        name: "Microsoft Convergence",
        location: "New Orleans, LA",
        startDate: new Date(currentYear, 2, 16),
        endDate: new Date(currentYear, 2, 19),
      },
      {
        id: 2,
        name: "Microsoft Build Developer Conference",
        location: "San Francisco, CA",
        startDate: new Date(currentYear, 3, 29),
        endDate: new Date(currentYear, 4, 1),
      },
      {
        id: 3,
        name: "Apple Special Event",
        location: "San Francisco, CA",
        startDate: new Date(currentYear, 8, 1),
        endDate: new Date(currentYear, 8, 1),
      },
      {
        id: 4,
        name: "Apple Keynote",
        location: "San Francisco, CA",
        startDate: new Date(currentYear, 8, 9),
        endDate: new Date(currentYear, 8, 9),
      },
      {
        id: 5,
        name: "Chrome Developer Summit",
        location: "Mountain View, CA",
        startDate: new Date(currentYear, 10, 17),
        endDate: new Date(currentYear, 10, 18),
      },
      {
        id: 6,
        name: "F8 2015",
        location: "San Francisco, CA",
        startDate: new Date(currentYear, 2, 25),
        endDate: new Date(currentYear, 2, 26),
      },
      {
        id: 7,
        name: "Yahoo Mobile Developer Conference",
        location: "New York",
        startDate: new Date(currentYear, 7, 25),
        endDate: new Date(currentYear, 7, 26),
      },
      {
        id: 8,
        name: "Android Developer Conference",
        location: "Santa Clara, CA",
        startDate: new Date(currentYear, 11, 1),
        endDate: new Date(currentYear, 11, 4),
      },
      {
        id: 9,
        name: "LA Tech Summit",
        location: "Los Angeles, CA",
        startDate: new Date(currentYear, 10, 17),
        endDate: new Date(currentYear, 10, 17),
      }]);


  const ref = useRef(0);


  const [technicianDetails, setTechnicianDetails, serviceList, setServiceList, services, setServices, selectedOptions, setSelectedOptions, technicianAvailabilty, setTechnicianAvailibility, technicianOfftime, setTechnicianOfftime, technicianOffday, setTechnicianOffday, selectYear, setSelectYear] = useContext(ModalPopContext);

  
  const [currentStaffId, setCurrentStaffId] = useState(technicianDetails.id);

  const [staffdata , setStaffData] = useState([]);


  useEffect(() => {
    setCurrentStaffId(technicianDetails.id);
  }, [technicianDetails.id]);

  
  

  useEffect(() => {
    if (technicianOffday.length > 0) {
      var offday = [];
      for (var i = 0; i < technicianOffday.length; i++) {
        if (technicianOffday[i].off_date.length > 10) {
          var start = moment(technicianOffday[i].off_date).format("YYYY-MM-DD");
          var end = moment(technicianOffday[i].off_date).endOf("month").format("YYYY-MM-DD");
          offday.push({
            id: technicianOffday[i].id,
            startDate: new Date(start),
            endDate: new Date(end),
            name: "Off Day",
            location: "Off Day",
            color: "#ff0000",
          });
        }
        else {
          var start = moment(technicianOffday[i].off_date).format("YYYY-MM-DD");
          var end = moment(technicianOffday[i].off_date).format("YYYY-MM-DD");
          offday.push({
            id: technicianOffday[i].id,
            startDate: new Date(start),
            endDate: new Date(end),
            name: "Off Day",
            location: "Off Day",
            color: "#ff0000",
          });
        }
      }
      console.log("offday", offday);
      setDataSource(offday);
    }
    else
      setDataSource([]);
  }, [technicianOffday]);



  const handleEventChanged = (currentEvent) => {
    setSelectYear(currentEvent.currentYear);
  }

  const OnDateSelect = (currentEvent) => {
     console.log("staffdata============", ref.current.value);

    const eventSize = currentEvent.events.length;
    const staffid = ref.current.value;

    if (eventSize > 0) {
      const ids = currentEvent.events.map((event) => event.id);
      if (ids.includes(0)) {
        alert("Off day already added");
      }
      else { 
        const formData = new FormData();
        formData.append("technician_id", staffid);
        formData.append("id", ids);
        deleteoffdaytechnician(formData).then((res) => {
          if (res.code === 200) {
            if (res.data.status === "success") {
              alert("Off day deleted successfully");
            }
            else {
              alert("Something went wrong");
            }
            // window.location.reload();
          }
          else {
            alert("Something went wrong");
          }
        }).catch((err) => {
          alert("Something went wrong");
        });
      }

    }else
    {
      var startDate = currentEvent.startDate;
      var startdate = moment(startDate).format("YYYY-MM-DD");
      const formData = new FormData();
      formData.append("technician_id", staffid);
      formData.append("date_id", startdate);
      // console.log("formData", formData);
      offdaytechnician(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "success") {
            alert("Off day added successfully");
            setTechnicianOffday(res.data.data)
          }
          else {
            alert(res.data.message)
          }
        }
        else {
          alert("Something went wrong");
        }
      }).catch((err) => {
        alert("Something went wrong");
      });
    }



  }





  return (
    <div>
      <input type="hidden" id="staffid" ref={ref} value={props.staffid} />
      { 
      
       
      currentStaffId &&  
      <Calendar
        //   enableRangeSelection={true}
        //   enableContextMenu={true}
        //   //alert when user change the year
        onYearChanged={currentEvent => handleEventChanged(currentEvent)}
        onRangeSelected={currentEvent => OnDateSelect(currentEvent)}
        dataSource={dataSource}
        enableRangeSelection={true}
        // onDayClick={e => { console.log("e", e); }}
      // onDayContextMenu={e => {console.log("e", e);}} 
      // onRangeSelected={e => {console.log("e", e);}} 
      // onRenderEnd={e => {console.log("e", e);}}
      />
      }


    
    </div>
  );
}

export default MyNewCalendar;
