import React from "react";
import Rightbar from "./camponent/Rightbar";
import Header2 from "./camponent/header/Header2";
import Footer from "./camponent/footer/Footer";

function BlogSingle() {
    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">House Cleaning Tips</h2>
                                    </div>
                                    <div className="title_bold">
                                        <div className="windowcleaning-comtent-text">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title-single">Cleaning Safely and Effectively for People with Chemical Sensitivities</h2>
                                        <div className="meta blog-meta">
                                            <span className="date">Aug 22, 2022 </span>
                                            <span className="separator">|</span>
                                            <span className="author"> By: Admin</span>
                                        </div>
                                    </div>
                                    <div className="blog-image-single">
                                        <img src={require('./assets/Images/bg-image/cleaningtip.webp')} alt="blog image" />
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Figuring the cost of window washing</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Although window cleaning may seem like a relatively straightforward job, there are variables that can affect the final cost:</p>
                                    </div>
                                    <div className="list-about">
                                        <ul className="list">
                                            <li className="list-item">How dirty is the glass? Dirtier windows generally require more tools and equipment, not to mention time.</li>
                                            <li className="list-item">Number of windows — The more that need washing, the more you can expect to pay.</li>
                                            <li className="list-item">Ease of access — Ladders, special tools and extra time may be required to clean some hard-to-reach windows.</li>
                                            <li className="list-item">Inside, outside or both? Selecting interior-only or exterior-only service may save you a little money today, but like most services, when you opt for a la carte pricing you pay more per service. If you think you’ll eventually want both, it’s best to get them done at the same time.</li>
                                        </ul>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>There can be extra charges for:</p>
                                    </div>
                                    <div className="list-about">
                                        <ul className="list">
                                            <li className="list-item">Screen cleaning, at 50 cents to $5 each, and sliding glass doors, which run $2.50 to $8 per door, according to CostHelper.</li>
                                            <li className="list-item">Cleaning windowsills and tracks; the cost can range from 50 cents to $5.</li>
                                            <li className="list-item">Paint or stain removal, which can cost $3.50 to $6 for a window of average size. Mineral deposit removal can cost up to $20 extra per pane if it’s not included in the company’s price.</li>
                                            <li className="list-item">Going up: The cost of window work on the first and second floors of a home is generally the same, but additional floors can cost up to $3 to $5 more for every window.</li>
                                        </ul>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p></p>
                                        <p>Originally posted by <a href="https://www.angieslist.com/" target="_blank">Angie’s List</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    )
}

export default BlogSingle;