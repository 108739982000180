import React, { useState, useContext, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Informasi from "../Components/DataTable OffTime/Informasi";
// import Calendar from '../Components/Calendar/Calendar';
import Oneyear from "../Components/YearCalendar/YearCalendar";
import { ModalPopContext } from "../Technician/ModalPopContext";
import { updateTechnicianAvailabilty } from "../Api/technician";
import { addmonthoffday } from "../Api/technician";
import { deletemonthoffday } from "../Api/technician";
import Aviliti from "./Aviliti";
import OffTimeRequest from "../Components/DataTable OffTimeRequest/Informasi";



function TabData2(props) {


  const [technicianDetails, setTechnicianDetails,serviceList ,setServiceList,services, setServices,selectedOptions, setSelectedOptions,technicianAvailabilty,setTechnicianAvailibility,technicianOfftime, setTechnicianOfftime,technicianOffday, setTechnicianOffday,selectYear , setSelectYear,technicianofftimerequest, setTechnicianofftimerequest] = useContext(ModalPopContext);

  
  const Offtime = () => {
    const dataaa = [];
    technicianOfftime && technicianOfftime.map((item) => {
      dataaa.push(item.off_day);
    });
  }

  useEffect(() => {
    Offtime();
  }, [technicianOfftime]);


  const AddoffMonthTechnician = (monthNum) => {

    //get year and month from button id
    var month = monthNum;
    var year = selectYear;
    var startDate = year + "-" + month;

    //check if month is already off
    var monthAlreadyOff = false;
    technicianOffday && technicianOffday.map((item) => {
      var monthOff = moment(item.off_date).format("YYYY-MM");
      if (monthOff === startDate) {
        monthAlreadyOff = true;
      }
    }
    );

    //if month is already off then delete month off
    if (monthAlreadyOff) {
      var month = monthNum;
      var year = selectYear;
      var startDate = year + "-" + month;
      const formData = new FormData();
      formData.append("technician_id", technicianDetails.id);
      formData.append("date_id", startDate);
      deletemonthoffday(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "success") {
            setTechnicianOffday(res.data.data);
          }
          else {
            alert("Something went wrong");
          }
          // window.location.reload();
        }
        else {
          alert("Something went wrong");
        }
      }).catch((err) => {
        alert("Something went wrong");
      });

    }
    else {

      var startDate = selectYear + "-" + monthNum;
      var today = moment().format("YYYY-MM");

      var startYear = moment(startDate).format("YYYY-MM");
      const formData = new FormData();
      formData.append("technician_id", technicianDetails.id);
      formData.append("date_id", startYear);
      addmonthoffday(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "success") {
            alert("Month added successfully");
            console.log("res.data.data", res.data.data);
            setTechnicianOffday(res.data.data);
          }
          else {
            alert("Something went wrong");
          }
          // window.location.reload();
        }
        else {
          alert("Something went wrong");
        }
      }).catch((err) => {
        alert("Something went wrong");
      });

    }





  }

  const getMonthOff = () => {

    if (technicianOffday !== null && technicianOffday !== undefined && technicianOffday.length > 0) {

      const countOffDaysByMonth = (offdays) => {
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        const monthslist = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

        const result = {};
        months.forEach(month => {
          const offdayThisMonth = offdays.filter(offday => {
            const monthIndex = new Date(offday.off_date).getMonth();
            console.log("monthIndex", monthIndex);
            console.log("monthIndex.indexOf(month)", months.indexOf(month));
            return monthIndex === months.indexOf(month);
          }).length;

          const totalDays = moment(month + "-" + selectYear, "MM-YYYY").daysInMonth();
          //console.log(month+" amineshintotalDays", totalDays);
          //console.log(month+" amineshinoffdayThisMonth", offdayThisMonth);


          if (offdayThisMonth >= totalDays) {
            console.log(month + " amineshinoffdayThisMonth", "FULL MONTH OFF");
            document.getElementById(monthslist[months.indexOf(month)]).style.backgroundColor = "#ff0000";
          }
          else {
            console.log(month + " amineshinoffdayThisMonth", "NOT FULL MONTH OFF");
            document.getElementById(monthslist[months.indexOf(month)]).style.backgroundColor = "#75B317";

          }
        });
      }

      countOffDaysByMonth(technicianOffday);

    }
    else{
      // const monthslist = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
      // monthslist.map((item)=>{
      //   document.getElementById(item).style.backgroundColor = "#75B317";
      // });
    }


  }

  useEffect(() => {
    getMonthOff();
  }, [selectYear]);

  useEffect(() => {
    getMonthOff();
  }, [technicianOffday]);



  return (

    <Tabs
      defaultActiveKey="home"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="home" title="Availability">
        <div className="schedule-list">
          <Aviliti />
        </div>
      </Tab>
      <Tab eventKey="profile" title="Off Time">
        <div className="off-time-detail">
          <div className="card-custom">
            <Informasi />
          </div>
        </div>
      </Tab>

      {/* <div className="row">
          <div className="col-md-2">
            <div className="month-detail">
              <div className="month-detail-inner">
                <div className="month-name"><button class="month-btn" id="january" onClick={
                  (e) => {
                    AddoffMonthTechnician("01");
                  }
                }>January</button></div>
                <div className="month-name"><button class="month-btn" id="february" onClick={
                  (e) => {
                    AddoffMonthTechnician("02");
                  }
                }>February</button></div>
                <div className="month-name"><button class="month-btn" id="march" onClick={
                  (e) => {
                    AddoffMonthTechnician("03");
                  }
                }>March</button></div>
                <div className="month-name"><button class="month-btn" id="april" onClick={
                  (e) => {
                    AddoffMonthTechnician("04");
                  }
                }>April</button></div>
                <div className="month-name"><button class="month-btn" id="may" onClick={
                  (e) => {
                    AddoffMonthTechnician("05");
                  }
                }>May</button></div>
                <div className="month-name"><button class="month-btn" id="june" onClick={
                  (e) => {
                    AddoffMonthTechnician("06");
                  }
                }>June</button></div>
                <div className="month-name"><button class="month-btn" id="july" onClick={
                  (e) => {
                    AddoffMonthTechnician("07");
                  }
                }>July</button></div>
                <div className="month-name"><button class="month-btn" id="august" onClick={
                  (e) => {
                    AddoffMonthTechnician("08");
                  }
                }>August</button></div>
                <div className="month-name"><button class="month-btn" id="september" onClick={
                  (e) => {
                    AddoffMonthTechnician("09");
                  }
                }>September</button></div>
                <div className="month-name"><button class="month-btn" id="october" onClick={
                  (e) => {
                    AddoffMonthTechnician("10");
                  }
                }>October</button></div>
                <div className="month-name"><button class="month-btn" id="november" onClick={
                  (e) => {
                    AddoffMonthTechnician("11");
                  }
                }>November</button></div>
                <div className="month-name"><button class="month-btn" id="december" onClick={
                  (e) => {
                    AddoffMonthTechnician("12");
                  }
                }>December</button></div>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="off-time-detail">
              <Oneyear staffid={props.staffid} />
            </div>
          </div>
        </div> */}

      {/* <Tab eventKey="longer-tab" title="Off Time Request">
          <OffTimeRequest />
      </Tab> */}
    </Tabs>
  );
}

export default TabData2;
