import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Dashboard.css";
import { FiUsers, FiCalendar } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import Informasi from "../Components/DataTable/Informasi";
import { getcard } from "../Api/auth";
import { getlatestUser } from "../Api/auth";
import { dashboarddata } from "../Api/auth";
import { getproductlist } from "../Api/auth";
import moment from "moment";
import ReactDOM from "react-dom";
import { Roller } from "react-awesome-spinners";




function Dashboard() {

  useEffect(() => {
    const data = localStorage.getItem("user");
    const dataParse = JSON.parse(data);
    if (dataParse === null) {
      window.location.href = "/";
    } else {
      if (dataParse.token !== dataParse.token) {
        window.location.href = "/";
      }
    }
  }, []);


  const [loading, setLoading] = useState(false)
  const [card, setCard] = React.useState([]);
  const [latestUser, setLatestUser] = React.useState([]);
  const Today = new Date();
  const [currentdate, setCurrentDate] = React.useState(moment(Today).format('YYYY-MM-DD'))


  // const loadCard = () => {
  //   setLoading(true);
  //   getcard().then((response) => {
  //     if (response.code === 200) {
  //       const apiResponse = response.data;
  //       setCard(apiResponse.data);
  //     }
  //   })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const loadLatestUser = () => {
    setLoading(true);
    getlatestUser().then((data) => {
      if (data.code === 200) {
        setLoading(false);
        setLatestUser(data.data);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    // loadCard();
    //updatejsonfile();

    dashboarddata().then((response) => {
      if (response.code === 200) {
        const apiResponse = response.data;
        setCard(apiResponse.data);
      }
    }).catch((err) => {
      console.log("err", err);
  }).finally(() => {
    setLoading(false);
  });
  }, []);

  console.log("Card is+++", card);

  useEffect(() => {
    const data = localStorage.getItem("user");
    const dataParse = JSON.parse(data);
    console.log(dataParse);
  }, []);


  const updatejsonfile = () => {
    setLoading(true);
    getcard().then((response) => {
      if (response.code === 200) {
        const apiResponse = response.data;
        setCard(apiResponse.data);
      }
    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        const ttt = new Date();
        setCurrentDate(moment(ttt).format('YYYY-MM-DD'))
      });
  }

  const updatejsonfileproduct = () => {
    setLoading(true);
    getproductlist().then((response) => {
      if (response.code === 200) {
        // const apiResponse = response.data;
        // setCard(apiResponse.data);
        alert("success");
      }
    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }



  return (
    // <>
    <div>
      {
        loading ? (
          <div className="loading">
            <Roller />
          </div>
        ) : (
          <div>
            <div className="dashboard-container d-flex">
              <div className="sidebar-container">
                <Sidebar />
              </div>
              <div className="main-container">
                <div className="main-page-body-content">
                  <div className="main-page-body-content-body">
                    <div className="main-page-body-content-body-container">
                      <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                          <div className="card dashboard-card">
                            <div className="card-body dashboard-card-body">
                              <div className="card-inner">
                                <div className="card-left-content">
                                  <p className="card-text">TOTAL BILLED HOURS NEXT WEEK</p>
                                  <h5 className="card-title">{card ? card.total_appointments_next_week : 0 ? 0 : "0"}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                          <div className="card dashboard-card">
                            <div className="card-body dashboard-card-body">
                              <div className="card-inner">
                                <div className="card-left-content">
                                  <p className="card-text">TOTAL UNBILLED NEXT WEEK</p>
                                  <h5 className="card-title">{card ? card.totalunbooked : 0 ? 0 : "0"}</h5>
                                </div>
                                <div className="card-right-content">
                                  <div className="card-icons">
                                    <div className="icon-box bg-red">
                                      <FiUsers />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                          <div className="card dashboard-card">
                            <div className="card-body dashboard-card-body">
                              <div className="card-inner">
                                <div className="card-left-content">
                                  <p className="card-text">CAPACITY NEXT WEEK</p>
                                  <h5 className="card-title">{card ? (card.total_ratio_capacity !== undefined ? card.total_ratio_capacity + "%" : "0%") : "0%"}</h5>
                                </div>
                                <div className="card-right-content">
                                  <div className="card-icons">
                                    <div className="icon-box bg-green">
                                      <BiUserCircle />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                          <div className="card dashboard-card">
                            <div className="card-body dashboard-card-body">
                              <div className="card-inner justify-content-end">
                                <div className="card-right-content">
                                  <button className="btn btn-sm float-right add-user-btn btn btn-primary" onClick={updatejsonfile}>Refresh</button>
                                  <button className="btn btn-sm float-right add-user-btn btn btn-primary" onClick={updatejsonfileproduct}>Get Product</button>
                                  <div className="card-left-content mt-3">
                                    <p className="card-text">{currentdate ? currentdate : "0"}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pd-26">
                        <div className="table-card card">
                          <div className="card-body table-card-body">
                            <div className="table-card-header">
                              <h5 className="card-title">New Clients</h5>
                            </div>
                            <div className="table-card-body-inner">
                              <Informasi />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>

    // </>
  );
}

export default Dashboard;