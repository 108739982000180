import React from "react";
import Social from './camponent/social/Social';
import Rightbar from './camponent/Rightbar';
import Testimonial from "./camponent/Testimonials/Testimonial";
import Usercomment from "./camponent/Testimonials/UserReviews";
import Footer from './camponent/footer/Footer';
import './assets/css/Contact.css';
import Header2 from "./camponent/header/Header2";
import Comman from "./camponent/CommanContent/Comman";

function Application() {
    return (
        <>
            <section className="contact-us">
                <div className="Header">
                    <Header2 />
                </div>
                <div className="social">
                    <Social />
                </div>

                {/* hero section start here  */}
                <div className="Contact-us-hero custom-section" id="Contact-us-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Technician<span className='bold-title'><span className='bold-title bg-color'> Job Application</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* contact us form section start here  */}
                <div className="contact-us-section">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-xl-9 col-lg-8 col-md-8 col-sm-12'>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">House Cleaning Technician Job Application</h2>
                                    </div>
                                </div>
                                <div className="Contact-us-form cstm-mrgn">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">First Name</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Last Name</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Last Name" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Phone Number</label>
                                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Phone Number" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Email Address</label>
                                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">How did you hear about this position?</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="How did you hear about this position?" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">What zip code do you live in?</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder=" What zip code do you live in?" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="content-body-form">
                                                <form>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlTextarea1" className="form-label">Comments</label>
                                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="submit-btn">
                                                <button type="button" className="btn submit-btn-contact-us">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12'>
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </div>
                {/* contact us form section end here  */}

                <Comman />

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}


            </section>
        </>
    );
}

export default Application