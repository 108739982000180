import React from "react";
import { useContext, useEffect, useState, useRef } from 'react';
import { SettingContext } from '../../Setting/SettingContext';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { updateemailtemplatestatus } from '../../Api/settingApi';
import { updateemailtemplate } from '../../Api/settingApi';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function TabData6() {

    const textareaRef = useRef(null);
    const textareaRef2 = useRef(null);
    const textareaRef3 = useRef(null);
    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings, paymenetmethod, setPaymenetmethod, emailData, setEmailData, emailTemplate, setEmailTemplate, promocodeData, setPromocodeData] = useContext(SettingContext);


    const [appointmentCancelled, setAppointmentCancelled] = useState(false);
    const [clientAppointmentReminder, setClientAppointmentReminder] = useState(false);
    const [appointementrequest, setAppointementrequest] = useState(false);

    const [appointementsmsbody, setAppointementsmsbody] = useState("");
    const [clientappointmentreminderbody, setClientappointmentreminderbody] = useState("");
    const [appointementrequestbody, setAppointementrequestbody] = useState("");

    const [emaildesign, setEmaildesign] = useState('');
    const [templatename, setTemplatename] = useState('');

    const [reminderBuffer ,  setReminderBuffer] = useState('');

    const CheckSMSTemplate = (e) => {
        emailTemplate.map((item) => {
            if (item.id === "10") {
                if (item.email_template_status === "E") {
                    setAppointmentCancelled(true);
                    setAppointementsmsbody(item.email_message);
                }
                else {
                    setAppointmentCancelled(false);
                    setAppointementsmsbody(item.email_message);
                }
            }

            if (item.id === "16") {
                if (item.email_template_status === "E") {
                    setClientAppointmentReminder(true);
                    setClientappointmentreminderbody(item.email_message);
                    setReminderBuffer(item.email_reminder_buffer);
                }
                else {
                    setClientAppointmentReminder(false);
                    setClientappointmentreminderbody(item.email_message);
                    setReminderBuffer(item.email_reminder_buffer);
                }
            }

            if (item.id === "1") {
                if (item.email_template_status === "E") {
                    setAppointementrequest(true);
                    setAppointementrequestbody(item.email_message);
                }
                else {
                    setAppointementrequest(false);
                    setAppointementrequestbody(item.email_message);
                }
            }
        })

    }

    useEffect(() => {
        CheckSMSTemplate();
    }, [emailTemplate])

    const [cursorPosition, setCursorPosition] = useState(0);

    const extraTags = (e) => {
        const extraText = e;
        const newTextareaValue = appointementsmsbody.substring(0, cursorPosition)
            .concat(extraText)
            .concat(appointementsmsbody.substring(cursorPosition));
        setAppointementsmsbody(newTextareaValue);

        setCursorPosition(cursorPosition + extraText.length);
    }

    useEffect(() => {
        textareaRef.current.selectionStart = textareaRef.current.selectionEnd = cursorPosition;
        textareaRef.current.focus();
    }, [appointementsmsbody, cursorPosition])


    const extraTags2 = (e) => {
        const extraText = e;
        const newTextareaValue = clientappointmentreminderbody.substring(0, cursorPosition)
            .concat(extraText)
            .concat(clientappointmentreminderbody.substring(cursorPosition));
        setClientappointmentreminderbody(newTextareaValue);
        setCursorPosition(cursorPosition + extraText.length);
    }


    useEffect(() => {
        textareaRef2.current.selectionStart = textareaRef2.current.selectionEnd = cursorPosition;
        textareaRef2.current.focus();
    }, [clientappointmentreminderbody, cursorPosition])



    const extraTags3 = (e) => {
        const extraText = e;
        const newTextareaValue = appointementrequestbody.substring(0, cursorPosition)
            .concat(extraText)
            .concat(appointementrequestbody.substring(cursorPosition));
        setAppointementrequestbody(newTextareaValue);
        setCursorPosition(cursorPosition + extraText.length);
    }


    useEffect(() => {
        textareaRef.current.selectionStart = textareaRef.current.selectionEnd = cursorPosition;
        textareaRef.current.focus();
    }, [appointementrequestbody, cursorPosition])



    const clientAppointment = (e) => {
        setClientAppointmentReminder(e.target.checked);
        var clientAppointment = "";
        if (e.target.checked === true) {
            clientAppointment = "E";
        }
        else {
            clientAppointment = "D";
        }

        const formData = new FormData();
        formData.append("status", clientAppointment);
        formData.append("id", "16");

        updateemailtemplatestatus(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const clientAppointmentrequest = (e) => {
        setAppointementrequest(e.target.checked);
        var clientAppointment = "";
        if (e.target.checked === true) {
            clientAppointment = "E";
        }
        else {
            clientAppointment = "D";
        }

        const formData = new FormData();
        formData.append("status", clientAppointment);
        formData.append("id", "1");

        updateemailtemplatestatus(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    const adminAppointment = (e) => {
        setAppointmentCancelled(e.target.checked);
        var smsstatus = "";
        if (e.target.checked === true) {
            smsstatus = "E";
        }
        else {
            smsstatus = "D";
        }

        const formData = new FormData();
        formData.append("status", smsstatus);
        formData.append("id", "10");
        updateemailtemplatestatus(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Status change successfully!");
                }
                else {
                    alert("change not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const saveAppointmentEmailTemplate = (e) => {
        const formData = new FormData();
        formData.append("email_message", appointementsmsbody);
        formData.append("id", "10");

        updateemailtemplate(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const saveClientEmailTemplate = (e) => {
        const formData = new FormData();
        formData.append("id", "16");
        formData.append("email_message", clientappointmentreminderbody);
        formData.append("email_reminder_buffer", reminderBuffer);

        updateemailtemplate(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }
    const saveClientEmailTemplateAppoinmentrequest = (e) => {
        const formData = new FormData();
        formData.append("id", "1");
        formData.append("email_message", appointementrequestbody);

        updateemailtemplate(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    alert("Data updated successfully!");
                }
                else {
                    alert("Data not updated!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const onTextAreaChange = (e) => {
        setAppointementsmsbody(e.target.value);
        setCursorPosition(e.target.selectionStart);
    }

    const OnTextclientappointmentreminderbodyChange = (e) => {
        setClientappointmentreminderbody(e.target.value);
        setCursorPosition(e.target.selectionStart);
    }
    const OnTextclientappointmentrequest = (e) => {
        setAppointementrequestbody(e.target.value);
        setCursorPosition(e.target.selectionStart);
    }

    const tagsname = ["{{booking_id}}", "{{booking_date}}", "{{booking_time}}", "{{service_name}}", "{{client_name}}", "{{methodname}}", "{{units}}", "{{booking_notes}}", "{{addons}}", "{{firstname}}", "{{lastname}}", "{{client_email}}", "{{client_phone}}", "{{payment_method}}", "{{vaccum_cleaner_status}}", "{{parking_status}}", "{{notes}}", "{{preferences}}", "{{family_info}}", "{{parking_access}}", "{{preferrend_technician}}", "{{special_instructions}}", "{{contact_status}}", "{{price}}", "{{client_address}}", "{{client_city}}", "{{client_state}}", "{{client_zip}}", "{{client_promocode}}", "{{app_remain_time}}", "{{reject_status}}", "{{business_logo}}", "{{admin_name}}", "{{company_name}}", "{{company_address}}", "{{company_city}}", "{{company_state}}", "{{company_zip}}", "{{company_country}}", "{{company_phone}}", "{{company_email}}"];


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const showemailtemplatate = (type) => {
        switch (type) {
            case "appointementrequest":
                setEmaildesign(appointementrequestbody);
                setTemplatename("Appointment Request");
                break;
            case "appointmentcancel":
                setEmaildesign(appointementsmsbody);
                setTemplatename("Appointment Cancel");
                break;
            case "appointmentreminder":
                setEmaildesign(clientappointmentreminderbody);
                setTemplatename("Appointment Reminder");
                break;
            default:
                setEmaildesign('');
                setTemplatename('');
                break;
        }
        handleShow();
    }


    return (
        <div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{templatename}</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div dangerouslySetInnerHTML={{ __html: emaildesign }} /></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-3 custom-accordion-item">
                        <Accordion.Header>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={appointementrequest}
                                onChange={clientAppointmentrequest}
                            />
                            Appointment Request
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-9">
                                    <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                        <Form.Control
                                            ref={textareaRef3}
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '571px' }}
                                            value={appointementrequestbody || ""}
                                            onChange={OnTextclientappointmentrequest}
                                        />
                                    </FloatingLabel>
                                    <div className="mt-3">
                                        <div className="btn-list">
                                            <button type="button" className="btn setting-btn bg-green-dark" onClick={saveClientEmailTemplateAppoinmentrequest}>Save Template</button>
                                            <button type="button" className="btn setting-btn bg-purple-dark" onClick={() => { showemailtemplatate('appointementrequest') }}>Preview Template</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="accordian-header-title-small">
                                        <h4>Tags</h4>
                                    </div>

                                    <div className="tag-list">
                                        {
                                            tagsname.map((tag, index) => {
                                                return (
                                                    <div key={index} className="tag">
                                                        <button className="tag-btn-data" onClick={
                                                            () => {
                                                                extraTags3(tag);
                                                            }
                                                        }>{tag}</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-3 custom-accordion-item">
                        <Accordion.Header>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={appointmentCancelled}
                                onChange={adminAppointment}
                            />
                            Appointment Cancelled by you
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-9">
                                    <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                        <Form.Control
                                            ref={textareaRef}
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '571px' }}
                                            value={appointementsmsbody || ""}
                                            onChange={
                                                (e) => {
                                                    onTextAreaChange(e);
                                                }
                                            }
                                            onFocus={
                                                (e) => {
                                                    setCursorPosition(e.target.selectionStart);
                                                }
                                            }
                                        />
                                    </FloatingLabel>
                                    <div className="mt-3">
                                        <div className="btn-list">
                                            <button type="button" className="btn setting-btn bg-green-dark" onClick={saveAppointmentEmailTemplate}>Save Template</button>
                                            <button type="button" className="btn setting-btn bg-purple-dark" onClick={() => { showemailtemplatate('appointmentcancel') }}>Preview Template</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="accordian-header-title-small">
                                        <h4>Tags</h4>
                                    </div>

                                    <div className="tag-list">
                                        {
                                            tagsname.map((tag, index) => {
                                                return (
                                                    <div key={index} className="tag">
                                                        <button className="tag-btn-data" onClick={
                                                            () => {
                                                                extraTags(tag);
                                                            }
                                                        }>{tag}</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="custom-accordion-item">
                        <Accordion.Header>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={clientAppointmentReminder}
                                onChange={clientAppointment}
                            />
                            Client Appointment Reminder
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-9">
                                    <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                        <Form.Control
                                            ref={textareaRef2}
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '571px' }}
                                            value={clientappointmentreminderbody || ""}
                                            onChange={OnTextclientappointmentreminderbodyChange}
                                        />
                                    </FloatingLabel>
                                    <select className="form-control mt-3" onChange={(e) => { setReminderBuffer(e.target.value) }} value={reminderBuffer}>
                                        <option value="">Set Email Reminder Buffer</option>
                                        <option value="60">1 Hours</option>
                                        <option value="120">2 Hours</option>
                                        <option value="180">3 Hours</option>
                                        <option value="240">4 Hours</option>
                                        <option value="300">5 Hours</option>
                                        <option value="360">6 Hours</option>
                                        <option value="420">7 Hours</option>
                                        <option value="480">8 Hours</option>
                                        <option value="1440">1 Days</option>
                                        <option value="2880">2 Days</option>
                                        <option value="4320" selected="">3 Days</option>
                                    </select>
                                    <div className="mt-3">
                                        <div className="btn-list">
                                            <button type="button" className="btn setting-btn bg-green-dark" onClick={saveClientEmailTemplate}>Save Template</button>
                                            <button type="button" className="btn setting-btn bg-purple-dark" onClick={() => { showemailtemplatate('appointmentreminder') }}>Preview Template</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="accordian-header-title-small">
                                        <h4>Tags</h4>
                                    </div>

                                    <div className="tag-list">
                                        {
                                            tagsname.map((tag, index) => {
                                                return (
                                                    <div key={index} className="tag">
                                                        <button className="tag-btn-data" onClick={
                                                            () => {
                                                                extraTags2(tag);
                                                            }
                                                        }>{tag}</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            {/* <div className="row custom-pt-20">
                <div className="col-md-12">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            <button type="button" className="btn bg-green-dark action-btn btn-green">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>

    )
}

export default TabData6;