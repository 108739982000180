// Create React Component
import React from 'react';
import './Social.css';

// Create Component
const Social = () => {
    return (
        <>
            {/* <div className="socialicon">
                <ul>
                    <li><a href="https://www.facebook.com/greenfroghousecleaning/"><span>Facebook</span><img src={Facebook} alt='fb' className='fb-img' /></a></li>
                    <li><a href="https://www.angi.com/companylist/us/ca/la-mesa/green-frog-house-cleaning-reviews-6431756.htm"><span>Angiest List</span><img src={Angi} alt='Angi' className='fb-img' /></a></li>
                    <li><a href="https://www.yelp.com/biz/green-frog-house-cleaning-san-diego-2"><span>Yelp</span><img src={Yelp} alt='yelp' className='fb-img' /></a></li>
                    <li><a href="https://nextdoor.com/pages/green-frog-cleaning-chula-vista-ca/recommendations/"><span>Nextdoor</span><img src={Nextdoor} alt='nextdoor' className='fb-img' /></a></li>
                </ul>
            </div> */}
        </>
    );
}

// Export Component
export default Social;