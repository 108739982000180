import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Footer from './camponent/footer/Footer';
import './assets/css/About.css';
import About01 from '../Booking/assets/Images/aboutus/image01.webp';
import About02 from '../Booking/assets/Images/aboutus/image02.webp';
import About03 from '../Booking/assets/Images/aboutus/image03.webp';
import Rated from './assets/Images/aboutus/stars.webp';
import series from './assets/Images/aboutus/series.webp';
import Hand from './assets/Images/aboutus/Hand.webp';
import List from './assets/Images/aboutus/List.webp';
import Trust from './assets/Images/aboutus/Trust.webp';
import Usercomment from "./camponent/Testimonials/Usercomment";
import Testimonial from "./camponent/Testimonials/Testimonials";
import Profile from './assets/Images/profile.jpg';
import Star from './assets/Images/star.webp';
import Social from './camponent/social/Social';
import Header2 from './camponent/header/Header2';
import Comman from './camponent/CommanContent/Comman';
import { Helmet } from "react-helmet";

function About() {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    const generateMetaTags = () => {
        const metaTags = [
            { name: "title", content: `Greenfrog About us` },
            { name: "description", content: `Greenfrog About us` },
            { property: "og:title", content: `Greenfrog About us` },
            { property: "og:description", content: `Greenfrog About us` },
            { property: "og:url", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@greenfrog" },
            { name: "twitter:title", content: `Greenfrog About us` },
            { name: "twitter:description", content: `Greenfrog About us` },
        ];

        metaTags.push({ property: "og:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        metaTags.push({ name: "twitter:image", content: `https://pro.greenfrogcleaning.com/static/media/initial.a79ed404821403b0f477.webp` });
        // if (og_image) {
        // }

        return metaTags;
    };


    return (
        <div>

            {/* <div>
                <Helmet>
                    {generateMetaTags().map((tag, index) => (
                        <meta key={index} {...tag} />
                    ))}
                </Helmet>
            </div> */}

            <Helmet>
                <title>Greenfrog About</title>
                <meta name="Greenfrog about" content="Greenfrog About us" />
            </Helmet>


            <div className="About">
                <div className="Header">
                    <Header2 />
                </div>
                <Social />
            </div>
            {/* hero section end here  */}

            {/* about content start here  */}
            <section className="about-content custom-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-md-1">
                            <div className="about-text-content">
                                <div className="about-tltle">
                                    <h2 className="">It's all about you.</h2>
                                </div>
                                <div className="description">
                                    <p>We are on a mission to provide the highest quality, punctual, and friendliest house cleaning in San Diego. We have a great group of technicians here at Green Frog that have been with us for a long time. We’ve been locally owned and operated by husband and wife team Jaime and Brian Nilles since 2004. They would be happy to speak with you anytime about how we can best serve you because, it’s all about you.</p>
                                </div>
                                <div className="user-name">- Jaime & Brian</div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12  order-md-1">
                            <div className="about-image-content">
                                <img src={About01} alt="about" className="w-100 img-fix" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-image-content">
                                <img src={About02} alt="about" className="w-100 img-fix" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-text-content">
                                <div className="about-tltle">
                                    <h2 className="">Quality technicians.</h2>
                                </div>
                                <div className="description">
                                    <p>All of our Technicians are chosen for their outstanding qualities and good history. In addition each employee undergoes a through background check. We would never send someone to your home who we wouldn’t want in ours. We’ll make sure to match you with a cleaner that fits with your personal needs. </p>
                                </div>
                                <div className="about-tltle">
                                    <h2 className="">100% guaranteed.</h2>
                                </div>
                                <div className="description">
                                    <p>Our work is 100% guaranteed. If you are not completely satisfied with our cleaning just give us a call and well come fix it – free! </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-md-1">
                            <div className="about-text-content">
                                <div className="about-tltle">
                                    <h2 className="">Licensed, bonded and insured.</h2>
                                </div>
                                <div className="description">
                                    <p>We carry a $1Million general liability policy (USLI #CP 1646125) and a $250K Bond (Western Surety #61251950). We have a business license with the city of La Mesa. While our training and cleaning system minimizes accidents, they do occur. When they do you know your covered. </p>
                                </div>
                                <div className="about-tltle">
                                    <h2 className="">Professional house cleaning system.</h2>
                                </div>
                                <div className="description">
                                    <p>Our professional house cleaning system guarantees a thorough clean every time. We use our Quality Control Checklist with every visit.  </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 order-md-1">
                            <div className="about-image-content">
                                <img src={About03} alt="about" className="w-100 img-fix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* about content end here  */}

            <section className="Rated custom-section-mr">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="rating-box">
                                <div className="rating-image">
                                    <img src={Rated} alt="rated" className="W-90" />
                                </div>
                                <div className="rating-tltle">
                                    <h2 className="">5 Star-rated & recognized</h2>
                                </div>
                                <div className="reating-dis">Green Frog Cleaning has over 600 five star reviews, is fully licensed and our mission is to provide top-notch service and customer experience.</div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="rating-box">
                                <div className="rating-image">
                                    <img src={series} alt="rated" className="W-90" />
                                </div>
                                <div className="rating-tltle">
                                    <h2 className="">Designed for impact</h2>
                                </div>
                                <div className="reating-dis">Green Frog Cleaning has been recognized as an Inc. 5000 company and we attribute that success to our outstanding service.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="get-quote">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 ">
                            <div className="quote-title">With Green Frog, it's all about you</div>
                        </div>
                        <div className="col-md-6">
                            <div className="quote-btn">
                                <a href="#Getaquate" className="custom-btn">GET A QUOTE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* testimonial section start here  */}
            <section className="custom-section">
                <div className="container">
                    <Testimonial ratingimage={Star} sectiontitle={'The #1 Rated Cleaning Company In San Diego!'} sectiondescription={'Find out why Green Frog Cleaning is San Diego’s favorite cleaning company...'} />
                    <div className="row">
                        <div className="col-md-6">
                            <Usercomment imagesrc={Profile} username={'Andrea L.'} userdiscription1={'We’ve been with Green Frog for a while and have been happy with our housekeeping services. I found them on Yelp and they responded through the app to my message. They always respond to my emails and answer the phone. '} heighlight={'The staff here is awesome!'} />
                        </div>
                        <div className="col-md-6">
                            <Usercomment imagesrc={Profile} username={'Sarah B.'} heighlight={'I love Green Frog! '} userdiscription2={' I can not believe how thorough the initial cleaning was. It was fantastic. I have had my house cleaned by a few different services - no one has cleaned as well as Green Frog. I love that they use natural products, too.'} />
                        </div>
                    </div>
                </div>
            </section>
            {/* testimonial section end here  */}

            <Comman />
            {/* footer section start here  */}
            <Footer />
            {/* footer section end here  */}
        </div>
    );
}

export default About;