import React, { useContext, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import Informasi from '../Components/DataTable Technician/Informasi';
import TabData2 from './TechnicianTab';
import { ModalPopContext } from '../Technician/ModalPopContext';
import Select from "react-select";
import { edittechnician } from '../Api/technician';
import { getservicezoneApi } from '../Api/technician';
import Spinner from 'react-bootstrap/Spinner';
import { getzones, changepassword, getrevieforstaff } from '../Api/technician';
import { Typeahead } from 'react-bootstrap-typeahead';
import Button from "react-bootstrap/Button";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { BsStarFill, BsStar } from 'react-icons/bs';
import Card from 'react-bootstrap/Card';


function TabData(props) {
  const [technicianDetails, setTechnicianDetails, serviceList, setServiceList, services, setServices, selectedOptions, setSelectedOptions, technicianAvailabilty, setTechnicianAvailibility, technicianOfftime, setTechnicianOfftime, technicianOffday, setTechnicianOffday, selectYear, setSelectYear, technicianofftimerequest, setTechnicianofftimerequest] = useContext(ModalPopContext);
  const [edittechnicianservices, setEditTechnicianServices] = useState([]);

  const [servicezone, setServicezone] = React.useState([]);
  const [techservicezone, setTechservicezone] = React.useState([]);
  
  useEffect(() => {
    if (technicianDetails) {
      const selectedValues = technicianDetails.service_ids.split(",");
      const initialSelectedOptions = services.filter(service => selectedValues.includes(service.value));
      setSelectedOptions(initialSelectedOptions);
    }
  }, [technicianDetails,services]);
  
  const [timesheetoptions, setTimesheetOptions] = useState(false);
  const [enablebooking, setEnableBooking] = useState(false);
  const [enableavailability, setEnableAvailability] = useState(false);
  const [techstatus, setTechStatus] = useState(false);
  const [role, setRole] = useState(null);
  const [reviews, setReviews] = useState([]);

  const [multiselectzone, setMultiselectzone] = useState([]);
  const [zonesData, setZonesData] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [myZip, setMyZip] = useState('');
  const [zipCodes, setZipCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  
  useEffect(() => {
    setZipCodes(servicezone.map((item) => item.zip_code).join(","));
  }, [servicezone]);

  const handlechangezipcodes = (event) => {
    const inputValue = event.target.value;
    // Validate input using regex: only numbers and commas allowed
    const validInput = /^[0-9,]*$/.test(inputValue);
    if (validInput) {
      setZipCodes(inputValue);
    }
  };

  const getservicezone = (zoneID) => { 

    getservicezoneApi(zoneID).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;

        //add new array of servicezone in servicezone array
        setServicezone((prevUserData) => ([
          ...prevUserData,
          ...apiResponse.data
        ]));


      }
      else { 
        alert("No data found");
      }
    }
    );
  };

  const getzonesData = () => {
    getzones().then((res) => {
      if (res.code === 200) {
        if (res.data.status === "1") {
          const apiResponse = res.data;
          setZonesData(apiResponse.data);
        }
        else {
          console.log("Data didn`t not found!");
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getzonesData();
  }, []);

  const getrevieData = () => {
    const formData = new FormData();
    formData.append('staff_id', technicianDetails.id);
    formData.append('type', 0);
    getrevieforstaff(formData).then((res) => {
      if (res.code === 200) {
        if (res.data.status === 1) {
          const apiResponse = res.data;
          setReviews(apiResponse.data);
        }
        else {
          console.log("Data didn`t not found!");
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  //set timesheetoptions , enablebooking , enableavailability , techstatus from technicianDetails
  useEffect(() => {
    setMyZip(technicianDetails.my_zip);
    if (technicianDetails.timesheet_option !== null || technicianDetails.timesheet_option !== undefined) {
      const timesheetoptions = technicianDetails.timesheet_option.trim();
      if (timesheetoptions === "Y") {
        setTimesheetOptions(true);
      }
    } else {
      setTimesheetOptions(false);
    }
    if (technicianDetails.enable_booking != null) {
      const enable_booking = technicianDetails.enable_booking.trim();
      if (enable_booking == "Y") {
        setEnableBooking(true);
      }
    } else {
      setEnableBooking(false);
    }
    if (technicianDetails.enable_availabilty == "Y" || technicianDetails.enable_availabilty != null) {
      const enable_availabilty = technicianDetails.enable_availabilty.trim();
      if (enable_availabilty == "Y") {
        setEnableAvailability(true);
      }
    } else {
      setEnableAvailability(false);
    }
    if (technicianDetails.tech_status != null) {
      const tech_status = technicianDetails.tech_status.trim();
      if (tech_status == 0) {
        setTechStatus(true);
      }
    } else {
      setTechStatus(false);
    }

    if (technicianDetails.role != null) {
      const role = technicianDetails.role.trim();
      if (role == 'staff') {
        setRole('staff');
      } else {
        setRole('trainee');
      }
    } else {
      setRole('trainee');
    }
 

    if (technicianDetails.working_zone != null || technicianDetails.working_zone != undefined) {
      const workingZones = technicianDetails.working_zone.split(',').map(zoneValue => zoneValue.trim());
   

      // const initialSelected = zonesData.map(zone => {
      //   if (workingZones.includes(zone.value)) {
      //     return zone;
      //   }
      // }).filter(zone => zone !== undefined);

      setSelectedZone(technicianDetails.working_zone);
    }

    setZipCodes(technicianDetails.zip);

    getrevieData();

  }, [technicianDetails]);


  // const getmultiselectzone = (selectedOptions) => {
  //   setSelectedZone(selectedOptions);
  //   const selectedValues = selectedOptions.map(option => option.value).join(',');
  //   technicianDetails.working_zone = selectedValues;
  //   // Any other logic you want to perform with the selected values
  // };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setTechnicianDetails((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    })); 
  };

  const updateTechnician = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('technician_id', technicianDetails.id);
    formData.append('name', technicianDetails.fullname);
    formData.append('email', technicianDetails.email);
    formData.append('phone_number', technicianDetails.phone);
    formData.append('keap_id', technicianDetails.keap_id);
    formData.append('address', technicianDetails.address);
    formData.append('city', technicianDetails.city);
    formData.append('state', technicianDetails.state);
    formData.append('country', technicianDetails.country);
    formData.append('description', technicianDetails.description);
    formData.append('my_zip', myZip);



    if (servicezone.length > 0) {
      const zip_codes = [];
      for (let i = 0; i < servicezone.length; i++) {
        zip_codes.push(servicezone[i].zip_code);
      }
      formData.append('zipcode', zip_codes);
    }
    else {
      formData.append('zipcode', technicianDetails.zip);
    }

    // if (selectedZone.length > 0) {
    //   const zone_ids = [];
    //   for (let i = 0; i < selectedZone.length; i++) {
    //     zone_ids.push(selectedZone[i].value);
    //   }
    //   formData.append('working_zone', zone_ids);
    // }
    // else {
    //   formData.append('working_zone', technicianDetails.working_zone);
    // }


    // if(selectedZone.length > 0 ){
    formData.append('working_zone', selectedZone);
    // }
    // else {
    //   formData.append('working_zone', technicianDetails.working_zone);
    // }





    const service_ids = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      service_ids.push(selectedOptions[i].value);
    }
    formData.append('service_ids', service_ids);

    if (timesheetoptions === true) {
      formData.append('timesheet_option_for_teach', "Y");
    } else {
      formData.append('timesheet_option_for_teach', "N");
    }

    if (enablebooking === true) {
      formData.append('enable_booking', "Y");
    } else {
      formData.append('enable_booking', "N");
    }

    if (enableavailability === true) {
      formData.append('enable_availabilty', "Y");
    } else {
      formData.append('enable_availabilty', "N");
    }

    if (techstatus === true) {
      formData.append('tech_status', 0);
    } else {
      formData.append('tech_status', 1);
    }

    formData.append('role', role);


    // formData.append('timesheet_option_for_teach',timesheetoptions );
    // formData.append('enable_booking', enablebooking);
    // formData.append('enable_availabilty', enableavailability);
    // formData.append('tech_status', techstatus);

    edittechnician(formData).then((res) => {
      if (res.code === 200) {
        alert(res.data.message);
        window.location.reload();
      }
      else {
        alert("Something went wrong");
      }
    }).catch((err) => {
      console.log("err", err);
    }).finally(() => {
      setLoading(false);
    });

  };

  const getmultiselectzone = (e) => {
 
    setServicezone([]); //set servicezone empty
    getservicezone(e);
    let index = servicezone.findIndex(x => x.id === e);
    if (index !== -1) {
      servicezone.splice(index, 1);
    }


    // e.forEach(element => {
    //   getservicezone(element.value);
    // });





    // e.forEach(element => {
    //   console.log("element.value", element.value);
    //   let index = servicezone.findIndex(x => x.id === element.value);
    //   if (index !== -1) {
    //     servicezone.splice(index, 1);
    //   }
    // });
  };

  const getdata = zonesData.map((item) => {
    return { value: item.id, zone_name: item.zone_name };
  });

  const updatePassword = (e) => {
    if (password === "") {
      alert("Please enter password");
      return;
    }
    if (password !== confirmpassword) {
      alert("Password and confirm password should be same");
      return;
    }

    const formData = new FormData();
    formData.append('technician_id', technicianDetails.id);
    formData.append('password', password);
    changepassword(formData).then((res) => {
      if (res.code === 200) {
        alert(res.data.message);
        window.location.reload();
      }
      else {
        alert("Something went wrong");
      }
    }).catch((err) => {
      console.log("err", err);
    });
  }


  return (
    <Tabs
      defaultActiveKey="home"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="home" title="Technician Details">
        <form id='form'>
          <div className="row custom-pt-20">
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                  <input type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Name"
                    value={technicianDetails.fullname}
                    name="fullname"
                    onChange={handleEditInputChange} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                  <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="email" value={technicianDetails.email} name='email' onChange={handleEditInputChange} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Contact</label>
                  <input type="text" className="form-control" id="exampleFormControlInput1" name='phone' onChange={handleEditInputChange} placeholder="Phone Number" value={technicianDetails.phone} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Keap Id</label>
                  <input type="number" className="form-control" id="exampleFormControlInput1" name='keap_id' onChange={handleEditInputChange} value={technicianDetails.keap_id} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Description</label>
                  <input type="text" className="form-control" id="exampleFormControlInput1" name='description' onChange={handleEditInputChange} value={technicianDetails.description} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Address</label>
                  <Form.Control as="textarea" rows={3} value={technicianDetails.address} name='address' onChange={handleEditInputChange} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>State</Form.Label>
                {/* <Form.Select aria-label="Default select example" className="custom-select-box">
                  <option>Select State</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}
                <input type="text" className="form-control" id="exampleFormControlInput1" name='state' onChange={handleEditInputChange} value={technicianDetails.state} />
              </Form.Group>
            </div>
            <div className="col-md-6 mb-3">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>City</Form.Label>
                {/* <Form.Select aria-label="Default select example" className="custom-select-box">
                  <option>Select City</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}
                <input type="text" className="form-control" id="exampleFormControlInput1" name='city' onChange={handleEditInputChange} value={technicianDetails.city} />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Zip code</label>
                  <input type="text" className="form-control" id="exampleFormControlInput1" name='zone' onChange={
                    (e) => { setMyZip(e.target.value) } //setMyZip(e.target.value)
                  } placeholder="zone" value={myZip} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Country</Form.Label>
                <input type="text" className="form-control" id="exampleFormControlInput1" name='country' onChange={handleEditInputChange} value={technicianDetails.country} />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Services</label>
                  <Typeahead
                    id="basic-typeahead-multiple"
                    labelKey="label" // Use 'zone_name' property as the label for options
                    multiple
                    options={services}
                    placeholder="Choose a services..."
                    selected={selectedOptions}
                    onChange={(selectedOptions) => {
                      // Assuming getmultiselectzone accepts an array of selected options
                      setSelectedOptions(selectedOptions);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="row"> <h5>Working Area</h5> </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Zone</label>
                  {zonesData && (
                    // <Select
                    //   options={zonesData}
                    //   defaultValue={selectedZone}
                    //   className="basic-multi-select"
                    //   classNamePrefix="select"
                    //   isMulti
                    //   name='zone'
                    //   onChange={
                    //     (e) => {
                    //       console.log("eaminesh", e);
                    //       getmultiselectzone(e);
                    //       setSelectedZone(e);
                    //     }
                    //   }
                    // />

                    <Form.Select aria-label="Default select example" onChange={
                      (e) => {
                        setSelectedZone(e.target.value);
                        getmultiselectzone(e.target.value);
                      }
                    } className="custom-select-box">
                      <option>Select Zone</option>
                      {zonesData.map((item) => {
                        return (
                          <option value={item.value} selected={item.value === technicianDetails.working_zone} >{item.label}</option>
                        )
                      })}

                    </Form.Select>



                  )}
                  {/* <Typeahead
                    id="basic-typeahead-multiple"
                    labelKey="zone_name" // Use 'zone_name' property as the label for options
                    multiple
                    options={getdata}
                    placeholder="Choose a state..."
                    selected={selectedZone}
                    onChange={(selectedOptions) => {
                      // Assuming getmultiselectzone accepts an array of selected options
                      getmultiselectzone(selectedOptions);
                      setSelectedZone(selectedOptions);
                    }}
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Zip Code</label>
                  <Form.Control as="textarea" rows={3} value={zipCodes} name='zip' onChange={handlechangezipcodes} />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ display: 'none' }}>
            <div className="col-md-6 mb-3">
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Services</Form.Label>
                {
                  selectedOptions && (
                    <Select
                      isMulti
                      defaultValue={selectedOptions}
                      options={services}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name='services'
                      onChange={
                        (e) => {
                          setEditTechnicianServices(e)
                        }
                      }
                    />
                  )
                }
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="content-body-form">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Timesheet Option For Teach."
                  checked={timesheetoptions}
                  onChange={
                    (e) => {
                      setTimesheetOptions(e.target.checked)
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="content-body-form">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Enable Booking"
                  // checked={technicianDetails.enable_booking == 'Y' ? true : false}
                  // name='enable_booking' 
                  // value= {technicianDetails.enable_booking}
                  // onChange={handleEditInputChange}
                  checked={enablebooking}
                  onChange={
                    (e) => {
                      setEnableBooking(e.target.checked)
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="content-body-form">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Enable Availability"
                  // checked={technicianDetails.enable_availability == 'N' ? true : false}
                  // value = {technicianDetails.enable_availability}
                  // name='enable_availability' 
                  // onChange={handleEditInputChange}
                  checked={enableavailability}
                  onChange={
                    (e) => {
                      setEnableAvailability(e.target.checked)
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="content-body-form">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Technician Status"
                  // checked={technicianDetails.tech_status == '0' ? true : false}
                  // value = {technicianDetails.tech_status}
                  // name='tech_status'
                  // onChange={handleEditInputChange}
                  checked={techstatus}
                  onChange={
                    (e) => {
                      setTechStatus(e.target.checked)
                    }
                  }

                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="content-body-form">
                {/* <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Tainee Status"
                  // checked={technicianDetails.tech_status == '0' ? true : false}
                  // value = {technicianDetails.tech_status}
                  // name='tech_status'
                  // onChange={handleEditInputChange}
                  checked={taineestatus}
                  onChange={
                    (e) => {
                      setTaineeStatus(e.target.checked)
                    }
                  }

                /> */}
                <Form.Label>Role</Form.Label>
                <Form>
                  {["radio"].map((type) => (
                    <div
                      key={`inline-${type}`}
                      className="popup-radios mb-3"
                    >
                      <Form.Check
                        inline
                        label="Staff"
                        name="group1"
                        checked={role == 'staff'}
                        type={type}
                        id={`inline-${type}-2`}
                        onClick={(e) => setRole('staff')}
                      />
                      <Form.Check
                        inline
                        label="Trainee"
                        name="group1"
                        checked={role == 'trainee'}
                        type={type}
                        id={`inline-${type}-1`}
                        onClick={(e) => setRole('trainee')}
                      />

                    </div>
                  ))}
                </Form>


              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className='form-btn'>
                <div className='form-btn-right'>
                  {
                    loading ? (
                      <Button className="btn bg-green-dark action-btn btn-green" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={updateTechnician}>
                        Save
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </Tab>
      <Tab eventKey="profile" title="Technician bookings">
        <Informasi />
      </Tab>
      <Tab eventKey="longer-tab" title="Technician Availability">
        <div className='card-custom card-custom-mr'>
          <div className='card-header-custom'>
            <h5 className="tab-content-title">Schedule</h5>
          </div>
          <div className='card-body'>
            <TabData2 staffid={technicianDetails.id} />
          </div>
        </div>
      </Tab>
      <Tab eventKey="password" title="Security">
        <form id='form'>
          <div className="row custom-pt-20">
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Password</label>
                  <input type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    id="exampleFormControlInput1"
                    placeholder="password"
                    name="password" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content-body-form">
                <div className="form-group mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Confirm Password</label>
                  <input type="password" className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} id="exampleFormControlInput1" placeholder="confirm password" name='confirm' />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className='form-btn'>
                <div className='form-btn-right'>
                  {
                    loading ? (
                      <Button className="btn bg-green-dark action-btn btn-green" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={updatePassword}>
                        Save
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </Tab>
      <Tab eventKey="review" title="Review">
        <form id='form'>
          <div className="row custom-pt-20">

            {
              reviews && reviews.length > 0 ? (
                reviews && reviews.map((review, index) => (
                  <div className="col-md-12 mb-3">
                    <div className="content-body-form">
                      <Card>
                        <Card.Header className="card-header-custom">
                          <div className='card-header-name'>{review.review_title} - </div>
                          <div className="form-rating new-line">
                            {
                              Array.from({ length: 5 }, (_, i) => (
                                i < review.star ? <BsStarFill key={i} /> : <BsStar key={i} />
                              ))
                            }

                          </div>
                        </Card.Header>
                        <Card.Body>
                          <blockquote className="blockquote mb-0">
                            <p>
                              {' '}{review.review_text}.{' '}
                            </p>
                            <footer className="blockquote-footer">
                              Review by <cite title="Source Title">{review.reviewer_name}</cite>
                            </footer>
                          </blockquote>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Created {getTimeAgo(review.created)}</small>
                        </Card.Footer>
                      </Card>
                    </div>
                  </div>
                ))) : (
                <div className="col-md-12 mb-3">
                  <div className="content-body-form">
                    <Card>
                      <Card.Body>
                        <p>
                          {' '}No reviews yet.{' '}
                        </p>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              )}
          </div>

        </form>
      </Tab>


    </Tabs>
  );
}


function getTimeAgo(timestamp) {
  // Convert the timestamp to milliseconds
  let currentTime = new Date().getTime();
  let targetTime = new Date(timestamp).getTime();

  // Calculate the time difference in milliseconds
  let difference = currentTime - targetTime;

  // Convert the time difference to seconds, minutes, hours, and days
  let seconds = Math.floor(difference / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  // Format the time ago
  let formattedTimeAgo = '';

  if (days > 0) {
    formattedTimeAgo += `${days} day${days > 1 ? 's' : ''} `;
  }
  if (hours > 0) {
    formattedTimeAgo += `${hours % 24} hour${hours % 24 > 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    formattedTimeAgo += `${minutes % 60} minute${minutes % 60 > 1 ? 's' : ''} `;
  }
  if (seconds > 0 && days === 0 && hours === 0 && minutes === 0) {
    formattedTimeAgo = 'Just now';
  } else {
    formattedTimeAgo += 'ago';
  }

  return formattedTimeAgo.trim();
}

export default TabData;