import React from "react";
import { useState,useEffect } from "react";
import Informasi from "../Components/DataTable/Informasi";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Crm.css";
import { Roller } from "react-awesome-spinners";


function Crm() {
    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 2000);
    // }, []);
    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fulid">
                                            <div className="">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header">
                                                            <h5 className="card-title">CRM</h5>
                                                        </div>
                                                        <div className="table-card-body-inner">
                                                            <Informasi />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Crm;