import React, { useState, useEffect, useContext } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { informasiList } from './repository';
import pagination from './pagination';
import SearchBox from './SearchBox';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import { RiAddFill } from 'react-icons/ri';
import './table.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { BsCalendarDate } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import Delete from "../Delete";
import { SettingContext } from "../../Setting/SettingContext";
import { getzones } from "../../Api/settingApi";
import { addzonesData } from "../../Api/settingApi";
import { getzonesbyId } from "../../Api/settingApi";
import { updatezoneDataApi } from "../../Api/settingApi";
import { deletezoneDataApi } from "../../Api/settingApi";




const ZoneTable = () => {

    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings, paymenetmethod, setPaymenetmethod, emailData, setEmailData, emailTemplate, setEmailTemplate, promocodeData, setPromocodeData, zonesdata, setZonesData] = useContext(SettingContext);

    useEffect(() => {
        
    }, [zonesdata])


    const [show05, setShow05] = useState(false);

    const handleClose05 = () => setShow05(false);
    const handleShow05 = () => setShow05(true);

    const [show005, setShow005] = useState(false);

    const handleClose005 = () => setShow005(false);
    const handleShow005 = () => setShow005(true);

    const [selected, setSelected] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [zoneName, setZoneName] = useState('');
    const [zoneid, setZoneId] = useState('');


    const saveZoneData = () => {
        const formData = new FormData();
        formData.append('zoneName', zoneName);

        addzonesData(formData).then((res) => {
            if (res.code === 200) {
                SearchBox();
                handleClose05();
            }
        }).catch((err) => {
            console.log(err);
        });
    }



    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Delete-Active-bttn me-2" onClick={() => {
                    deletezoneData(row.id)
                }}><RiDeleteBin6Line /></Button>
                <Button className="Edit-Active-bttn" onClick={() => { handleEdit(row.id); }} ><FaRegEdit /></Button>
            </div>
        );
    }


    const columns = [
        {
            dataField: 'zone_name',
            text: 'Zone Name',
            sort: true
        },
        {
            dataField: 'action',
            text: 'ACTION',
            formatter: optionFormatter,
        }];

    useEffect(() => {
        SearchBox();
    }, [searchText])

    const SearchBox = () => {
        getzones(searchText).then((res) => {
            if (res.code === 200) {
                if (res.data.status === '1') {
                    const apiResponse = res.data;

                    setZonesData(apiResponse.data);
                }
                else {
                    setZonesData([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const deletezoneData = (id) => {
        if (window.confirm('Are you sure you want to delete?')) {
            const formData = new FormData();
            formData.append('id', id);

            deletezoneDataApi(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === 1) {
                        SearchBox();
                    }
                    else {
                        setZonesData([]);
                    }
                }
            }).catch((err) => {
                console.log(err);
            });

        }
    }

    const handleEdit = (id) => {
        const formData = new FormData();
        formData.append('id', id);

        getzonesbyId(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === '1') {
                    const apiResponse = res.data;
                    const data = apiResponse.data;
                    setZoneName(data.zone_name);
                    setZoneId(data.id);
                    handleShow005();
                }
                else {
                    setZonesData([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "zoneName") {
            setZoneName(value);
        }
    }

    const updatezoneData = () => {
        const formData = new FormData();
        formData.append('zoneId', zoneid);
        formData.append('zoneName', zoneName);

        
        updatezoneDataApi(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === 1) {
                    SearchBox();
                    handleClose005();
                }
                else {
                    setZonesData([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }



    return (
        <>

            {/* New Promocode modal start here */}
            <Modal show={show05} onHide={handleClose05} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Zone</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Zone Name</Form.Label>
                                <Form.Control type="text" placeholder="ZoneName" value={zoneName} onChange={(e) => { setZoneName(e.target.value) }} />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={saveZoneData}> Save </button>
                    <button className="Cancel-bttn btn" onClick={handleClose05}> Cancel </button>
                </Modal.Footer>
            </Modal>
            {/* modal End here */}

            {/* Edit Promocode modal start here */}
            <Modal show={show005} onHide={handleClose005} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Zone</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Zone Name</Form.Label>
                                <Form.Control type="text" value={zoneName || ""} name="zoneName" onChange={handleEditInputChange} placeholder="Zone Name" />
                                <Form.Control type="hidden" value={zoneid || ""} name="zoneid" placeholder="Promocode Id" />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={updatezoneData}> Update </button>
                    <button className="Cancel-bttn btn" onClick={handleClose005}> Cancel </button>
                </Modal.Footer>
            </Modal>
            {/* modal End here */}

            <div className="animated fadeIn" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex table-card-header">
                                <Form.Group>
                                    <div className='d-flex'>
                                        <div addonType="prepend">
                                        </div>
                                        <Form.Control type="text" placeholder="Keywords..." onChange={
                                            (e) => {
                                                setSearchText(e.target.value)
                                            }
                                        } />
                                    </div>
                                </Form.Group>
                                <Button onClick={handleShow05} className="btn btn-sm float-right add-user-btn"> <RiAddFill /> Add New</Button>
                            </div>
                            <div className="card-body">
                                <BootstrapTable bootstrap4 keyField='id' data={zonesdata} columns={columns} pagination={pagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ZoneTable;