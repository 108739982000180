import React, { useEffect, useState, useContext } from "react";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import "./Setting.css";
import { FaRegBuilding } from "react-icons/fa";
import { FiSettings, FiSend } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { BsTag } from "react-icons/bs";
import { MdPayment, MdOutlineDesignServices } from "react-icons/md";
import TabData1 from "../Components/SettingTabs/TabeData1";
import TabData2 from "../Components/SettingTabs/TabData2";
import { RiAddFill ,RiLockPasswordFill } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import TabData3 from "../Components/SettingTabs/TabData3";
import TabData4 from "../Components/SettingTabs/TabData4";
import Informasi from "../Components/PromocodesDataTable/Informasi";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import TabData5 from "../Components/SettingTabs/TabData5";
import TabData6 from "../Components/SettingTabs/TabData6";
import TabData7 from "../Components/SettingTabs/TabData7";
import { SettingContext } from "./SettingContext";
import { companyData } from "../Api/settingApi";
import { companyservicearea } from "../Api/settingApi";
import { addservicezone } from "../Api/settingApi";
import { getgeneralsettings } from "../Api/settingApi";
import { getpaymentsettings } from "../Api/settingApi";
import { getnotificationsettings } from "../Api/settingApi";
import { getemailtemplatesetting } from "../Api/settingApi";
import { getSMSStatusAPI } from "../Api/settingApi";
import { getzones } from "../Api/settingApi";
import { getmasterpass } from "../Api/settingApi";
import { getpromocode ,getsearchpricestatus } from "../Api/settingApi";
import { Roller } from "react-awesome-spinners";
import ZoneTable from "../Components/ZoneDataTable/Informasi";
import TabData8 from "../Components/SettingTabs/TabData8";
import TabData9 from "../Components/SettingTabs/TabData9";
import { MdOutlinePriceChange } from "react-icons/md";

function Setting() {

    const [companyInfo, setCompanyInfo,companyServiceArea , setCompanyServiceArea,generalSettings , setGeneralSettings,paymenetmethod , setPaymenetmethod,emailData, setEmailData,emailTemplate, setEmailTemplate,promocodeData , setPromocodeData,zonesdata , setZonesData , smsstatus , setSmsstatus,masterpassword , setMasterpassword,searcpricestatus , setSearcpricestatus] = useContext(SettingContext);
    const [zonename, setZonename] = useState('');
    const [zipcode, setZipcode] = useState('');

    const [show08, setShow08] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleClose08 = () => setShow08(false);
    const handleShow08 = () => setShow08(true);

    const getcompanyInfo = () => {
        setLoading(true);
        companyData().then((res) => {
            if (res.code === 200) {
                setLoading(false);
                const apiResponse = res.data;
                setCompanyInfo(apiResponse.data);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        setLoading(true);
        getcompanyInfo();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const getcompanyServiceArea = () => {
        // setLoading(true);
        companyservicearea().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                setCompanyServiceArea(apiResponse.data);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const SaveServiceArea = () => {
        const formData = new FormData();
        formData.append('zone_name', zonename);
        formData.append('zip_code', zipcode);
        addservicezone(formData).then((res) => {
            if (res.code === 200) {
                if(res.data.status === "success"){
                    alert(res.data.message);
                    getcompanyServiceArea();
                    handleClose08();
                }else{
                    alert(res.data.message);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getgeneralInfo = () => {
        // setLoading(true);
        getgeneralsettings().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                if (res.data.status === "success") {
                    const apiResponse = res.data;
                    setGeneralSettings(apiResponse.data);
                }
                else {
                    alert(res.data.message)
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getPaymentMethod = () => {
        // setLoading(true);
        getpaymentsettings().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                if (res.data.status === "success") {
                    const apiResponse = res.data;
                    setPaymenetmethod(apiResponse.data);
                }
                else {
                    alert(res.data.message)
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getEmailData = () => {
        // setLoading(true);
        getnotificationsettings().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                if (res.data.status === "success") {
                    const apiResponse = res.data;
                    setEmailData(apiResponse.data);
                }
                else {
                    alert("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getEmailTemplate = () => {
        // setLoading(true);
        getemailtemplatesetting().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                if (res.data.status === "success") {
                    const apiResponse = res.data;
                    setEmailTemplate(apiResponse.data);
                }
                else {
                    alert("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getSMSStatus = () => {
        // setLoading(true);
        getSMSStatusAPI().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setSmsstatus(apiResponse.data)
                }
                else {
                    alert("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getpromocodeData = () => {
        // setLoading(true);
        getpromocode().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                if (res.data.status === "success") {
                    const apiResponse = res.data;
                    setPromocodeData(apiResponse.data);
                }
                else {
                    console.log("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getzonesData = () => {
        getzones().then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setZonesData(apiResponse.data);
                }
                else {
                    console.log("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });   
    }

    const getmasterpassword = () => {
        getmasterpass().then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setMasterpassword(apiResponse.data);
                }
                else {
                    console.log("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });   
    }

    const getsearchprice = () => {
        getsearchpricestatus().then((res) => {
            if (res.code === 200) {
                if (res.data.status === 1) {
                    const apiResponse = res.data;
                    setSearcpricestatus(apiResponse.data);
                }
                else {
                    console.log("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });   
    }



    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    {/* new Servicezone modal start here */}
                    <Modal show={show08} onHide={handleClose08} className="Sidebar-Modal-end">
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Zip Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='custum-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Zone Name</Form.Label>
                                        <select className="form-control" onChange={(e) => { setZonename(e.target.value); }}>
                                            <option value="">Select Zone</option>
                                            {
                                                zonesdata && zonesdata.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.zone_name}</option>
                                                ))

                                            }
                                            
                                        </select>

                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control type="text" onChange={(e) => { setZipcode(e.target.value); }} placeholder="" />
                                    </Form.Group>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="Active-bttn btn" onClick={SaveServiceArea}> Save </button>
                            <button className="Cancel-bttn btn" onClick={handleClose08}> Cancel </button>
                        </Modal.Footer>
                    </Modal>
                    {/* modal end here */}

                    <div className="technician-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                            <Header />
                        </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="tab-content-body">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                <Row>
                                                    <div className="col-xl-3 col-lg-4 col-md-4">
                                                        <div className="tab-card">
                                                            <div className="tab-card-body">
                                                                <div className="tab-content">
                                                                    <Nav variant="pills" className="flex-column">
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="first" className="technician-tab-link" onClick={getcompanyInfo} > <div className="user-profile-box"><FaRegBuilding /></div> <span className="tab-user-name"  >Company</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="second" className="technician-tab-link" onClick={() =>{
                                                                                getcompanyServiceArea();
                                                                                getzonesData();
                                                                            }}> <div className="user-profile-box"><MdOutlineDesignServices /></div> <span className="tab-user-name" >Zip code</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="third" className="technician-tab-link" onClick={getgeneralInfo} > <div className="user-profile-box"><FiSettings /></div> <span className="tab-user-name" >General</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="fourth" className="technician-tab-link" onClick={getPaymentMethod}> <div className="user-profile-box"><MdPayment /></div> <span className="tab-user-name" >Payment</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item style={{display:'none'}}>
                                                                            <Nav.Link eventKey="fifth" className="technician-tab-link" onClick={getEmailData}> <div className="user-profile-box"><FiSend /></div> <span className="tab-user-name" >Email Notification</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="sixth" className="technician-tab-link" onClick={getEmailTemplate}> <div className="user-profile-box"><HiOutlineMail /></div> <span className="tab-user-name" >Email Template</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="nine" className="technician-tab-link" onClick={getSMSStatus}> <div className="user-profile-box"><HiOutlineMail /></div> <span className="tab-user-name" >SMS Template</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="seventh" className="technician-tab-link" onClick={getpromocodeData}> <div className="user-profile-box"><BsTag /></div> <span className="tab-user-name" >Promocode</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="eighth" className="technician-tab-link" onClick={getzonesData}> <div className="user-profile-box"><MdOutlineDesignServices /></div> <span className="tab-user-name" >Zone</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="tenth" className="technician-tab-link" onClick={getmasterpassword}> <div className="user-profile-box"><RiLockPasswordFill /></div> <span className="tab-user-name" >Master Password</span></Nav.Link>
                                                                        </Nav.Item>
                                                                        {/* <Nav.Item>
                                                                            <Nav.Link eventKey="eleventh" className="technician-tab-link" onClick={getsearchprice}> <div className="user-profile-box"><MdOutlinePriceChange /></div> <span className="tab-user-name" >Surge Price</span></Nav.Link>
                                                                        </Nav.Item> */}
                                                                    </Nav>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-9 col-lg-8 col-md-8">
                                                        <div className="tab-content bx-1">
                                                            <Tab.Content>
                                                                <Tab.Pane eventKey="first">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Company Info Settings</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData1 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="second">
                                                                    <div className="tab-content-header add-btn d-flex">
                                                                        <h5 className="tab-content-title">Zip Code</h5>
                                                                        <div className="tab-content-header-right">
                                                                            <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow08} > <RiAddFill />Add Zip Code</Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData2 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="third">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">General Settings</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData3 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="fourth">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Payment Settings</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData4 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="seventh">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Promocodes</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <div className="table-card-header mb-4 d-flex justify-content-between">
                                                                            <h5 className="card-title">Promocodes list</h5>
                                                                        </div>
                                                                        <div className="table-card-body-inner">
                                                                            <Informasi />
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>

                                                                <Tab.Pane eventKey="eighth">
                                                                    <div className="tab-content-header add-btn d-flex">
                                                                        <h5 className="tab-content-title">Zone</h5>
                                                                        {/* <div className="tab-content-header-right">
                                                                            <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow08} > <RiAddFill />Zone list</Button>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <ZoneTable />
                                                                    </div>
                                                                </Tab.Pane>

                                                                <Tab.Pane eventKey="fifth" style={{display:'none'}}>
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Email Settings</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData5 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="sixth">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Client Email Template</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData6 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="nine">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">SMS Template</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData7 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="tenth">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Master Password</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData8 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="eleventh">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Surge Price</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <TabData9 />
                                                                    </div>
                                                                </Tab.Pane>
                                                                {/* <Tab.Pane eventKey="">
                                                                    <div className="tab-content-header">
                                                                        <h5 className="tab-content-title">Zone</h5>
                                                                    </div>
                                                                    <div className="tab-content-body">
                                                                        <ZoneTable />
                                                                    </div>
                                                                </Tab.Pane> */}
                                                            </Tab.Content>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Setting;