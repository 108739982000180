import React, { createContext, useState } from "react";

export const ModalPopContext = createContext();

export const ModalPopProvider = (props) => {
  const [showNewBooking, setShowNewBooking] = useState(false);
  const [showBookingDetails, setShowBookingDeatils] = useState(false);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [booking, setBooking] = useState([]);
  const [staffId, setStaffId] = useState(null);


  const updateCalendarData = (data) => {
    setBooking(data);
  };

  return (
    <ModalPopContext.Provider value={[showNewBooking, setShowNewBooking, showBookingDetails, setShowBookingDeatils, bookingDetails, setBookingDetails, dateData, setDateData, booking, setBooking, staffId, setStaffId, updateCalendarData]}>
      {props.children}
    </ModalPopContext.Provider>
  );
};



