import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Crm.css";
import { Roller } from "react-awesome-spinners";
import { Button, Form } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from "react-select";
import { RiAddFill } from "react-icons/ri";
import { addblogs } from "../Api/blogsapi";
import MyEditor from "./MyEditor";


function CreateBlog() {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const categorylist = {
        "guides-and-tutorials": "guides & tutorials",
        "user-stories": "user stories",
        "operations-and-observability": "operations & observability",
        "engineering-culture": "engineering culture",
        "news": "news",
    };


    const tagslist = [
        {
            value: "guides & tutorials",
            label: "guides & tutorials",
        },
        {
            value: "user stories",
            label: "user stories",
        },
        {
            value: "operations & observability",
            label: "operations & observability",
        },
        {
            value: "engineering culture",
            label: "engineering culture",
        },
        {
            value: "news",
            label: "news",
        },

    ]

    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [content, setContent] = useState("");
    const [category, setCategory] = useState("");
    const [publishdate, setPublishdate] = useState("");
    const [tags, setTags] = useState([]);
    const [editor, setEditor] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("author", author);
        formData.append("content", content);
        formData.append("category_id", category);
        formData.append("publish_date", publishdate);

        const tagsss = [];
        for (let i = 0; i < tags.length; i++) {
            tagsss.push(tags[i].value);
        }

        formData.append("tags", tagsss);

        addblogs(formData).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if(apiResponse.status === 1){
                    alert("Blog created successfully");
                    window.location.href = "/blogs";
                }else{
                    alert(apiResponse.message);
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((err) => {
            alert("Something went wrong");
        }).finally(() => {
            setLoading(false);
        })
    }


    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fulid">
                                            <div className="">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header">
                                                            <h5 className="card-title">Create Blogs</h5>
                                                        </div>
                                                        <div className="table-card-body">
                                                            <Form >
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <Form.Group className="mb-4" controlId="validationCustom01">
                                                                            <Form.Label className='custom-lable'>Title</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                className='custom-input'
                                                                                onChange={(e) => setTitle(e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="mb-4" controlId="validationCustom01">
                                                                            <Form.Label className='custom-lable'>Author</Form.Label>
                                                                            <Form.Select aria-label="Default select example" className='custom-input' onChange={(e) => setAuthor(e.target.value)}>
                                                                                <option>Select Author</option>
                                                                                <option value="William Shakespeare">William Shakespeare</option>
                                                                                <option value="George Orwell">George Orwell</option>
                                                                                <option value="J.K. Rowling">J.K. Rowling</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-12 mb-4">
                                                                        <Form.Group className="" controlId="validationCustom01">
                                                                            <Form.Label className='custom-lable'>Content</Form.Label>
                                                                        </Form.Group>
                                                                        {/* <CKEditor
                                                                            editor={ClassicEditor}
                                                                            data="<p>Hello from CKEditor&nbsp;5!</p>"
                                                                            onReady={editor => {
                                                                                console.log('Editor is ready to use!', editor);
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                if (editor) {
                                                                                    console.log(event);
                                                                                    setContent(editor.getData());
                                                                                } else {
                                                                                    console.error('Editor is undefined.');
                                                                                }
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                console.log('Blur.', editor);
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                console.log('Focus.', editor);
                                                                            }}
                                                                        /> */}

                                                                        <MyEditor  initialContent={content} onContentChange={(editorContent) => setContent(editorContent)}/>

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="mb-4" controlId="validationCustom01">
                                                                            <Form.Label className='custom-lable'>Publish Date</Form.Label>
                                                                            <Form.Control
                                                                                type="date"
                                                                                onChange={(e) => setPublishdate(e.target.value)}
                                                                                className='custom-input' />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="mb-4" controlId="validationCustom01">
                                                                            <Form.Label className='custom-lable'>Category</Form.Label>
                                                                            <Form.Select aria-label="Default select example" onChange={(e) => setCategory(e.target.value)} className='custom-input'>
                                                                                <option>Please select category id</option>
                                                                                {
                                                                                    Object.entries(categorylist).map(([key, value]) => (
                                                                                        <option key={key} value={key}>
                                                                                            {value}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <Form.Group className="mb-4" controlId="validationCustom01">
                                                                            <Form.Label className='custom-lable'>Tags</Form.Label>
                                                                            <Select
                                                                                height={50}
                                                                                isMulti
                                                                                className="basic-multi-select blog-tags-select"
                                                                                classNamePrefix="select"
                                                                                options={tagslist}
                                                                                onChange={(e) => {
                                                                                    setTags(e);
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <Button
                                                                            className="btn btn-sm float-right add-user-btn"
                                                                            onClick={handleSubmit}
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default CreateBlog;