import React, { useState, useEffect, useContext } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { informasiList } from './repository';
import pagination from './pagination';
import SearchBox from './SearchBox';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import { RiAddFill } from 'react-icons/ri';
import './table.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { BsCalendarDate } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import Delete from "../Delete";
import { SettingContext } from "../../Setting/SettingContext";
import { getpromocode } from "../../Api/settingApi";
import { addpromocode } from "../../Api/settingApi";
import { getpromocodeData } from "../../Api/settingApi";
import { updatepromocode } from "../../Api/settingApi";
import { deletepromocode } from "../../Api/settingApi";



const Informasi = () => {

    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings, paymenetmethod, setPaymenetmethod, emailData, setEmailData, emailTemplate, setEmailTemplate, promocodeData, setPromocodeData] = useContext(SettingContext);

    useEffect(() => {
    }, [promocodeData])


    const [show05, setShow05] = useState(false);

    const handleClose05 = () => setShow05(false);
    const handleShow05 = () => setShow05(true);

    const [show005, setShow005] = useState(false);

    const handleClose005 = () => setShow005(false);
    const handleShow005 = () => setShow005(true);

    const [selected, setSelected] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [promocodeId, setPromocodeId] = useState('');
    const [couponcode, setCouponcode] = useState('');
    const [coupantype, setCoupantype] = useState('');
    const [value, setValue] = useState('');
    const [limit, setLimit] = useState('');
    const [expiry, setExpiry] = useState('');

    
    const data = promocodeData && promocodeData.length > 0 ? promocodeData.map((item) => {
        return {
            coupon: item.id,
            couponcode: item.coupon_code,
            type: item.type == 1 ? "Percentage" : "Flat",
            value: item.value,
            limit: item.limits,
            used: item.used,
            expiry: item.expiry_date,
        }
    }) : [];




    const saveCoupanData = () => {
        const formData = new FormData();
        formData.append('coupon_code', couponcode);
        formData.append('coupon_type', coupantype);
        formData.append('coupon_value', value);
        formData.append('coupon_limit', limit);
        formData.append('coupon_expiry_date', expiry);

        addpromocode(formData).then((res) => {
            if (res.code === 200) {
                SearchBox();
                handleClose05();
            }
        }).catch((err) => {
            console.log(err);
        });
    }



    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Delete-Active-bttn me-2" onClick={() => {
                    deletepromocodeData(row.coupon)
                }}><RiDeleteBin6Line /></Button>
                <Button className="Edit-Active-bttn" onClick={() => { handleEdit(row.coupon) }} ><FaRegEdit /></Button>
            </div>
        );
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id]);
        } else {
            setSelected(selected.filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids);
        } else {
            setSelected([]);
        }
    }

   

    const columns = [{
        dataField: 'coupon',
        text: 'COUPON#',
        sort: true
    }, {
        dataField: 'couponcode',
        text: 'COUPON CODE',
        sort: true
    }, {
        dataField: 'type',
        text: 'TYPE',
        sort: true
    },
    {
        dataField: 'limit',
        text: 'LIMIT',
        sort: true
    },
    {
        dataField: 'used',
        text: 'USED',
        sort: true
    },
    {
        dataField: 'value',
        text: 'VALUE',
        sort: true,
    },
    {
        dataField: 'expiry',
        text: 'EXP. DATE',
        sort: true,
    },
    {
        dataField: 'action',
        text: 'ACTION',
        formatter: optionFormatter,
    }];

    useEffect(() => {
        SearchBox();
    }, [searchText])

    const SearchBox = () => {
        getpromocode(searchText).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    const apiResponse = res.data;
                    setPromocodeData(apiResponse.data);

                }
                else {
                    setPromocodeData([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const deletepromocodeData = (id) => {
        //confiramation box
        if (window.confirm('Are you sure you want to delete?')) {
            const formData = new FormData();
            formData.append('id', id);

            deletepromocode(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === "success") {
                        SearchBox();
                    }
                    else {
                        setPromocodeData([]);
                    }
                }
            }).catch((err) => {
                console.log(err);
            });

        }
    }

    const handleEdit = (id) => {
        const formData = new FormData();
        formData.append('id', id);

        getpromocodeData(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    const apiResponse = res.data;
                    const data = apiResponse.data;
                    setPromocodeId(data.id);
                    setCouponcode(data.coupon_code);
                    setCoupantype(data.type);
                    setValue(data.value);
                    setLimit(data.limits);
                    setExpiry(data.expiry_date);
                    handleShow005();
                }
                else {
                    setPromocodeData([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "couponcode") {
            setCouponcode(value);
        }
        else if (name === "coupantype") {
            setCoupantype(value);
        }
        else if (name === "value") {
            setValue(value);
        }
        else if (name === "limit") {
            setLimit(value);
        }
        else if (name === "expiry") {
            setExpiry(value);
        }
    }

    const UpdatePromoCodeData = () => {
        const formData = new FormData();
        formData.append('id', promocodeId);
        formData.append('coupon_code', couponcode);
        formData.append('coupon_type', coupantype);
        formData.append('coupon_value', value);
        formData.append('coupon_limit', limit);
        formData.append('coupon_expiry_date', expiry);

        updatepromocode(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "success") {
                    SearchBox();
                    handleClose005();
                }
                else {
                    setPromocodeData([]);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }



    return (
        <>

            {/* New Promocode modal start here */}
            <Modal show={show05} onHide={handleClose05} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Promocode</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Coupon Code</Form.Label>
                                <Form.Control type="text" placeholder="Bedrooms" onChange={(e) => { setCouponcode(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Coupon Type</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={(e) => { setCoupantype(e.target.value) }}>
                                    <option>Select</option>
                                    <option value="1">Percentage</option>
                                    <option value="2">Flat</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Value</Form.Label>
                                <Form.Control type="text" placeholder="293" onChange={(e) => { setValue(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Limit</Form.Label>
                                <Form.Control type="text" placeholder="325" onChange={(e) => { setLimit(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Expiry Date</Form.Label>
                                <div className="input-group">
                                    <input type="date" className="form-control" placeholder=" " onChange={(e) => { setExpiry(e.target.value) }} />
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={saveCoupanData}> Save </button>
                    <button className="Cancel-bttn btn" onClick={handleClose05}> Cancel </button>
                </Modal.Footer>
            </Modal>
            {/* modal End here */}

            {/* Edit Promocode modal start here */}
            <Modal show={show005} onHide={handleClose005} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Promocode</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Coupon Code</Form.Label>
                                <Form.Control type="text" value={couponcode || ""} name="couponcode" onChange={handleEditInputChange} placeholder="Bedrooms" />
                                <Form.Control type="hidden" value={promocodeId || ""} name="promocodeId" placeholder="Bedrooms" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Coupon Type</Form.Label>
                                <Form.Select aria-label="Default select example" name="coupantype" onChange={handleEditInputChange}>
                                    <option>Select</option>
                                    <option value="1" selected={coupantype == 1 ? true : false}>Percentage</option>
                                    <option value="2" selected={coupantype == 2 ? true : false}>Flat</option>
                                </Form.Select>

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Value</Form.Label>
                                <Form.Control type="text" value={value || ""} name="value" onChange={handleEditInputChange} placeholder="293" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Limit</Form.Label>
                                <Form.Control type="text" value={limit || ""} name="limit" onChange={handleEditInputChange} placeholder="325" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Expiry Date</Form.Label>
                                <div className="input-group">
                                    <input type="date" className="form-control" value={expiry || ""} name="expiry" onChange={handleEditInputChange} placeholder=" " />
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={UpdatePromoCodeData}> Update </button>
                    <button className="Cancel-bttn btn" onClick={handleClose005}> Cancel </button>
                </Modal.Footer>
            </Modal>
            {/* modal End here */}

            <div className="animated fadeIn" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex table-card-header">
                                <Form.Group>
                                    <div className='d-flex'>
                                        <div addonType="prepend">
                                        </div>
                                        <Form.Control type="text" placeholder="Keywords..." onChange={
                                            (e) => {
                                                setSearchText(e.target.value)
                                            }
                                        } />
                                    </div>
                                </Form.Group>
                                <Button onClick={handleShow05} className="btn btn-sm float-right add-user-btn"> <RiAddFill /> Add New</Button>
                            </div>
                            <div className="card-body">
                                <BootstrapTable bootstrap4 keyField='id' data={data} columns={columns}  pagination={pagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Informasi;