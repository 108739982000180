import React, { useRef } from 'react';
import { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ImageCropper } from 'react-bootstrap-image-cropper';
import { SettingContext } from '../../Setting/SettingContext';
import { UpdateCompanyInfo } from '../../Api/settingApi';

function TabData1() {

    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings,paymenetmethod , setPaymenetmethod,emailData, setEmailData,emailTemplate, setEmailTemplate,promocodeData , setPromocodeData] = useContext(SettingContext);

    const [CompanyLogo, setCompanyLogo] = useState(null);

    useEffect(() => {
        if (companyInfo.logo) {
            setCompanyLogo(companyInfo.logo);
        }
    }, [companyInfo.logo]);



    const handleEditSettingImageChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setCompanyInfo((prevUserData) => ({
            ...prevUserData,
            image: file,
          }));
          setCompanyLogo(reader.result);
        };
      };


    const handleEditInputChange = (event) => {
        const { name, value } = event.target;
        setCompanyInfo((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        })); 
    };


    const onchangeHandle = (e) => {
        console.log('e.target.value', e.target.value);
        console.log('e.target.name', e.target.name);
        setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
    }

    const fileRef = useRef();
    const canvas = document.createElement('canvas');

    const SaveCompanyInfo = () => {
        const formData = new FormData();
        formData.append('language', companyInfo.language);
        formData.append('time_zone', companyInfo.time_zone);
        formData.append('company_name', companyInfo.company_name);
        formData.append('email', companyInfo.email);
        formData.append('country_code', companyInfo.country_code);
        formData.append('phone', companyInfo.phone);
        formData.append('address', companyInfo.address);
        formData.append('state', companyInfo.state);
        formData.append('city', companyInfo.city);
        formData.append('country', companyInfo.country);
        formData.append('zip_code', companyInfo.zip_code);
        formData.append('image',CompanyLogo);


        UpdateCompanyInfo(formData).then((response) => {
            if (response.code === 200) {
                console.log('response', response);
                if (response.data.status === "success") {
                    alert("Company Info Updated Successfully");
                    setCompanyInfo(response.data);
                }
                else {
                    alert("Company Info Not Updated");
                }
            } else {
                alert("Something went wrong");
            }
        });
    }

    function handleChange(croppedFile) { 
        console.log(fileRef.current);

        var width = 200;
        var height = 200;

        var canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        //upload image to database
        var reader = new FileReader();
        reader.onload = function (e) {  
            var img = new Image();
            img.onload = function () {
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                var dataURL = canvas.toDataURL("image/png");
                console.log('dataURL', dataURL);
                setCompanyInfo((prevUserData) => ({
                    ...prevUserData,
                    logo: dataURL,
                }));
            };
            img.src = e.target.result;
        };
        // reader.readAsDataURL(croppedFile);
        setCompanyLogo(croppedFile); 
    }
    return (
        <div>
            <form id="Company-Info-Settings">
                <div className="row" style={{display:"none"}}>
                    <div className="col-md-6">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Language</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" name="language" onChange={handleEditInputChange}>
                                <option>Select Language</option>
                                <option value="1" selected={companyInfo.language == 1 ? true : false}>English</option>
                                <option value="2" selected={companyInfo.language == 2 ? true : false}>Testing-English</option>
                                <option value="3" selected={companyInfo.language == 3 ? true : false}>Testing-Hindi</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Time Zone</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" name='time_zone' onChange={handleEditInputChange}>
                                <option>Select Time Zone</option>
                                <option value="1" selected={companyInfo.time_zone == 1 ? true : false}>One</option>
                                <option value="2" selected={companyInfo.time_zone == 2 ? true : false}>Two</option>
                                <option value="3" selected={companyInfo.time_zone == 3 ? true : false}>Three</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Company Name</label>
                                <input type="text" className="form-control" value={companyInfo.company_name || " "} name="company_name" onChange={handleEditInputChange} id="exampleFormControlInput1" placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput2" className="form-label">Email</label>
                                <input type="email" className="form-control" value={companyInfo.email || " "} name="email" id="exampleFormControlInput2" onChange={handleEditInputChange} placeholder="email" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput3" className="form-label">Country Code</label>
                                <input type="number" className="form-control" value={companyInfo.country_code || " "} name='country_code' id="exampleFormControlInput3" onChange={handleEditInputChange} placeholder="901254" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput4" className="form-label">Phone</label>
                                <input type="number" className="form-control" value={companyInfo.phone || " "} name='phone' id="exampleFormControlInput4" onChange={handleEditInputChange} placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput5" className="form-label">Address</label>
                                <Form.Control as="textarea" rows={3} value={companyInfo.address || " "} name='address' onChange={handleEditInputChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>State</Form.Label>
                            <input type="text" className="form-control" value={companyInfo.state || " "} name='state' id="exampleFormControlInput4" onChange={handleEditInputChange} placeholder="" />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>City</Form.Label>
                            <input type="text" className="form-control" value={companyInfo.city || " "} name='city' id="exampleFormControlInput4" onChange={handleEditInputChange} placeholder="" />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Country</Form.Label>
                            <input type="text" className="form-control" value={companyInfo.country || " "} name='country' id="exampleFormControlInput4" onChange={handleEditInputChange} placeholder="" />
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <div className="content-body-form">
                            <div className="form-group mb-3">
                                <label htmlFor="exampleFormControlInput6" className="form-label">Zip Code</label>
                                <input type="number" className="form-control" value={companyInfo.zip_code || " "} name='zip_code' id="exampleFormControlInput6" onChange={handleEditInputChange} placeholder="Zip Code" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="image-picker">
                        <label htmlFor="exampleFormControlInput6" className="form-label">Company Logo</label><br />
                        <ImageCropper
                            fileRef={fileRef}
                            onChange={handleChange}
                            cropOptions={{ aspect: 4 / 3, maxZoom: 10 }}
                            outputOptions={{ maxWidth: 200, maxHeight: 200 }}
                            previewOptions={{ width: 200, height: 200 }}
                            value={companyInfo.company_logo}
                            name='company_logo'
                            
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className='form-btn'>
                            <div className='form-btn-right'>
                                <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={SaveCompanyInfo}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default TabData1;