import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import BootstrapTable from 'react-bootstrap-table-next';
import pagination from './pagination';
import "./Stax.css";
import { Roller } from "react-awesome-spinners";
import { getstaxdataapi, getstaxdatabyemail } from "../Api/crm";
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";



function Getstaxdata() {
    const [loading, setLoading] = useState(false);
    // const [apiloader, setApiloader] = useState(false);
    const [staxdata, setStaxdata] = useState([]);
    // const [fitercontectids, setFiterContectids] = useState([]);
    // const [contectids, setContectids] = useState([]);
    // const [masterid, setMasterid] = useState(null);
    // const [error, setError] = useState(null);

    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const email = urlParams.get('email');

    console.log("staxdata", staxdata);

    const getstaxdata = () => {
        setLoading(true);
        getstaxdataapi().then((response) => {
            if (response.code === 200) {
                const apiResponse = response.data;
                if (apiResponse.status === 1) {
                    const fetchedMargedata = apiResponse.data;
                    setStaxdata(apiResponse.data);
                } else {
                    console.log(apiResponse);
                }
            }
        }).catch(function (err) {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    // const handleRadioChange = (e) => {
    //     // const selectedId = parseInt(e.target.value); // Parse the selected ID as an integer
    //     setMasterid(e.target.value); // Set the selected master ID

    //     const updatedFilteredContectIds = fitercontectids.filter((id) => id !== e.target.value);
    //     setContectids(updatedFilteredContectIds);
    // };

    useEffect(() => {
        getstaxdata();
    }, []);


    const [currentsatxuserid, setCurrentsatxuserid] = useState(0);




    const startstaxsync = async () => {
        for (let i = 1; i <= staxdata.length; i++) {

            let id = document.getElementById("user_id_" + i);
            let email = document.getElementById("user_email_" + i);
            let action = document.getElementById("user_action_" + i);

            setCurrentsatxuserid(i);
            const userEmail = email.textContent;
            const formData = new FormData();
            formData.append('user_email', userEmail);
            getstaxdatabyemail(formData).then((response) => {
                if (response.code === 200) {
                    const apiResponse = response.data;
                    if (apiResponse.status === 1) {
                        const fetchedMargedata = apiResponse.data;
                        setStaxdata((prevStaxdata) => {
                            const updatedData = [...prevStaxdata];
                            updatedData[i - 1].customer_id = fetchedMargedata.customer_id; // Update customer_id
                            return updatedData;
                        });
                        // setCurrentsatxuserid(0);
                    } else {
                        console.log(apiResponse);
                    }
                }
            }).catch(function (err) {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });


            console.log(`Iteration ${i}`);
            // Wait for 5 seconds before proceeding to the next iteration
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    };









    const columns = [
        {
            dataField: 'fullname',
            text: 'NAME',
        }, {
            dataField: 'user_email',
            text: 'EMAIL',
        }, {
            dataField: 'phone',
            text: 'PHONE',
        },
        {
            dataField: 'address',
            text: 'ADDRESS',
        },
        {
            text: 'STAX',
            formatter: (cell, row) => (
                <div>
                    {row.customer_id !== null ? "No" : "YEs"}
                </div>
            ),
        },

    ];

    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fulid">
                                            <div className="">
                                                <div className="table-card ">
                                                    <div className="card-body table-card-body">
                                                        <div className="table-card-header">
                                                            <h5 className="card-title">Stax data</h5>
                                                        </div>
                                                        <div className="table-card-body-inner">
                                                            <div className="animated fadeIn">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="card">
                                                                            <div className="card-header d-flex justify-content-between table-card-header" >
                                                                                <div className="card-header d-flex table-card-header justify-content-between align-items-center">
                                                                                    <div className="ml-auto d-flex">

                                                                                        <Button
                                                                                            onClick={startstaxsync}
                                                                                            className="btn btn-sm float-right add-user-btn"
                                                                                        >
                                                                                            Get stax id`s`
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body">

                                                                                <Table striped bordered hover>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>#</th>
                                                                                            <th>Name</th>
                                                                                            <th>Email</th>
                                                                                            <th>Mobile</th>
                                                                                            <th>Address</th>
                                                                                            <th>Stax</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {staxdata && staxdata.map((item, index) => {
                                                                                            const currentUserIndex = index + 1; // Adjust index to match your ID logic
                                                                                            return (
                                                                                                <React.Fragment key={currentUserIndex}>
                                                                                                    {currentUserIndex === currentsatxuserid && currentUserIndex < staxdata.length ? (
                                                                                                        <tr>
                                                                                                            <td colSpan={6} style={{ textAlign: 'center' }}>
                                                                                                                <Spinner
                                                                                                                    as="span"
                                                                                                                    animation="border"
                                                                                                                    size="sm"
                                                                                                                    role="status"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ) : (
                                                                                                        <tr id={`user_id_${currentUserIndex}`}>
                                                                                                            <td>{currentUserIndex}</td>
                                                                                                            <td>{item.name}</td>
                                                                                                            <td id={`user_email_${currentUserIndex}`}>{item.user_email}</td>
                                                                                                            <td>{item.phone}</td>
                                                                                                            <td>{item.address}</td>
                                                                                                            <td id={`user_action_${currentUserIndex}`}>
                                                                                                                {/* {item.customer_id !== "null" ? "No" : item.customer_id} */}
                                                                                                                {item.customer_id ? item.customer_id : "NO"}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}

                                                                                    </tbody>
                                                                                </Table>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Getstaxdata;