import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";


//get active staff
export const getactiveStaff = () => apiCall(`${API_URL}activestaff`, "GET", );

//get active staff
export const getinactivestaff = () => apiCall(`${API_URL}inactivestaff`, "GET", );

//getstaffbookings
export const getstaffbookings = (technician_id,start_date,end_date) => apiCall(`${API_URL}getTechnicianServices?technician_id=${technician_id}&start_date=${start_date}&end_date=${end_date}`, "GET", );

//getservicesmethods
export const getservicesmethods = () => apiCall(`${API_URL}getservicesmethods`, "GET", );

//edittechnician
export const edittechnician = (formData) => apiCall(`${API_URL}edittechnician`, "POST", formData);

//updateTechnicianAvailabilty 
export const updateTechnicianAvailabilty = (formData) => apiCall(`${API_URL}updateTechnicianAvailabilty`, "POST", formData);

//getTechnicianAvailabilty
export const getTechnicianAvailabilty = (id) => apiCall(`${API_URL}getTechnicianAvailabilty?technician_id=${id}`, "GET",);

//addTechnicianOffTime
export const addTechnicianOffTime = (formData) => apiCall(`${API_URL}addTechnicianOffTime`, "POST", formData);

//getTechnicianOffTime
export const getTechnicianOffTime = (id) => apiCall(`${API_URL}getTechnicianOffTime?technician_id=${id}`, "GET",);

//deleteTechnicianOffTime
export const deleteTechnicianOffTime = (formData) => apiCall(`${API_URL}deleteTechnicianOffTime`, "POST",formData);

//addmonthoffday
export const addmonthoffday = (formData) => apiCall(`${API_URL}addmonthoffday`, "POST", formData);

//deletemonthoffday
export const deletemonthoffday = (formData) => apiCall(`${API_URL}deletemonthoffday`, "POST", formData);


//offdaytechnician
export const offdaytechnician = (formData) => apiCall(`${API_URL}offdaytechnician`, "POST", formData);

//offdaytechnician
export const getoffdaytechnician = (id,selectYear) => apiCall(`${API_URL}getoffdaytechnician?technician_id=${id}&year=${selectYear}`, "GET", );

//deleteoffdaytechnician
export const deleteoffdaytechnician = (formData) => apiCall(`${API_URL}deleteoffdaytechnician`, "POST", formData);

//getStaffDatabyId
export const getStaffDatabyId = (id) => apiCall(`${API_URL}getStaffDatabyId?technician_id=${id}`, "GET", );

//getStaffDatabyId
export const getservicezoneApi = (zoneid) => apiCall(`${API_URL}getservicezoneApi?zoneid=${zoneid}`, "GET", );

//deleteoffdaytechnician
export const getzones = (formData) => apiCall(`${API_URL}getzonesbytechnicians`, "POST", formData);

//deleteoffdaytechnician
// export const TechnicianOffTimerequest = (id) => apiCall(`${API_URL}technicianOffTimerequest?technician_id=${id}`, "GET", );
export const TechnicianOffTimerequest = (StaffId, startTime, endTime,statustype, techname) => apiCall(`${API_URL}technicianOffTimerequest?StaffId=${StaffId}&startTime=${startTime}&endTime=${endTime}&statustype=${statustype}&techname=${techname}`, "GET", );

//deleteoffdaytechnician
export const approveTechnicianOffTimerquest = (formData) => apiCall(`${API_URL}approveTechnicianOffTimerquest`, "POST",formData);

//deleteoffdaytechnician
export const rejectTechnicianOffTimerquest = (formData) => apiCall(`${API_URL}rejectTechnicianOffTimerquest`, "POST",formData);

//deleteoffdaytechnician
export const getreport = (formData) => apiCall(`${API_URL}getreport`, "POST",formData);

//deleteoffdaytechnician
export const getstaffreportlist = (formData) => apiCall(`${API_URL}getreport`, "POST",formData);

//deleteoffdaytechnician
export const getnewncontactapi = () => apiCall(`${API_URL}getnewncontact`, "GET",[]);

//addTechnician
export const addTechnician = (formData) => apiCall(`${API_URL}addTechnician`, "POST",formData);

//changepassword
export const changepassword = (formData) => apiCall(`${API_URL}changepasswordtechnician`, "POST",formData);

//updateofftimerequest
export const updateofftimerequest = (formData) => apiCall(`${API_URL}updateofftimerequest`, "POST",formData);

//getrevieforstaff
export const getrevieforstaff = (formData) => apiCall(`${API_URL}getrevieforstaff`, "POST",formData);