import React, { useState, useContext, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
// import moment from 'moment';
import moment from "moment-timezone";
import { informasiList } from './repository';
import pagination from './pagination';
import SearchBox from './SearchBox';
import Button from 'react-bootstrap/Button';
import { RiAddFill } from 'react-icons/ri';
import './table.css';
import Icon from '../../assets/images/traffic-lights.png';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Profile from '../../assets/images/Avatar2.png';
import { FiCopy } from "react-icons/fi";
import Accordion from "../Accordion/Accordion";
import { ModalPopContext } from "../../Technician/ModalPopContext";
import { FormControl } from "react-bootstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { getstaffbookings } from "../../Api/technician";


const Informasi = () => {

    const [technicianDetails, setTechnicianDetails, serviceList, setServiceList, services, setServices, selectedOptions, setSelectedOptions] = useContext(ModalPopContext);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show02, setShow02] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);

    const [selected, setSelected] = useState([]);

    const datetimeFormatter = (cell, row) => {
        return moment(cell).format('hh:mm');
    }


    const handleDelete = (id) => {
        alert('delete :' + id);
    }

    const handleEdit = (id) => {
        alert('edit :' + id);
    }

    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button className="Yes-Active-bttn" onClick={(id) => handleEdit(row.id, window.event)}>Yes</Button>
            </div>
        );
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id]);
        } else {
            setSelected(selected.filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids);
        } else {
            setSelected([]);
        }
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    const columns = [{
        dataField: 'client_name',
        text: 'CLIENT NAME',
        sort: true
    }, {
        dataField: 'technician_name',
        text: 'TECHNICIAN NAME',
        sort: true
    }, {
        dataField: 'service_name',
        text: 'SERVICE NAME',
        sort: true
    },
    {
        dataField: 'order_date',
        text: 'ORDER DATE',
        sort: true
    },
    {
        dataField: 'order_time',
        text: 'ORDER TIME',
        sort: true,
        formatter: datetimeFormatter
    },

    ];

    const data = informasiList;



    const handleSearch = (e) => {
        console.log(e.target);
    }

    const [start, setStart] = useState(moment());
    const [end, setEnd] = useState(moment(start).add(5, "days").subtract(1, "second"));

    const [startTime, setStartTime] = useState(moment().format("YYYY-MM-DD"));
    const [endTime, setEndTime] = useState(moment(moment(start).add(5, "days").subtract(1, "second")).format("YYYY-MM-DD"));

    const applyCallback = (startDate, endDate) => {
        
        console.log("Apply Callback");
        console.log(startDate.format("DD-MM-YYYY HH:mm:ss"));
        console.log(endDate.format("DD-MM-YYYY HH:mm:ss"));


        setStart(startDate);
        setEnd(endDate);

        const start_date = startDate.format("YYYY-MM-DD");
        const end_date = endDate.format("YYYY-MM-DD");

        console.log("start_date", start_date);
        console.log("end_date", end_date);

        setStartTime(start_date);
        setEndTime(end_date);

    };


    const rangeCallback = (index, value) => {
        console.log(index, value);
    };

    const onClick = () => {
        const newStart = moment(start).subtract(3, "days");
        setStart(newStart);
    };

    const renderVanillaPicker = (ranges, local, maxDate) => {
        const value = `${start.format("DD-MM-YYYY HH:mm")} - ${end.format("DD-MM-YYYY HH:mm")}`;
        const disabled = false;



        return (
            <div>
                {/* <br /> */}
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    // maxDate={maxDate}
                    applyCallback={applyCallback}
                    rangeCallback={rangeCallback}
                    smartMode
                >
                    <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                        style={{ cursor: "pointer" }}
                        disabled={disabled}
                        value={value}
                    />
                </DateTimeRangeContainer>
                {/* <br /> */}
            </div>
        );
    };

    let now = new Date();

    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)],
        "5 Days": [moment(start).subtract(5, "days"), moment(end)],
        "1 Week": [moment(start).subtract(7, "days"), moment(end)],
        "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
        "1 Month": [moment(start).subtract(1, "months"), moment(end)],
        "1st August 18": [
            moment("2018-08-01 00:00:00"),
            moment("2018-08-02 23:59:59")
        ],
        "1 Year": [moment(start).subtract(1, "years"), moment(end)]
    };

    let local = {
        format: "DD-MM-YYYY HH:mm",
        sundayFirst: false
    };

    let maxDate = moment(end).add(24, "hour");
    let pickersRender = (
        <div className="col-md-5">
            {/* <br /> */}
            {renderVanillaPicker(ranges, local, maxDate)}
        </div>
    );

    useEffect(() => {
        // const start_Time = startTime.moment("YYYY-MM-DD");
        // const end_time = endTime.moment("YYYY-MM-DD");
        getstaffbookings(technicianDetails.id,startTime,endTime).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                setServiceList(apiResponse.data);
                // console.log("services",apiResponse.data);
            }
            else {
                console.log("Active Staff List is--------");
                alert("No data found");
            }
        });
    }, [startTime, endTime]);


    return (
        <>

            {/* Delete Card modal start here */}
            < Modal show={show02} onHide={handleClose02} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title>Create New User</Modal.Title>
                    {/* <CloseButton variant="white" /> */}
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="logo-content">
                                <div className="add-logo">
                                    <div className="image-preview">
                                        <div className="loading-screen"><span className="loader">No logo</span></div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="form-group block-upload">
                                            <div className="upload-logo">
                                                <div className="custom-file"><input type="file" className="custom-file-input" id="customFile" alt="Choose" /><label className="custom-file-label" htmlFor="customFile">Choose</label></div>
                                            </div>
                                        </div>
                                        {/* <button className="btn btn-default btn-remove">Remove logo</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-pt-20">
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Last Name" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Phone Number</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Phone Number" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>Customer Stage</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select Customer Stage</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>Client Owner</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select Client Owner</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Street Address</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Street Address" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Zip Code</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Zip Code" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>State</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select State</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>City</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select City</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Special requests ( Notes )</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose02}> Update </button>
                    <button className="Cancel-bttn btn" onClick={handleClose02}> Cancel </button>
                </Modal.Footer>
            </Modal >
            {/* modal End here */}

            {/* modal Start here */}
            <Modal show={show} onHide={handleClose} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Denial Dev</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="user-profile-details">
                                <div className="user-profile-details-inner">
                                    <div className="user-profile-img">
                                        <img src={Profile} alt="Profile" />
                                    </div>
                                    <div className="user-profile-name">
                                        <h4>Denial Dev</h4>
                                        <p>denialdev@gmail.com <button className="copy-btn" ><FiCopy /></button></p>
                                        <p>+1312654654 <button className="copy-btn" ><FiCopy /></button></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-pt-20">
                        <div className="col-md-12">
                            <Accordion />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose}> Update </button>
                    <button className="Cancel-bttn btn" onClick={handleClose}> Cancel </button>
                </Modal.Footer>
            </Modal>




            <div className="animated fadeIn" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <Form.Group>
                                    <div className="row">
                                        {pickersRender}
                                    </div>
                                </Form.Group>
                                {/* <Button onClick={handleShow02} className="btn btn-sm float-right add-user-btn"> <RiAddFill /> Add New User</Button> */}
                            </div>
                            <div className="card-body">
                                <BootstrapTable bootstrap4 keyField='id' data={serviceList} columns={columns} pagination={pagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default Informasi;