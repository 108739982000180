import axios from "axios";
import { API_URL } from "./config.js";
import { apiCall } from "./auth";

//getpaymentMethod 
export const getpaymentMethod = (formData) => apiCall(`${API_URL}getpaymentMethod`, "POST", formData);

//getpaymentMethod 
export const deletepaymentmethod = (formData) => apiCall(`${API_URL}deletepaymentmethod`, "POST", formData);

//getpaymentMethod 
export const getstaffApi = () => apiCall(`${API_URL}getstafflistforfront`, "GET", []);

//getpaymentMethod 
export const cancelbookingbyid = (formData) => apiCall(`${API_URL}cancelbookingbyid`, "POST", formData);

//getpaymentMethod 
export const updateuserprofile = (formData) => apiCall(`${API_URL}updateuserprofile`, "POST", formData);

//getpaymentMethod 
export const getUserBookingfront = (formData) => apiCall(`${API_URL}getUserBookingfront`, "POST", formData);

//getpaymentMethod 
export const getbookingstaff = (formData) => apiCall(`${API_URL}getbookingstaff`, "POST", formData);

//addpaymentmethods
export const createPaymentMethod = (formData) =>apiCall(`${API_URL}createPaymentMethod`, "POST", formData);

//confirmbookingstatus 
export const saverescheduledata = (formData) => apiCall(`${API_URL}saverescheduledata`, "POST",formData);

//confirmbookingstatus 
export const changepassword = (formData) => apiCall(`${API_URL}changepassword`, "POST",formData);

//confirmbookingstatus 
export const gettranscation = (formData) => apiCall(`${API_URL}gettranscation`, "POST",formData);

//createinvoiceApi 
export const getinvoiceApi = (customer_id) => apiCall(`${API_URL}getinvoicefront?customer_id=${customer_id}`, "GET",[]);

//payinvoice 
export const payinvoiceApi = (formData) => apiCall(`${API_URL}payinvoice`, "POST", formData);

//updatepaymentMethod 
export const updatepaymentMethod = (formData) => apiCall(`${API_URL}updatepaymentMethod`, "POST", formData);

//getdefaultpayment 
export const getdefaultpayment = (formData) => apiCall(`${API_URL}getdefaultpayment`, "POST", formData);

//getdefaultpayment 
export const checkTimeis24hours = (formData) => apiCall(`${API_URL}checkTimeis24hours`, "POST", formData);

//getservertimzoneapi 
export const getservertimzoneapi = (formData) => apiCall(`${API_URL}getservertimzone`, "POST", formData);

//postreview 
export const postreview = (formData) => apiCall(`${API_URL}postreviewuser`, "POST", formData);