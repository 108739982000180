import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { informasiList } from "./repository";
import paginationFactory from "react-bootstrap-table2-paginator";
import SearchBox from "./SearchBox";
import Button from "react-bootstrap/Button";
import "./table.css";
import Delete from "../Delete";
import { FaRegEdit } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsStopwatch } from "react-icons/bs";
import { getsubservice } from "../../Api/servicesApi";
import { getsubservicebyid } from "../../Api/servicesApi";
import { updatesubservicedata } from "../../Api/servicesApi";
import { deleteSubServiceData } from "../../Api/servicesApi";
import { activesubservicestatus } from "../../Api/servicesApi";
import { deactivetesubservicestatus } from "../../Api/servicesApi";
import { RiDeleteBin6Line } from "react-icons/ri";


const Informasi = () => {
  const [showError, setShowError] = useState(false)
  const [show018, setShow018] = useState(false);
  const handleClose018 = () => setShow018(false);
  const handleShow018 = () => setShow018(true);
  const [selected, setSelected] = useState([]);
  const [subservicelist, setSubservicelist] = useState([]);
  const [delcount, setDelcount] = useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [base_hours, setBase_hours] = useState("");
  const [base_minutes, setBase_minutes] = useState("");
  const [tableConfig, setTableConfig] = React.useState({
    page: 1,
    sizePerPage: 10,
    filters: {},
    sortField: "",
    sortOrder: "",
  });

  const [editsubservice, setEditsubservice] = useState({
    id: "",
    service_id: "",
    servicename: "",
    base_duration: "",
    extra_duration_per_unit_in_minute: "",
    base_price_for_first_unit: "",
    base_price_extra_unit: "",
  });



  const subService = (page, sizePerPage, filters, sortField, sortOrder) => {
    const id = window.location.pathname.split("/")[2];
    getsubservice(
      page,
      sizePerPage,
      filters,
      sortField,
      sortOrder,
      searchText,
      id
    ).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setSubservicelist(apiResponse.data.subservices);
        setPageOption({
          totalSize: apiResponse.data.total_row,
          hideSizePerPage: false,
          hidePageListOnlyOnePage: true,
        });
      } else {
        alert("Something went wrong");
      }
    });
  };

  useEffect(() => {
    const {
      page,
      sizePerPage,
      filters,
      sortField = "",
      sortOrder = "",
    } = tableConfig;
    subService(
      page,
      sizePerPage,
      JSON.stringify(filters),
      sortField,
      sortOrder
    );
  }, [delcount]);

  useEffect(() => {
    const { page, sizePerPage, filters, sortField, sortOrder } = tableConfig;
    subService(
      page,
      sizePerPage,
      JSON.stringify(filters),
      sortField,
      sortOrder
    );
  }, [searchText]);

  const [pageOption, setPageOption] = React.useState({
    totalSize: 0,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    console.log("type", type);
    console.log("page", page);
    console.log("sizePerPage", sizePerPage);
    console.log("filters", filters);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    setTableConfig({ page, sizePerPage, filters, sortField, sortOrder });

    if (sortField === null) {
      sortField = "";
    }
    if (sortOrder === undefined) {
      sortOrder = "";
    }
    subService(page, sizePerPage, filters, sortField, sortOrder);
  };

  const handleActivate = (id) => {
    if (window.confirm("Are you sure you want to activate this service?")) {
      const formData = new FormData();
      formData.append("sub_service_id", id);
      activesubservicestatus(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "1") {
            alert("Sub service activated successfully");
          } else {
            alert("Sub service not active");
          }
          setDelcount(delcount + 1);

        } else {
          alert("Something went wrong");
        }
      });
    }
  };

  const handledeactivete = (id) => {
    if (window.confirm("Are you sure you want to deactivate this service?")) {
      const formData = new FormData();
      formData.append("sub_service_id", id);
      deactivetesubservicestatus(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status === "1") {
            alert("Sub service deactivated successfully");
          } else {
            alert("Sub service not deactivated");
          }
          setDelcount(delcount + 1);

        } else {
          alert("Something went wrong");
        }
      });
    }


  };

  const optionFormatter2 = (cell, row) => {
    return (
      <div className="button-group">
        <div className="button-group">
          {row.status === "D" ? (
            <Button
              className="Enable-Active-bttn"
              onClick={(id) => handleActivate(row.id, window.event)}
            >
              Enable
            </Button>
          ) : (
            <Button
              className="Enable-Active-bttn"
              onClick={(id) => handledeactivete(row.id, window.event)}
            >
              Disable
            </Button>
          )}
        </div>
      </div>
    );
  };

  const optionFormatter3 = (cell, row) => {
    return (
      <>
        <div className="button-group">
          <Button className="Delete-Active-bttn me-2" onClick={
            (e) => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                deleteSubService(row.id)
            }
          }><RiDeleteBin6Line /></Button>
          <Button className="Edit-Active-bttn" onClick={
            (e) => {
              getsubservicedata(row.id)
            }
          }>
            <FaRegEdit />
          </Button>
        </div>
      </>
    );
  };

  const deleteSubService = (id) => {
    //comfirmation alert 
    const formData = new FormData();
    formData.append("sub_service_id", id);
    deleteSubServiceData(formData).then((res) => {
      if (res.code === 200) {
        console.log("response", res);
        setDelcount(delcount + 1);
        alert("User deleted successfully");

      } else {
        alert("Something went wrong");

      }
    });
  };


  const getsubservicedata = (id) => {
    handleShow018();
    const formData = new FormData();
    formData.append("sub_service_id", id);
    getsubservicebyid(formData).then((res) => {
      if (res.code === 200) {
        const apiResponse = res.data;
        setEditsubservice(apiResponse.data);
        setBase_hours(getHours(apiResponse.data.base_duration));
        setBase_minutes(getMinutes(apiResponse.data.base_duration));
        handleShow018();
      } else {
        alert("Something went wrong");
      }
    });
  };

  const getHours = (base_duration) => {
    var hours = Math.floor(base_duration / 60);
    var minutes = base_duration % 60;
    return hours;
  }

  const getMinutes = (base_duration) => {
    var hours = Math.floor(base_duration / 60);
    var minutes = base_duration % 60;
    return minutes;
  }

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditsubservice((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    console.log("event.target", event.target);
  };

  const UpdateSubService = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("sub_service_id", editsubservice.id);
    formData.append("service_id", editsubservice.service_id);
    formData.append("sub_service_name", editsubservice.servicename);
    formData.append("base_hours", base_hours);
    formData.append("base_minutes", base_minutes);
    formData.append("extra_duration_per_unit_in_minute", editsubservice.extra_duration_per_unit_in_minute);
    formData.append("base_price_for_first_unit", editsubservice.base_price_for_first_unit);
    formData.append("price_for_per_extra_unit", editsubservice.base_price_extra_unit);



    if (editsubservice.servicename == '' ||
      editsubservice.base_price_extra_unit == '' ||
      editsubservice.base_price_for_first_unit == '' ||
      editsubservice.extra_duration_per_unit_in_minute == '') {
      setShowError(true)
    } else {
      updatesubservicedata(formData).then((res) => {
        if (res.code === 200) {
          const apiResponse = res.data;
          if (apiResponse.status === "success") {
            setEditsubservice(apiResponse.data);
            handleClose018();
            setDelcount(delcount + 1);
          }
        } else {
          alert("Something went wrong");
        }
      });
    }

  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.id]);
    } else {
      setSelected(selected.filter((x) => x !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };


  // const selectRow = {
  //   mode: "checkbox",
  //   clickToSelect: true,
  //   selected: selected,
  //   onSelect: handleOnSelect,
  //   onSelectAll: handleOnSelectAll,
  // };

  const columns = [
    {
      dataField: "servicename",
      text: "SERVICE NAME",
      //   formatter: priceFormatter,
      sort: true,
    },
    {
      text: "STATUS",
      isDummy: true,
      formatter: optionFormatter2,
    },
    {
      text: "ACTION",
      isDummy: true,
      formatter: optionFormatter3,
    },
  ];

  const data = informasiList;

  const handleSearch = (e) => {
    console.log(e.target);
  };

  function priceFormatter(cell, row) {
    return (
      <div className="d-flex align-items-center priceFormatterp">
        <div className="priceFormatterp-img me-4">
          <img src={cell.src} className="img-fluid" />
        </div>
        <p className="mb-0">{cell.name}</p>
      </div>
    );
  }

  return (
    <div>
      {/* Edit Service modal start here */}
      <Modal
        show={show018}
        onHide={handleClose018}
        className="Sidebar-Modal-end"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Sub-Service</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custum-modal-body">
          <form id="Edit Sub-Service">
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Sub Service Name</Form.Label>
                  <Form.Control type="text" placeholder="Bedrooms" value={editsubservice.servicename || ""}
                    name="servicename"
                    onChange={handleEditInputChange} />
                  <Form.Control type="hidden" placeholder="Bedrooms" value={editsubservice.id || ""}
                    name="sub_service_id"
                    onChange={handleEditInputChange} />
                  {showError && editsubservice.servicename == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Current Base Duration</Form.Label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsStopwatch />
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0"
                          value={getHours(editsubservice.base_duration) || 0}
                          readOnly={true}
                        />
                        <span className="input-group-text">Hours</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0"
                          value={getMinutes(editsubservice.base_duration) || 0}
                          readOnly={true}
                        />
                        <span className="input-group-text">Minutes</span>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>New Base Duration</Form.Label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsStopwatch />
                        </span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0"
                          value={base_hours}
                          onChange={(event) => {
                            setBase_hours(event.target.value);
                          }}
                        />
                        <span className="input-group-text">Hours</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0"
                          value={base_minutes}
                          onChange={(event) => {
                            setBase_minutes(event.target.value);
                          }}
                        />
                        <span className="input-group-text">Minutes</span>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Extra Duration Per Unit In Minute</Form.Label>
                  <Form.Control type="text" placeholder="0" value={editsubservice.extra_duration_per_unit_in_minute || ""}
                    name="extra_duration_per_unit_in_minute"
                    onChange={handleEditInputChange} />
                  {showError && editsubservice.extra_duration_per_unit_in_minute == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Base Price For First Unit</Form.Label>
                  <Form.Control type="text" placeholder="0" value={editsubservice.base_price_for_first_unit || ""}
                    name="base_price_for_first_unit"
                    onChange={handleEditInputChange} />
                  {showError && editsubservice.base_price_for_first_unit == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Price For Per Extra Unit</Form.Label>
                  <Form.Control type="text" placeholder="0" value={editsubservice.base_price_extra_unit || ""}
                    name="base_price_extra_unit"
                    onChange={handleEditInputChange} />
                  {showError && editsubservice.base_price_extra_unit == '' ? <p style={{ color: 'red' }}>field is required</p> : ''}
                </Form.Group>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="Active-bttn btn" onClick={UpdateSubService}>
            {" "}
            Update{" "}
          </button>
          <button className="Cancel-bttn btn" onClick={handleClose018}>
            {" "}
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
      {/* modal End here */}
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header d-flex table-card-header">
                <Form.Group>
                  <div className='d-flex'>
                    <div addonType="prepend">
                    </div>
                    <Form.Control type="text" placeholder="Keywords..." onChange={(e) => {
                      setSearchText(e.target.value);
                    }} />
                  </div>
                </Form.Group>
              </div>
              <div className="card-body">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={subservicelist}
                  columns={columns}
                  remote
                  // selectRow={selectRow}
                  onTableChange={handleTableChange}
                  pagination={paginationFactory(pageOption)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informasi;
