import React, { useState } from "react";
// import { useRef } from 'react';
import Loginbg from "../../assets/images/Login/login-bg.jpg";
import Logo from "../../assets/images/logo/Logo.png";
import './Login.css';
import { resetpassword } from "../../Api/auth";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";


const ForgotPassword2 = () => {


    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [passworderror, setPassworderror] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('tokan');
    console.log("email", email);


    const handelSubmit = () => {

        if (password === "") {
            setPassworderror(true);
            return false;
        }

        if (password !== confirmpassword) {
            NotificationManager.error("Password and confirm password does not match", "Error", 3000);
            return;
        }

        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        formData.append("confirm_password", confirmpassword);

        resetpassword(formData).then((response) => {
            if (response.code === 200) {
                if (response.data.status == "0") {
                    NotificationManager.error(response.data.message, "Error", 3000);
                    return;
                }else if (response.data.status == "error") {
                    NotificationManager.error(response.data.message, "Error", 3000);
                    return;
                }
                else {
                    NotificationManager.success(response.data.message, "Success", 3000);
                    window.location.href = "/";
                }
            }
        }).catch((error) => {
            alert(error);
        });
    }


    // useEffect(() => {   
    //     console.log("Login Page");
    //     if (localStorage.getItem("user") != null) {
    //         window.location.href = "/dashboard";
    //     }
    // }, []);



    return (
        <>
            <section className="vh-100" style={{ backgroundColor: '#9A616D' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-12">
                            <div className="card" style={{ borderRadius: '1rem' }}>
                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-6 d-none d-md-block">
                                        <img src={Loginbg}
                                            alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem' }} />
                                    </div>
                                    <div className="col-md-6 col-lg-6 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">
                                            <form id="loginform" >
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    {/* <i className="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i> */}
                                                    <span className="h1 fw-bold mb-0"><img src={Logo} alt="logo" className="logo-icon" /></span>
                                                </div>

                                                <h3 className=" login-head" style={{ letterSpacing: '1px' }}>Welcome to <span className="color-text">Green Frog!</span> 👋</h3>

                                                <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>Please reset your password</h5>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlhtmlFor="form2Example17">Password</label>
                                                    <input type="password" id="password" name="password" onChange={
                                                        (e) => {
                                                            setPassword(e.target.value);
                                                        }
                                                    } className="form-control form-control-lg" />
                                                    {

                                                        passworderror === true && <p className="text-danger">Password is required</p>
                                                    }
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlhtmlFor="form2Example27">Confirm Password</label>
                                                    <input type="password" id="password" name="password" onChange={
                                                        (e) => {
                                                            setConfirmPassword(e.target.value);
                                                        }
                                                    } className="form-control form-control-lg" />
                                                    {
                                                        password !== confirmpassword && <p className="text-danger">Password and confirm password does not match</p>
                                                    }
                                                </div>

                                                <div className="pt-1 mb-4">
                                                    {/* <Link to="/" className="btn custom-login-btn btn-lg btn-block" type="button">Sign In</Link> */}
                                                    <button type="button" className="btn custom-login-btn btn-lg btn-block"
                                                        onClick={() => {
                                                            handelSubmit()
                                                        }}
                                                    >Reset Password</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NotificationContainer />
        </>
    );
}


export default ForgotPassword2;