import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";



//companyInfo
export const companyData = () => apiCall(`${API_URL}getinfocompany`, "GET", []);

//UpdateCompanyInfo
export const UpdateCompanyInfo = (formData) => apiCall(`${API_URL}updateinfocompany`, "POST", formData);

//companyservicearea
export const companyservicearea = (searchText) => apiCall(`${API_URL}getzoneservice?searchText=${searchText}`, "GET", []);

//addservicezone
export const addservicezone = (formData) => apiCall(`${API_URL}addservicezone`, "POST", formData);

//getgeneralsettings
export const getgeneralsettings = () => apiCall(`${API_URL}getgeneralsettings`, "GET", []);


//UpdateGeneralSettings
export const UpdateGeneralSettings = (formData) => apiCall(`${API_URL}UpdateGeneralSettings`, "POST", formData);

//getpaymentsettings
export const getpaymentsettings = () => apiCall(`${API_URL}getpaymentsettings`, "GET", []);

//paymentsmethodchange
export const paymentsmethodchange = (formData) => apiCall(`${API_URL}paymentstatuchange`, "POST", formData);

//getnotificationsettings
export const getnotificationsettings = () => apiCall(`${API_URL}getnotificationsettings`, "GET", []);

//updatenotificationsettings
export const updatenotificationsettings = (formData) => apiCall(`${API_URL}updatenotificationsettings`, "POST", formData);


//getemailtemplatesetting
export const getemailtemplatesetting = () => apiCall(`${API_URL}getemailtemplatesetting`, "GET", []);

//updateemailtemplate
export const updateemailtemplatestatus = (formData) => apiCall(`${API_URL}updateemailtemplatestatus`, "POST", formData);

//updateemailtemplate
export const updateemailtemplate = (formData) => apiCall(`${API_URL}updateemailtemplate`, "POST", formData);

//updatesmstemplate
export const updatesmstemplate = (formData) => apiCall(`${API_URL}updatesmstemplate`, "POST", formData);

//getemailtemplatesetting
export const getpromocode = (searchText) => apiCall(`${API_URL}getpromocode?searchText=${searchText}`, "GET", []);

//addpromocode
export const addpromocode = (formData) => apiCall(`${API_URL}addpromocode`, "POST", formData);

//getpromocodeData
export const getpromocodeData = (formData) => apiCall(`${API_URL}getpromocodeData`, "POST", formData);

//getpromocodeData
export const updatepromocode = (formData) => apiCall(`${API_URL}updatepromocode`, "POST", formData);

//deletepromocode
export const deletepromocode = (formData) => apiCall(`${API_URL}deletepromocode`, "POST", formData);

//getemailtemplatesetting
export const getzones = (searchText) => apiCall(`${API_URL}getZoneData?searchText=${searchText}`, "GET", []);

//addzonesData
export const addzonesData = (formData) => apiCall(`${API_URL}addzonesData`, "POST", formData);

//getzonesbyId
export const updatezoneDataApi = (formData) => apiCall(`${API_URL}updatezoneData`, "POST", formData);

//getzonesbyId
export const getzonesbyId = (formData) => apiCall(`${API_URL}getzonesbyId`, "POST", formData);

//deletepromocode
export const deletezoneDataApi = (formData) => apiCall(`${API_URL}deletezoneData`, "POST", formData);

//deletepromocode
export const deletezipcode = (formData) => apiCall(`${API_URL}deletezipcode`, "POST", formData);

//saveappointment_request
export const getSMSStatusAPI = () => apiCall(`${API_URL}getSMSStatus`, "GET", );
//saveappointment_request
export const saveappointment_request = (formData) => apiCall(`${API_URL}saveappointment_request`, "POST", formData);
//saveappointment_request
export const updatesmstemplatestatus = (formData) => apiCall(`${API_URL}updatesmstemplatestatus`, "POST", formData);


//saveappointment_request
export const getmasterpass = () => apiCall(`${API_URL}getmasterpass`, "GET",);

//saveappointment_request
export const updatemasterpassword = (formData) => apiCall(`${API_URL}updatemasterpassword`, "POST",formData);

//getsearchpricestatus
export const getsearchpricestatus = (formData) => apiCall(`${API_URL}getsearchpricestatus`, "POST",formData);
//updatepricedata
export const updatepricedata = (formData) => apiCall(`${API_URL}updatepricedata`, "POST",formData);