import React, { useState, useEffect, useContext } from "react";
import { ModalPopProvider } from "./ModalPopContext";
import Technician from "./Technician";



const TechnicianMain = () => {

    return (
        <>
            <ModalPopProvider>
            <Technician />
            </ModalPopProvider>


        </>
    )

}


export default TechnicianMain;