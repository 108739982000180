import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";


//getdata
export const getblogsApi = () => apiCall(`${API_URL}getblogs`, "get", []);

//addblogs
export const addblogs = (FormData) => apiCall(`${API_URL}addblogs`, "POST", FormData);

//uploadImage
export const uploadImage = (FormData) => apiCall(`${API_URL}uploadImage`, "POST", FormData);


//addblogcategory
export const addblogcategory = (FormData) => apiCall(`${API_URL}addblogcategory`, "POST", FormData);

//getdata
export const getblogcategory = () => apiCall(`${API_URL}getblogcategory`, "GET", []);

//editblogcategory
export const editblogcategory = (FormData) => apiCall(`${API_URL}editblogcategory`, "POST", FormData);


//getblogtags
export const getblogtags = () => apiCall(`${API_URL}getblogtags`, "GET", []);

//addblogtags
export const addblogtags = (FormData) => apiCall(`${API_URL}addblogtags`, "POST", FormData);

//getblogtags
export const editblogtags = (FormData) => apiCall(`${API_URL}editblogtags`, "POST", FormData);

export const getgoogleleads = (FormData) => apiCall(`${API_URL}getgoogleleads`, "POST", FormData);


