import React, { createContext, useState } from "react";

export const SettingContext = createContext();

export const SettingProvider = (props) => {
    const [companyInfo , setCompanyInfo] = useState([]);
    const [companyServiceArea , setCompanyServiceArea] = useState([]);
    const [generalSettings , setGeneralSettings] = useState([]);
    const [paymenetmethod , setPaymenetmethod] = useState([]);
    const [emailData, setEmailData] = useState([]);
    const [emailTemplate, setEmailTemplate] = useState([]);
    const [promocodeData , setPromocodeData] = useState([]);
    const [zonesdata , setZonesData] = useState([]);
    const [smsstatus , setSmsstatus] = useState([]);
    const [masterpassword , setMasterpassword] = useState("");
    const [searcpricestatus , setSearcpricestatus] = useState(false);




  return (
    <SettingContext.Provider value={[companyInfo, setCompanyInfo,companyServiceArea , setCompanyServiceArea,generalSettings , setGeneralSettings,paymenetmethod , setPaymenetmethod,emailData, setEmailData,emailTemplate, setEmailTemplate,promocodeData , setPromocodeData,zonesdata , setZonesData , smsstatus , setSmsstatus,masterpassword , setMasterpassword,searcpricestatus , setSearcpricestatus]}>
      {props.children}
    </SettingContext.Provider>
  );
};



