import React from "react";
import Header2 from "./camponent/header/Header2";
import Rightbar from "./camponent/Rightbar";
import Footer from "./camponent/footer/Footer";

function Covid() {
    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="covid-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>COVID-19 <span className='bold-title'><span className='bold-title bg-color'>Update</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-text">
                                        <p>Green Frog is here to help. As part of the Hygiene and Sanitation workforce, we are taking seriously our responsibility to keep your home or office clean throughout this very difficult time.</p>
                                        <p>For many of us, professional cleaning is an important step in keeping our homes and offices operating normally and preventing unsanitary conditions from developing. Also, having a clean home gives us peace of mind especially when most of us are spending more time at home.</p>
                                        <p>However, we do want to take some precautions. Such as:</p>
                                    </div>
                                    <div className="list-about">
                                        <ul className="list">
                                            <li className="list-item">We’ve temporarily changed from our regular cleaning product to a hospital-grade disinfectant that kills viruses such as covid-19</li>
                                            <li className="list-item">Our technicians will be using gloves while cleaning</li>
                                            <li className="list-item">We are paying technicians to stay home when they feel ill so you may have an alternate technician than usual.</li>
                                        </ul>
                                    </div>
                                    <div className="covid-image">
                                        <img src={require('./assets/Images/image_covid.jpeg')} alt="covid" />
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Also, we would like to express our appreciation to our local medical professionals who have been working so hard under very stressful conditions lately. We are accepting donations for house cleaning gift certificates. We will be donating 100% of the cleaning gift certificates to local medical professionals. The first $5000 worth of cleaning gift certificates will be donated to Grossmont Hospital (our main office is in La Mesa). Additional gift certificates will be donated to another local hospital.If you would like to make a donation to our local medical professionals you can give us a call or send it via PayPal at this link: <a href="https://paypal.me/greenfrog">paypal.me/greenfrogsandiego</a></p>
                                        <p></p>
                                        <p>We have a long history at Green Frog of giving back to the community through our <a href="https://greenfrog.org">Cleaning for a Reason</a> program that gives free cleanings to cancer victims.</p>
                                        <p></p>
                                        <p>As always, you can give us a call/text or visit our website anytime to schedule an appointment.</p>
                                        <p></p>
                                        <p>-Brian Nilles</p>
                                        <p></p>
                                        <p>CEO | Green Frog Cleaning | 1-619-337-0722</p>
                                    </div>
                                    <div class="service-list-box-button"><a href="/#" class="btn custom-btn2">GET A QUOTE</a></div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>
    );
}

export default Covid;