import Accordion from 'react-bootstrap/Accordion';
import './Accordion.css';
import Form from 'react-bootstrap/Form';
import Doller from '../../assets/images/icons/currency-dollar.png';
import Pay from '../../assets/images/icons/pay.png';
import Active from '../../assets/images/icons/recycle.png';
import User from '../../assets/images/icons/Avatar.png';

function AccordionData() {
    return (
        <Accordion defaultActiveKey="0">
            <form id='viewuser'>
                <Accordion.Item eventKey="0" className='custom-accordion-item'>
                    <Accordion.Header className='accordion-card-header'>About This Customer</Accordion.Header>
                    <Accordion.Body className='accordion-card-body'>
                        <div className="row custom-pt-20">
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Last Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Phone Number</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Phone Number" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Customer Stage</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Customer Stage</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Client Owner</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Client Owner</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='custom-accordion-item mt-2'>
                    <Accordion.Header>Billing Details</Accordion.Header>
                    <Accordion.Body>
                        <div className="row custom-pt-20">
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Billing Address</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Self Street" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Billing Zip Code</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="910257" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Billing City</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Billing City</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Billing State</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Billing State</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Default Shipping Address</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Self Street" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Shipping Zip Code</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="910257" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Shipping City</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Billing City</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="booking-custom-lable">Shipping State</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Billing State</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='custom-accordion-item mt-2'>
                    <Accordion.Header>Booking Data</Accordion.Header>
                    <Accordion.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Default Payment Method</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Payment Method</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Billed Amount</label>
                                        <input type="text" className="form-control non-edit-input-box" id="exampleFormControlInput1" placeholder="500" readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Bathrooms</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Bathrooms</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Bedrooms</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Bedrooms</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Zone</Form.Label>
                                    <Form.Select aria-label="Default select example" className="custom-select-box">
                                        <option>Select Zone</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Billed Hours</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="500" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Key Number</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Preferences</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Family Info</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Parking Access</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Preferred Technician</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Client notes from tech</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Special instructions from the client</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-body-form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Special instructions from the admin</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className='custom-accordion-item mt-2'>
                    <Accordion.Header>Booking Appointments</Accordion.Header>
                    <Accordion.Body>
                        <div className="row">
                            <div className='booking-appoinment-card'>
                                <div className='booking-appoinment-card-header'>
                                    <div className='booking-appoinment-card-header-left'>
                                        <div className='booking-appoinment-card-header-left-title'>
                                            <h5>Appointment 1</h5>
                                            <p>Jan-10-2023 16:00</p>
                                        </div>
                                    </div>
                                    <div className='booking-appoinment-card-header-right'>
                                        <div className='booking-appoinment-card-header-btn'>
                                            <div className='appointment-btn-item'>
                                                <div className='appointment-btn-item-icon'>
                                                    <img src={Doller} alt="Doller" />
                                                </div>
                                                <div className='appointment-btn-item-text'>
                                                    <p>5000</p>
                                                </div>
                                            </div>
                                            <div className='appointment-btn-item'>
                                                <div className='appointment-btn-item-icon'>
                                                    <img src={Pay} alt="Pay" />
                                                </div>
                                                <div className='appointment-btn-item-text'>
                                                    <p>Pay Stax</p>
                                                </div>
                                            </div>
                                            <div className='appointment-btn-item'>
                                                <div className='appointment-btn-item-icon'>
                                                    <img src={Active} alt="Active" />
                                                </div>
                                                <div className='appointment-btn-item-text'>
                                                    <p>Active</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='booking-appoinment-card-body'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <div className='booking-appoinment-plx'>
                                                <h5 className='booking-appoinment-plx-title'>Technician Booking Status </h5>
                                            </div>
                                            <div className='booking-appoinment-plx-details'>
                                                <div className='booking-appoinment-plx-details-item'>
                                                    <div className='booking-appoinment-plx-details-item-left'>
                                                        <div className='booking-appoinment-plx-details-item-left-icon'>
                                                            <img src={User} alt="User" />
                                                        </div>
                                                    </div>
                                                    <div className='booking-appoinment-plx-details-item-right'>
                                                        <div className='user-name'>Isabelle Cole</div>
                                                        <div className='user-status'>Rejected Booking</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='booking-appoinment-plx'>
                                                <h5 className='booking-appoinment-plx-title'>Job Details </h5>
                                            </div>
                                            <div className='booking-appoinment-plx-details'>
                                                <div className='booking-appoinment-plx-details-item'>
                                                    <div className='booking-appoinment-plx-details-item-box'>
                                                        <div className='user-name'>Price : </div>
                                                        <div className='user-name'>Cleaning Time : </div>
                                                        <div className='user-name'>Duration : </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <div className='booking-appoinment-plx'>
                                                <h5 className='booking-appoinment-plx-title'>Booking Details </h5>
                                            </div>
                                            <div className='booking-appoinment-plx-details'>
                                                <div className='booking-appoinment-plx-details-item'>
                                                    <div className='booking-appoinment-plx-details-item-box'>
                                                        <div className='user-name'>Methods - Home Size</div>
                                                        <div className='user-name'>Addons : None</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='booking-appoinment-plx'>
                                                {/* <h5 className='booking-appoinment-plx-title'>Job Details </h5> */}
                                            </div>
                                            <div className='booking-appoinment-plx-details'>
                                                <div className='booking-appoinment-plx-details-item'>
                                                    <div className='booking-appoinment-plx-details-item-box'>
                                                        <div className='user-name'>Bedrooms-1 ,  Bathrooms-2</div>
                                                        <div className='user-name'>Note : </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='booking-appoinment-card-footer'>
                                    <div className='booking-appoinment-btn-list'>
                                        <div className='booking-appoinment-btn-item'>
                                            <button className='btn btn-primary bg-yellow-dark acc-custom-btn'>Edit</button>
                                        </div>
                                        <div className='booking-appoinment-btn-item'>
                                            <button className='btn btn-primary bg-green-dark acc-custom-btn'>Confirm</button>
                                        </div>
                                        <div className='booking-appoinment-btn-item'>
                                            <button className='btn btn-primary bg-red-dark acc-custom-btn'>Delete</button>
                                        </div>
                                        <div className='booking-appoinment-btn-item'>
                                            <button className='btn btn-primary bg-blue-dark acc-custom-btn'>Reschedule</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </form>
        </Accordion>
    );
}

export default AccordionData;