import React, { useState, useContext, useEffect } from "react";
import '../../assets/css/Booking.css';
import { BookingContext } from "../BookingForm/BookingContext";
import { LoginContext } from "../../LoginContext";
import { getsubservicebyservice, getuser, getuserdetails } from "../../Api/frontbooingApi";
import { getaddon } from "../../Api/frontbooingApi";
import { min } from "moment";

function Totalpayment() {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword, technicianList, setTechnicianList, expectedprice, setExpectedprice, expectedduration, setExpectedduration, zipcode, setZipcode] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);

    const [userbilledhours, setUserbilledhours] = useState(0);
    const [userbilledamount, setUserbilledamount] = useState(0);
    const [userid, setUserid] = useState('');

    console.log("userbilledhours", userbilledhours);

    const [defaultsubservice, setdefaultsubservice] = useState([]);
    const [defaultaddons, setdefaultaddons] = useState([]);

    useEffect(() => {
        if (LoggedInData != null) {
            setUserid(LoggedInData.id);
        }
    }, [LoggedInData]);


    useEffect(() => {
        if (chooseservice === 0) {
            setdefaultsubservice([]);
            setdefaultaddons([]);
        }

        getsubservice();
        getaddonsservice();
    }, [chooseservice]);


    const getEndtime = (time) => {
        if (time == null || time == undefined || time == "") {
            return "";
        }
        else {
            // var d = new Date(time);
            // var hours = d.getHours();
            // var minutes = d.getMinutes();
            // var ampm = hours >= 12 ? 'PM' : 'AM';
            // hours = hours % 12;
            // hours = hours ? hours : 12;

            // var totalMinutes = parseInt(expectedduration);
            // var totalHours = parseInt(totalMinutes / 60);
            // var totalMinutes = parseInt(totalMinutes % 60);
            // var totalHours = parseInt(totalHours % 12);
            // var totalHours = parseInt(totalHours ? totalHours : 12);
            // // var totalMinutes = parseInt(totalMinutes < 10 ? '0'+totalMinutes : totalMinutes);
            // var totalMinutes = totalMinutes < 10 ? '0' + totalMinutes : totalMinutes;
            // // var totalHours = parseInt(totalHours < 10 ? '0'+totalHours : totalHours);
            // var totalHours = totalHours < 10 ? '0' + totalHours : totalHours;
            // var strTime = totalHours + ':' + totalMinutes + ' ' + ampm;
            // setEndchoosetime(strTime);

            const choosetimeDate = new Date(choosetime);

            // Split the choosetime into hours and minutes
            const choosetimeHours = choosetimeDate.getHours();
            const choosetimeMinutes = choosetimeDate.getMinutes();

            // Calculate the expectedduration in hours
            const expecteddurationHours = expectedduration / 60;

            // Add the expectedduration in hours to the choosetime
            choosetimeDate.setHours(choosetimeHours + Math.floor(expecteddurationHours));
            choosetimeDate.setMinutes(choosetimeMinutes + (expecteddurationHours % 1) * 60);

            // Format the end time to a string in the 12-hour clock format
            const ampm = choosetimeDate.getHours() >= 12 ? 'PM' : 'AM';
            var endHours = choosetimeDate.getHours() % 12 || 12;
            var endMinutes = choosetimeDate.getMinutes();
            endHours = endHours < 10 ? '0' + endHours : endHours;
            const strTime = endHours + ':' + (endMinutes < 10 ? '0' + endMinutes : endMinutes) + ' ' + ampm;
            setEndchoosetime(strTime);
            return strTime;
        }
    }



    useEffect(() => {
        getEndtime(choosetime);
    }, [totalDuration]);

    const getDate = (date) => {
        if (choosetime == null || choosetime == undefined || choosetime == "") {
            return "";
        } else {
            var d = new Date(date);
            var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            var dayName = days[d.getDay()];
            var monthName = d.toLocaleString('default', { month: 'short' });
            var date = d.getDate();
            var year = d.getFullYear();
            var fullDate = dayName + " " + date + " " + monthName + " " + year;
            return fullDate;
        }
    }

    const getTime = (time) => {

        if (time == null || time == undefined || time == "") {
            return "";
        }
        else {
            var d = time && new Date(time);
            var hours = d.getHours();
            var minutes = d.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            console.log("minutes", minutes);
            minutes = minutes < 10 ? '0' + minutes : minutes;
            hours = hours < 10 ? '0' + hours : hours;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

    }

    const getsubservice = () => {
        // setLoading(true);
        const formData = new FormData();
        formData.append("service_id", chooseservice);
        formData.append("user_id", userid);
        getsubservicebyservice(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                // setSubService(apiResponse.data);
                setdefaultsubservice(apiResponse.data);
            } else {
                alert("Something went wrong");
            }
        });
    };

    const getaddonsservice = () => {
        const formData = new FormData();
        formData.append("service_id", chooseservice);
        formData.append("user_id", userid);
        getaddon(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setdefaultaddons(apiResponse.data);
                }
                else {
                    setdefaultaddons([]);
                }
            } else {
                alert("Something went wrong");
            }
        });
    };


    useEffect(() => {
        if (chooseservice === "1") {
            const formData = new FormData();
            // formData.append("user_id", LoggedInData.id);
            if (LoggedInData && LoggedInData.id) {
                formData.append("user_id", LoggedInData.id);
            } else {
                console.error("LoggedInData is null or does not have an id property");
            }
            getuserdetails(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === 1) {
                        const apiResponse = res.data;
                        setUserbilledhours(apiResponse.data.billed_hours);
                        setUserbilledamount(apiResponse.data.billed_amount);
                    }
                } else {
                    alert("Something went wrong");
                }
            });
        }
    }, [chooseservice]);



    return (
        <div>
            <div className="booking-card">
                <div className="booking-card-header-title">Booking Summary</div>
                <div className="booking-card-header">
                    <div className="booking-left">
                        <div className="booking-card-title">Booking Start</div>
                        {

                            choosetime && <div>
                                <div className="booking-card-subtitle">{choosetime && getDate(choosedate) || ""}</div>
                                <div className="booking-card-time">{getTime(choosetime) || ""}</div>
                            </div>

                        }

                    </div>
                    <div className="booking-right">
                        <div className="booking-card-title">Booking End</div>
                        <div className="booking-card-subtitle">{choosetime && getDate(choosedate) || ""}</div>
                        <div className="booking-card-time">{getEndtime(choosetime) || ""}</div>
                    </div>
                </div>
                <hr />
                <div className="booking-card-body">
                    <div className="booking-card-body-item">
                        <div className="sub-total">
                            <div className="booking-card-footer-item-subtitle">Estimated Billed Amount</div>
                            {/* {
                                chooseservice === "1" ? (
                                    !userbilledhours || userbilledhours === 0 ? (
                                        <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + expectedprice}</div>
                                    ) : (
                                        <div className="booking-card-footer-item-price">{"$" + userbilledamount + " to $" + userbilledamount}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + expectedprice}</div>
                                )
                            } */}


                            {
                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + Math.round(expectedprice)}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">{"$" + userbilledamount }</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">{"$" + Totalpayment}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + Math.round(expectedprice)}</div>
                                )
                            }

                        </div>
                        <div className="sub-total discount">
                            <div className="booking-card-footer-item-subtitle">Estimated Billed Duration</div>
                            {/* {
                                chooseservice === "1" ? (
                                    !userbilledhours || userbilledhours === 0 ? (
                                        <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                    ) : (
                                        <div className="booking-card-footer-item-price">{convertToHHMM(userbilledhours) + " to " + convertToHHMM(userbilledhours)}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                )
                            } */}

                            {
                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">{convertToHHMM(userbilledhours) }</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration)}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                )
                            }

                        </div>
                    </div>
                </div>
                <hr />
                <div className="booking-card-body">
                    <div className="booking-card-body-item">
                        <div className="booking-card-body-item-title">Cart Items-:</div>
                        <div className="booking-card-service-name">{servicename || ""}</div>
                        {
                            choosesubservice && choosesubservice.map((item) => {
                                return (
                                    <div className="multi-service">
                                        <div className="booking-card-service-name">{item.units_title}</div>
                                        <div className="booking-card-service-name">{item.quantity}</div>
                                    </div>
                                )
                            }
                            )
                        }
                        {
                            chooseaddon && chooseaddon.map((item) => {
                                {
                                    if (item.quantity != 0) {
                                        return (
                                            <div className="multi-service">
                                                <div className="booking-card-service-name">{item.addon_service_name}</div>
                                                <div className="booking-card-service-name">{item.quantity}</div>
                                            </div>

                                        )
                                    }
                                }

                            }
                            )

                        }
                    </div>
                </div>
                <hr />
                <div className="booking-card-footer">
                    <div className="booking-card-footer-item">
                        <div className="booking-card-footer-item-title">Your Price Summary</div>
                        <div className="sub-total">
                            <div className="booking-card-footer-item-subtitle">Sub Total</div>
                            {/* {
                                chooseservice === "1" ? (
                                    !userbilledamount || userbilledamount === 0 ? (
                                        <div className="booking-card-footer-item-price">${expectedprice || "0"}</div>
                                    ) : (
                                        <div className="booking-card-footer-item-price">${userbilledamount || "0"}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">${expectedprice || "0"}</div>
                                )
                            } */}


                            {
                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">${userbilledamount || "0"}</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                )
                            }


                        </div>
                        <div className="sub-total discount">
                            <div className="booking-card-footer-item-subtitle">Discount</div>
                            <div className="booking-card-footer-item-price">$0</div>
                        </div>
                        <div className="sub-total total-payment">
                            <div className="booking-card-footer-item-subtitle">Total</div>
                            {/* {
                                chooseservice === "1" ? (
                                    !userbilledamount || userbilledamount === 0 ? (
                                        <div className="booking-card-footer-item-price">${expectedprice || "0"}</div>
                                    ) : (
                                        <div className="booking-card-footer-item-price">${userbilledamount || "0"}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">${expectedprice || "0"}</div>
                                )
                            } */}
                            {
                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && (parseInt(chooseaddon[0]?.quantity) === parseInt(defaultaddons[0]?.quantity))) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">${userbilledamount || "0"}</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                )
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function convertToHHMM(value) {
    const start_time = value;
    const hours = Math.floor(start_time / 60);
    const minutes = start_time % 60;

    // Format hours and minutes as two-digit numbers
    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

    // Combine hours and minutes in the "00:00" format
    const formattedTime = formattedHours + ':' + formattedMinutes;
    return formattedTime;
}

export default Totalpayment;