import React, { useState } from "react";
import Header2 from "./camponent/header/Header2";
import Footer from "./camponent/footer/Footer";
import Rightbar from "./camponent/Rightbar";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from "react-router-dom";

function BlogList() {
    const blogsPerPage = 5; // Number of blogs to show per page
    const [currentPage, setCurrentPage] = useState(1);

    // Dummy data for blogs. Replace this with your actual blog data.
    const allBlogs = [
        {
            id: 1,
            title: "Cleaning Safely and Effectively for People with Chemical Sensitivities",
            date: "May 29, 2022",
            author: "Green Frog",
            description: "Cleaning is something that we have to do every day. It keeps our homes and workplaces clean and healthy, which reduces the chances of getting sick and helps us stay healthy. However, cleaning can be difficult and sometimes even dangerous for some people. People who are sensitive to chemicals may have a bad reaction to [...]",
        },
        {
            id: 2,
            title: "Cleaning Safely and Effectively for People with Chemical Sensitivities",
            date: "May 29, 2022",
            author: "Green Frog",
            description: "Cleaning is something that we have to do every day. It keeps our homes and workplaces clean and healthy, which reduces the chances of getting sick and helps us stay healthy. However, cleaning can be difficult and sometimes even dangerous for some people. People who are sensitive to chemicals may have a bad reaction to [...]",
        },
        {
            id: 3,
            title: "Cleaning Safely and Effectively for People with Chemical Sensitivities",
            date: "May 29, 2022",
            author: "Green Frog",
            description: "Cleaning is something that we have to do every day. It keeps our homes and workplaces clean and healthy, which reduces the chances of getting sick and helps us stay healthy. However, cleaning can be difficult and sometimes even dangerous for some people. People who are sensitive to chemicals may have a bad reaction to [...]",
        },
        {
            id: 4,
            title: "Cleaning Safely and Effectively for People with Chemical Sensitivities",
            date: "May 29, 2022",
            author: "Green Frog",
            description: "Cleaning is something that we have to do every day. It keeps our homes and workplaces clean and healthy, which reduces the chances of getting sick and helps us stay healthy. However, cleaning can be difficult and sometimes even dangerous for some people. People who are sensitive to chemicals may have a bad reaction to [...]",
        },
        {
            id: 5,
            title: "Cleaning Safely and Effectively for People with Chemical Sensitivities",
            date: "May 29, 2022",
            author: "Green Frog",
            description: "Cleaning is something that we have to do every day. It keeps our homes and workplaces clean and healthy, which reduces the chances of getting sick and helps us stay healthy. However, cleaning can be difficult and sometimes even dangerous for some people. People who are sensitive to chemicals may have a bad reaction to [...]",
        },
        {
            id: 6,
            title: "Cleaning Safely and Effectively for People with Chemical Sensitivities",
            date: "May 29, 2022",
            author: "Green Frog",
            description: "Cleaning is something that we have to do every day. It keeps our homes and workplaces clean and healthy, which reduces the chances of getting sick and helps us stay healthy. However, cleaning can be difficult and sometimes even dangerous for some people. People who are sensitive to chemicals may have a bad reaction to [...]",
        },
        {
            id: 7,
            title: "Cleaning Safely and Effectively for People with Chemical Sensitivities",
            date: "May 29, 2022",
            author: "Green Frog",
            description: "Cleaning is something that we have to do every day. It keeps our homes and workplaces clean and healthy, which reduces the chances of getting sick and helps us stay healthy. However, cleaning can be difficult and sometimes even dangerous for some people. People who are sensitive to chemicals may have a bad reaction to [...]",
        },
    ];

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = allBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Blogs</h2>
                                    </div>
                                    <div className="title_bold">
                                        <div className="windowcleaning-comtent-text">
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog-list">
                                    {currentBlogs.map((blog) => (
                                        <div className="blog-item" key={blog.id}>
                                            <div className="blog-inner">
                                                <Link to="/blog-single/" className="blog-link">
                                                    <div className="blog-header">
                                                        <div className="blog-image">
                                                            {/* Use the actual image source */}
                                                            <img src={require('./assets/Images/blogs.webp')} alt="blog image" />
                                                        </div>
                                                        <div className="blog-title">
                                                            <h4>{blog.title}</h4>
                                                            <div className="meta blog-meta">
                                                                <span className="date">{blog.date} </span>
                                                                <span className="separator">|</span>
                                                                <span className="author"> By: {blog.author}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="blog-description">
                                                    {blog.description}
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/blog-single/">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="pagination">
                                    <span onClick={() => paginate(currentPage - 1)} className={`arrow ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <FaChevronLeft />
                                    </span>

                                    {Array.from({ length: Math.ceil(allBlogs.length / blogsPerPage) }).map((_, index) => (
                                        <span key={index} onClick={() => paginate(index + 1)} className={`page-number ${currentPage === index + 1 ? 'active' : ''}`}>
                                            {index + 1}
                                        </span>
                                    ))}

                                    <span onClick={() => paginate(currentPage + 1)} className={`arrow ${currentPage === Math.ceil(allBlogs.length / blogsPerPage) ? 'disabled' : ''}`}>
                                        <FaChevronRight />
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}

export default BlogList;
