import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Confirmation() {
  let query = useQuery();
  let bookingId = query.get("booking");
  
  if (bookingId) {
    const otherSiteURL = `https://pro-api.greenfrogcleaning.com/confirmbooking?booking=${bookingId}`;
    window.location.href = otherSiteURL;
  }

  return null; // Assuming this component is just for redirecting
}

export default Confirmation;
