import React from "react";
import { useState, useEffect } from "react";
import Informasi from "../Components/DataTable blogs/Informasi";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Crm.css";
import { Roller } from "react-awesome-spinners";
import { getallnotificationapi } from '../Api/auth';
import { updateallbookingapi } from '../Api/auth';
import { updatebookingapi } from '../Api/auth';
import ChildComponent from "../Bookingdata/Appointment"



function Allnotification() {
    const [loading, setLoading] = useState(false);

    const [notification, setNotification] = useState([]);
    const [bookingid, setBookingId] = useState(0);
    const [oldbookingid, setOldBookingId] = useState(0);

    const getnotifications = async () => {
        setLoading(true);
        getallnotificationapi().then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setNotification(ApiResponse.data);
                console.log("aminesh", ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getnotifications();
    }, []);

    function getTimeAgo(datetimeString) {
        if (datetimeString) {
            const bookedDate = new Date(datetimeString);
            const currentDate = new Date();
            const differenceInMilliseconds = currentDate - bookedDate;

            // Convert milliseconds to minutes, hours, etc.
            const seconds = Math.floor(differenceInMilliseconds / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            let result = "";
            if (days > 0) {
                result += `${days} days `;
            }
            if (hours % 24 > 0) {
                result += `${hours % 24} hours `;
            }
            if (minutes % 60 > 0) {
                result += `${minutes % 60} minutes `;
            }
            if (seconds % 60 > 0) {
                result += `${seconds % 60} seconds `;
            }

            return result.trim() + " ago" || "Just now";
        } else {
            return "";
        }
    }

    const updateallboking = async () => {
        const formData = new FormData();
        updateallbookingapi(formData).then((response) => {
            if (response.code === 200) {
                getnotifications();
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            window.location.reload();
        })
    }


    const updatebooking = async (id) => {
        const formData = new FormData();
        formData.append("order_id", id);
        updatebookingapi(formData).then((response) => {
            if (response.code === 200) {
                getnotifications();
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const callChildFunction = (booking_id) => {
        setBookingId(booking_id);
    };

    useEffect(() => {
        if (bookingid === 0) {
            updatebooking(oldbookingid)
        }
    }, [bookingid])

    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="container-fulid">
                                            <div className="">

                                                {

                                                    notification && notification.length > 0 ? (
                                                        <div className="table-card ">
                                                            <div className="card-body table-card-body">
                                                                <div className="table-card-header mb-4 d-flex justify-content-between">
                                                                    <h5 className="card-title">Latest Bookings</h5>
                                                                    {
                                                                        notification && notification.length > 0 && (
                                                                            <button type="button" className="btn btn-primary btn-sm add-user-btn" onClick={updateallboking}>
                                                                                Check All
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="notification-ui_dd-content">
                                                                    {
                                                                        notification && notification.map((item, index) => (
                                                                            <div className="notification-list notification-list--unread">
                                                                                <div className="notification-list_content">
                                                                                    {/* <div className="notification-list_img">
                                <img src={require('../assets/images/Avatar.png')} alt="user" />
                            </div> */}
                                                                                    <div className="notification-list_detail">
                                                                                        <p>
                                                                                            <b>Staff : {item.fullname}</b>
                                                                                        </p>
                                                                                        <p className="text-muted">
                                                                                            Booking {item.servicename} is booked by {item.fullname} for {item.clientname}
                                                                                        </p>
                                                                                        <p className="text-muted">
                                                                                            <small>{getTimeAgo(item.booking_date_time)}</small>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="notification-list_feature-btn">
                                                                                    <button type="button" className="btn btn-primary btn-sm add-user-btn" onClick={
                                                                                        () => {
                                                                                            callChildFunction(item.order_id);
                                                                                        }
                                                                                    }>
                                                                                        View
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ) : (<>   </>)
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                bookingid != 0 ? (
                                    <ChildComponent order_id={bookingid} onCloseidChangebooking={(editbookingid) => setBookingId(editbookingid)} onCloseidChangeoldbookingid={(oldbookingid) => setOldBookingId(oldbookingid)} />
                                ) : (
                                    <></>
                                )

                            }
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Allnotification;