import React, { useState, useEffect, useContext } from "react";
import { ModalPopProvider } from "./ModalPopContext";
import Appointment from "./Appointment";






const AppointmentMain = () => {


    return (
        <>
            <ModalPopProvider>
            <Appointment />
            </ModalPopProvider>


        </>
    )
}


export default AppointmentMain;