import React from "react";
import { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { SettingContext } from '../../Setting/SettingContext';
import { UpdateGeneralSettings } from "../../Api/settingApi";

function TabData3() {
    const [companyInfo, setCompanyInfo, companyServiceArea, setCompanyServiceArea, generalSettings, setGeneralSettings,paymenetmethod , setPaymenetmethod,emailData, setEmailData,emailTemplate, setEmailTemplate,promocodeData , setPromocodeData] = useContext(SettingContext);
    
    const [timeinterval , setTimeinterval] = useState('');
    const [breaktimeinterval , setBreaktimeinterval] = useState('');
    const [minimumadvancebookingtime , setMinimumadvancebookingtime] = useState('');
    const [maximumadvancebookingtime , setMaximumadvancebookingtime] = useState('');
    const [cancellationbuffertime , setCancellationbuffertime] = useState('');
    const [reshedulebuffertime , setReshedulebuffertime] = useState('');


    useEffect(() => {
        setTimeinterval(generalSettings.time_interval);
        setBreaktimeinterval(generalSettings.break_time_interval);
        setMinimumadvancebookingtime(generalSettings.minimum_advance_booking_time);
        setMaximumadvancebookingtime(generalSettings.maximum_advance_booking_time);
        setCancellationbuffertime(generalSettings.cancellation_buffer_time);
        setReshedulebuffertime(generalSettings.reshedule_buffer_time);
    }, [generalSettings]);


    const SaveGeneralSetting = (e) => {

        const formData = new FormData();
        formData.append("time_interval", timeinterval);
        formData.append("break_time_interval", breaktimeinterval);
        formData.append("minimum_advance_booking_time", minimumadvancebookingtime);
        formData.append("maximum_advance_booking_time", maximumadvancebookingtime);
        formData.append("cancellation_buffer_time", cancellationbuffertime);
        formData.append("reshedule_buffer_time", reshedulebuffertime);

        UpdateGeneralSettings(formData).then((res) => {
            if (res.code === 200) {
                if(res.data.status === "success"){
                    alert(res.data.message)
                }
                else{
                    alert(res.data.message)
                }
            }
        }).catch((err) => {
            console.log(err);
        });
        
    }

    return (
        <div>
            <form id='form'>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Time Interval</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => {setTimeinterval(e.target.value);}}>
                                <option>Select Time Interval</option>
                                <option value="1" selected={timeinterval == "1" ? true : false}>10 Minutes</option>
                                <option value="2" selected={timeinterval == "2" ? true : false}>15 Minutes</option>
                                <option value="3" selected={timeinterval == "3" ? true : false}>20 Minutes</option>
                                <option value="4" selected={timeinterval == "4" ? true : false}>30 Minutes</option>
                                <option value="5" selected={timeinterval == "5" ? true : false}>45 Minutes</option>
                                <option value="6" selected={timeinterval == "6" ? true : false}>1 Hours</option>
                                <option value="7" selected={timeinterval == "7" ? true : false}>1.5 Hours</option>
                                <option value="8" selected={timeinterval == "8" ? true : false}>2 Hours</option>
                                <option value="9" selected={timeinterval == "9" ? true : false}>2.5 Hours</option>
                                <option value="10" selected={timeinterval == "10" ? true : false}>3 Hours</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Break Time Interval</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => {setBreaktimeinterval(e.target.value);}}>
                                <option>Select Break Time Interval</option>
                                <option value="1" selected={breaktimeinterval == "1" ? true : false}>0 Minutes</option>
                                <option value="2" selected={breaktimeinterval == "2" ? true : false}>15 Minutes</option>
                                <option value="3" selected={breaktimeinterval == "3" ? true : false}>30 Minutes</option>
                                <option value="4" selected={breaktimeinterval == "4" ? true : false}>45 Minutes</option>
                                <option value="5" selected={breaktimeinterval == "5" ? true : false}>1 Hours</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 ">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Minimum advance booking time</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => {setMinimumadvancebookingtime(e.target.value);}}>
                                <option value="">Minimum advance booking time</option>
                                <option value="10" selected={minimumadvancebookingtime == "10" ? true : false}>10 Minutes</option>
                                <option value="20" selected={minimumadvancebookingtime == "20" ? true : false}>20 Minutes</option>
                                <option value="30" selected={minimumadvancebookingtime == "30" ? true : false}>30 Minutes</option>
                                <option value="40" selected={minimumadvancebookingtime == "40" ? true : false}>40 Minutes</option>
                                <option value="60" selected={minimumadvancebookingtime == "60" ? true : false}>1 Hours</option>
                                <option value="120" selected={minimumadvancebookingtime == "120" ? true : false}>2 Hours</option>
                                <option value="180" selected={minimumadvancebookingtime == "180" ? true : false}>3 Hours</option>
                                <option value="240" selected={minimumadvancebookingtime == "240" ? true : false}>4 Hours</option>
                                <option value="300" selected={minimumadvancebookingtime == "300" ? true : false}>5 Hours</option>
                                <option value="360" selected={minimumadvancebookingtime == "360" ? true : false}>6 Hours</option>
                                <option value="420" selected={minimumadvancebookingtime == "420" ? true : false}>7 Hours</option>
                                <option value="480" selected={minimumadvancebookingtime == "480" ? true : false}>8 Hours</option>
                                <option value="720" selected={minimumadvancebookingtime == "720" ? true : false}>12 Hours</option>
                                <option value="1440" selected={minimumadvancebookingtime == "1440" ? true : false}>1 Day</option>
                                <option value="2880" selected={minimumadvancebookingtime == "2880" ? true : false}>2 Days</option>
                                <option value="4320" selected={minimumadvancebookingtime == "4320" ? true : false}>3 Days</option>
                                <option value="5760" selected={minimumadvancebookingtime == "5760" ? true : false}>4 Days</option>
                                <option value="7200" selected={minimumadvancebookingtime == "7200" ? true : false}>5 Days</option>
                                <option value="8640" selected={minimumadvancebookingtime == "8640" ? true : false}>6 Days</option>
                                <option value="10080" selected={minimumadvancebookingtime == "10080" ? true : false}>7 Days</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Maximum advance booking time</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => {setMaximumadvancebookingtime(e.target.value);}}>
                                <option value="">Maximum advance booking time</option>
                                <option value="2" selected={maximumadvancebookingtime == "2" ? true : false}>2 Months</option>
                                <option value="1" selected={maximumadvancebookingtime == "1" ? true : false}>1 Months</option>
                                <option value="3" selected={maximumadvancebookingtime == "3" ? true : false}>3 Months</option>
                                <option value="4" selected={maximumadvancebookingtime == "4" ? true : false}>4 Months</option>
                                <option value="5" selected={maximumadvancebookingtime == "5" ? true : false}>5 Months</option>
                                <option value="6" selected={maximumadvancebookingtime == "6" ? true : false}>6 Months</option>
                                <option value="12" selected={maximumadvancebookingtime == "12" ? true : false}>1 Year</option>
                                <option value="24" selected={maximumadvancebookingtime == "24" ? true : false}>2 Year</option>
                                <option value="36" selected={maximumadvancebookingtime == "36" ? true : false}>3 Year</option>
                                <option value="48" selected={maximumadvancebookingtime == "48" ? true : false}>4 Year</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 ">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Cancellation Buffer Time</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => {setCancellationbuffertime(e.target.value);}}>
                                <option value="">Cancellation Buffer Time</option>
                                <option value="60" selected={cancellationbuffertime == "60" ? true : false}>1 Hours</option>
                                <option value="120" selected={cancellationbuffertime == "120" ? true : false} >2 Hours</option>
                                <option value="180" selected={cancellationbuffertime == "180" ? true : false}>3 Hours</option>
                                <option value="240" selected={cancellationbuffertime == "240" ? true : false}>4 Hours</option>
                                <option value="300" selected={cancellationbuffertime == "300" ? true : false}>5 Hours</option>
                                <option value="360" selected={cancellationbuffertime == "360" ? true : false}>6 Hours</option>
                                <option value="420" selected={cancellationbuffertime == "420" ? true : false}>7 Hours</option>
                                <option value="480" selected={cancellationbuffertime == "480" ? true : false}>8 Hours</option>
                                <option value="540" selected={cancellationbuffertime == "540" ? true : false}>9 Hours</option>
                                <option value="600" selected={cancellationbuffertime == "600" ? true : false}>10 Hours</option>
                                <option value="660" selected={cancellationbuffertime == "660" ? true : false}>11 Hours</option>
                                <option value="720" selected={cancellationbuffertime == "720" ? true : false}>12 Hours</option>
                                <option value="1440" selected={cancellationbuffertime == "1440" ? true : false}>24 Hours</option>
                                <option value="2880" selected={cancellationbuffertime == "2880" ? true : false}>48 Hours</option>
                                <option value="4320" selected={cancellationbuffertime == "4320" ? true : false}>72 Hours</option>
                                <option value="5760" selected={cancellationbuffertime == "5760" ? true : false}>96 Hours</option>
                            </Form.Select>
                        </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Reshedule Buffer Time</Form.Label>
                            <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => {setReshedulebuffertime(e.target.value);}}>
                                <option value="">Reshedule Buffer Time</option>
                                <option value="60" selected={reshedulebuffertime == "60" ? true : false}>1 Hours</option>
                                <option value="120" selected={reshedulebuffertime == "120" ? true : false}>2 Hours</option>
                                <option value="180" selected={reshedulebuffertime == "180" ? true : false}>3 Hours</option>
                                <option value="240" selected={reshedulebuffertime == "240" ? true : false}>4 Hours</option>
                                <option value="300" selected={reshedulebuffertime == "300" ? true : false}>5 Hours</option>
                                <option value="360" selected={reshedulebuffertime == "360" ? true : false}>6 Hours</option>
                                <option value="420" selected={reshedulebuffertime == "420" ? true : false}>7 Hours</option>
                                <option value="480" selected={reshedulebuffertime == "480" ? true : false}>8 Hours</option>
                                <option value="540" selected={reshedulebuffertime == "540" ? true : false}>9 Hours</option>
                                <option value="600" selected={reshedulebuffertime == "600" ? true : false}>10 Hours</option>
                                <option value="660" selected={reshedulebuffertime == "660" ? true : false}>11 Hours</option>
                                <option value="720" selected={reshedulebuffertime == "720" ? true : false}>12 Hours</option>
                                <option value="1440"selected={reshedulebuffertime == "1440" ? true : false}>24 Hours</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className='form-btn'>
                            <div className='form-btn-right'>
                                <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={SaveGeneralSetting}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default TabData3;