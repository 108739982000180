import React from "react";
import Loginbg from "../../assets/images/Login/login-bg.jpg";
import Logo from "../../assets/images/logo/Logo.png";
import './Login.css';
import { Link } from 'react-router-dom';
import { MdArrowBackIos } from "react-icons/md";

function ForgotPassword() {
    return (
        <div className="Login">
            <section className="vh-100" style={{ backgroundColor: '#9A616D' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-12">
                            <div className="card" style={{ borderRadius: '1rem' }}>
                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-6 d-none d-md-block">
                                        <img src={Loginbg}
                                            alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem' }} />
                                    </div>
                                    <div className="col-md-6 col-lg-6 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">
                                            <form>
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    {/* <i className="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i> */}
                                                    <span className="h1 fw-bold mb-0"><img src={Logo} alt="logo" className="logo-icon" /></span>
                                                </div>

                                                <h3 className=" login-head mb-4" style={{ letterSpacing: '1px' }}>Reset Password 🔒</h3>

                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="form2Example17">Email address</label>
                                                    <input type="email" id="form2Example17" className="form-control form-control-lg" />
                                                </div>
                                                <div className="pt-1 mb-4">
                                                    <button className="btn custom-login-btn btn-lg btn-block" type="button">Reset Password</button>
                                                    {/* <button className="btn custom-login-btn btn-lg btn-block" type="button">Sign In</button> */}
                                                </div>

                                                <p style={{ color: '#393f81', textAlign: 'center' }}>
                                                    <Link to="/" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}> <MdArrowBackIos /> Back to log in</Link></p>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ForgotPassword;