import axios from "axios";
import { API_URL } from "./config.js";

export const apiCall = (url, method, data) => {
    return axios({
        method: method,
        url: url,
        data: data,
    })
        .then((response) => {
            return { "code": response.status, "data": response.data };
        })
        .catch((error) => {
            return { "code": 0, "data": error.response.data };
        });
};






export const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/login";
}








