import React from "react";

function Testimonial({ sectiontitle, sectiondescription, ratingimage }) {
    return (
        <div className="row align-items-center testimonial">
            <div className="col-md-12">
                <div className="Rating-image">
                    {/* <img src={ratingimage} alt="rating" /> */}
                </div>
                <div className="testimonial-title">
                    <h2>{sectiontitle}</h2>
                </div>
                <div className="testimonial-description">
                    <p>{sectiondescription}</p>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;