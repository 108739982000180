import React, { createContext, useState } from 'react';

const LoginContext = createContext();

const LoginProvider = ({ children }) => {
  const [LoggedInData, setLoggedInData] = useState(null);

  const login = (data) => {
    setLoggedInData(data);
    localStorage.setItem("userdata", JSON.stringify(data));
  };

  const logout = () => {
    setLoggedInData(null);
    localStorage.removeItem("userdata");
    localStorage.removeItem("token");
  };

  const getloggedInData = () => {
    const loggedInUser = localStorage.getItem("userdata");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setLoggedInData(foundUser);
    }
  };

  React.useEffect(() => {
    getloggedInData();
  }, []);
  

  return (
    <LoginContext.Provider value={{ LoggedInData, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
