import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const SearchBox = ({ onSearch }) => {
  const [keywords, setKeywords] = useState('');

  const handleChange = (e) => {
    setKeywords(e.target.value);
  }

  const handleClick = () => {
    onSearch(keywords);
  }

  return (
    <Form.Group>
      <div className='d-flex'>
        <div addonType="prepend">
          {/* <Button type="button" color="primary" onClick={handleClick}><i className="fa fa-search"></i> Search</Button> */}
        </div>
        <Form.Control type="text" placeholder="Keywords..." onChange={handleChange} />
      </div>
    </Form.Group>
  );
}

export default SearchBox;

